import React from "react";

import { AppContext } from "../../contexts/AppContext";

import { WatchlistItem } from "./watchlist-item/WatchlistItem";
import { GetActiveAuctions } from "../../services/SearchService";
import { LoadingComponent } from "../loading/LoadingComponent";
import { Auction } from "../../services/AuctionService";
import { TablePagination, Typography } from "@material-ui/core";

export const GetWatchlist: React.FC = () => {
  const context = React.useContext(AppContext);

  const [auctionList, setAuctionList] = React.useState<Auction[]>();
  const [loading, setLoading] = React.useState(true);

  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  React.useEffect(() => {
    GetActiveAuctions(context.bidderState.bidder.watchlist)
      .then(response => {
        if (response.parsedBody !== undefined) {
          setAuctionList(response.parsedBody);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.bidderState]);

  const removeFromWatchlist = (auctionId: string) => {
    return context.bidderState.removeFromWatchlist(auctionId).then(() => {
      if (auctionList !== undefined) {
        var auctions = auctionList.filter(x => x.id !== `${auctionId}`);
        setAuctionList(auctions);
      }
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const paginate = () => {
    return auctionList ? auctionList.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize) : [];
  };

  const componentIfUndefined = <LoadingComponent label="Loading watchlist" />;

  const componentIfEmpty = (
    <Typography variant="subtitle1" component="h2" gutterBottom>
      You currently have no items on your watchlist...
    </Typography>
  );

  const componentIfWatchlist = paginate().map((item: Auction, index: number) => (
    <WatchlistItem key={item.id} auction={item} watchlistHandler={removeFromWatchlist} index={index} />
  ));

  const paginationComponent = (
    <TablePagination
      component="div"
      count={auctionList?.length ? auctionList?.length : 0}
      page={pageNumber}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" },
      ]}
      labelRowsPerPage="Rows"
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      nextIconButtonProps={{ style: { padding: 0 } }}
      backIconButtonProps={{ style: { padding: 0 } }}
    />
  );

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Watchlist
      </Typography>
      <Typography variant="subtitle1" component="h2" gutterBottom style={{ paddingBottom: "32px" }}>
        This page can be used to view all auctions you have marked to watch.
      </Typography>
      {loading && !auctionList && componentIfUndefined}
      {!loading && auctionList && auctionList.length === 0 && componentIfEmpty}
      {!loading && auctionList && auctionList.length >= 1 && paginationComponent}
      {!loading && auctionList && auctionList.length >= 1 && componentIfWatchlist}
      {!loading && auctionList && auctionList.length >= 1 && paginationComponent}
    </>
  );
};
