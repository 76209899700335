import { makeStyles, Theme } from "@material-ui/core/styles";
import * as Image from "../../../assets/hero_image.jpg";
import { MEMBER_SITE_IMAGES } from "../../../environmentVariables";

export const HeroImageNavigation = member =>
  makeStyles((theme: Theme) => ({
    root: {
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      //background: `linear-gradient(182deg,rgba(0,0,0,0.40) 85%,rgba(255,255,255,1) 85.5%),url('${MEMBER_SITE_IMAGES}${member.id}/banner.jpg'),url('${Image}')`,
      background: "linear-gradient(182deg,rgba(60,60,60,1) 85%,rgba(255,255,255,1) 85.5%)"
    },
    navigationRoot: {
      marginBottom: "32px",
      backgroundColor: theme.palette.common.white,
    },
    navigationContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "4px",
        paddingRight: "4px"
      },
    },
    contentContainer: {
      paddingTop: "32px",
      [theme.breakpoints.up("sm")]: {
        marginBottom: "75px",
      },
    },
    quickSearch: {
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",

      padding: "16px",
      textAlign: "left",
      height: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    auctionCountLoading: { marginRight: "8px" },
    heroTextContainer: {
      [theme.breakpoints.up("xs")]: {
        marginLeft: 8,
      },
    },
    heroText: {
      fontWeight: "bold",
      color: "#fff",
    },
    carouselNav: {
      color: 'transparent',
      opacity: 1,
      backgroundColor: "transparent !important", // Remove background color
      backgroundImage: 'url("data:image/svg+xml;charset=utf8,%3Csvg width=\'188\' height=\'292\' viewBox=\'0 0 188 292\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg clip-path=\'url(%23clip0_250_692)\'%3E%3Cpath d=\'M47 6.02318C68.8252 5.96767 90.1524 6.05203 111.474 5.75126C116.367 5.68224 118.213 8.45204 119.931 12.0122C128.287 29.3241 136.613 46.6507 145.003 63.9463C157.525 89.7596 170.2 115.5 182.477 141.429C183.773 144.166 183.972 148.474 182.749 151.18C175.582 167.036 167.83 182.628 160.296 198.318C148.823 222.212 137.397 246.13 125.878 270.002C123.508 274.913 120.683 279.605 118.32 284.52C116.938 287.397 114.868 288.055 111.906 288.046C81.9169 287.953 51.927 287.966 21.9372 287.95C19.0019 287.948 16.0595 287.819 13.133 287.979C6.84435 288.322 5.12854 285.875 7.93106 280.201C12.5519 270.847 17.3633 261.585 21.8814 252.182C30.8524 233.512 39.5766 214.723 48.5961 196.076C56.0192 180.73 63.8206 165.566 71.1722 150.186C72.0703 148.307 71.9736 145.232 71.0611 143.32C62.1413 124.633 52.8741 106.113 43.8553 87.4724C32.8917 64.8126 22.0518 42.0928 11.2096 19.3745C9.29093 15.3542 7.6227 11.2143 5.35559 6.02317C20.2881 6.02317 33.3941 6.02317 47 6.02318Z\' fill=\'%23EB6E14\'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=\'clip0_250_692\'%3E%3Crect width=\'188\' height=\'292\' fill=\'white\'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      width: '15px !important', // Set width
      height: '45px !important', // Set height
      borderRadius: '0',
      marginRight: '20px',
      top: 'calc(72% - 10px) !important',
      '&[aria-label="Previous"]': {
        transform: 'rotate(180deg)',
        top: 'calc(72% - 15px) !important',
        marginLeft: '20px',
        marginRight: '0px',
      }
    }
  }));
