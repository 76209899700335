import { makeStyles } from "@material-ui/core/styles";

export const BidderRegistrationStyles = makeStyles(theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  payzoneLogo: {
    height: "150px",
  },
  paymentContainer: {
    padding: "20px;",
  },
  confirmationContainer: {
    padding: "20px;",
  },
  divider: { paddingLeft: "40px", paddingRight: "0px", paddingBottom: "24px" },
  nextButton: {
    marginTop: "15px",
  },
  titleContainer: {
    padding: "40px",
    paddingBottom: "70px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },

  importantInfoContainer: {
    padding: "15px",
  },
  
  infoContainer: {
    padding: "40px",
    paddingBottom: "70px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
}));
