import React from "react";
import { IPaymentResult } from "../../interfaces/payments/IPaymentResult";
import { Paper, Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, Container, Grid, Divider } from "@material-ui/core";
import * as Styles from "./styles/PaymentFormStyles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { OrderStatus } from "../../interfaces/payments/OrderStatus";
import { OrderTypes } from "../../interfaces/payments/OrderTypes";

export const PaymentResultComponent: React.FC<{ result: IPaymentResult; crossRef: string }> = ({ result, crossRef }) => {
  const classes = Styles.PaymentFormStyles();

  const totalExclVAT = result.order?.amountExclVAT ?? 0;
  const total = result.order?.total ?? 0;
  const vat = total - totalExclVAT;

  const onClose = () => {};

  const handleClose = () => {
    if (result.order) {
      switch (result.order.orderType) {
        case OrderTypes.Subscription:
          if (result.orderStatus === OrderStatus.Succeeded) {
            window.location.href = "/Register";
          } else {
            window.location.href = "/Register?data=" + result.order.orderData;
          }
          return;
        case OrderTypes.Renewal:
          if (result.orderStatus === OrderStatus.Succeeded) {
            window.location.href = "/SubscriptionHistory";
          } else {
            window.location.href = "/Renewal?data=" + result.order.orderData;
          }
          return;
      }
    }

    window.location.href = "/";
  };

  const Icon = () => {
    switch (result.orderStatus) {
      case OrderStatus.Succeeded:
        return <CheckCircleOutlineIcon className={classes.image} />;
      default:
        return <ErrorOutlineIcon className={classes.image} />;
    }
  };
  const Title = () => {
    switch (result.orderStatus) {
      case OrderStatus.Succeeded:
        return "Payment Successful";
      case OrderStatus.Failed:
        return "Card Rejected";
      case OrderStatus.Error:
      case OrderStatus.Unknown:
        return "An error has occurred";
      default:
        return "...";
    }
  };

  const CloseButtonText = () => {
    if (result.order) {
      switch (result.order.orderType) {
        case OrderTypes.Subscription:
          return "Continue Registration";
      }
    }
    return "Ok";
  };
  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={true}>
      <DialogTitle id="customized-dialog-title">
        <div className={classes.message}>
          {Icon()}
          {Title()}
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          <strong>{result.message}</strong>
        </Typography>
        <Typography gutterBottom className={classes.wrapText}>
          If you need further advice or support regarding this transaction please contact{" "}
          <a href="mailto//:assist@salvagemarket.co.uk">assist@salvagemarket.co.uk</a> and quote the reference order number of{" "}
          {result.order?.orderId ?? crossRef}
        </Typography>
        {result.order && (
          <>
            <Typography gutterBottom>
              <strong>Original Order</strong>
            </Typography>
            <Container maxWidth="sm">
              <Paper elevation={3} className={classes.confirmSummary}>
                <Grid container className={classes.padding}>
                  <Grid item xs={12}>
                    <Typography gutterBottom align="center">
                      <strong>{result.order.orderDescription}</strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.padding}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container className={classes.padding} justify="flex-end">
                  <Grid item xs={4} md={2}>
                    Amount:
                  </Grid>
                  <Grid item xs={4} md={2} className={classes.amounts}>
                    £{totalExclVAT !== 0 ? totalExclVAT / 100 : "0.00"}
                  </Grid>
                </Grid>
                <Grid container className={classes.padding} justify="flex-end">
                  <Grid item xs={4} md={2}>
                    Vat:
                  </Grid>
                  <Grid item xs={4} md={2} className={classes.amounts}>
                    £{vat !== 0 ? vat / 100 : "0.00"}
                  </Grid>
                </Grid>
                <Grid container className={classes.padding}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container className={classes.padding} justify="flex-end">
                  <Grid item xs={4} md={2}>
                    <strong>Total:</strong>
                  </Grid>
                  <Grid item xs={4} md={2} className={classes.amounts} color="primary">
                    <strong>£{total !== 0 ? total / 100 : "0.00"}</strong>
                  </Grid>
                </Grid>
              </Paper>
            </Container>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {CloseButtonText()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
