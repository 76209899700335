import * as React from "react";
import * as Styles from "./styles/ContainerStyles";
import { Container } from "@material-ui/core";
import AuctionFeesContainer from "../information-pages/AuctionFeesContainer";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

function AuctionFees() {
  const classes = Styles.ContainerStyles();
  return (
    <Container fixed className={classes.container}>
      <AuctionFeesContainer />
    </Container>
  );
}

export default withAITracking(reactPlugin, AuctionFees, "AuctionFees");
