import React from "react";
import * as Styles from "../styles/ImportantInformationStyles";
import { Grid, Typography, Hidden, Divider, Box, List, ListItem, Button, Paper } from "@material-ui/core";

interface IProps {
  handleProceedToRegistration: () => void;
}

export const ImportantInformation: React.FC<IProps> = props => {
  const classes = Styles.ImportantInformationStyles();

  const proceedToRegistrationClicked = () => {
    props.handleProceedToRegistration();
  };

  const termsGridComponent = (position: number, copy: string) => {
    return (
      <Grid container alignItems="flex-start" justify="flex-start">
        <Grid item xs={1}>
          <Typography variant="subtitle2" color="primary" display="inline">
            {position}
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="subtitle2" color="textPrimary" display="inline">
            {copy}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper className={classes.importantInfoContainer}>
      <Grid container alignContent="center" justify="center">
        <Grid item xs={12} md={3} className={classes.titleContainer}>
          <Typography variant="h4">Registration information</Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={1} className={classes.divider}>
            <Divider orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={8} className={classes.infoContainer}>
          <Typography variant="h5" color="primary" display="block" gutterBottom>
            Thank you for registering to join our auction, to activate your account you will need to complete the following:
          </Typography>
          <List>
            <ListItem>{termsGridComponent(1, "Provide your address and a copy of your Id to validate your identity.")}</ListItem>
            <ListItem>{termsGridComponent(2, "Read and agree to the auction terms and conditions.")}</ListItem>
            <ListItem>{termsGridComponent(3, "Pay a subscription fee.")}</ListItem>
            {/* REMOVED FOR COMMERCIAL CHANGES IN CR28 */}
            {/* <ListItem>{termsGridComponent(3, "Pay an annual subscription fee of £50 for one year or £80 for two years (subject to VAT).")}</ListItem> */}
          </List>
          <Box marginTop={2}>
            <Typography variant="subtitle2" color="textPrimary" display="inline">
              As described in our T&C's you will be registered as a trade buyer.
            </Typography>
          </Box>
          <Box marginTop={1} marginBottom={4}>
            <Typography variant="subtitle2" color="textPrimary" display="inline">
              {/* REMOVED FOR COMMERCIAL CHANGES IN CR28 */}
              {/* Duplicate bidder accounts are not permitted and you may still be charged the subscription fee for registering. */}
              Duplicate bidder accounts are not permitted.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" justify="flex-end">
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => proceedToRegistrationClicked()}>
            Begin registration
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
