import React from "react";
import { useSnackbar } from "notistack";
import { Grid, Typography, Button, Container, Box, Paper, Dialog } from "@material-ui/core";
import { getFormOfId } from "./id-verification/entities/FormsOfId";
import { IVerificationSubmissionBidderDto } from "../../../interfaces/bidders/IVerificationSubmissionBidderDto";
import * as Styles from "../styles/DocumentationRemediationStyles";
import { useHistory } from "react-router-dom";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { allowedFileTypes, ISelectedFile } from "./id-verification/entities/ImageUploadStateManagement";
import { StepContainer } from "../StepContainer";
import { UploadFiles } from "../helpers/UploadFiles";
import { LoadingComponent } from "../../loading/LoadingComponent";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  documents: Array<IVerificationSubmissionBidderDto>;
  documentsSubmitted: () => void;
}

export const DocumentRemediationForm: React.FC<IProps> = ({ documents, documentsSubmitted }) => {
  const classes = Styles.DocumentationRemediationStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [replacementFiles, setReplacementFiles] = React.useState<ISelectedFile[]>([]);
  const [filesAllReplaced, setFilesAllReplaced] = React.useState(false);
  const [uploadingFiles, setUploadingFiles] = React.useState(false);

  const history = useHistory();

  const createFileWithNewName = (file: File) => {
    const newName = uuidv4() + "-" + file.name;

    return new File([file], newName, {
      type: file.type,
      lastModified: file.lastModified,
    });
  };

  const imagesUploaded = (event, documentType: string) => {
    const file: File = event.target.files[0];
    if (!allowedFileTypes.includes(file.type)) {
      window.alert("Please note we only support images being uploaded");
    } else {
      const newFile = createFileWithNewName(file);

      setReplacementFiles(existingFiles => [
        ...existingFiles.filter(p => p.formOfId?.serverKey !== documentType),
        {
          file: newFile,
          rotate: 0,
          formOfId: getFormOfId(documentType),
        },
      ]);
    }
  };
  React.useEffect(() => {
    if (documents) {
      const filesNeeded = documents!.filter(p => p.isNeedingBidderRemediation).length;
      const allUploaded = filesNeeded === replacementFiles.length;
      setFilesAllReplaced(allUploaded);
    }
  }, [documents, replacementFiles]);

  const loadingDialogContents = () => {
    return (
      <Dialog open={uploadingFiles}>
        <Grid
          direction="column"
          container
          alignItems="stretch"
          alignContent="center"
          justify="center"
          className={classes.loadingUploadingDocumentsComponent}
        >
          <LoadingComponent label="Submitting documents"></LoadingComponent>
        </Grid>
      </Dialog>
    );
  };

  const validDocument = (document: IVerificationSubmissionBidderDto) => {
    return (
      <Container fixed className={classes.padding}>
        <Paper elevation={3} className={classes.padding}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h4" className={classes.textLayout}>
                {getFormOfId(document.documentType)?.title ?? "Unknown document type!"}
                <DoneOutlineIcon className={classes.iconPosition} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <br></br>
              Original Image<br></br>
              <img style={{ width: "100px" }} alt="originalImage" src={document.url} />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  };
  const invalidDocument = (document: IVerificationSubmissionBidderDto) => {
    const replacementDocument = replacementFiles.find(p => p.formOfId?.serverKey === document.documentType);
    return (
      <Container fixed className={classes.padding}>
        <Paper elevation={3} className={document.isNeedingBidderRemediation ? classes.errorBackground : classes.padding}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h4" className={classes.textLayout}>
                {getFormOfId(document.documentType)?.title ?? "Unknown document type!"}
                <ErrorOutlineIcon className={classes.iconPosition} color="error" />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              The image you provided has been rejected because of the following reason: <strong>{document.feedbackIfInvalid}</strong>.
            </Typography>
            <Typography>Please select a new image taking into account the feedback given using the replace image button.</Typography>
          </Grid>

          <Grid container>
            <Grid item md={2} sm={4} xs={12}>
              <br></br>
              Original Image<br></br>
              <img className={!!replacementDocument ? classes.imageReplaced : ""} style={{ width: "100px" }} alt="originalImage" src={document.url} />
            </Grid>
            {replacementDocument && (
              <Grid item md={10} sm={8} xs={12}>
                <br></br>
                Replacement Image <br></br>
                <img style={{ width: "100px" }} alt="Replacement document" src={URL.createObjectURL(replacementDocument.file)} />
              </Grid>
            )}
          </Grid>

          <Grid container>
            <Grid item xs={12} className={classes.replaceImageButton}>
              <Box>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id={"image-upload-file-" + document.documentType}
                  type="file"
                  onChange={event => imagesUploaded(event, document.documentType)}
                />
                <label htmlFor={"image-upload-file-" + document.documentType}>
                  <Button variant="contained" component="span" startIcon={<AddCircleIcon />}>
                    Replace image
                  </Button>
                </label>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  };
  const documentRemediationComponents = () => {
    if (!documents) {
      return <></>;
    }
    return <>{documents.map(document => (document.isNeedingBidderRemediation ? invalidDocument(document) : validDocument(document)))}</>;
  };

  const nextClicked = async () => {
    if (replacementFiles.length > 0) {
      // Files to upload
      setUploadingFiles(true);

      const success = await UploadFiles(
        replacementFiles,
        message => enqueueSnackbar(message, { variant: "info" }),
        message => enqueueSnackbar(message, { variant: "error" }),
        false
      );

      if (success) {
        enqueueSnackbar(`All Documents uploaded successfully!`, {
          variant: "success",
        });
        documentsSubmitted();
      } else {
        enqueueSnackbar(`There was a failure uploading the documents you changed. Please try again.`, {
          variant: "error",
        });
      }
      setUploadingFiles(false);
    } else {
      history.push("/");
    }
  };
  return (
    <StepContainer
      nextButtonEnabled={filesAllReplaced && !uploadingFiles}
      title="Verification"
      subTitle="You need to update some of the submitted verification documents"
      handleNext={nextClicked}
      nextButtonText="Re-Submit Documents"
    >
      {loadingDialogContents()}
      {documentRemediationComponents()}
    </StepContainer>
  );
};
