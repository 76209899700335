import { makeStyles } from "@material-ui/core";

export const SuspensionDetailsStyles = makeStyles(theme => ({
  tableHeadCell: {
      fontWeight: 'bold',
      fontSize: 'medium',
      borderTop: "1px solid #CCC",
      borderBottom: "1px solid #CCC",
  },
  italicText: {
    fontStyle: "italic",
    color: theme.palette.secondary.dark,
  },
  td: {
    border: "none",
  },
  cellBorder:{
    borderTop: "1px solid #CCC",
    borderBottom: "1px solid #CCC",
  }
}));
