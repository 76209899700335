import { makeStyles } from "@material-ui/core";

export const ContinueRegistrationStyles = makeStyles(theme => ({
  hidden: {
    position: "absolute",
    opacity: "0",
  },
  messageContainer: {
    userSelect: "none",
    cursor: "pointer",
    position: "static",
    opacity: "1",
    backgroundColor: "rgb(33, 150, 243)",
    "&:hover": {
      backgroundColor: "rgba(33, 150, 243, 0.7)",
      transition: "background-color 0.1s ease",
    },
    transition: "opacity 0.5s linear, background-color 0.1s ease",
  },
  dangerMessageContainer: {
    userSelect: "none",
    cursor: "pointer",
    position: "static",
    opacity: "1",
    backgroundColor: "rgb(244, 67, 54)",
    "&:hover": {
      backgroundColor: "rgb(244, 67, 54, 0.7)",
      transition: "background-color 0.1s ease",
    },
    transition: "opacity 0.5s linear, background-color 0.1s ease",
  },
  textLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconPosition: {
    marginRight: "5px",
  },
}));
