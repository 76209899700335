import React from "react";
import { Grid, Typography, Container, Box } from "@material-ui/core";
import * as Styles from "../../styles/SubmitDocumentsStyles";
import { StatementRow } from "./StatementRow";
import { IAnsweredQuestions } from "./entities/FormsOfId";

interface IProps {
  registeredAsBusiness: boolean;
  ukResident: boolean;
  allQuestionsAnswered: (answers?: IAnsweredQuestions) => void;
}
export const DocumentOptionContainer: React.FC<IProps> = ({ registeredAsBusiness, ukResident, allQuestionsAnswered }: IProps) => {
  const classes = Styles.SubmitDocumentsStyles();
  const [haveLicence, setHaveLicence] = React.useState<boolean>();

  React.useEffect(() => {
    // Check that all the answers have been filled in.
    if ([haveLicence].some(p => p === undefined)) {
      allQuestionsAnswered();
      return;
    }

    const answers: IAnsweredQuestions = {
      hasLicense: haveLicence!,
      registeredAsBusiness: registeredAsBusiness,
      ukResident: ukResident,
    };

    allQuestionsAnswered(answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveLicence]);

  const questions = [
    {
      copy: "Do you have a driving license:",
      updateFunction: setHaveLicence,
      visibleFunction: () => true,
    },
  ];

  return (
    <Container className={classes.questionsContainer}>
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Grid item>
          <Box marginTop={4}>
            <Typography variant="h6" align="left" gutterBottom>
              Please answer the following question
            </Typography>
          </Box>
        </Grid>
        {questions
          .filter(p => p.visibleFunction())
          .map((question, index) => {
            return (
              <Grid key={question.copy}>
                <StatementRow {...question} />
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
};
