import * as React from "react";
import { useSnackbar } from "notistack";
import { FeaturedAuctionList } from "../auction-listings/FeaturedAuctionList";
import { Typography, Fab, Box } from "@material-ui/core";
import { AppContext, IInitialState } from "../../contexts/AppContext";
import FeaturedStyles from "./styles/FeaturedStyles";
import { LoadingComponent } from "../loading/LoadingComponent";
import { FeaturedAuction, useFeaturedAuction } from "../../services/AuctionService";

export function Featured() {
  const classes = FeaturedStyles();
  const { enqueueSnackbar } = useSnackbar();

  const pageSize = 20;

  const [searchResults, setSearchResults] = React.useState<FeaturedAuction[]>();
  const [page, setPage] = React.useState(0);

  const [featuredAuction, featuredAuctionLoading] = useFeaturedAuction(page, pageSize, () => {
    enqueueSnackbar("There was a problem loading the featured auction, please try again.", { variant: "error" });
  });

  const loadMore = () => {
    setPage(page + 1);
  };

  React.useEffect(() => {
    if (featuredAuction && searchResults) {
      setSearchResults(searchResults.concat(featuredAuction.results));
    } else if (featuredAuction) {
      setSearchResults(featuredAuction.results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuredAuction]);

  return (
    <AppContext.Consumer>
      {(context: IInitialState) => (
        <div>
          {searchResults !== undefined && (
            <>
              <Typography gutterBottom variant="h4" align="left">
                Featured Listings
              </Typography>
              <Box className={classes.featuredContainer}>
                <FeaturedAuctionList results={searchResults} context={context} />
              </Box>
            </>
          )}
          {featuredAuctionLoading && <LoadingComponent label="Loading Featured Auctions" />}
          {!featuredAuctionLoading && featuredAuction?.isMore && (
            <Box className={classes.showMoreContainer}>
              <Fab color="primary" variant="extended" aria-label="Load more" onClick={() => loadMore()}>
                Load more
              </Fab>
            </Box>
          )}
        </div>
      )}
    </AppContext.Consumer>
  );
}
