import React from "react";
import * as Styles from "./styles/ContainerStyles";
import { useSnackbar } from "notistack";
import { Container, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Hidden, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, TextField, Box, DialogActions, Grid } from "@material-ui/core";
import { DateToDateString, DateToDateTimeString } from "../../helpers/dateHelper";
import { currencyFormat } from "../../helpers/text-format/TextFormat";
import { LoadingComponent } from "../loading/LoadingComponent";
import { SecureArea } from "../authentication/SecureArea";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";
import { ReceiptData, useAllReceiptsForBidder } from "../../services/ReceiptService";
import { Link } from "react-router-dom";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useHistory } from "react-router-dom";
import { DefaultIssueRefundDto, IssueRefundDto } from "../../interfaces/bidders/IssueRefundDto";
import { postRequestRefund } from "../../services/StripeService";
import { AppContext } from "../../contexts/AppContext";
import { SubscriptionExpiry } from "../my-account/SubscriptionExpiry";
import moment from "moment";
import { GetSubscriptionStatus } from "../my-account/helpers/GetSubscriptionStatus";
import { BidderStatus } from "../../interfaces/bidders/IBidder";
import { canRenew } from "../../contexts/canRenew";

const SubscriptionHistory: React.FC = () => {
  const classes = Styles.ContainerStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const context = React.useContext(AppContext);
  const [canRenewSubscription, setCanRenewSubscription] = React.useState(false);

  const [receipts, loadingReceipts] = useAllReceiptsForBidder(() => {
    enqueueSnackbar("There was a problem loading your subscription history, please try again.", { variant: "error" });
  });


  const [selectedReceiptData, setSelectedReceiptData] = React.useState<ReceiptData>();
  const [showRefundModal, setShowRefundModal] = React.useState<boolean>(false);
  const [performingAction, setPerformingAction] = React.useState<boolean>(false);
  const [issueRefundDto, setIssueRefundDto] = React.useState<IssueRefundDto>(DefaultIssueRefundDto);
  const [refundReasonValidation, setRefundReasonValidation] = React.useState<string>("");
  const requestRefund = (receipt: ReceiptData) => {
    setSelectedReceiptData(receipt);
    issueRefundDto.orderId = receipt.id;
    setIssueRefundDto(issueRefundDto);
    setShowRefundModal(true);
  }

  const handleChange = (dto, setState: (value: any) => void) => prop => event => {
    setState({ ...dto, [prop]: event.target.value });
  }

  const createRefundRequest = async () => {
    // if (issueRefundDto.reason.length < 1) {
    //   setRefundReasonValidation("You must enter a refund reason");
    //   return;
    // }
    // setRefundReasonValidation("");
    setPerformingAction(true);
    try {
      await postRequestRefund(issueRefundDto);

      if (selectedReceiptData != undefined && selectedReceiptData != null && receipts != undefined && receipts != null) {
        selectedReceiptData.orderCurrentStatus = 'Refund Requested';
        let idx = receipts.findIndex(o => o.id == selectedReceiptData.id);

        if (idx > -1) {
          receipts[idx] = selectedReceiptData;
        }
      }

      enqueueSnackbar("Successfully requested refund.", { variant: "info" });
      setIssueRefundDto(DefaultIssueRefundDto);
    } catch {
      enqueueSnackbar("Something went wrong whilst trying to request the refund. Please try again.", { variant: "error" });
    } finally {
      setPerformingAction(false);
      setShowRefundModal(false);
    }
  };

  const friendlyStatusName = (status: string) => {
    switch (status) {
      case 'RefundRequested':
        return 'Refund Requested';
        break;
      case 'Refund Rejected':
      case 'RefundRejected':
        return 'Refund Rejected';
        break;
      default:
        return status;
        break;
    }
  }


  React.useEffect(() => {
    setCanRenewSubscription(
      (context.bidderState.bidder.bidderStatus === BidderStatus.ActiveFull && canRenew(context.bidderState.bidder.subscriptionEndDate)) ||
      context.bidderState.bidder.bidderStatus === BidderStatus.ActiveFree ||
      context.bidderState.bidder.bidderStatus === BidderStatus.SubscriptionExpired
    );
  }, []);


  return (
    <SecureArea>


      <Dialog

        open={showRefundModal}
        onClose={() => setShowRefundModal(false)}
        maxWidth="md"
        PaperProps={{
          style: {
            maxWidth: "623px",
            width: "100%",
          },
        }}
      >
        <DialogTitle
          style={{
            border: "1px solid #CCCCCC",
          }}
          id="form-dialog-title">Refund Request</DialogTitle>

        <DialogContent
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            paddingLeft: "23px",
            paddingRight: "23px",
          }}
        >
          <DialogContentText>
            Are you sure you wish to request a refund?
          </DialogContentText>
          <DialogContentText>
            We aim to respond to all refund requests within 2 working days.
          </DialogContentText>

          {/* <FormControl fullWidth>
            <TextField
              label="Refund Reason"
              value={issueRefundDto.reason}
              onChange={handleChange(issueRefundDto, setIssueRefundDto)("reason")}
              required
              error={refundReasonValidation.length > 0}
              helperText={refundReasonValidation}
            />
          </FormControl> */}
        </DialogContent>
        <DialogActions
          style={{
            padding: "0 23px 40px 23px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#FFFFFF",
              width: "50%",
            }}
            onClick={createRefundRequest} variant="contained" disabled={performingAction}>
            CONFIRM REFUND REQUEST
          </Button>
          <Button style={{ width: "50%", }} color="primary" onClick={() => setShowRefundModal(false)} disabled={performingAction} variant="contained">
            BACK TO SUBSCRIPTIONS
          </Button>

        </DialogActions>
      </Dialog>




      <Container fixed className={classes.container}>
        <Grid container spacing={1} style={{ marginBottom: "15px" }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" component="h1" gutterBottom>
              Subscription History
            </Typography>
            <Typography variant="subtitle1" component="h2" gutterBottom>
              This page can be used to view your subscription history.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Box bgcolor="#EBEBEB" borderRadius={5} p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">End Date</Typography>
                      <Typography variant="body1">{moment(context.bidderState.bidder.subscriptionEndDate).format("DD/MM/YYYY")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">Status</Typography>
                      <Typography variant="body1">{GetSubscriptionStatus(context.bidderState.bidder.subscriptionEndDate, context.bidderState.bidder.accountType)}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} container alignItems="center">
                <Button className={classes.button} variant="contained" color="primary" component={Link} to="/Renewal" disabled={!canRenewSubscription}>
                  Make a subscription payment
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>


        {loadingReceipts && receipts === null && <LoadingComponent label="Loading subscriptions" />}
        {!loadingReceipts && receipts && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Purchase Date</TableCell>
                  <Hidden smDown>
                    <TableCell>Status</TableCell>
                  </Hidden>
                  <TableCell>Description</TableCell>
                  <Hidden smDown>
                    <TableCell>Amount (Excl VAT)</TableCell>
                  </Hidden>
                  <TableCell>Amount (Inc VAT)</TableCell>
                  <TableCell>Receipt</TableCell>
                  <TableCell>Refund Receipt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receipts.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Typography variant="subtitle1" component="h2">
                        You have no subscriptions.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {receipts?.map((receipt, index) => (
                  <TableRow key={index}>
                    <TableCell>{DateToDateTimeString(receipt.saleDate)}</TableCell>
                    <Hidden smDown>
                      <TableCell>{friendlyStatusName(receipt.orderCurrentStatus)}</TableCell>
                    </Hidden>
                    <TableCell>{receipt.orderDescription}</TableCell>
                    <Hidden smDown>
                      <TableCell>{currencyFormat(receipt.totalExVat / 100)}</TableCell>
                    </Hidden>
                    <TableCell>{currencyFormat(receipt.totalIncVat / 100)}</TableCell>
                    <TableCell>
                      {/* <Button
                        component={Link}
                        to={`/Payments/Document/${receipt.id}`}
                        disabled={receipt.orderStatus === "Pending" || receipt.orderStatus === "Error" || receipt.orderStatus === "Failed"}
                      >
                        <GetAppIcon />
                      </Button> */}


                      <Button color="primary" variant="contained" onClick={() => { window.open(`/Payments/Document/${receipt.id}`, '_blank', 'noopener,noreferrer'); }}>
                        DOWNLOAD
                      </Button>
                    </TableCell>
                    <TableCell>

                      {receipt.orderCurrentStatus === "Refunded" && (

                        <Button color="primary" variant="contained" onClick={() => { window.open(`/Payments/Document/${receipt.id}/status/${receipt.orderCurrentStatus}`, '_blank', 'noopener,noreferrer'); }}>
                          DOWNLOAD
                        </Button>
                      )}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </SecureArea>
  );
};

export default withAITracking(reactPlugin, SubscriptionHistory, "SubscriptionHistory");
