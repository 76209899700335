import { makeStyles } from "@material-ui/core/styles";

export const MobileMediaRenderStyles = makeStyles(theme => ({
  playerWrapper: { position: "relative", paddingTop: "75%" },
  image: {
    width: "100%",
  },
  player: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));
