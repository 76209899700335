import React from "react";
import { SvgIcon } from "@material-ui/core";
import * as Styles from "./styles/EditorPicksArrowsStyles";

interface CustomArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<any>;
  currentSlide?: number;
  slideCount?: number;
}

export const NextArrow: React.FC<CustomArrowProps> = CustomArrowProps => {
  const classes = Styles.EditorPicksArrowsStyles();
  return (
    <div
      className={CustomArrowProps.className}
      onClick={CustomArrowProps.onClick}
    >
      <SvgIcon className={classes.svgIcon}>
        <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
      </SvgIcon>
    </div>
  );
};

export const PrevArrow: React.FC<CustomArrowProps> = CustomArrowProps => {
  const classes = Styles.EditorPicksArrowsStyles();
  return (
    <div
      className={CustomArrowProps.className}
      onClick={CustomArrowProps.onClick}
    >
      <SvgIcon className={classes.svgIcon}>
        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
      </SvgIcon>
    </div>
  );
};
