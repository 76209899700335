import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import { Auction, useAuction } from "../../services/AuctionService";
import { useDataScrapingMonitorEncryptedData } from "../../services/DataScrapingMonitorService";
import VehicleViewLot from "./vehicle-lot/VehicleViewLot";
import { LoadingComponent } from "../loading/LoadingComponent";
import { IMsalContext } from "../../authentication/MsalContext";
import { useMsal } from "../../authentication/MsalProvider";

interface VehicleAuctionContainerProps {
  auctionId: string;
}

const ViewLotComponent: React.FC<VehicleAuctionContainerProps> = ({ auctionId }) => {
  const msal: IMsalContext = useMsal();
  const { enqueueSnackbar } = useSnackbar();
  
  const [auction, auctionLoading] = useAuction(auctionId, msal.accounts.length > 0, () => {
    enqueueSnackbar('There was a problem loading the auction, please try again.', { variant: "error" });
  });
  
  const encryptedData = auction?.metadata.encryptedData;
  const [regNumber, isRegNumberLoading] = useDataScrapingMonitorEncryptedData(encryptedData, () => {
     enqueueSnackbar('There was a problem loading the registration number, please try again.', { variant: "error" });
  });
  
  const [auctionWithReg, setAuctionWithReg] = useState<Auction>();

  useEffect(() => {
    if (auction && regNumber) {
      setAuctionWithReg({...auction, metadata: {...auction.metadata, registrationNumber: regNumber}});
    }
    if (auction && !regNumber) {
      setAuctionWithReg({...auction});
    }
  }, [auction, regNumber]);
  
  if (!auctionWithReg || auctionLoading || isRegNumberLoading) {
    return <LoadingComponent label="Loading Auction" />;
  }
  
  return <VehicleViewLot auction={auctionWithReg} />;
};

export default ViewLotComponent;
