import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
const success = green[500];
const borderRadius = "8px";

export const WatchlistBidGridStyles = makeStyles(theme => ({
  root: {
    paddingRight: "4px",
    backgroundColor: theme.palette.secondary.light,
    height: "100%",
    borderLeft: "solid",
    borderColor: theme.palette.secondary.main,
    borderWidth: "1px",
  },
  bidStatusText: { height: "44px", marginBottom: "0px", marginTop: "0px", paddingTop: "6px" },
  outBidCard: {
    background: theme.palette.error.main,
    width: "100%",
    boxShadow: "none",
    borderRadius: borderRadius,
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "0",
  },
  biddingClosedCard: {
    background: theme.palette.error.main,
    width: "100%",
    boxShadow: "none",
    borderRadius: borderRadius,
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "0",
  },
  bidHistory: { margin: "34px 0 0 0", color: theme.palette.secondary.dark },
  winningCard: {
    background: success,
    width: "100%",
    boxShadow: "none",
    borderRadius: borderRadius,
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "0",
  },
  loggedOutCard: {
    background: theme.palette.common.black,
    width: "100%",
    boxShadow: "none",
    borderRadius: borderRadius,
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "0",
  },
  divider: {},
  bidContainer: {
    textAlign: "center",
    margin: 0,
    width: "100%",
  },
  bidStatus: {
    color: theme.palette.common.white,
    margin: "0px",
  },
  submitBid: {
    paddingLeft: "11px",
    paddingRight: "10px",
    background: theme.palette.secondary.light,
    padding: "0px",
  },
  currentBid: {
    background: theme.palette.secondary.light,
    marginBottom: "0px",
  },
  currentBidNoProxy: {
    background: theme.palette.secondary.light,
    padding: "12px 6px 0px 6px",
    marginBottom: "0px",
  },
  currentBidContainer: {
    background: theme.palette.secondary.light,
    height: "84px",
  },
  bidVat: { fontWeight: "bolder", fontSize: "14px", padding: "0", margin: "2px" },
  currentProxyBid: {
    display: "block",
    fontSize: "11px",
    fontWeight: "bolder",
  },
  bidIncrements: {
    background: theme.palette.secondary.light,
  },
  skeleton: { marginTop: "26px", marginBottom: "26px" },
  time: {
    background: theme.palette.common.white,
    color: theme.palette.secondary.dark,
    textAlign: "left",
  },
  watchlist: {
    background: theme.palette.common.white,
    color: theme.palette.secondary.dark,
    textAlign: "right",
    justifyContent: "flex-end",
    flex: 1,
  },
  reserveMet: {
    color: "white",
    background: theme.palette.success.main,
    borderRadius: "5px",
    lineHeight: "26px",
    margin: "28px 10px 0 0",
  },
  reserveNotMet: {
    color: "white",
    background: theme.palette.error.main,
    borderRadius: "5px",
    lineHeight: "26px",
    margin: "28px 10px 0 0",
  },
  padding: {
    height: "86px",
    padding: "0 4px 0 4px",
  },
}));
