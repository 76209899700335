import React from "react";
import { Container, Typography } from "@material-ui/core";
import * as Styles from "./styles/ContainerStyles";

export function AuthenticationInProgress() {
  const classes = Styles.ContainerStyles();
  return (
    <Container fixed className={classes.container}>
      <Typography variant="subtitle1" color="textPrimary" gutterBottom>
        Authenticating...
      </Typography>
    </Container>
  );
}
