import * as React from "react";
import { Box } from "@material-ui/core";

import * as Styles from "./styles/NavbarAuthenticationStyles";

import { LoggedOutButton } from "./LoggedOutButton";
import { LoggedInButton } from "./LoggedInButton";
import { IMsalContext } from "../../authentication/MsalContext";
import { useMsal } from "../../authentication/MsalProvider";

export const NavbarAuthentication: React.FC = () => {
  const classes = Styles.NavbarAuthenticationStyles();
  const msal: IMsalContext = useMsal();

  return (
    <Box maxWidth="xs" className={classes.authentication}>
      {msal.accounts.length > 0 && <LoggedOutButton />}
      {msal.accounts.length === 0 && <LoggedInButton />}
    </Box>
  );
};
