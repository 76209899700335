import { makeStyles, lighten } from "@material-ui/core/styles";

export const BidIncrementStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.common.white,
    disabledColor: theme.palette.common.white,
    background: `${theme.palette.common.black} !important`,
    "@media (hover: hover)": {
      "&:hover": {
        background: lighten(theme.palette.common.black, 0.45),
      },
    },
    borderRadius: "5px",
  },
  disabledButton: {
    color: theme.palette.secondary.main,
    background: `${theme.palette.secondary.dark} !important`,
    "@media (hover: hover)": {
      "&:hover": {
        background: lighten(theme.palette.secondary.dark, 0.45),
      },
    },
    borderRadius: "5px",
  },
  padding: {
    padding: "10px",
  },
}));
