import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { IValidation } from "../../../interfaces/IValidation";
import { IBusiness, isValid as businessIsValid } from "../../../interfaces/bidders/IBusiness";
import { isError, ErrorMessage } from "../helpers/ErrorHandlers";

interface IProps {
  validation: IValidation;
  business: IBusiness;
  onBusinessChange: React.Dispatch<React.SetStateAction<IBusiness>>;
  handleBusinessChanges: (business: IBusiness) => void;
  isValid: (isValid: boolean) => void;
}

export const BusinessDetails: React.FC<IProps> = ({ validation, business, onBusinessChange, handleBusinessChanges, isValid }) => {
  const onBusinessFieldChange = (e: any) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;

    onBusinessChange(b => ({
      ...b,
      [fieldName]: fieldValue,
    }));
    handleBusinessChanges(business);
  };

  React.useEffect(() => {
    isValid(businessIsValid(business));
  }, [isValid, business]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            error={isError("Business Name", validation)}
            helperText={ErrorMessage("Business Name", validation)}
            name="businessName"
            label="Business Name"
            value={business.businessName}
            onChange={value => onBusinessFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            error={isError("Registration Number", validation)}
            helperText={ErrorMessage("Registration Number", validation)}
            name="registrationNumber"
            label="Business Registration Number"
            value={business.registrationNumber}
            onChange={value => onBusinessFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            error={isError("VAT Number", validation)}
            helperText={ErrorMessage("VAT Number", validation)}
            name="vatNumber"
            label="VAT Number"
            value={business.vatNumber}
            onChange={value => onBusinessFieldChange(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={isError("ATF Number", validation)}
            helperText={ErrorMessage("ATF Number", validation)}
            name="atfNumber"
            label="ATF Number"
            value={business.atfNumber}
            onChange={value => onBusinessFieldChange(value)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
