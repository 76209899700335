import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const TitleContainerStyles = makeStyles((theme: Theme) => ({
  category: {
    borderRadius: "5px",
  },
  h5Grey: {
    color: theme.palette.secondary.dark,
    margin: "4px 0 4px 0",
  },
  title: { marginBottom: "4px" },
  image: {
    width: "100%",
  },
  gridItem: { padding: "4px 0 4px 0" },
}));
