import * as React from "react";

import Facebook from "@material-ui/icons/Facebook";
// import Twitter from "@material-ui/icons/Twitter"; // Remove this line
import LinkedIn from "@material-ui/icons/LinkedIn";

import moment from "moment";
import { Grid, Container, Typography, Link, Fab, Box } from "@material-ui/core";

import * as styles from "./styles/FooterStyles";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";
import { IOpeningTime, OpeningTimesStatus } from "../../interfaces/IOpeningDays";
import { LinkLocation, MemberSite } from "../../services/MemberService";
import { StyleContext } from "../../contexts/StyleContext";
import logo from "../../assets/logo-white.png";
import twitterLogo from "./../../assets/twitter-x.svg"; 
import { MEMBER_SITE_IMAGES } from "../../environmentVariables";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

const Footer: React.FC = () => {
  const classes = styles.FooterStyles();

  const context = React.useContext(StyleContext);

  const member = context.theme;

  const [site, setSite] = React.useState<MemberSite>();

  React.useEffect(() => {
    setSite(
      member.sites.filter(element => {
        return element.isMainSite;
      })[0]
    );
  }, [member]);

  const getCopyright = () => {
    return `${new Date().getFullYear()} Copyright: All rights reserved.`;
  };

  const getOpeningTime = (openingTime: IOpeningTime | undefined) => {
    if (openingTime === undefined) {
      return "";
    } else if (openingTime.openingTimeStatus === OpeningTimesStatus.Open) {
      return `${moment(openingTime.open).format("HH:mm")} - ${moment(openingTime.close).format("HH:mm")}`;
    } else {
      return "Closed";
    }
  };

  const footers = member.memberLinks?.filter(p => p.linkLocation === LinkLocation.footer).splice(0, 6) ?? [];

  return (
    <footer>
      <div className={classes.root}>
        <Container maxWidth={"lg"} fixed style={{ fontSize: "12px" }}>
          <Grid container spacing={3}>
            <Grid className={classes.container} item xs={12} sm={6} md={6} lg={3}>
              <object className={classes.logo} data={`${MEMBER_SITE_IMAGES}${member.id}/logo.png`} type="image/png">
                <img src={logo} className={classes.logo} alt="logo" />
              </object>
              <Typography className={classes.containerTitle} variant="subtitle1" align="left">
                {member?.name}
              </Typography>
              <Typography variant="body1" align="left" className={classes.containerItem}>
                {site?.location.line1}
              </Typography>
              <Typography variant="body1" align="left" className={classes.containerItem}>
                {site?.location.line2}
              </Typography>
              <Typography variant="body1" align="left" className={classes.containerItem}>
                {site?.location.line3}
              </Typography>
              <Typography variant="body1" align="left" className={classes.containerItem}>
                {site?.location.city}
              </Typography>
              <Typography variant="body1" align="left" className={classes.containerItem}>
                {site?.location.county}
              </Typography>
              <Typography variant="body1" align="left" className={classes.containerItem}>
                {site?.location.postcode}
              </Typography>
              {!member.isE2E && (
                <Typography variant="body1" align="left" className={classes.containerItem}>
                  {member?.phoneNumber}
                </Typography>
              )}
            </Grid>
            <Grid className={classes.container} item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.containerTitle} variant="subtitle1" align="left">
                Opening times
              </Typography>

              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    Monday
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    {getOpeningTime(site?.openingDays.monday)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    Tuesday
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    {getOpeningTime(site?.openingDays.tuesday)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    Wednesday
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    {getOpeningTime(site?.openingDays.wednesday)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    Thursday
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    {getOpeningTime(site?.openingDays.thursday)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    Friday
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    {getOpeningTime(site?.openingDays.friday)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    Saturday
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    {getOpeningTime(site?.openingDays.saturday)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    Sunday
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.containerItemGrey} align="left">
                    {getOpeningTime(site?.openingDays.sunday)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.container} item xs={12} sm={6} md={6} lg={3}>
              {footers.length > 0 && (
                <>
                  <Typography className={classes.containerTitle} variant="subtitle1" align="left">
                    Links
                  </Typography>
                  {footers.map(p => (
                    <a
                      href={p.url}
                      key={p.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                    >
                      <Typography className={classes.containerItemGrey} align="left">
                        {p.name}
                      </Typography>
                    </a>
                  ))}
                </>
              )}
            </Grid>

            <Grid className={classes.container} item xs={12} sm={6} md={6} lg={footers.length > 0 ? 2 : 3}>
              {(member.socialFacebook || member.socialTwitter || member.socialLinkedIn) && (
                <>
                  <Typography className={classes.containerItemGrey} align="left">
                    Follow us
                  </Typography>
                  <Box className={classes.socialContainer}>
                    {member.socialFacebook && (
                      <Fab href={member.socialFacebook} color="primary" size={"small"} aria-label="Facebook" className={classes.social}>
                        <Facebook />
                      </Fab>
                    )}
                    {member.socialTwitter && (
                      <Fab href={member.socialTwitter} color="primary" size={"small"} aria-label="Twitter" className={classes.social}>
                        {/* Replace the Twitter icon with the new logo */}
                        <img src={twitterLogo} alt="Twitter" className={classes.socialIcon} />
                      </Fab>
                    )}
                    {member.socialLinkedIn && (
                      <Fab href={member.socialLinkedIn} color="primary" size={"small"} aria-label="LinkedIn" className={classes.social}>
                        <LinkedIn />
                      </Fab>
                    )}
                  </Box>
                </>
              )}
              <Typography className={classes.containerItemGrey} align="left">
                &copy; {getCopyright()}
              </Typography>
              <Typography className={classes.containerItemGrey} align="left">
                {"Powered by "}
                <Link href={"https://www.e2etotalloss.com/"}>e2e</Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.versionContainer}>
        <p className={classes.versionText}>{ClientSettings.Version}</p>
      </div>
    </footer>
  );
};

export default Footer;
