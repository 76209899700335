import { makeStyles, darken } from "@material-ui/core/styles";

export const WatchlistItemStyles = makeStyles(theme => ({
  metricsColumn: {
    width: "100%",
  },
  noLinkDecoration: {
    textDecoration: "none",
    color: "inherit"
  },
  metricsColumnItem: {
    marginBottom: "5px",
  },
  metricsValue: {
    marginLeft: "5px",
  },
  divider: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  category: {
    borderRadius: "6px",
  },
  itemContainer: {
    border: "2px solid",
    borderRadius: "16px;",
    marginBottom: "20px",
    overflow: "hidden",
  },
  updatingWatchlist: {
    marginTop: "90px",
    marginBottom: "90px",
  },
  watchingContainerBorder: {
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 0px 0px 2px " + theme.palette.primary.main,
    borderWidth: "0px",
  },
  notWatchingContainerBorder: {
    borderColor: theme.palette.secondary.main,
    boxShadow: "0px 0px 0px 2px rgba(145, 145, 145, 0.65);",
    borderWidth: "0px",
  },
  auctionImage: {
    borderTopLeftRadius: "15px;",
    borderBottomLeftRadius: "15px",
  },
  imageContainer: {
    width: "100%",
  },
  mainDetailsContainer: {
    padding: "20px;",
  },
  watching: {
    color: theme.palette.secondary.main,
    background: `linear-gradient(-45deg, transparent 50%, ${theme.palette.primary.main} 50.5%)`,
  },
  notWatching: {
    color: theme.palette.primary.main,
    background: `linear-gradient(-45deg, transparent 50%, ${theme.palette.secondary.main} 50.5%)`,
    overflow: "hidden",
  },
  watchingContainer: {
    display: "inline-block",
    height: "60px",
    width: "60px",
  },
  memberImageContainer: {
    marginTop: "8px",
    marginBottom: "8px",
  },
  button: {
    color: theme.palette.common.white,
    disabledColor: theme.palette.common.white,
    background: theme.palette.primary.main,
    borderRadius: "5px",
    padding: "8px 9px",
    "&:hover": {
      background: darken(theme.palette.primary.main, 0.15),
    },
  },
  lotDetailsContainer: { position: "relative" },
  lotDetailsAmountContainer: {
    margin: "auto",
    minHeight: "32px",
    bottom: "0",
    paddingBottom: "8px",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
    },
  },
  lotDetailsAmountItem: {
    margin: "auto",
  },
  altBackground: {
    backgroundColor: "#e4e4e4",
  },
  auctionTagItem: {
    marginTop: "10px",
    marginRight: "25px",
  },
}));
