import { makeStyles } from "@material-ui/core/styles";

export const AuctionTimeStyles = makeStyles((theme) => ({
  root: {
    verticalAlign: "top",
  },
  tabContainer: {
    marginTop: "40px",
  },
  tableContainer: {
    margin: "24px 0 24px 0",
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
    marginRight: "16px",
  },
  sellerIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
    marginRight: "16px",
    height: "20px",
    width: "auto",
  },
  sellerContainer: {
    display: "flex",
    alignItems: "center",
  },
}));
