import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Container } from "@material-ui/core";
import * as Styles from "./styles/ContainerStyles";
import ViewLotComponent from "../view-lot/ViewLotComponent";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

type TParams = { id: string };

function LotViewPage({ match }: RouteComponentProps<TParams>) {
  const classes = Styles.ContainerStyles();

  return (
    <Container fixed className={classes.container}>
      <ViewLotComponent auctionId={match.params.id} />
    </Container>
  );
}

export default withAITracking(reactPlugin, LotViewPage, "LotViewPage");
