import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SalesDocumentContainer } from "../sales-document/SalesDocumentContainer";
import { SecureArea } from "../authentication/SecureArea";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

type TParams = { id: string };

function SalesDocument({ match }: RouteComponentProps<TParams>) {
  return (
    <SecureArea>
      <SalesDocumentContainer auctionId={match.params.id} />;
    </SecureArea>
  );
}

export default withAITracking(reactPlugin, SalesDocument, "SalesDocument");
