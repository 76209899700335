import React from "react";
import { TableContainer, Table, TableRow, TableCell, TableBody, Link } from "@material-ui/core";
import { ISuspension } from "../../interfaces/bidders/ISuspension";
import { DateTimeFormat } from "../../helpers/dateHelper";
import * as Styles from "./styles/SuspensionDetailsStyles";
import { suspensionStatusDescription } from "../../enums/SuspensionTypeStatus";

import { Link as RouterLink } from "react-router-dom";

interface SuspensionsDetailsProps {
  suspensions: ISuspension[];
}

const SuspensionDetails = ({ suspensions }: SuspensionsDetailsProps) => {
  const classes = Styles.SuspensionDetailsStyles();
  const key = "memberId";

  const memberList = suspensions?.map(({ memberId, memberName, memberEmail, memberPhoneNumber }) => ({
    memberId,
    memberName,
    memberEmail,
    memberPhoneNumber,
  }));

  const arrayUniqueByKey = [...new Map(memberList?.map(item => [item[key], item])).values()];

  if (suspensions?.length > 0) {
    return (
      <TableContainer>
        <h4 className={classes.italicText}>Please contact the seller that has suspended your account to resolve the issues listed below.</h4>
        <Table aria-label="simple table">
          <TableBody>
            {arrayUniqueByKey?.map(member => (
              <>
                <TableRow key={member.memberId}>
                  <TableCell className={classes.tableHeadCell}>{member.memberName}</TableCell>
                  <TableCell className={classes.cellBorder}>
                    Email: {<Link href={`mailto:${member.memberEmail}`}>{member.memberEmail}</Link>}
                  </TableCell>
                  <TableCell className={classes.cellBorder}>
                    Tel:{" "}
                    {
                      <Link href={`tel:${member.memberPhoneNumber}`} color="primary">
                        {member.memberPhoneNumber}
                      </Link>
                    }
                  </TableCell>
                  <TableCell className={classes.cellBorder} />
                </TableRow>
                {suspensions
                  .filter(m => m.memberId === member.memberId)
                  ?.map(suspension => (
                    <TableRow key={suspension.id}>
                      <TableCell className={classes.td}>{suspensionStatusDescription(suspension.suspensionType)}</TableCell>
                      <TableCell className={classes.td}>{suspension.reason}</TableCell>
                      <TableCell className={classes.td}>{DateTimeFormat(suspension.timestamp)}</TableCell>
                      {suspension.auctionId && (
                        <TableCell className={classes.td}>
                          <Link component={RouterLink} to={`/Lot/${suspension.auctionId}`}>
                            {`${suspension.lotRegistrationNumber ? suspension.lotRegistrationNumber + " - " : ""}${suspension.lotTitle}`}
                          </Link>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <h4 className={classes.italicText}>No suspensions against your account.</h4>;
  }
};

export default SuspensionDetails;
