import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useVersionedData } from "./useVersionedData";
import { ILatestBid, ILatestBidHistoryInfo, isBidderWinning, IAuctionInfo } from "../interfaces/auctions/ILatestBid";
import { IInitialState } from "../contexts/AppContext";
import { SignalRHub, useSignalR, useSignalrLatestBid } from "../components/view-lot/hooks/useSignalR";
import React from "react";
import { useIsActiveOrMember } from "../helpers/ActiveUserHelper";
import { ApiService, HttpResponse } from "./ApiService";
import { IBidResponse } from "../interfaces/auctions/IBidResponse";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

const useBid = (auctionId: string, connectionId: string | null | undefined, onError: (httpStatusCode: number, resultBody?: string) => void) => {
  const isActive = useIsActiveOrMember();

  return useVersionedData<ILatestBid>(
    `${isActive ? ClientSettings.AuctionPrivateApiUrl : ClientSettings.AuctionPublicApiUrl}/auctions/id/${auctionId}/latestbid${isActive && connectionId != null ? `/${connectionId}` : ''}`,
    onError
  );
};

export const useBidHistory = (auctionId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useVersionedData<ILatestBidHistoryInfo[]>(`${ClientSettings.AuctionPublicApiUrl}/auctions/id/${auctionId}/bids`, onError);
  
export const useBidWithPostAndSignalR = (
  context: IInitialState,
  auctionId: string,
  onError: (httpStatusCode: number, resultBody?: string) => void,
  auctionUpdated: (updatedAuction: IAuctionInfo) => void
): [ILatestBid | null, boolean, boolean, () => Promise<void>, (auctionId: string, amount: number) => Promise<boolean>] => {
  // Getting latest bid data.
  const [latestBidMaster, setLatestBidMaster] = React.useState<ILatestBid | null>(null);
  const [bidProcessing, setBidProcessing] = React.useState(false)
  const [latestBidSignalR, connectionId] = useSignalrLatestBid(auctionId);
  const [latestBidApi, , isFetching, , , refreshLatestBidApi] = useBid(auctionId, connectionId, onError);

  // Receiving asynchronous errors back.
  const bidResult = useSignalR(SignalRHub.WebsiteBidResult)();
  const [bidNonce, setBidNonce] = React.useState<string | undefined>();

  const bidderId = context.bidderState?.bidder?.id;

  // Process SignalR latest bid. Update Master if SignalR is more recent.
  React.useEffect(() => {
    if (!latestBidSignalR) return;

    // SignalR doesn't know who the bidder is so can't report if they have ever bid on this auction.
    // Instead used the latest bid master which is the bid object retrieved using the authenticated bid api.
    // This API does know if you have bid or not.
    latestBidSignalR.auctionInfo.hasEverBidOn = latestBidMaster?.auctionInfo.hasEverBidOn ?? false;

    if (!latestBidMaster || latestBidSignalR.increment > latestBidMaster.increment) {
      if (bidderId && isBidderWinning(latestBidSignalR, bidderId)) {
        // Winning bidder might have placed a Proxy, which is secret and not in the SignalR update, so need to fetch from API.
        refreshLatestBidApi();
      } else {
        // Safe to just use the SignalR data.
        setLatestBidMaster(latestBidSignalR);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestBidSignalR?.increment]);

  // Process API latest bid. Update Master if API fetch is more recent.
  React.useEffect(() => {
    if (!latestBidApi) return;
    auctionUpdated(latestBidApi.auctionInfo);

    if (!latestBidMaster) {
      setLatestBidMaster(latestBidApi);
    }

    if (latestBidMaster && latestBidApi.auctionId !== latestBidMaster.auctionId) {
      setLatestBidMaster(latestBidApi);
    }

    if (latestBidMaster && latestBidApi.increment > latestBidMaster.increment) {
      setLatestBidMaster(latestBidApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestBidApi]);


  // Handle any returned error messages via signalr. Ignore any messages relating to earlier/non-matching bid nonces.
  React.useEffect(() => {
    if (bidResult && bidResult.operationNonce === bidNonce) {
      setBidProcessing(false);
      if (bidResult.type === "error")
        onError(0, bidResult.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidResult]);

  const postBid = async (auctionId: string, amount: number): Promise<boolean> => {
    const bidNonce = Math.floor(Math.random() * 10000000);
    setBidNonce(bidNonce.toString());
    setBidProcessing(true);
    const uri = `${ClientSettings.BiddingPrivateApiUrl}/auctions/id/${auctionId}/bids/`;
    try {
      var result = await ApiService().Post<IBidResponse>(uri, { amount: amount, bidNonce: bidNonce });
      if (result.parsedBody) {
        if (!result.parsedBody.pending) {
          setBidProcessing(false);
        }
        return result.parsedBody.pending;
      }
      setBidProcessing(false);
    }
    catch (e) {
      onError(0, "There was an error placing the bid");
    }
    onError(0, 'We have been unable to place the bid')
    return false;
  } 

  return [latestBidMaster, isFetching, bidProcessing, refreshLatestBidApi, postBid];
};

