import { makeStyles } from "@material-ui/core/styles";

export const SellerDetailsStyles = makeStyles(theme => ({
  containerTitle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    paddingTop: "24px"
  },
  sellerName: { margin: "0 0 10px 0", textAlign: "left", fontWeight: "bold" },
  map: {
    paddingBottom: "8px",
    border: "none",
    width: "100%",
    height: "100%",
  },
  locationDeatils : {
    display: "inline-block",
    paddingLeft:"10px", 
    paddingRight:"10px", 
    paddingTop:"6px"
  }
  
}));
