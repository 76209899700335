import moment from "moment";
import React from "react";
import * as Styles from "./styles/MyAccountContainerStyles";
import { Grid } from "@material-ui/core";

export const SubscriptionExpiry: React.FC<{ endDate: string }> = ({
  endDate,
}) => {
  const classes = Styles.MyAccountContainerStyles();
  const end = moment(endDate);
  return moment(end).isValid() ? (
    <Grid container>
      <Grid item xs={6}>
        <h4 className={classes.orangeText}>ACCOUNT EXPIRY</h4>
      </Grid>
      <Grid item xs={6}>
        <h4 className={classes.greyText}>{end.format("DD/MM/YYYY")}</h4>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};
