import React from "react";
import { useSnackbar } from "notistack";
import { Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, Hidden, TableBody, TablePagination, Grid, TextField, Button } from "@material-ui/core";
import { LoadingComponent } from "../loading/LoadingComponent";
import { UseDefaultAuctionsWonRequest, useAuctionsBidOn } from "../../services/AuctionService";
import { BidHistoryRow } from "./BidHistoryRow";
import * as Styles from "./styles/BidHistoryStyles";

export const BidHistoryComponent: React.FC = () => {
  const classes = Styles.BidHistoryStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const [request, setRequest, apiPostAsync, bidderBidHistory, loadingBidderBidHistory] = useAuctionsBidOn(pageNumber, pageSize, statusCode => {
    if (statusCode !== 401) {
      enqueueSnackbar("There was a problem loading you bid history, please try again.", { variant: "error" });
    }
  });

  React.useEffect(() => {
    setRequest(UseDefaultAuctionsWonRequest());
    apiPostAsync(UseDefaultAuctionsWonRequest());
  }, []);

  React.useEffect(() => {
    if (request != null && pageNumber !== null && pageSize !== null) {
      apiPostAsync();
    }
  }, [pageNumber, pageSize]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  const changeHandler = (event: any, property: string) => {
    if (request) {
      if (event.target.value === "All") {
        setRequest({ ...request, [property]: null });
      } else {
        setRequest({ ...request, [property]: event.target.value });
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      apiPostAsync();
    }
  };

  const search = () => {
    setPageNumber(0);
    apiPostAsync();
  };

  const paginationComponent = (
    <TablePagination
      component="div"
      count={bidderBidHistory?.count ? bidderBidHistory?.count : 0}
      page={pageNumber}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" },
      ]}
      labelRowsPerPage="Rows"
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      nextIconButtonProps={{ style: { padding: 0 } }}
      backIconButtonProps={{ style: { padding: 0 } }}
    />
  );

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Bid History
      </Typography>
      <Typography variant="subtitle1" component="h2" gutterBottom className={classes.headerPadding}>
        This page can be used to view your bid history.
      </Typography>
      <Grid container spacing={1}>

        <Grid item xs={12} lg={4}>
          <TextField
            variant="outlined"
            id="search"
            label="Search"
            disabled={loadingBidderBidHistory}
            placeholder="Search bid history.."
            type="text"
            margin="dense"
            value={request?.search}
            onChange={value => {
              changeHandler(value, 'search')
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onKeyPress={handleKeyPress}
          />
        </Grid>

        <Grid item xs={12} lg={1}>
          <Button variant="contained" color="primary" disabled={loadingBidderBidHistory} onClick={search} className={classes.searchButton} fullWidth>
            Search
          </Button>
        </Grid>

        {loadingBidderBidHistory && bidderBidHistory === null && <LoadingComponent label="Loading bid history" />}
        {!loadingBidderBidHistory && bidderBidHistory && (
          <>

            <Grid item xs={12} lg={7}>
              {paginationComponent}
            </Grid>

            <Grid item xs={12} lg={12}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell>Title</TableCell>
                      <Hidden smDown>
                        <TableCell>Auction End</TableCell>
                        <TableCell>Highest Bid</TableCell>
                        <TableCell>Winning</TableCell>
                      </Hidden>
                      <TableCell>Auction</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bidderBidHistory.count === 0 && (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography variant="subtitle1" component="h2">
                            You have not bid on any auctions.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {bidderBidHistory?.results.map((bidHistory, index) => (
                      <TableRow className={classes.pointer} key={index} onClick={() => window.location.href = `/Lot/${bidHistory.id}`}>
                        <BidHistoryRow auctionListing={bidHistory} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} lg={12}>
              {paginationComponent}
            </Grid>
          </>
        )}

      </Grid>

    </>
  );
};
