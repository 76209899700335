import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import * as Styles from "./styles/GlobalAlertStyles";
import { AccountType } from "../../interfaces/bidders/IBidder";
import { AppContext } from "../../contexts/AppContext";
import { IGlobalAlert } from "../../interfaces/IGlobalAlert";
import * as AlertService from "../../services/AlertService";
import { AlertType } from "../../interfaces/AlertType";

const GlobalAlert: React.FC = () => {
    const context = React.useContext(AppContext);
    const classes = Styles.GlobalAlertStyles();
    const [alertDetails, setAlertDetails] = React.useState<IGlobalAlert | null>(null);
    const [message, setMessage] = React.useState<string | null>(null);

    React.useEffect(() => {
        AlertService.findSingleActive()
            .then(productResult => {
                if (productResult.parsedBody !== undefined) {
                    setAlertDetails(productResult.parsedBody);
                }
            })
            .catch(async response => {
                // Handle error here
            });
    }, []);

    return (
        <>
            {alertDetails != null && (
                <Box className={alertDetails.alertType === AlertType.Alert ? classes.alertMessageContainer : classes.notificationMessageContainer} p={2}>
                    <Grid container className={classes.alertContainer}>
                        <Grid item xs={12}>
                            <Typography className={classes.textLayout} align="left">
                                <InfoIcon className={classes.iconPosition} />
                                {alertDetails.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default GlobalAlert;