import * as React from "react";
import { Box } from "@material-ui/core";
import * as Styles from "./styles/NavigationStyles";
import { LinkLocation, MemberThemeDto } from "../../services/MemberService";

export const MemberTopBar: React.FC<{ member: MemberThemeDto }> = ({ member }) => {
  const classes = Styles.NavigationStyles();
  const headers = member.memberLinks?.filter(p => p.linkLocation === LinkLocation.header).slice(0, 6) ?? [];

  if (headers.length === 0) {
    return <></>;
  }
  return (
    <Box display="flex" flexDirection="row" className={classes.secondaryNavigationContainer}>
      {headers.map(p => (
        <a
          key={p.url}
          href={p.url}
          target="_blank"
          rel="noopener noreferrer"
          className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
        >
          <Box p={1} className={classes.secondaryNavigationItem}>
            {p.name}
          </Box>
        </a>
      ))}
    </Box>
  );
};
