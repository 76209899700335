import moment from "moment";
import { AccountType } from "../../../interfaces/bidders/IBidder";

export const GetSubscriptionStatus = (endDate: string, accountType: AccountType) => {
  if (accountType === AccountType.Member) {
    return "Member Account";
  }

  if (accountType === AccountType.Free) {
    return "Free Account";
  }

  const now = moment();
  const end = moment(endDate);

  if (moment(end).isValid()) {
    if (now > end) {
      return "Subscription Expired";
    } else {
      return "Subscribed";
    }
  } else {
    return "Not Subscribed";
  }
};
