import * as React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { NavbarAuthentication } from "../authentication/NavbarAuthentication";
import { INavigationGroup } from "../../interfaces/navigation/INavigationGroup";
import { MemberTopBar } from "./MemberTopBar";
import logo from "../../assets/logo.png";
import { Box, Divider, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";
import * as queryString from "query-string";

import * as Styles from "./styles/NavigationStyles";
import { AppContext, IInitialState } from "../../contexts/AppContext";
import { SearchOption } from "../../interfaces/search/ISearchOptions";
import { useMsal } from "../../authentication/MsalProvider";
import { IMsalContext } from "../../authentication/MsalContext";
import { StyleContext } from "../../contexts/StyleContext";

interface NavigationProps {
  lightTheme: boolean;
  context: IInitialState;
}

export const Navigation: React.FC<NavigationProps> = ({ lightTheme }) => {
  const theme = useTheme();
  const msal: IMsalContext = useMsal();

  const classes = Styles.NavigationStyles();

  const context = React.useContext(StyleContext);
  const appContext = React.useContext(AppContext);
  const bidderLatitude = appContext.bidderState.bidder?.address?.latitude ?? 0;
  const bidderLongitude = appContext.bidderState.bidder?.address?.longitude ?? 0;

  const member = context.theme;

  const carSearchQueryString = () => {
    var options = SearchOption(bidderLatitude, bidderLongitude);
    options.auction = ["Car"];
    return `?${queryString.stringify(options, { arrayFormat: 'bracket' })}`;
  };

  const motorcycleSearchQueryString = () => {
    var options = SearchOption(bidderLatitude, bidderLongitude);
    options.auction = ["Motorcycle"];
    return `?${queryString.stringify(options, { arrayFormat: 'bracket' })}`;
  };

  const commercialSearchQueryString = () => {
    var options = SearchOption(bidderLatitude, bidderLongitude);
    options.auction = ["Commercial"];
    return `?${queryString.stringify(options, { arrayFormat: 'bracket' })}`;
  };

  const homeMenuItems: INavigationGroup[] = [
    {
      navigationGroupName: "Home",
      navigationGroupLinks: [{ title: "Home", path: "/" }],
    },
  ];

  const searchMenuItems: INavigationGroup[] = [
    {
      navigationGroupName: "Auction Lots",
      navigationGroupLinks: [
        {
          title: "Cars",
          path: "/Search",
          search: carSearchQueryString(),
        },
        {
          title: "Motorbikes",
          path: "/Search",
          search: motorcycleSearchQueryString(),
        },
        {
          title: "Commercials",
          path: "/Search",
          search: commercialSearchQueryString(),
        },
        { title: "Search", path: "/Search" },
      ],
    },
  ];

  const auctionTimesMenuItems: INavigationGroup[] = [
    {
      navigationGroupName: "Auction Times",
      navigationGroupLinks: [{ title: "Auction Times", path: "/AuctionTimes" }],
    },
  ];

  const helpMenuItems: INavigationGroup[] = [
    {
      navigationGroupName: "Help centre",
      navigationGroupLinks: [
        { title: "About SalvageMarket", path: "/AboutUs" },
        { title: "Auction Fees", path: "/AuctionFees" },
        { title: "Terms & conditions", path: "/TermsConditions" },
        { title: "Privacy Policy", path: "/PrivacyPolicy" },
        { title: "Cookie Policy", path: "/Cookies" },
        { title: "Help & FAQ", path: "/HelpAndFaq" },
      ],
    },
  ];

  const loggedInMenuItems: INavigationGroup[] = [
    {
      navigationGroupName: "Your Account",
      navigationGroupLinks: [
        { title: "Your Details", path: `/MyAccount` },
        //  { title: "Watchlist", path: `/watchlist` },
        { title: "Bid History", path: `/BidHistory` },
        { title: "Auctions Won", path: `/AuctionsWon` },
        { title: "Saved Searches", path: '/Search/Saved' },
      ],
    },
    {
      navigationGroupName: "Watchlist",
      navigationGroupLinks: [{ title: "Watchlist", path: `/watchlist` }]
    }
  ];

  const [menuItems, setMenuItems] = React.useState(
    homeMenuItems
      .concat(searchMenuItems)
      .concat(auctionTimesMenuItems)
      .concat(helpMenuItems)
  );

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    if (msal.accounts.length > 0) {
      if (matches) {
        const menu = homeMenuItems
          .concat(searchMenuItems)
          .concat(loggedInMenuItems)
          .concat(auctionTimesMenuItems)
          .concat(helpMenuItems);

        setMenuItems(menu);
      } else {
        const menu = homeMenuItems
          .concat(searchMenuItems)
          .concat(auctionTimesMenuItems)
          .concat(helpMenuItems)
          .concat(loggedInMenuItems);

        setMenuItems(menu);
      }
    } else {
      const menu = homeMenuItems
        .concat(searchMenuItems)
        .concat(auctionTimesMenuItems)
        .concat(helpMenuItems);
      setMenuItems(menu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msal.accounts, matches]);

  return (
    <AppBar position={"static"}>
      <MemberTopBar member={member} />
      <Toolbar variant="regular" className={classes.navigationBar}>
        <Hidden smDown>
          <Box>
            <NavLink to={"/"}>
              <img src={logo} className={classes.logo} alt="logo" />
            </NavLink>
          </Box>
          <Box flexGrow={1} display="flex" justifyContent="center">
            {menuItems.map((item: INavigationGroup, index: number) => {
              return <DesktopMenu lightTheme={lightTheme} key={index} navigationGroup={item} />;
            })}
          </Box>
          <Box>
            <NavbarAuthentication />
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box flexGrow={1} display="flex">
            <NavLink to={"/"}>
              <img className={classes.logo} alt="logo" src={logo} />
            </NavLink>
          </Box>
          <MobileMenu navigationGroups={menuItems} />
        </Hidden>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};
