import React, { useState } from "react";
import { Grid, Divider, Button, Hidden, FormControlLabel, Checkbox, CircularProgress } from "@material-ui/core";
import * as Styles from "./styles/MyAccountContainerStyles";
import BidderDetailsList from "./BidderDetailsList";
import BusinessDetailsList from "./BusinessDetailsList";
import AccountRegisteredWithDetails from "./AccountRegisteredWithDetails";
import PaymentDetails from "./PaymentDetails";
import SuspensionDetails from "./SuspensionDetails";
import { AppContext } from "../../contexts/AppContext";
import { NotificationPreferencesContainer } from "./NotificationPreferencesContainer";
import { UpdateBidderMailingList } from "../../services/BidderService";
import { useSnackbar } from "notistack";
import { useMyMember } from "../../services/MemberService";
import { LoadingComponent } from "../loading/LoadingComponent";

const MyAccountContainer: React.FC = () => {
  const classes = Styles.MyAccountContainerStyles();
  const [openNotificationPreferences, setOpenNotificationPreferences] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const context = React.useContext(AppContext);
  const [processing, setProcessing] = React.useState<boolean>(false);

  const [member, memberLoading] = useMyMember(() => {
    enqueueSnackbar("There was a problem loading member details, please try again.", { variant: "error" });    
  });

  const handleMailingListClicked = async (value: boolean) => {
    setProcessing(true);
    try {
      await UpdateBidderMailingList(value);
      context.bidderState.bidder.mailingListOptIn = value;
      enqueueSnackbar("Marketing Preferences Updated", { variant: "success" });
    } catch {
      enqueueSnackbar("There was a problem changing your marketing preferences. Please try again.", { variant: "error" });
    } finally {
      setProcessing(false);
    }
  };
  const mailToLink = "mailto:" + member?.email;
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={3} className={classes.titleContainer}>
          <h1 className={classes.greyText}>Your registered details</h1>
        </Grid>
        <Hidden smDown>
          <Grid item xs={1} className={classes.divider}>
            <Divider orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={8} className={classes.infoContainer}>
          <BidderDetailsList bidder={context.bidderState.bidder} />
          <h4 className={classes.italicText}>
            If you need to change or update any of your personal details, please contact your account holder below. Additional identity checks maybe
            required.
          </h4>
          {memberLoading && member === null && <CircularProgress color="primary" size={16} thickness={8} />}
          {!memberLoading && member !== null && (
            <Button href={mailToLink} className={classes.button} variant="contained" color="primary">
              Request change
            </Button>
          )}
        </Grid>

        {context.bidderState.bidder.business && (
          <>
            <Grid item xs={12} md={3} className={classes.titleContainer}>
              <h1 className={classes.greyText}>Your registered business details</h1>
            </Grid>
            <Hidden smDown>
              <Grid item xs={1} className={classes.divider}>
                <Divider orientation="vertical" />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={8} className={classes.infoContainer}>
              <BusinessDetailsList business={context.bidderState.bidder.business} bidder={context.bidderState.bidder} />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3} className={classes.titleContainer}>
          <h1 className={classes.greyText}>Your account is registered with</h1>
        </Grid>
        <Hidden smDown>
          <Grid item xs={1} className={classes.divider}>
            <Divider orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={8} className={classes.infoContainer}>
          {memberLoading && member === null && <LoadingComponent label="Loading member details" />}
          {!memberLoading && member !== null && <AccountRegisteredWithDetails member={member} />}
        </Grid>

        <Grid item xs={12} md={3} className={classes.titleContainer}>
          <h1 className={classes.greyText}>Marketing and Notification Preferences</h1>
        </Grid>
        <Hidden smDown>
          <Grid item xs={1} className={classes.divider}>
            <Divider orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={8} className={classes.infoContainer}>
          <h4 className={classes.italicText}>Notification preferences allow you to customise when and how different notifications are sent.</h4>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenNotificationPreferences(!openNotificationPreferences);
            }}
          >
            {openNotificationPreferences ? "Hide Notification Preferences" : "Manage Notifications"}
          </Button>
          {openNotificationPreferences && <NotificationPreferencesContainer />}

          <h4 className={classes.italicText}>Opt in or opt out of receiving marketing and promotional emails</h4>
          {context.bidderState.bidder.mailingListOptIn !== undefined ? (
            <FormControlLabel
              control={
                <Checkbox
                  value={context.bidderState.bidder.mailingListOptIn}
                  checked={context.bidderState.bidder.mailingListOptIn}
                  onChange={e => handleMailingListClicked(e.target.checked)}
                  disabled={processing}
                  name="mailingListOptIn"
                  color="primary"
                />
              }
              label="Opt In to receive Marketing Communications"
            />
          ) : (
            <CircularProgress color="primary" size={24} thickness={8} />
          )}
        </Grid>

        <Grid item xs={12} md={3} className={classes.titleContainer}>
          <h1 className={classes.greyText}>Suspensions</h1>
        </Grid>
        <Hidden smDown>
          <Grid item xs={1} className={classes.divider}>
            <Divider orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={8} className={classes.infoContainer}>
          <SuspensionDetails suspensions={context.bidderState.bidder.suspensionDetails} />
        </Grid>

        <Grid item xs={12} md={3} className={classes.titleContainer}>
          <h1 className={classes.greyText}>Payments</h1>
        </Grid>
        <Hidden smDown>
          <Grid item xs={1} className={classes.divider}>
            <Divider orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={8} className={classes.infoContainer}>
          <PaymentDetails bidder={context.bidderState.bidder} />
        </Grid>
      </Grid>
    </>
  );
};

export default MyAccountContainer;
