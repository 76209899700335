import * as React from "react";
import { Container } from "@material-ui/core";
import * as Styles from "./styles/ContainerStyles";
import { RenewalProcessComponent } from "../renewals/RenewalProcessComponent";
import { SecureArea } from "../authentication/SecureArea";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

function RenewalProcess() {
  const classes = Styles.ContainerStyles();

  return (
    <SecureArea>
      <Container fixed className={classes.container}>
        <RenewalProcessComponent />
      </Container>
    </SecureArea>
  );
}

export default withAITracking(reactPlugin, RenewalProcess, "RenewalProcess");
