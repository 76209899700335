import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useReadonlyData } from "./useVersionedData";
import { IAddress } from "../interfaces/IAddress";
import { IOpeningDays } from "../interfaces/IOpeningDays";
import { appInsights } from "./TelemetryService";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface Member {
  name: string;
  phoneNumber: string;
  email: string;
  websiteUrl: string;
  sites: MemberSite[];
}

export interface MemberSite {
  id: string;
  isMainSite: boolean;
  siteName: string;
  sitePhoneNumber: string;
  siteEmail: string;
  location: IAddress;
  openingDays: IOpeningDays;
  collectionDays: IOpeningDays;
}

export interface MemberDetails {
  name: string;
  line1: string;
  line2: string;
  line3: string | null;
  city: string;
  postcode: string;
  county: string;
  phoneNumber: string;
  email: string;
  isE2E: boolean;
}

export enum LinkLocation {
  unknown = 0,
  header = 1,
  footer = 2,
}
export interface MemberLink {
  name: string;
  url: string;
  linkLocation: LinkLocation;
}
export interface MemberThemeDto {
  id: string;
  name: string;
  phoneNumber: string;
  primaryColors: string;
  sites: MemberSite[];
  memberLinks: MemberLink[];
  socialTwitter: string | null;
  socialLinkedIn: string | null;
  socialFacebook: string | null;
  email: string | null;
  isE2E: boolean | null;
}
const DefaultEmailAddress = "assist@salvagemarket.co.uk";
export const DefaultMemberThemeDto: MemberThemeDto = {
  id: "4a40c4c8-0273-41a4-bf35-6e8a49d4edfd",
  name: "e2e",
  phoneNumber: "01325 352626",
  isE2E: true,
  primaryColors: "#EC6E13",
  sites: [
    {
      id: "9291e37f-42e3-479a-b9ea-894fe06c1f8c",
      isMainSite: true,
      siteName: "Darlington",
      sitePhoneNumber: "01325 352626",
      siteEmail: "assist@salvagemarket.co.uk",
      location: {
        line1: "e2e, Unity House",
        line2: "Dudley Road",
        line3: "",
        city: "Darlington",
        postcode: "DL1 4GG",
        county: "Durham",
        country: { name: "United Kingdom", alpha2: "GB", countryCode: "826" },
        latitude: 54.5251568,
        longitude: -1.5030695,
      },
      openingDays: {
        monday: { openingTimeStatus: 2, open: "0001-01-01T09:00:00", close: "0001-01-01T17:00:00" },
        tuesday: { openingTimeStatus: 2, open: "0001-01-01T09:00:00", close: "0001-01-01T17:00:00" },
        wednesday: { openingTimeStatus: 2, open: "0001-01-01T09:00:00", close: "0001-01-01T17:00:00" },
        thursday: { openingTimeStatus: 2, open: "0001-01-01T09:00:00", close: "0001-01-01T17:00:00" },
        friday: { openingTimeStatus: 2, open: "0001-01-01T09:00:00", close: "0001-01-01T17:00:00" },
        saturday: { openingTimeStatus: 1, open: "0001-01-01T00:00:00", close: "0001-01-01T00:00:00" },
        sunday: { openingTimeStatus: 1, open: "0001-01-01T00:00:00", close: "0001-01-01T00:00:00" },
      },
      collectionDays: {
        monday: { openingTimeStatus: 1, open: "0001-01-01T00:00:00", close: "0001-01-01T00:00:00" },
        tuesday: { openingTimeStatus: 1, open: "0001-01-01T00:00:00", close: "0001-01-01T00:00:00" },
        wednesday: { openingTimeStatus: 1, open: "0001-01-01T00:00:00", close: "0001-01-01T00:00:00" },
        thursday: { openingTimeStatus: 1, open: "0001-01-01T00:00:00", close: "0001-01-01T00:00:00" },
        friday: { openingTimeStatus: 1, open: "0001-01-01T00:00:00", close: "0001-01-01T00:00:00" },
        saturday: { openingTimeStatus: 1, open: "0001-01-01T00:00:00", close: "0001-01-01T00:00:00" },
        sunday: { openingTimeStatus: 1, open: "0001-01-01T00:00:00", close: "0001-01-01T00:00:00" },
      },
    },
  ],
  memberLinks: [],
  socialTwitter: "https://x.com/e2etotalloss",
  socialLinkedIn: "https://www.linkedin.com/company/e2etotalloss/",
  socialFacebook: "https://www.facebook.com/e2etotalloss/",
  email: DefaultEmailAddress,
};

export const useMember = (memberId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<Member>(`${ClientSettings.AuctionPublicApiUrl}/member/${memberId}`, onError);

export const useMyMember = (onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<MemberDetails>(`${ClientSettings.UsersPrivateApiUrl}/me/member/`, onError);

const theme_key = "theme";
export const getCachedSiteTheme = (): MemberThemeDto | null => {
  const cachedThemeString = localStorage.getItem(theme_key);
  if (cachedThemeString) {
    const cachedItem = JSON.parse(cachedThemeString) as MemberThemeDto;
    return cachedItem;
  }
  return null;
};

export const updateCachedTheme = async (): Promise<MemberThemeDto | null> => {
  const uri = `${ClientSettings.AuctionPublicApiUrl}/members/site/`;
  const requestInit: RequestInit = {
    method: "GET",
  };
  try {
    const response = await fetch(uri, requestInit);
    if (response.ok) {
      const theme = (await response.json()) as MemberThemeDto;

      localStorage.setItem(theme_key, JSON.stringify(theme));
      return theme;
    } else {
      appInsights.trackException({
        error: new Error("Unable to load styling data for site: " + response.statusText),
        severityLevel: SeverityLevel.Error,
      });
      console.log("Unable to load styling data because " + response.statusText);
    }
  } catch (error) {
    appInsights.trackException({
      error: error,
      severityLevel: SeverityLevel.Error,
    });
  }
  return DefaultMemberThemeDto;
};
