import React from "react";
import ReactPlayer from "react-player";
import { MediaType } from "./types/MediaType";
import * as Styles from "./../styles/MobileMediaRenderStyles";
import { Media } from "../../../services/MediaService";

interface MobileMediaRenderProps {
  media: Media;
}

const MobileMediaRender: React.FC<MobileMediaRenderProps> = ({ media }) => {
  const classes = Styles.MobileMediaRenderStyles();
  if (media.mediaType === MediaType.image) {
    return <img alt={media.description} src={media.url} className={classes.image} />;
  } else {
    return (
      <div className={classes.playerWrapper}>
        <ReactPlayer className={classes.player} url={`https://www.youtube.com/watch?v=${media.video}`} width="100%" height="100%" />
      </div>
    );
  }
};

export default MobileMediaRender;
