import React from "react";
import { Button, TableCell, TableRow, Table, TableHead,Fab, TableBody, Box, ButtonGroup, Typography, Hidden, Grid } from "@material-ui/core";
import * as Styles from "../../styles/DocumentAdditionComponentStyles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { IFormsOfId } from "./entities/FormsOfId";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import HelpIcon from "@material-ui/icons/Help";

import DeleteIcon from "@material-ui/icons/Delete";
import { selectedImagesReducer, ActionTypes, ISelectedFile } from "./entities/ImageUploadStateManagement";

interface IProps {
  /** All the forms of ID we require the bidder to upload */
  fileTypesAvailable: Array<IFormsOfId>;
  /** This call back is passed a list of files if all the required documents have been provided, otherwise this is called with null. */
  allFilesUploaded: (files?: Array<ISelectedFile>) => void;
}

export const DocumentAdditionComponent: React.FC<IProps> = (props: IProps) => {
  const classes = Styles.DocumentAdditionComponentStyles();
  const allFilesUploaded = props.allFilesUploaded;
  const [fileTypes, setFileTypes] = React.useState(props.fileTypesAvailable);
  const [imagesToUpload, dispatch] = React.useReducer(selectedImagesReducer, []);
  const [allFileTypesSelected, setAllFileTypesSelected] = React.useState(false);
  const [allFilesCatagorised, setAllFilesCatagorised] = React.useState(false);
  const [oneImagePerCategory, setOneImagePerCategory] = React.useState(false);

  React.useEffect(() => {
    setFileTypes(props.fileTypesAvailable);
  }, [props.fileTypesAvailable]);

  React.useEffect(() => {
    const allIdFormsSelected = fileTypes.every(fileType =>
      imagesToUpload.some(selectedImage => selectedImage.formOfId !== undefined && selectedImage.formOfId.title === fileType.title)
    );
    setAllFileTypesSelected(allIdFormsSelected);
    const allImagesCategorized = imagesToUpload.every(p => p.formOfId !== undefined);
    setAllFilesCatagorised(allImagesCategorized);

    setOneImagePerCategory(imagesToUpload.length === fileTypes.length);
  }, [imagesToUpload, fileTypes]);

  React.useEffect(() => {
    if (allFileTypesSelected && allFilesCatagorised) {
      allFilesUploaded(imagesToUpload);
    } else {
      allFilesUploaded();
    }
  }, [allFileTypesSelected, allFilesCatagorised, imagesToUpload, allFilesUploaded]);

  const imagesUploaded = (event: any) => {
    for (var i = 0; i < event.target.files.length; i++) {
      dispatch({ type: ActionTypes.Add, payload: { selectedFile: event.target.files[i] } });
    }
  };
  const removeImage = (fileToRemove: ISelectedFile) => {
    dispatch({ type: ActionTypes.Remove, payload: { selectedFile: fileToRemove } });
  };
  const rotateImage = (degrees: number) => (file: ISelectedFile) => {
    dispatch({ type: ActionTypes.Rotate, payload: { selectedFile: file, angle: degrees } });
  };

  const setFormOfId = (formOfId: IFormsOfId) => (file: ISelectedFile) => {
    dispatch({ type: ActionTypes.SetFormOfId, payload: { selectedFile: file, formOfId: formOfId } });
  };
  const getFormsOfIdButtons = (file: ISelectedFile) => {
    return (
      <ButtonGroup aria-label="outlined primary button group">
        {fileTypes.map((filetype, index) => (
          <Button
            key={index}
            onClick={() => setFormOfId(filetype)(file)}
            color={"primary"}
            variant={file.formOfId !== undefined && file.formOfId!.title === filetype.title ? "contained" : "outlined"}
          >
            {filetype.title}
          </Button>
        ))}
      </ButtonGroup>
    );
  };

  const formattedValidation = (message: string) => {
    return (
      <Box bgcolor="info.main" color="info.contrastText" p={2} marginTop={4}>
        <Typography className={classes.validationMessage}>
          <HelpIcon className={classes.validationImage} /> {message}
        </Typography>
      </Box>
    );
  };

  const validation = () => {
    if (imagesToUpload.length > 0) {
      if (!allFilesCatagorised) {
        return formattedValidation("You must choose the document type of each uploaded image");
      }
      if (!allFileTypesSelected) {
        return formattedValidation("Please ensure you have added an image for every type of required identification");
      }
      if (!oneImagePerCategory) {
        return formattedValidation("You can only submit one photo per form of id.");
      }
    }
    return <></>;
  };

  const filesSelectedComponent = () => {
    if (!imagesToUpload || imagesToUpload.length === 0) {
      return <></>;
    }

    return (
      <>
        <Hidden mdUp>
          <Grid container alignItems="center" justify="center">
            {imagesToUpload.map(selectedFile => (
              <>
                <Grid key={selectedFile.file.name} item xs={5}>
                  <Box marginTop={2} marginBottom={2}>
                    <img
                      className={classes.uploadedImages}
                      alt={selectedFile.file.name}
                      src={URL.createObjectURL(selectedFile.file)}
                      style={{ transform: `rotate(${selectedFile.rotate}deg)` }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  {selectedFile.file.name}
                </Grid>
                <Grid item xs={2}>
                <Fab size="small" color="secondary"onClick={() => removeImage(selectedFile)} aria-label="delete">
        <DeleteIcon />
      </Fab>
            
                </Grid>
                <Grid item sm={8} xs={12}>
                  {getFormsOfIdButtons(selectedFile)}
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Box marginTop={1}>
                    <ButtonGroup>
                      <Button onClick={() => rotateImage(-90)(selectedFile)}>
                        <RotateLeftIcon />
                      </Button>
                      <Button onClick={() => rotateImage(90)(selectedFile)}>
                        <RotateRightIcon />
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        </Hidden>

        <Hidden smDown>
          <Table className={classes.filesTable} table-la>
            <TableHead>
              <TableRow>
                <TableCell>Document</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Document Type</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {imagesToUpload.map(selectedFile => (
              <TableBody key={selectedFile.file.name}>
                <TableRow style={{ height: "110px" }}>
                  <TableCell rowSpan={2}>{selectedFile.file.name}</TableCell>
                  <TableCell style={{ borderBottom: "none", textAlign: "center" }}>
                    <img
                      className={classes.uploadedImages}
                      alt={selectedFile.file.name}
                      src={URL.createObjectURL(selectedFile.file)}
                      style={{ transform: `rotate(${selectedFile.rotate}deg)` }}
                    />
                  </TableCell>
                  <TableCell rowSpan={2}>{getFormsOfIdButtons(selectedFile)}</TableCell>
                  <TableCell rowSpan={2}>
                  <Fab size="small" color="secondary" onClick={() => removeImage(selectedFile)} aria-label="delete">
        <DeleteIcon />
      </Fab>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <ButtonGroup>
                      <Button onClick={() => rotateImage(-90)(selectedFile)}>
                        <RotateLeftIcon />
                      </Button>
                      <Button onClick={() => rotateImage(90)(selectedFile)}>
                        <RotateRightIcon />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </Hidden>
      </>
    );
  };

  const iOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  };

  return (
    <>
      <Box className={classes.chooseDocsButton}>
        <input
          accept="image/*"
          className={classes.fileInput}
          style={{ display: "none" }}
          id="image-upload-file"
          type="file"
          multiple
          onChange={imagesUploaded}
        />
        <label htmlFor="image-upload-file">
          <Button variant="contained" color="primary" component="span" startIcon={<AddCircleIcon />}>
            Upload images
          </Button>
        </label>
        {iOS() && (
          <Typography variant="body1" component="h1" className={classes.orangeText}>
            ID images should be used from your camera library rather than from your camera.
          </Typography>
        )}
      </Box>
      {filesSelectedComponent()}
      {validation()}
    </>
  );
};
