import React from "react";

import { IAuctionTimesWeek } from "../../interfaces/auctionTimes/IAuctionTimesWeek";
import { Tabs, Tab } from "@material-ui/core";

interface WeekCommencingTabProps {
  auctionWeeks: IAuctionTimesWeek[] | undefined;
  tabChangeCallback: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const AuctionTimesTabs: React.FC<WeekCommencingTabProps> = ({ auctionWeeks, tabChangeCallback }) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleChange = (clickEvent: React.ChangeEvent<{}>, newTabIndex: number) => {
    tabChangeCallback(clickEvent, newTabIndex);
    setSelectedTabIndex(newTabIndex);
  };

  return (
    <Tabs value={selectedTabIndex} indicatorColor="primary" textColor="primary" scrollButtons="auto" variant="scrollable" onChange={handleChange}>
      {auctionWeeks !== undefined &&
        auctionWeeks.map((week, index) => {
          return <Tab key={`week-commencing-tab-${index}`} label={week.auctionTimesWeekTitle} />;
        })}
    </Tabs>
  );
};

export default AuctionTimesTabs;
