export const FormatPhoneNumber = (phoneNumber: string): string => {
  //removes spaces and/or parentheses
  phoneNumber = phoneNumber.replace(/\(|\)|\s+/g, "");
  // regex for numbers which start 07
  var regexMobile = /0\d{8,9}/;
  if (regexMobile.test(phoneNumber)) {
    phoneNumber = phoneNumber.replace(/^0/, "+44");
  }
  return phoneNumber;
};

export const IsValidUkMobileNumber = (phoneNumber: string): boolean => {
  const regex = /\+447\d{8,9}|07\d{8,9}/;
  return regex.test(phoneNumber);
};

export const IsValidInternationalNumber = (phoneNumber: string): boolean => {
  const regex = /\+\d{7,18}/;
  return regex.test(phoneNumber);
};
