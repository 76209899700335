import { makeStyles } from "@material-ui/core/styles";

export const EditorPicksArrowsStyles = makeStyles(theme => ({
  svgIcon: {
    position: "absolute",
    color: theme.palette.secondary.dark,
    "&:hover": {
      color: theme.palette.primary.main
    }
  }
}));
