import React from "react";
import { Grid, Button } from "@material-ui/core";
import * as Styles from "../styles/BidIncrementStyles";
import { currencyFormat } from "../../../helpers/text-format/TextFormat";
import { Auction } from "../../../services/AuctionService";
import { ILatestBid } from "../../../interfaces/auctions/ILatestBid";

interface BidIncrementProps {
  pendingBid: number | null;
  isDisabled: boolean;
  currentBidValue: number | null;
  auction: Auction;
  incrementScale: number[];
  latestBid: ILatestBid | null;
  onBidValueSelected(amount: number): void;
}



const BidIncrements: React.FC<BidIncrementProps> = ({ pendingBid, isDisabled, currentBidValue, auction, incrementScale, latestBid, onBidValueSelected }) => {
  const classes = Styles.BidIncrementStyles();

  const getIncrements = (currentBid: number | null, incrementScale: number[], advanceOneIncrement: boolean): (number | null)[] => {

    if (!currentBid) {
      //return empty
      return [];
    }


    let result: number[] = [];
    var indexOfCurrentIncrement = incrementScale.indexOf(currentBid);
  
    // if the current bid is not within the increment scale then find the next valid increment's index
    if (indexOfCurrentIncrement == -1) {
      for (var i = 0; i < incrementScale.length; i++) {
        if (incrementScale[i] >= currentBid) {
          indexOfCurrentIncrement = i;
          break;
        }
      }
    } else if (advanceOneIncrement == true) {
      indexOfCurrentIncrement += 1;
    }
  
    // add the next 4 valid increments as quick bids
    for (var i = indexOfCurrentIncrement; i < indexOfCurrentIncrement + 4; i++) {
      if (incrementScale[i] != null) {
        result.push(incrementScale[i]);
      }
    }
  
    if(result.length < 4){
      //return empty if there arent 4 values
      result = [];
    }


    return result;
  };



  const increments = getIncrements(currentBidValue, incrementScale, (latestBid != null && latestBid.amount != null));

  return (
    <div className={classes.padding}>
      <Grid container spacing={1}>
        {increments.map((increment: number | null, index: number) => {
          const disabled = isDisabled || !increment || (pendingBid !== null && increment <= pendingBid)
          return (
            <Grid item xs={6} key={index}>
              <Button
                className={disabled ? classes.disabledButton : classes.button}
                onClick={() => increment && onBidValueSelected(increment)}
                fullWidth
                disabled={disabled}
              >
                {!increment && "Loading..."}
                {increment && currencyFormat(increment)}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
export default BidIncrements;
