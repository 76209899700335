import React from "react";
import { IBusiness } from "../../interfaces/bidders/IBusiness";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { Grid } from "@material-ui/core";
import * as Styles from "./styles/MyAccountContainerStyles";

interface IProps {
  business: IBusiness;
  bidder: IBidder;
}

const BusinessDetailsList: React.FC<IProps> = props => {
  const classes = Styles.MyAccountContainerStyles();

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <h4 className={classes.orangeText}>BUSINESS NAME </h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.greyText}>{props.business.businessName}</h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.orangeText}>BUSINESS REGISTRATION NUMBER </h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.greyText}>{props.business.registrationNumber}</h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.orangeText}>BUSINESS VAT NUMBER </h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.greyText}>{props.business.vatNumber}</h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.orangeText}>BUSINESS LANDLINE NUMBER </h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.greyText}>{props.bidder.landlineNumber}</h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.orangeText}>MOBILE NUMBER</h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.greyText}>{props.bidder.mobileNumber ? props.bidder.mobileNumber : "not given"}</h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.orangeText}>BUSINESS ADDRESS </h4>
        </Grid>
        <Grid item xs={6}>
          <h4 className={classes.greyText}>{props.bidder.address.line1}</h4>
          <h4 className={classes.greyText}>{props.bidder.address.line2}</h4>
          <h4 className={classes.greyText}>{props.bidder.address.line3}</h4>
          <h4 className={classes.greyText}>{props.bidder.address.city}</h4>
          <h4 className={classes.greyText}>{props.bidder.address.county}</h4>
          <h4 className={classes.greyText}>{props.bidder.address.country.name}</h4>
          <h4 className={classes.greyText}>{props.bidder.address.postcode}</h4>
        </Grid>
      </Grid>
    </div>
  );
};

export default BusinessDetailsList;
