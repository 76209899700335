import { makeStyles } from "@material-ui/core";

export const DocumentAdditionComponentStyles = makeStyles(theme => ({
  idPaper: {
    padding: "15px",
  },
  fileInput: {
    visibility: "hidden",
  },
  submitDocsButton: {
    float: "right",
  },
  fileNameTextbox: {
    marginRight: "15px",
    width: "80%",
  },
  loadingUploadingDocumentsComponent: {
    marginTop: "150px;",
    marginBottom: "150px;",
    width: "500px",
  },
  filesTable: {
    marginTop: "15px",
  },
  documentsPaper: {
    padding: "20px",
  },
  chooseDocsButton: {
    marginBottom: "10px",
    marginTop: "10px",
    display: "block",
  },
  uploadedImages: {
    maxHeight: "100px",
    maxWidth: "100px",
    transition: "transform .2s",
  },
  validationMessage: {
    display: "flex",
    alignItems: "center",
  },
  validationImage: {
    marginRight: "5px",
  },
  orangeText: {
    color: theme.palette.primary.main,
  }
}));
