import { makeStyles } from "@material-ui/core";
import { darkGrey } from "../../../theme";

export const GlobalAlertStyles = makeStyles(theme => ({
  alertMessageContainer: {
    userSelect: "none",
    position: "static",
    opacity: "1",
    backgroundColor: "#811616",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#811616",
      transition: "background-color 0.1s ease",
    },
    transition: "opacity 0.5s linear, background-color 0.1s ease",
  },
  notificationMessageContainer: {
    userSelect: "none",
    position: "static",
    opacity: "1",
    backgroundColor: "#808080",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#808080",
      transition: "background-color 0.1s ease",
    },
    transition: "opacity 0.5s linear, background-color 0.1s ease",
  },
  textLayout: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    fontSize: "14px",
  },
  iconPosition: {
    marginRight: "5px",
  },
  alertContainer: { 
    maxWidth: "1265px",
    margin: "0 auto",
    textAlign: "left",
    display: "flex",
    alignItems: "start",
    justifyContent: "center"
  }
}));
