import { StorageURL, AnonymousCredential, ContainerURL, BlockBlobURL, Aborter, uploadBrowserDataToBlockBlob } from "@azure/storage-blob";
import { IBlobToken } from "../interfaces/IBlobToken";

const pipeline = StorageURL.newPipeline(new AnonymousCredential());

export async function UploadFileToBlobStorage(token: IBlobToken, blobName: string, file: any) {
  const containerURL = new ContainerURL(token.url, pipeline);
  const blockBlobURL = BlockBlobURL.fromContainerURL(containerURL, blobName);
  const response = await uploadBrowserDataToBlockBlob(Aborter.none, file, blockBlobURL);
  const blobUrl: string = blockBlobURL.url.split("?", 1)[0];
  return {
    response: response,
    blobUrl: blobUrl,
  };
}
