import { Image, View, Text } from "@react-pdf/renderer";
import { styles } from "../receipt-pdf/styles/PdfStyles";
import React from "react";
import smlogo from "../receipt-pdf/styles/smlogo.png";
import nsglogosite from "../receipt-pdf/styles/nsglogosite.png";
import { ReceiptData } from "../../services/ReceiptService";

interface ReceiptHeaderProps {
  receiptDetails: ReceiptData;
  isRefundReceipt: boolean;
  orderStatus: string | undefined;
}

export const ReceiptHeader: React.FC<ReceiptHeaderProps> = ({ receiptDetails, isRefundReceipt, orderStatus }) => (
  <>
    <View style={styles.headerLayout}>
      <View style={styles.nsgLogo}>
        <Image src={nsglogosite} />
      </View>
      <View style={styles.nsgAddress}>
        <Text>{receiptDetails.nsgAddress.line1}</Text>
        <Text>{receiptDetails.nsgAddress.line2}</Text>
        <Text>{receiptDetails.nsgAddress.line3}</Text>
        <Text>{receiptDetails.nsgAddress.city}</Text>
        <Text>{receiptDetails.nsgAddress.county}</Text>
        <Text>{receiptDetails.nsgAddress.postcode}</Text>
        <Text>{receiptDetails.nsgEmail}</Text>
      </View>
      <View style={styles.smLogo}>
        <Image src={smlogo} />
      </View>
    </View>
    <View style={styles.headerRow}>
      <Text style={styles.header}>{orderStatus == 'Refunded' && isRefundReceipt === true ? "Refund Receipt" : "Sales Receipt"}</Text>
    </View>
  </>
);
