import { faPoundSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardMedia } from "@material-ui/core";
import React from "react";
import * as Styles from "./styles/IconStyles";

interface RegistrationNumberIconProps {
  image: string;
}

const Check: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon}>
      <div className="bt-stack">
        <div className="btb bt-square bt-stack-1x"></div>
        <div className={`btb bt-check bt-stack-sm ${classes.green}`}></div>
      </div>
    </div>
  );
};

const Colour: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon}>
      <div className="btb bt-spinner"> </div>
    </div>
  );
};

const Cross: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon}>
      <div className="bt-stack">
        <div className="btb bt-square bt-stack-1x"></div>
        <div className={`btb bt-times bt-stack-sm ${classes.red}`}></div>
      </div>
    </div>
  );
};

const Unknown: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon}>
      <div className={`btb bt-question-circle ${classes.red}`}></div>
    </div>
  );
};

const Info: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon}>
      <div className="btb bt-info-circle"> </div>
    </div>
  );
};

const Gear: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon}>
      <div className="btb bt-gear"> </div>
    </div>
  );
};

const Pound: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon}>
      <FontAwesomeIcon icon={faPoundSign} size="lg" />
    </div>
  );
};

const Mileage: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIconMileage}>
      <div className="btb bt-spinner-clock"> </div>
    </div>
  );
};

const Registration: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon}>
      <div className="btb bt-eye"> </div>
    </div>
  );
};

const RegistrationNumberIcon: React.FC<RegistrationNumberIconProps> = ({ image }) => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon}>
      <CardMedia component='img' src={`data:image/png;base64, ${image}`} />
    </div>
  );
};

const Label: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIconReference}>
      <div className="btb bt-label"> </div>
    </div>
  );
};

const VehicleType: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon}>
      <div className="btb bt-folder"> </div>
    </div>
  );
};

const Commute: React.FC = () => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIconCommute}>
      <div className="btb bt-map-arrow"> </div>
    </div>
  );
};

export { Colour, Cross, Check, Unknown, Info, Label, Mileage, Registration, Gear, VehicleType, Commute, RegistrationNumberIcon, Pound };
