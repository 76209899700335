import { Image, View } from "@react-pdf/renderer";
import { SalesDocument } from "../../../services/SalesDocumentService";
import { styles } from "../styles/PdfStyles";
import React from "react";

interface LogoBanner {
  salesDocumentInfo: SalesDocument;
}

export const LogoBanner: React.FC<LogoBanner> = ({ salesDocumentInfo }) => (
  <View style={styles.logoBanner}>
    <View style={styles.logoBlock}>
      <Image style={styles.logo} src={salesDocumentInfo.memberImageUrl} />
    </View>
  </View>
);
