import { makeStyles } from "@material-ui/core/styles";

export const LargeMediaStyles = makeStyles(theme => ({
  playerWrapper: {
    position: "relative",
    paddingBottom: "75%" /* 16:9 */,
    height: "0",
  },
  img: {
    width: "100%",
    cursor: "pointer",
  },
  player: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));
