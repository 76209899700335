/***
 *      _____ __  __ _____   ____  _____ _______       _   _ _______    _____  ______          _____    ____  ______ _      ______          __
 *     |_   _|  \/  |  __ \ / __ \|  __ \__   __|/\   | \ | |__   __|  |  __ \|  ____|   /\   |  __ \  |  _ \|  ____| |    / __ \ \        / /
 *       | | | \  / | |__) | |  | | |__) | | |  /  \  |  \| |  | | (_) | |__) | |__     /  \  | |  | | | |_) | |__  | |   | |  | \ \  /\  / / 
 *       | | | |\/| |  ___/| |  | |  _  /  | | / /\ \ | . ` |  | |     |  _  /|  __|   / /\ \ | |  | | |  _ <|  __| | |   | |  | |\ \/  \/ /  
 *      _| |_| |  | | |    | |__| | | \ \  | |/ ____ \| |\  |  | |  _  | | \ \| |____ / ____ \| |__| | | |_) | |____| |___| |__| | \  /\  /   
 *     |_____|_|  |_|_|     \____/|_|  \_\ |_/_/    \_\_| \_|  |_| (_) |_|  \_\______/_/    \_\_____/  |____/|______|______\____/   \/  \/    
 *                                                                                                                                            
 * 
 * ML 2023-21-22               
 * When updating the terms and conditions content please create a new container regardless of how small the content.
 * It needs to be done like this to maintain the historical data as part of the client requirements.
 * Also don't forget to update the version number in the settings table in the database to match the new page.        
 * It is crucial that these version containers are maintained correctly.                                                                                                                    
 */


import * as React from "react";
import { Container } from "@material-ui/core";
import * as Styles from "./styles/ContainerStyles";
import TermsConditionsContainer from "../information-pages/TermsAndConditionContainers/1.0 - TermsConditionsContainer";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

function TermsConditions() {
  const classes = Styles.ContainerStyles();

  return (
    <Container fixed className={classes.container}>
      <TermsConditionsContainer />
    </Container>
  );
}

export default withAITracking(reactPlugin, TermsConditions, "TermsConditions");
