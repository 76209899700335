import React from "react";
import { Grid, Typography, Divider, Container, Box } from "@material-ui/core";
import * as Styles from "../../styles/SubmitDocumentsStyles";
import { DocumentAdditionComponent } from "./DocumentAdditionComponent";
import { IAnsweredQuestions, getRequiredFormsOfId } from "./entities/FormsOfId";
import { ISelectedFile } from "./entities/ImageUploadStateManagement";
import { RequiredDocument } from "./RequiredDocumentComponent";
import { useSnackbar } from "notistack";
import { UploadFiles } from "../../helpers/UploadFiles";

interface ISubmitDocumentsComponentsProps {
  documentsSubmitted: () => void;
  answeredQuestions: IAnsweredQuestions;
  setSubmitButtonEnabled: (submitButtonEnabled: boolean) => void;
  uploadDocuments: boolean;
  closeDialog: () => void;
}
export const SubmitDocumentsComponent: React.FC<ISubmitDocumentsComponentsProps> = ({
  documentsSubmitted,
  answeredQuestions,
  setSubmitButtonEnabled,
  uploadDocuments,
  closeDialog,
}: ISubmitDocumentsComponentsProps) => {
  const classes = Styles.SubmitDocumentsStyles();
  const [filesToSubmit, setFilesToSubmit] = React.useState<Array<ISelectedFile>>([]);
  const documentsRequired = getRequiredFormsOfId(answeredQuestions);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (filesToSubmit.length > 0) {
      setSubmitButtonEnabled(false);
    }
  }, [filesToSubmit, setSubmitButtonEnabled]);

  React.useEffect(() => {
    if (uploadDocuments) {
      UploadFiles(
        filesToSubmit,
        message => enqueueSnackbar(message, { variant: "info" }),
        message => enqueueSnackbar(message, { variant: "error" })
      )
        .then(success => {
          if (success) {
            enqueueSnackbar(`All Documents uploaded successfully!`, {
              variant: "success",
            });
            documentsSubmitted();
          }
        })
        .finally(() => {
          closeDialog();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadDocuments]);

  const allFilesUploaded = (files?: Array<ISelectedFile>) => {
    if (files !== undefined) {
      if (files.length === documentsRequired.length) {
        setSubmitButtonEnabled(true);
        setFilesToSubmit(files!);
      }
    } else {
      setSubmitButtonEnabled(false);
    }
  };

  return (
    <Container>
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Grid item>
          <Box marginTop={4}>
            <Typography variant="h6" gutterBottom>
              We require the following document{documentsRequired.length > 1 ? "s" : ""} to verify your identity:
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Divider className={classes.bottomDivider} />
        </Grid>
        {documentsRequired.map((doc, index) => (
          <Grid item key={index}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
              <RequiredDocument document={doc}></RequiredDocument>
            </Grid>
          </Grid>
        ))}
        <Grid item>
          <Divider className={classes.bottomDivider} />
        </Grid>
        <Grid item>
          <DocumentAdditionComponent fileTypesAvailable={documentsRequired} allFilesUploaded={allFilesUploaded} />
        </Grid>
      </Grid>
    </Container>
  );
};
