import { ICountry, CountryDefault } from "./ICountry";

export interface IAddress {
  line1: string;
  line2: string;
  line3: string;
  city: string;
  postcode: string;
  county: string;
  country: ICountry;
  latitude?: number;
  longitude?: number;
}

export const AddressDefault: IAddress = {
  line1: "",
  line2: "",
  line3: "",
  city: "",
  postcode: "",
  county: "",
  country: CountryDefault,
  latitude: 0,
  longitude: 0,
};

export const IsValid = (address: IAddress) => {
  return (
    address.line1.length > 0 && address.city.length > 0 && (address.country.countryCode !== CountryDefault.countryCode || address.postcode.length > 0)
  );
};
