import { ApiService, HttpResponse } from "./ApiService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IStripeProduct } from "../interfaces/payments/IStripeProduct";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export async function GetActive() {
  return await ApiService().Get<IStripeProduct[]>(`${ClientSettings.PaymentsPrivateApiUrl}/stripeproducts/active`);  
}

export async function GetUnAuthedActive() {
  return await ApiService().Get<IStripeProduct[]>(`${ClientSettings.LotPublicApiUrl}/stripeproducts/active`);  
}
