import { makeStyles } from "@material-ui/core/styles";

const FeaturedStyles = makeStyles((theme) => ({
  featuredContainer: {
    marginBottom: theme.spacing(12),
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(12),
  },
  showMoreContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(12),
  },
}));

export default FeaturedStyles;
