import { useSignalR, SignalRHub } from "../components/view-lot/hooks/useSignalR";
import React from "react";
import { useSnackbar } from "notistack";

const useNotificationsReceiver = () => {
  const privateMessage = useSignalR(SignalRHub.PrivateUser)();

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (enqueueSnackbar && privateMessage) {
      enqueueSnackbar(privateMessage.message, { variant: "info" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateMessage?.timeStamp]);
};

export { useNotificationsReceiver };
