import { makeStyles } from "@material-ui/core/styles";

export const NavbarAuthenticationStyles = makeStyles(theme => ({
  authentication: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      paddingRight: "8px"
    },
    marginLeft: "auto",
  },
}));
