import React from "react";

import { AuctionTimeStyles } from "./styles/AuctionTimeStyles";
import { Box, Typography } from "@material-ui/core";

interface WeekCommencingTabPanelProps {
  selectedTabIndex: number;
  tabIndex: number;
  tabTitle: string;
}

const AuctionTimesTabPanel: React.FC<WeekCommencingTabPanelProps> = ({
  selectedTabIndex,
  tabIndex,
  tabTitle,
  children,
}) => {
  var classes = AuctionTimeStyles();

  return (
    <div
      className={classes.tabContainer}
      role="tabpanel"
      hidden={selectedTabIndex !== tabIndex}
      aria-labelledby={`scrollable-auto-tab-${tabIndex}`}
    >
      {selectedTabIndex === tabIndex && (
        <Box p={3}>
          <Typography variant="h4" align="left">
            {tabTitle}
          </Typography>
          <>{children}</>
        </Box>
      )}
    </div>
  );
};

export default AuctionTimesTabPanel;
