import * as React from "react";
import { Step, Stepper, StepLabel, Container, Typography, MobileStepper, Box, Paper, Grid, Hidden, Divider } from "@material-ui/core";
import { AppContext } from "../../contexts/AppContext";
import { ImportantInformation } from "./steps/ImportantInformation";
import * as BidderService from "../../services/BidderService";
import { useHistory } from "react-router-dom";
import { IValidation } from "../../interfaces/IValidation";
import { BidderRegistrationForm } from "./steps/BidderRegistrationForm";
import { LoadingComponent } from "../loading/LoadingComponent";
import { ICreateBidder, CreateBidderDefault } from "../../interfaces/bidders/ICreateBidder";
import { RegistrationSteps } from "./steps/RegistrationSteps";
import { useSnackbar } from "notistack";
import { StepContainer } from "./StepContainer";
import { IdVerificationContainer } from "./steps/id-verification/IdVerificationContainer";
import { DocumentRemediationForm } from "./steps/DocumentRemediationForm";
import { BidderStatus, IBidder } from "../../interfaces/bidders/IBidder";
import { RegistrationStep } from "../../services/BidderService";
import { BidderPaymentForm } from "./steps/BidderPaymentForm";

export const BidderRegistration: React.FC = () => {
  const context = React.useContext(AppContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [registeredBidder, setRegisteredBidder] = React.useState<IBidder | undefined>(undefined);
  const [initializeBidder, setInitializeBidder] = React.useState(false);
  const [step, setStep] = React.useState<RegistrationSteps>(RegistrationSteps.Unknown);
  const [creatingBidder, setCreatingBidder] = React.useState<boolean>(false);
  const [bidder, setBidder] = React.useState<ICreateBidder>(CreateBidderDefault);

  const [detailsValidation, setDetailsValidation] = React.useState<IValidation>({} as IValidation);
  const [canSubmitDetails, setCanSubmitDetails] = React.useState(true);

  // REMOVED FOR COMMERCIAL CHANGES IN CR28
  //const [accountType, setAccountType] = React.useState<UpdateBidderAccountType>({ accountType: AccountType.Subscribed });
  //const stepList: Array<string> = ["Important Information", "Details", "Account Type", "Payment", "ID Submission", "ID Verification"];
  const stepList: Array<string> = ["Important Information", "Details", "Payment", "ID Submission", "ID Verification"];

  React.useEffect(() => {
    if (step === RegistrationSteps.Unknown && context) {
      setInitializeBidder(true);
    }
  }, [context, step]);

  React.useEffect(() => {
    if (initializeBidder) {
      //checkDuplication();


      BidderService.CheckDuplication()
        .then(dupelicateDetails => {
          if (dupelicateDetails.parsedBody?.duplicate) {
            setStep(RegistrationSteps.Duplicate);
          } else {

            // Load the bidder
            BidderService.GetBidderValidationDetails()
              .then(details => {
                setRegisteredBidder(details.parsedBody);

                if (step != RegistrationSteps.Duplicate) {
                  setStep(RegistrationStep(details.parsedBody));
                }

                if (context.bidderState.bidder.id && details.parsedBody) {
                  context.bidderState.bidder.bidderStatus = details.parsedBody.bidderStatus;
                  context.bidderState.bidder.verification.status = details.parsedBody.verification.status;
                }
              })
              .catch((error: Response) => {
                //TODO:  app insights
                if (error.status === 404 && step != RegistrationSteps.Duplicate) {
                  setStep(RegistrationSteps.Start);
                } else {
                  enqueueSnackbar("We have been unable to re-load your details due to an error. Please close your browser and try again", {
                    variant: "error",
                  });
                }
              });
          }
        })
        .catch((duplicateError: Response) => {
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeBidder]);

  React.useEffect(() => {
    if (creatingBidder) {
      setCreatingBidder(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidder, detailsValidation]);

  // Consider moving this into the bidder section.
  React.useEffect(() => {
    function doCreateBidder() {
      BidderService.CreateBidder(bidder)
        .then(result => {
          if (result.parsedBody !== undefined) {
            context.bidderState.bidderRegistered(result.parsedBody);
            setRegisteredBidder(result.parsedBody);
            // REMOVED FOR COMMERCIAL CHANGES IN CR28
            // setStep(RegistrationSteps.AccountType);
            setStep(RegistrationSteps.Payment);
          }
          enqueueSnackbar("Your details have been saved!", { variant: "info" });
        })
        .catch(async response => {
          if (response.status === 400) {
            setDetailsValidation(response.validation);
            enqueueSnackbar("Some of the information you have provided is not valid. Please correct it and then save it again.", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("Sorry, but something went wrong while trying to save your details. Please try again.", { variant: "error" });
          }
        })
        .finally(() => {
          setCreatingBidder(false);
        });
    }

    if (creatingBidder) {
      doCreateBidder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingBidder]);

  const idVerificationDocumentsSubmitted = () => {
    context.bidderState.bidder.bidderStatus = BidderStatus.AwaitingValidation;
    setStep(RegistrationSteps.AwaitingVerification);
  };

  const handleProceedToRegistrationClicked = () => {
    setStep(RegistrationSteps.Details);
  };

  const handleBidderDetailsSubmitClicked = () => {
    setCreatingBidder(true);
  };

  const handleBidderPaymentSubmitted = () => {
    setStep(RegistrationSteps.IdValidation);
  };

  // REMOVED FOR COMMERCIAL CHANGES IN CR28
  // const handleAccountTypeSubmitClicked = () => {
  //   BidderService.UpdateBidderAccountType(accountType).then(() => {
  //     //Update context bidder object
  //     context.bidderState.bidder.accountType = accountType.accountType;

  //     //Set the next step
  //     if (accountType.accountType === AccountType.Free) {
  //       context.bidderState.bidder.bidderStatus = BidderStatus.AwaitingIdenfificaiton;
  //       setStep(RegistrationSteps.IdValidation);
  //     } else if (accountType.accountType === AccountType.Subscribed) {
  //       context.bidderState.bidder.bidderStatus = BidderStatus.AwaitingPayment;
  //       setStep(RegistrationSteps.Payment);
  //     }
  //   });
  // };

  const bidderRegistrationFormContents = () => {
    return (
      <>
        {creatingBidder && <LoadingComponent label="Creating account..."></LoadingComponent>}
        <BidderRegistrationForm bidder={bidder} setBidder={setBidder} validation={detailsValidation} isValid={setCanSubmitDetails} />
      </>
    );
  };

  const getActiveStep = () => {
    switch (step) {
      case RegistrationSteps.FailedValidation:
        return RegistrationSteps.IdValidation - 1;
    }
    return step - 1;
  };

  const renderStep = () => {
    switch (step) {
      case RegistrationSteps.Unknown:
        return (
          <StepContainer
            nextButtonVisible={false}
            title="Loading your details"
            subTitle="We are just looking up who you are, and where you are in the registration process."
            handleNext={() => { }}
          >
            <LoadingComponent label="Please Wait"></LoadingComponent>
          </StepContainer>
        );

      case RegistrationSteps.Details:
        return (
          <StepContainer
            nextButtonEnabled={canSubmitDetails && !creatingBidder}
            title="Your Details"
            nextButtonText="Save My Details"
            subTitle="Please complete your address details below, we will verify that your details match your proof of ID"
            handleNext={handleBidderDetailsSubmitClicked}
          >
            {bidderRegistrationFormContents()}
          </StepContainer>
        );

      // REMOVED FOR COMMERCIAL CHANGES IN CR28
      // case RegistrationSteps.AccountType:
      // return (
      //   <StepContainer
      //     nextButtonEnabled={canSubmitDetails && !creatingBidder}
      //     title="Account"
      //     nextButtonText="Select"
      //     subTitle="Select the type of account you would like to register for?"
      //     handleNext={handleAccountTypeSubmitClicked}
      //   >
      //     <AccountTypeForm setAccountType={setAccountType} />
      //   </StepContainer>
      // );

      case RegistrationSteps.Payment:
      return (
        <StepContainer title="Select Your Subscription Plan" subTitle="You need to purchase a subscription before you can bid on any lots" nextButtonVisible={false}>
          <BidderPaymentForm paymentStepPostback={handleBidderPaymentSubmitted}></BidderPaymentForm>
        </StepContainer>
      );

      case RegistrationSteps.IdValidation:
        return <IdVerificationContainer bidder={registeredBidder!} documentsSubmitted={idVerificationDocumentsSubmitted} />;

      case RegistrationSteps.AwaitingVerification:
        return (
          <StepContainer
            nextButtonText="Finish"
            handleNext={() => {
              history.push("/");
            }}
            title="Awaiting Validation"
            subTitle="Thank you for registering"
          >
            <Container>
              <Typography variant="h5" align="left">
                There's nothing for you to do yet.
              </Typography>

              <Typography variant="subtitle1" align="left" style={{ paddingTop: "16px" }}>
                Your documents will be checked and verified; you will receive an email upon completion.
              </Typography>
            </Container>
          </StepContainer>
        );

      case RegistrationSteps.FailedValidation:
        return (
          <DocumentRemediationForm
            documents={registeredBidder!.verification.verificationDocumentSubmission}
            documentsSubmitted={idVerificationDocumentsSubmitted}
          />
        );

      case RegistrationSteps.Complete:
        return (
          <StepContainer
            nextButtonText="Complete"
            handleNext={() => {
              history.push("/");
            }}
            title="Registration Completed"
            subTitle="You have already registered. There is no other information required from you, feel free to continue using the site!"
          >
            <></>
          </StepContainer>
        );

      case RegistrationSteps.Duplicate:
        return (
          <StepContainer
            title="Sorry..."
            subTitle="It looks like you are already signed up with us. Please try logging in again."
            nextButtonVisible={false}
          >
            <></>
          </StepContainer>

        );

      case RegistrationSteps.Start:
      default:
        return <ImportantInformation handleProceedToRegistration={handleProceedToRegistrationClicked}></ImportantInformation>;
    }
  };

  // const checkDuplication = () => {

  //   BidderService.CheckDuplication()
  //     .then(details => {

  //       console.log('dupe: ', details.parsedBody?.duplicate);


  //       if (details.parsedBody?.duplicate) {
  //         console.log('logic check1');


  //         setStep(RegistrationSteps.Duplicate);
  //       }
  //     })
  //     .catch((error: Response) => {
  //     });

  // };

  return (
    <>
      {step != RegistrationSteps.Duplicate ? (
        <Container maxWidth="lg">
          <Box display={{ xs: "none", sm: "block" }}>
            <Stepper activeStep={getActiveStep()} alternativeLabel>
              {stepList.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box display={{ xs: "block", sm: "none" }}>
            <MobileStepper
              style={{ justifyContent: "center" }}
              steps={stepList.length}
              activeStep={getActiveStep()}
              position="static"
              backButton={<></>}
              nextButton={<></>}
            />
          </Box>
        </Container>
      ) : (

        <Box mt={5}></Box>
      )}
      {renderStep()}
    </>
  );
};
