import { makeStyles } from "@material-ui/core/styles";

export const NavigationStyles = makeStyles(theme => ({
  logo: {
    maxHeight: "48px",
    width: "100%",
    maxWidth: "152px",
  },
  navigationBar: {
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingRight: "0"
    },
  },
  secondaryNavigationContainer: {
    background: "#393939",
    fontSize: "12px",
    overflowX: "auto",
  },
  secondaryNavigationItem: {
    color: "#E1E1E1",
    fontSize: "12px",
    paddingRight: "12px",
    paddingLeft: "12px",
  },
}));
