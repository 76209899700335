import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Typography, Chip, Box, useMediaQuery, Theme, Link, Grid, Divider } from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";

import * as Styles from "./styles/AuctionItemStyles";

import { currencyFormat, timeLeftFormat, ellipsisText } from "../../helpers/text-format/TextFormat";
import moment, { Duration } from "moment";

import { IInitialState } from "../../contexts/AppContext";
import { AddToWatchlistIcon, RemoveFromWatchlistIcon } from "../icons/HomePageIcons";
import { useLotMedia, Media } from "../../services/MediaService";
import { FeaturedAuction } from "../../services/AuctionService";
import { adjustedCurrentTime } from "../../helpers/TimeSync";
import { useIsLoggedIn } from "../../helpers/ActiveUserHelper";

interface AuctionItemProps {
  item: FeaturedAuction;
  watchlistHandler: (e: any, auctionId: string) => void;
  context: IInitialState;
}

export const FeaturedAuctionItem: React.FC<AuctionItemProps> = ({ item, watchlistHandler, context }) => {
  const classes = Styles.AuctionItemStyles();
  const loggedIn = useIsLoggedIn();

  const [isWatching, setIsWatching] = useState(
    context.bidderState.checkWatchlist({
      auctionId: item.id,
    })
  );

  const [currentDate, setCurrentDate] = useState(adjustedCurrentTime());
  const [endDate, setEndDate] = useState(moment(item.auctionEnd));
  const [timeLeftMS, setTimeLeftMS] = useState(0);
  const [timeLeftDuration, setTimeLeftDuration] = useState<Duration>();
  const [mediaUrl, setMediaUrl] = useState<string>("");
  const [price, setPrice] = useState(item.currentHighestBid ? item.currentHighestBid.amount : item.startPrice);
  const [media] = useLotMedia(item.lotId, item.lotMediaCacheName);

  useEffect(() => {
    setCurrentDate(adjustedCurrentTime());
  }, []);

  useEffect(() => {
    setEndDate(moment(item.auctionEnd));
  }, [item.auctionEnd]);

  useEffect(() => {
    setTimeLeftMS(endDate.diff(currentDate));
  }, [endDate, currentDate]);

  useEffect(() => {
    setTimeLeftDuration(moment.duration(timeLeftMS));
  }, [timeLeftMS]);

  useEffect(() => {
    setIsWatching(
      context.bidderState.checkWatchlist({
        auctionId: item.id,
      })
    );
  }, [context, item.id]);

  useEffect(() => {
    if (media !== null && media.length > 0) {
      const primaryMedia: Media[] = media.filter(item => {
        return item.order === 0;
      });
      if (primaryMedia.length > 0) {
        setMediaUrl(primaryMedia[0].versions.thumbnail);
      } else if (primaryMedia.length > 1) {
        setMediaUrl(primaryMedia[0].versions.thumbnail);
      } else {
        setMediaUrl(media[0].versions.thumbnail);
      }
    }
  }, [item.id, media]);

  const getWatchingContainerClasses = () => {
    return `${classes.watchingContainer} ${isWatching ? classes.watching : classes.notWatching}`;
  };

  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const lg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const getTitleLength = () => {
    if (xs) {
      return 75;
    }

    if (sm) {
      return 77;
    }

    if (md) {
      return 55;
    }

    if (lg) {
      return 85;
    }

    return 85;
  };

  const imageContainer = () => {
    if (mediaUrl !== undefined) {
      return <CardMedia className={classes.cardMedia} component="img" alt={item.title} image={mediaUrl} title={item.title} />;
    }
    return <></>;
  };

  useEffect(() => {
    setPrice(item.currentHighestBid ? item.currentHighestBid.amount : item.startPrice);
  }, [price, item]);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardMediaContainer}>
        <Link component={RouterLink} to={`/Lot/${item.id}`}>
          {imageContainer()}
        </Link>
        {loggedIn && (
          <Box
            className={getWatchingContainerClasses()}
            onClick={(e: any) => {
              watchlistHandler(e, item.id);
            }}
          >
            {isWatching ? <RemoveFromWatchlistIcon /> : <AddToWatchlistIcon />}
          </Box>
        )}
      </CardContent>
      <CardContent>
        <Link component={RouterLink} to={`/Lot/${item.id}`} className={classes.noUnderline}>
          <Box className={classes.titleBox}>
            <Chip
              size="small"
              color="primary"
              label={`CATEGORY ${item.damageCategory ? item.damageCategory : "Unknown"}`}
              className={classes.category}
            />
          </Box>
          <Typography variant="h6" color="textSecondary" component="h3" className={classes.title} gutterBottom>
            {ellipsisText(item.title, getTitleLength())}
          </Typography>
          <Typography variant="overline" color="textSecondary" component="p" className={classes.location}>
            {item.location}
          </Typography>
          <Divider className={classes.spacing} />
          <Grid container spacing={0}>
            <Grid item xs={6} className={classes.timeLeftGrid}>
              <Typography variant="h6" component="h1" color="primary" className={classes.currentBitAmount}>
                {currencyFormat(price)}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.timeLeftGrid}>
              {loggedIn && (
                <Typography variant="h6" color="textSecondary" component="h1" className={classes.timeLeft}>
                  {timeLeftFormat(timeLeftDuration)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Link>
      </CardContent>
    </Card>
  );
};
