import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slick-carousel/_hacks.css";
import "react-image-lightbox/style.css";
import MediaRender from "./MediaRender";
import MobileMediaRender from "./MobileMediaRender";
import LargeMedia from "./LargeMedia";
import { Hidden, Container } from "@material-ui/core";
import Lightbox from "react-image-lightbox";
import { NextArrow, PrevArrow, PrevArrowLessThanFourImages, NextArrowLessThanFourImages } from "../../icons/CarouselIcons";
import { LoadingComponent } from "../../loading/LoadingComponent";
import { useLotMedia, Media } from "../../../services/MediaService";

interface MediaContainerProps {
  lotId: string;
  lotMediaCacheName: string;
}

const MediaContainer: React.FC<MediaContainerProps> = ({ lotId, lotMediaCacheName }) => {
  const [mediaIndex, setMediaIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const [media, mediaLoading] = useLotMedia(lotId, lotMediaCacheName);
  const [orderedMedia, setOrderedMedia] = React.useState<Media[]>([]);

  React.useEffect(() => {
    var ordered = [...(media ?? [])].sort((a, b) => a.order - b.order);
    setOrderedMedia(ordered);
  }, [media]);

  const sliderSettings = () => {
    if (orderedMedia.length < 4) {
      const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        dots: true,
        nextArrow: <NextArrowLessThanFourImages />,
        prevArrow: <PrevArrowLessThanFourImages />,
      };
      return settings;
    } else {
      const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        speed: 500,
        draggable: true,
        dotsClass: "slick-dots slick-thumb",
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              infinite: true,
              dots: true,
            },
          },
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      };
      return settings;
    }
  };

  return mediaLoading ? (
    <LoadingComponent label="Loading lot" />
  ) : orderedMedia.length >= 4 ? (
    <>
      <Hidden smDown>
        <div onClick={() => setLightboxOpen(true)}>
          <LargeMedia media={orderedMedia} mediaIndex={mediaIndex} />
        </div>
        <Slider {...sliderSettings()}>
          {orderedMedia.map((image: Media, index: number) => (
            <MediaRender key={index} media={image} onChangeMediaIndex={setMediaIndex} mediaIndex={index} />
          ))}
        </Slider>
      </Hidden>

      <Hidden mdUp>
        <Slider {...sliderSettings()}>
          {orderedMedia.map((media: Media, index: number) => (
            <div
              key={index}
              onClick={() => {
                setMediaIndex(index);
                setLightboxOpen(true);
              }}
            >
              <MobileMediaRender key={index} media={media} />
            </div>
          ))}
        </Slider>
      </Hidden>

      {lightboxOpen && (
        <Lightbox
          mainSrc={orderedMedia[mediaIndex].versions.fullsize}
          nextSrc={orderedMedia[(mediaIndex + 1) % orderedMedia.length].versions.fullsize}
          prevSrc={orderedMedia[(mediaIndex + orderedMedia.length - 1) % orderedMedia.length].versions.fullsize}
          onCloseRequest={() => {
            setLightboxOpen(false);
          }}
          onMovePrevRequest={() => {
            setMediaIndex((mediaIndex + orderedMedia.length - 1) % orderedMedia.length);
          }}
          onMoveNextRequest={() => {
            setMediaIndex((mediaIndex + 1) % orderedMedia.length);
          }}
          reactModalStyle={{
            overlay: { zIndex: "9999" },
          }}
        />
      )}
    </>
  ) : (
    <>
      <Container>
        <Slider {...sliderSettings()}>
          {orderedMedia.map((image: Media, index: number) => (
            <div
              key={index}
              onClick={() => {
                setMediaIndex(index);
                setLightboxOpen(true);
              }}
            >
              <MobileMediaRender key={index} media={image} />
            </div>
          ))}
        </Slider>

        {lightboxOpen && (
          <Lightbox
            mainSrc={orderedMedia[mediaIndex].versions.fullsize}
            nextSrc={orderedMedia[(mediaIndex + 1) % orderedMedia.length].versions.fullsize}
            prevSrc={orderedMedia[(mediaIndex + orderedMedia.length - 1) % orderedMedia.length].versions.fullsize}
            onCloseRequest={() => {
              setLightboxOpen(false);
            }}
            onMovePrevRequest={() => {
              setMediaIndex((mediaIndex + orderedMedia.length - 1) % orderedMedia.length);
            }}
            onMoveNextRequest={() => {
              setMediaIndex((mediaIndex + 1) % orderedMedia.length);
            }}
            reactModalStyle={{
              overlay: { zIndex: "9999" },
            }}
          />
        )}
      </Container>
    </>
  );
};

export default MediaContainer;
