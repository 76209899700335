import * as React from "react";
import { IPublicClientApplication, AccountInfo, PublicClientApplication, Configuration, LogLevel } from "@azure/msal-browser";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../services/SettingsService";

export interface IMsalContext {
  instance: IPublicClientApplication;
  loginInProgress: boolean;
  logoutInProgress: boolean;
  accounts: AccountInfo[];
}

const clientSettings: ISalvageClientSettings = GetClientSettings();

const MSAL_CONFIG: Configuration = {
  auth: {
    authority: clientSettings.B2CSettings.TenantSignIn,
    clientId: clientSettings.B2CSettings.ClientId,
    redirectUri: window.origin,
    postLogoutRedirectUri: window.origin,
    knownAuthorities: [clientSettings.B2CSettings.TenantSignIn],
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

const msalClient: IPublicClientApplication = new PublicClientApplication(MSAL_CONFIG);

const defaultMsalContext: IMsalContext = {
  instance: msalClient,
  loginInProgress: true,
  logoutInProgress: false,
  accounts: [],
};

export const MsalContext = React.createContext<IMsalContext>(defaultMsalContext);
export const MsalConsumer = MsalContext.Consumer;
export const MsalClient = msalClient;
