import { makeStyles } from "@material-ui/core";
import * as Image from "../../../assets/grey_door_car.jpg";

export const InformationPagesStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  orangeBox: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    background: `linear-gradient(rgba(236, 110, 19, 0.75) 0.5%,rgba(236, 110, 19,0.75)),url('${Image}')`,
    padding: "40px",
  },
  whiteText: { color: theme.palette.common.white },
  container: {
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  user: {
    textAlign: "center",
  },
  amount: {
    textAlign: "center",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginRight: "10px",
  },
  orangeText: {
    color: theme.palette.primary.main,
  },
  greyText: {
    color: theme.palette.secondary.dark,
  },
  blackText: {
    color: theme.palette.common.black,
  },
  list: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  unorderedList: {
    listStyleType: "none",
    fontWeight: "bold",
  },
  table: {
    width: "100%",
  },
  tableContent: {
    textAlign: "left",
  },
  tablePadding: {
    paddingRight: "10px",
    paddingBottom: "10px",
  },
  textPadding: {
    paddingBottom: "12px",
  },
  textPaddingTop: {
    paddingTop: "24px",
  },
  indentText: {
    paddingLeft: "32px",
  },
  bulletPoints: {
    "& .MuiListItemIcon-alignItemsFlexStart": {
      marginTop: "0px",
    },
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  acceptCookiesButton: {
    marginTop: "8px",
    marginRight: "8px",
    textTransform: "none",
  },
  rejectCookiesButton: {
    marginTop: "8px",
    textTransform: "none",
  },
  bulletList: {
    "& li": {
      marginBottom: "10px",
    },
  },
}));
