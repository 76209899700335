import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Box } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import * as Styles from "./styles/PagesStyles";

//Components
import HeroImageNavigation from "../components/layout/HeroImageNavigation";
import Footer from "../components/layout/Footer";
import StandardNavigation from "../components/layout/StandardNavigation";
import InformationNavigation from "../components/layout/InformationNavigation";
import RegisteredRoute from "./RegisteredRoute";
import WebsiteNotifications from "../components/core/WebsiteNotifications";
import ContinueRegistration from "../components/notifications/ContinueRegistration";
import PaymentResultContainer from "../components/payment/PaymentResultContainer";
import SuspensionNotification from "../components/notifications/SuspensionNotification";
import CookieWrapper from "../components/core/CookieWrapper";

//Pages
import AboutUs from "../components/pages/AboutUs";
import AuctionFees from "../components/pages/AuctionFees";
import AuctionsWon from "../components/pages/AuctionsWon";
import AuctionTimes from "../components/pages/AuctionTimes";
import BidHistory from "../components/pages/BidHistory";
import ConfirmAccount from "../components/pages/ConfirmAccount";
import Cookies from "../components/pages/Cookies";
import HelpAndFaq from "../components/pages/HelpAndFaq";
import Homepage from "../components/pages/Homepage";
import LotViewPage from "../components/pages/LotViewPage";
import MyAccount from "../components/pages/MyAccount";
import NotAuthorised from "../components/pages/NotAuthorised";
import NotFoundPage from "../components/pages/NotFoundPage";
import PrivacyPolicy from "../components/pages/PrivacyPolicy";
import Register from "../components/pages/Register";
import Renewal from "../components/pages/Renewal";
import RenewalProcess from "../components/pages/RenewalProcess";
import SalesDocument from "../components/pages/SalesDocument";
import Search from "../components/pages/Search";
import SubscriptionHistory from "../components/pages/SubscriptionHistory";
import TermsConditions from "../components/pages/TermsConditions";
import Watchlist from "../components/pages/Watchlist";
import MemberNotification from "../components/notifications/MemberNotification";
import ScrollToTop from "../components/core/ScrollToTop";
import PaymentReceipt from "../components/pages/PaymentReceipt";
import DisconnectedNotification from "../components/notifications/DisconnectedNotification";
import UpdateTerms from "../components/pages/UpdateTerms";
import SavedSearches from "../components/pages/SavedSearches";
import Archived from "../components/pages/Archived";

const Pages = (): any => {
  const classes = Styles.PagesStyles();

  return (
    <SnackbarProvider maxSnack={4}>
      <WebsiteNotifications>
        <CookieWrapper>
          <PaymentResultContainer>
            <ScrollToTop />
            <ContinueRegistration />
            <SuspensionNotification />
            <MemberNotification />
            <DisconnectedNotification />
            <Box component="div" className={classes.pageContainer}>            
              <Route
                render={({ location }) => {
                  switch (location.pathname) {
                    case "/": {
                      return <HeroImageNavigation />;
                    }
                    case "/AboutUs": {
                      return <InformationNavigation title={"About SalvageMarket"} />;
                    }
                    case "/HelpAndFaq": {
                      return <InformationNavigation title={"Help & FAQ"} />;
                    }
                    case "/TermsConditions": {
                      return <InformationNavigation title={"Terms & Conditions"} />;
                    }
                    case "/AuctionFees": {
                      return <InformationNavigation title={"Auction Fees"} />;
                    }
                    case "/PrivacyPolicy": {
                      return <InformationNavigation title={"Privacy Policy"} />;
                    }
                    case "/MyAccount": {
                      return <InformationNavigation title={"My Account"} />;
                    }
                    case "/AuctionTimes": {
                      return <InformationNavigation title={"Auction times"} />;
                    }
                    case "/PaymentHistory": {
                      return <InformationNavigation title={"Payment History"} />;
                    }
                    case "/Cookies": {
                      return <InformationNavigation title={"Cookie Policy"} />;
                    }
                    default: {
                      return <StandardNavigation />;
                    }
                  }
                }}
              />
              

              <Switch>
                <RegisteredRoute path="/" component={Homepage} exact={true} />
                <RegisteredRoute path="/NotAuthorised" component={NotAuthorised} exact={true} />
                <RegisteredRoute path="/lot/:id" component={LotViewPage} exact={true} />
                <RegisteredRoute path="/MyAccount" component={MyAccount} exact={true} />
                <RegisteredRoute path="/Watchlist" component={Watchlist} exact={true} />
                <RegisteredRoute path="/Renewal" component={Renewal} exact={true} />
                <RegisteredRoute path="/RenewalProcess" component={RenewalProcess} exact={true} />
                <RegisteredRoute path="/search" component={Search} exact={true} />
                <RegisteredRoute path="/search/saved" component={SavedSearches} exact={true} />
                <RegisteredRoute path="/SubscriptionHistory" component={SubscriptionHistory} exact={true} />
                <RegisteredRoute path="/BidHistory" component={BidHistory} exact={true} />
                <RegisteredRoute path="/AuctionsWon" component={AuctionsWon} exact={true} />
                <RegisteredRoute path="/SalesDocument/:id" component={SalesDocument} exact={true} />
                <RegisteredRoute path="/Payments/Document/:id/(status)?/:status?" component={PaymentReceipt} exact={true} />
                <Route path="/Register" component={Register} exact={true} />
                <Route path="/UpdateTerms" exact={true} component={UpdateTerms} />
                <Route path="/AuctionTimes" exact={true} component={AuctionTimes} />
                <Route path="/AboutUs" exact={true} component={AboutUs} />
                <Route path="/TermsConditions" exact={true} component={TermsConditions} />
                <Route path="/AuctionFees" exact={true} component={AuctionFees} />
                <Route path="/PrivacyPolicy" exact={true} component={PrivacyPolicy} />
                <Route path="/HelpAndFaq" exact={true} component={HelpAndFaq} />
                <Route path="/Cookies" exact={true} component={Cookies} />
                <Route path="/ConfirmAccount" component={ConfirmAccount} exact={true} />
                <Route path="/ResetPassword" component={ConfirmAccount} exact={true} />
                <Route path="/Archived" exact={true} component={Archived} />
                <Route path="*" component={NotFoundPage} />                
              </Switch>
            </Box>
            <Footer />
          </PaymentResultContainer>
        </CookieWrapper>
      </WebsiteNotifications>
    </SnackbarProvider>
  );
};

export default Pages;
