import React from "react";
import { useHistory } from "react-router";
import * as queryString from "query-string";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import EventIcon from "@material-ui/icons/Event";
import { AuctionTimeStyles } from "./styles/AuctionTimeStyles";
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Link } from "@material-ui/core";
import { IAuctionTimesDay } from "../../interfaces/auctionTimes/IAuctionTimesDay";
import { DateToDateString, DateToTimeString } from "../../helpers/dateHelper";
import { IAuctionTimesItem } from "../../interfaces/auctionTimes/IAuctionTimesItem";
import { ICS } from "../ics/ics";

interface AuctionTimeTableProps {
  auctionDay: IAuctionTimesDay;
}

const AuctionTimesTable: React.FC<AuctionTimeTableProps> = ({ auctionDay }) => {
  var classes = AuctionTimeStyles();
  const history = useHistory();

  const createReminder = (auction: IAuctionTimesItem) => {
    const datetimeStart = new Date(auction.beginsClosing);
    const datetimeEnd = new Date(auction.beginsClosing);

    const filename = `${auction.memberName} Auction @${DateToTimeString(auction.beginsClosing)}`;
    const dateTimeStamp = new Date().getTime();
    const dateTimeStart = datetimeStart.getTime();
    const dateTimeEnd = datetimeEnd.getTime();
    const summary = `${auction.memberName} Auction @${DateToTimeString(auction.beginsClosing)}`;
    const description = `${auction.memberName} Auction @${DateToTimeString(auction.beginsClosing)} `;

    const ics = new ICS(filename, summary, description, dateTimeStamp, dateTimeStart, dateTimeEnd);
    ics.exportIcsWithTimezone();
  };

  return (
    <>
      <Typography variant="subtitle1" color="primary" align="left" gutterBottom>
        <ArrowForwardIcon className={classes.wrapIcon} color="primary" />
        {auctionDay.auctionTimesDayTitle}
      </Typography>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Begins closing</TableCell>
              <TableCell style={{ width: "60%" }}>Seller</TableCell>
              <TableCell># for sale</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auctionDay.auctionTimesItems.map((auction, index) => (
              <TableRow key={`auction-times-items${index}`}>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      createReminder(auction);
                    }}
                  >
                    <EventIcon />
                    <Typography variant="body1" color="textPrimary">
                      {DateToTimeString(auction.beginsClosing)}
                    </Typography>
                  </IconButton>
                </TableCell>
                <TableCell>
                  <div className={classes.sellerContainer}>
                    <img className={classes.sellerIcon} alt={auction.memberName} src={auction.memberLogoUrl} />
                    <Typography variant="body1">{auction.memberName}</Typography>
                  </div>
                </TableCell>
                <TableCell>
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const searchLocation = {
                        pathname: "/Search",
                        search: auction.bucketId
                          ? queryString.stringify({
                            bucketId: auction.bucketId,
                            bucketDetails: `${auction.memberName} - ${DateToDateString(auction.beginsClosing)}`,
                          })
                          : "",
                      };
                      history.push(searchLocation);
                    }}
                  >
                    {auction.auctionCount}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AuctionTimesTable;
