import React from "react";
import { Button, Grid, Tooltip, Typography, withStyles } from "@material-ui/core";
import { getStaticIncrements } from "../../../services/AuctionService";

interface BidIncrementTooltip {

}

const BidIncrementTooltip: React.FC<BidIncrementTooltip> = ({  }) => {
   //const classes = Styles.IconStyles();
   const [bidIncrements, setBidIncrements] = React.useState<any[]>([]);
   const [tooltipOpen, setTooltipOpen] = React.useState(false);

   React.useEffect(() => {   
    getStaticIncrements().then(result => {   
      if(result.parsedBody != null){  
        setBidIncrements(result.parsedBody);         
      }
    }).catch(async response => {
      
    });
  }, []);

   const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      minWidth: 392,      
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      textAlign: 'center',
      borderRadius: 16,    
    },
  }))(Tooltip);

  const handleTooltipToggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div>
    <HtmlTooltip open={tooltipOpen} onClose={() => setTooltipOpen(false)}
        title={
          <React.Fragment>            
            <Grid container>
              <Grid item xs={6}> 
                <b>Bid Range (£)</b>
              </Grid>
              <Grid item xs={6}> 
                <b>Fixed Bid Increment (£)</b>
              </Grid>
              </Grid>
              {bidIncrements.map((incrementItem: any, index: number) => (
                     <Grid container key={index}>                
              <Grid item xs={6}> 
              {incrementItem.lowerBand == 25001 ? ( 
                <Typography>{incrementItem.lowerBand}+</Typography>
              ) : (
                <Typography>{incrementItem.lowerBand}-{incrementItem.upperBand}</Typography>
              )}
              </Grid>
              <Grid item xs={6}> 
              <Typography>{incrementItem.bidIncrement}</Typography>
              </Grid>         
              </Grid> 
                             ))} 
          
          </React.Fragment>
        }
      >
        <Button color="primary"  onClick={handleTooltipToggle}><Typography style={{ textDecoration: 'underline', textTransform: 'lowercase' }}>See bidding information</Typography></Button>
      </HtmlTooltip>
      </div>
  );
};

export { BidIncrementTooltip };
