import * as React from "react";
import { Container, Grid } from "@material-ui/core";

import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";
import { useMsal } from "../../authentication/MsalProvider";
import { IMsalContext } from "../../authentication/MsalContext";
import { GetClientSettings } from "../../services/SettingsService";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { LoadingComponent } from "../loading/LoadingComponent";
import { UpdateTermsForm } from "../update-terms-form/UpdateTermsForm";

function Archived() {


    return (
        <Container maxWidth={"lg"} fixed style={{ minHeight: "350px" }}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <h3>Your Account has been archived!</h3>
                    <p>Oops! it looks like your account has been archived due to the lack of recent activity. If you believe this to be incorrect or would like your account to be reactivated, please contact us via email.</p>
                    <p>Email: assist@salvagemarket.co.uk</p>
                </Grid>
            </Grid>
        </Container>
    );
}

export default withAITracking(reactPlugin, Archived, "Archived");
