import * as React from "react";

import { Container } from "@material-ui/core";

import * as AuctionService from "../../services/AuctionService";

import { IAuctionTimesWeek } from "../../interfaces/auctionTimes/IAuctionTimesWeek";
import AuctionTimesTabs from "./AuctionTimesTabs";
import AuctionTimesTabPanel from "./AuctionTimesTabPanel";
import AuctionTimesTable from "./AuctionTimesTable";

export const AuctionTimesComponent: React.FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [auctionTimesWeek, setAuctionTimesWeek] = React.useState<IAuctionTimesWeek[]>();

  React.useEffect(() => {
    AuctionService.GetAuctionTimes().then(result => {
      if (result.parsedBody !== undefined) {
        setAuctionTimesWeek(result.parsedBody);
      }
    });
  }, []);

  const handleChange = (clickEvent: React.ChangeEvent<{}>, newValue: number) => {
    clickEvent.preventDefault();
    setSelectedTabIndex(newValue);
  };

  return (
    <Container maxWidth={"lg"} fixed>
      <AuctionTimesTabs auctionWeeks={auctionTimesWeek} tabChangeCallback={handleChange} />

      {auctionTimesWeek !== undefined &&
        auctionTimesWeek.map((week, index) => {
          return (
            <AuctionTimesTabPanel
              key={`week-commencing-tabpanel-${index}`}
              selectedTabIndex={selectedTabIndex}
              tabIndex={index}
              tabTitle={week.auctionTimesWeekTitle}
            >
              {week.auctionTimesDays.map((day, index) => {
                return <AuctionTimesTable key={`auction-listings-${index}`} auctionDay={day} />;
              })}
            </AuctionTimesTabPanel>
          );
        })}
    </Container>
  );
};
