import React from "react";
import { useSnackbar } from "notistack";
import { LoadingComponent } from "../loading/LoadingComponent";
import { ReceiptPdf } from "./ReceiptPdf";
import { useBidderPaymentReceipt } from "../../services/ReceiptService";

interface ReceiptContainerProps {
  orderId: string;
  orderStatus: string | undefined;
}

export const ReceiptContainer: React.FC<ReceiptContainerProps> = ({ orderId, orderStatus }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [receipt, loadingReceipt] = useBidderPaymentReceipt(orderId, () => {
    enqueueSnackbar("There was a problem loading your receipt, please try again.", { variant: "error" });
  });

  return (
    <>
      {loadingReceipt && <LoadingComponent label="Loading document" />}
      {!loadingReceipt && receipt !== null && <ReceiptPdf receiptDetails={receipt} orderStatus={orderStatus} />}
    </>
  );
};
