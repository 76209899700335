export interface IBusiness {
  businessName: string;
  vatNumber: string;
  registrationNumber: string;
  atfNumber: string;
}

export const BusinessDefault: IBusiness = {
  businessName: "",
  vatNumber: "",
  registrationNumber: "",
  atfNumber: "",
};

export const isValid = (business: IBusiness) => {
  return business.businessName.length > 0;
};
