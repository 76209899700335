import React from "react";
import { AppContext } from "../../../contexts/AppContext";
import { AuctionSearchResult } from "../../../services/AuctionService";
import { useBidWithPostAndSignalR } from "../../../services/BidService";
import { AuctionListItemFooter } from "./AuctionListItemFooter";

interface LiveAuctionListItemFooterProps {
  auction: AuctionSearchResult;
}

export const LiveAuctionListItemFooter = ({ auction }: LiveAuctionListItemFooterProps) => {
  const context = React.useContext(AppContext);
  const [latestBid] = useBidWithPostAndSignalR(context, auction.id, () => { }, () => { });

  return <AuctionListItemFooter auction={auction} latestBid={latestBid} />;
};
