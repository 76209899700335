import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import { useSnackbar } from "notistack";
import * as SearchService from "../../services/SearchService";
import { SavedSearchesList } from "./SavedSearchesList";
import { Close } from "@material-ui/icons";

interface SavedSearchesContainerProps {
    hideTitle?: boolean;        // Prop to conditionally hide the title
    hideCreateNewButton?: boolean; // Prop to conditionally hide the "Create New" button
    isDialog?: boolean; // Add this line
    onViewSearchFromModal?(queryString: string);
}

const SavedSearchesContainer: React.FC<SavedSearchesContainerProps> = ({ hideTitle = false, hideCreateNewButton = false, isDialog, onViewSearchFromModal }) => {
    const [results, setResults] = React.useState<Array<SearchService.SavedSearchResult>>();
    const { enqueueSnackbar } = useSnackbar();
    const context = React.useContext(AppContext);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [deletionConfirmation, setDeletionConfirmation] = React.useState<boolean>(false);
    const [selectedSearchId, setSelectedSearchId] = React.useState<string>('');

    async function getSavedSearches() {
        setLoading(true);
        const response = await SearchService.GetSavedSearches();
        const searches: Array<SearchService.SavedSearchResult> = response.parsedBody as Array<SearchService.SavedSearchResult>;

        if (searches !== undefined) {
            setResults(searches);
            setLoading(false);
        }
    }

    const onItemDeleted = (id: string) => {
        setSelectedSearchId(id);
        setDeletionConfirmation(true);
    }

    const onItemDeletedConfirmation = () => {
        setLoading(true);
        setDeletionConfirmation(false);        
        SearchService.DeleteSavedSearch(selectedSearchId)
            .then(result => {
                enqueueSnackbar("Saved search has been deleted.", { variant: "success", autoHideDuration: 5000 });
                getSavedSearches();
            })
            .catch(async () => {
                enqueueSnackbar("Could not delete saved search, please try again.", { variant: "error" });
                setLoading(false);
            });
    }

    const onItemViewSearch = (queryString: string) => {
        if (onViewSearchFromModal) {
            onViewSearchFromModal(queryString);
        }
    }

    React.useEffect(() => {
        if (context.bidderState.bidderLoaded) {
            getSavedSearches();
        }
    }, [context.bidderState.bidderLoaded]);

    return (
        <>
            {deletionConfirmation && (
                <Dialog open={deletionConfirmation} onClose={() => setDeletionConfirmation(false)} fullWidth>
                    <DialogTitle>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                Delete Confirmation
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => setDeletionConfirmation(false)}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <p>Are you sure you wish to delete this saved search? Doing so is permanent.</p>
                    </DialogContent>
                    <DialogActions>
                        <Grid item xs={12}>
                            <Button variant={"contained"} color={"primary"} fullWidth onClick={onItemDeletedConfirmation}>
                                Delete
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
            )}



            <Grid container alignItems="center" style={{display:"flex", alignItems: "center"}}>
                {!hideTitle && (
                    <Grid item xs={12} lg={10} style={{display:"flex", alignItems: "center"}}>
                        <h1 style={{margin: "0", padding: "0"}}>Saved Searches</h1>
                    </Grid>
                )}

                {!hideCreateNewButton && (
                    <Grid item xs={12} lg={2} style={{display:"flex", alignItems: "center", paddingTop: "20px"}}>
                        <Button component={RouterLink} to={`/Search`} variant="contained" color="primary" style={{ display:"flex", alignItems: "center", justifyContent: "center", marginBottom: "20px" }} fullWidth>
                            Create New
                        </Button>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <SavedSearchesList results={results} isDialog={isDialog} onItemDeleted={onItemDeleted} onItemViewSearch={onItemViewSearch} loading={loading} />
                </Grid>
            </Grid>
        </>
    );
};

export default SavedSearchesContainer;
