import * as React from "react";
import { Button, Paper, Grid, Typography, Hidden, Divider } from "@material-ui/core";
import * as Styles from "./styles/BidderRegistrationStyles";

interface IStepsProperty {
  handleNext?: () => void;
  nextButtonText?: string;
  title: string;
  subTitle?: string;
  nextButtonEnabled?: boolean;
  nextButtonVisible?: boolean;
}

export const StepContainer: React.FC<IStepsProperty> = ({
  handleNext = () => {},
  nextButtonText = "Next",
  title,
  children,
  nextButtonEnabled = true,
  subTitle,
  nextButtonVisible = true,
}) => {
  const classes = Styles.BidderRegistrationStyles();

  const next = () => {
    handleNext();
    window.scrollTo(0, 0);
  };

  return (
    <Paper className={classes.paymentContainer}>
      {subTitle === undefined ? (
        <Typography variant="h4">{title}</Typography>
      ) : (
        <Grid container alignContent="center" justify="center">
          <Grid item xs={12} md={3} className={classes.titleContainer}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Hidden smDown>
            <Grid item xs={1} className={classes.divider}>
              <Divider orientation="vertical" />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8} className={classes.infoContainer}>
            <Typography variant="h6">{subTitle}</Typography>
          </Grid>
        </Grid>
      )}

      <Grid container alignItems="center" justify="center">
        {children}
      </Grid>

      {nextButtonVisible && (
        <Grid container alignItems="center" justify="flex-end">
          <Grid item>
            <Button className={classes.nextButton} color="primary" variant="contained" onClick={next} disabled={!nextButtonEnabled}>
              {nextButtonText}
            </Button>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
