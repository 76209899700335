import React from "react";
import { Typography, Grid, Link, Card } from "@material-ui/core";
import { Auction } from "../../../../services/AuctionService";
import { GetClientSettings } from "../../../../services/SettingsService";
import { ISalvageClientSettings } from "../../../../interfaces/ISalvageClientSettings";
import * as Styles from "../../styles/SellerDetailsStyles";
import { openingTimes, roundedMileFormat } from "../../../../helpers/text-format/TextFormat";
import { IOpeningDays } from "../../../../interfaces/IOpeningDays";
import { Member, MemberSite } from "../../../../services/MemberService";
import { LoadingComponent } from "../../../loading/LoadingComponent";
import Room from "@material-ui/icons/Room";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

interface LocationDetailsContainerProps {
  auction: Auction;
  seller: Member | null;
  sellerLoading: boolean;
}


const LocationDetailsContainer: React.FC<LocationDetailsContainerProps> = ({ auction, seller, sellerLoading }) => {
  const classes = Styles.SellerDetailsStyles();

  const [site, setSite] = React.useState<MemberSite>();

  const hideDistance: boolean = auction.distanceToBidder === undefined || auction.distanceToBidder === 0 || auction.distanceToBidder > 600;

  React.useEffect(() => {
    if (seller?.sites.find(s => s.id === auction.metadata.siteId) !== undefined) {
      setSite(seller?.sites.find(s => s.id === auction.metadata.siteId));
    } else {
      setSite(seller?.sites.find(s => s.isMainSite));
    }
  }, [seller, auction]);

  const openingTimesComponent = (openingDays: IOpeningDays) => {
    if (openingDays) {
      return (
        <Grid container>
          <Grid item xs={6}>
            <Typography align="left">Monday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.monday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Tuesday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.tuesday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Wednesday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.wednesday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Thursday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.thursday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Friday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.friday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Saturday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.saturday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Sunday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.sunday)}</Typography>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      {sellerLoading && <LoadingComponent label="Loading Seller" />}
      {!sellerLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography align="left">{auction?.metadata.line1}</Typography>
            <Typography align="left">{auction?.metadata.line2}</Typography>
            <Typography align="left">{auction?.metadata.line3}</Typography>
            <Typography align="left">{auction?.metadata.city}</Typography>
            <Typography align="left">{auction?.metadata.county}</Typography>
            <Typography align="left">{auction?.metadata.postcode}</Typography>
            <br />
            <Typography align="left">{<Link href={`tel:${site?.sitePhoneNumber}`}>{site?.sitePhoneNumber ?? ""}</Link>}</Typography>
            <Typography align="left">{<Link href={`mailto:${site?.siteEmail}`}>{site?.siteEmail ?? ""}</Link>}</Typography>
            <br />
            <Card className={classes.locationDeatils}>
              <Grid container spacing={1}  justify="center">
                <Grid item>
                  <Typography variant="button" color="textPrimary">
                    {auction.metadata.city}
                  </Typography>
                </Grid>
                <Grid item >
                  <Room color="primary"/>
                </Grid>
                <Grid item>
                  <Typography variant="button" color="textPrimary">
                  {hideDistance ? auction.metadata.location : `${auction.distanceToBidder && roundedMileFormat(auction.distanceToBidder)}`}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            
          </Grid>
          <Grid item xs={12} md={6}>
            <iframe
              className={classes.map}
              title="google map location for seller address"
              src={`https://www.google.com/maps/embed/v1/place?q=${auction?.metadata.postcode}&key=${ClientSettings.GoogleApiKey}`}
            ></iframe>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.containerTitle} align="left">
              Opening times
            </Typography>
            {site?.openingDays !== undefined && openingTimesComponent(site?.openingDays)}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.containerTitle} align="left">
              Collection times
            </Typography>
            {site?.collectionDays !== undefined && openingTimesComponent(site?.collectionDays)}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default LocationDetailsContainer;
