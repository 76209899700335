import { ApiService, HttpResponse } from "./ApiService";
import { AccountType, BidderStatus, IBidder } from "../interfaces/bidders/IBidder";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { ICreateBidder } from "../interfaces/bidders/ICreateBidder";
import { RegistrationSteps } from "../components/bidder-registration/steps/RegistrationSteps";
import { IVerifyNumberDto } from "../interfaces/bidders/IVerifyNumberDto";
import { ICheckTokenResponse } from "../interfaces/bidders/ICheckTokenResponse";
import { IDuplicationCheckDto } from "../interfaces/bidders/IDuplicationCheckDto";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface UpdateBidderAccountType {
  accountType: AccountType;
}

export async function GetBidder() {
  const bidder = ApiService().Get<IBidder>(`${ClientSettings.UsersPrivateApiUrl}/me`);
  return bidder;
}

export async function UpdateTermsAndConditionsAcceptance() {
  const bidder = ApiService().Patch(`${ClientSettings.UsersPrivateApiUrl}/my/terms-and-conditions`, null);
  return bidder;
}

export async function CheckDuplication() {
  const bidder = ApiService().Get<IDuplicationCheckDto>(`${ClientSettings.UsersPrivateApiUrl}/DuplicationCheck`);
  return bidder;
}

export const CreateBidder = async (bidder: ICreateBidder): Promise<HttpResponse<IBidder>> => {
  return await ApiService().Post<IBidder>(`${ClientSettings.UsersPrivateApiUrl}/bidder/`, bidder);
};

export const UpdateBidderAccountType = async (data: UpdateBidderAccountType): Promise<HttpResponse<unknown>> => {
  return await ApiService().Put<IBidder>(`${ClientSettings.UsersPrivateApiUrl}/bidder/AccountType`, data);
};

export const UpdateBidderMailingList = async (data: boolean): Promise<HttpResponse<unknown>> => {
  return await ApiService().Put<IBidder>(`${ClientSettings.UsersPrivateApiUrl}/bidder/MailingList`, data);
};

export async function GetBidderValidationDetails() {
  const bidder = ApiService().Get<IBidder>(`${ClientSettings.UsersPrivateApiUrl}/verification/me`);
  return bidder;
}

export const VerifyNumber = async (number: IVerifyNumberDto): Promise<HttpResponse<string>> => {
  return await ApiService().Post<string>(`${ClientSettings.UsersPrivateApiUrl}/verify/number/`, number);
};
export const CheckToken = async (number: IVerifyNumberDto, token: string): Promise<HttpResponse<ICheckTokenResponse>> => {
  return await ApiService().Post<ICheckTokenResponse>(`${ClientSettings.UsersPrivateApiUrl}/verify/number/${token}`, number);
};

export const RegistrationStep = (bidder: IBidder | undefined) => {
  if (!bidder) {
    return RegistrationSteps.Start;
  }
  if (bidder.bidderStatus === BidderStatus.NotRegistered) {
    return RegistrationSteps.Payment;
  }
  if (bidder.bidderStatus === BidderStatus.AwaitingPayment) {
    return RegistrationSteps.Payment;
  }
  if (bidder.bidderStatus === BidderStatus.AwaitingIdenfificaiton) {
    return RegistrationSteps.IdValidation;
  }
  if (bidder.bidderStatus === BidderStatus.FailedValidation) {
    return RegistrationSteps.FailedValidation;
  }
  if (bidder.bidderStatus === BidderStatus.AwaitingValidation || bidder.bidderStatus === BidderStatus.PotentialDuplicate) {
    return RegistrationSteps.AwaitingVerification;
  }
  if (
    bidder.bidderStatus === BidderStatus.ActiveFree ||
    bidder.bidderStatus === BidderStatus.ActiveFull ||
    bidder.bidderStatus === BidderStatus.SubscriptionExpired ||
    bidder.bidderStatus === BidderStatus.Suspended
  ) {
    return RegistrationSteps.Complete;
  }
  return RegistrationSteps.Unknown;
};
