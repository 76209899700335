import React from "react";
import {
  Grid,
  Hidden,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import * as Styles from "./styles/InformationPagesStyles";

const PrivacyPolicyContainer: React.FC = () => {
  const classes = Styles.InformationPagesStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Introduction
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            NSG Limited trading as End-to-End Total Loss Vehicle Management ("<strong>e2e</strong>") is a registered company (no.01907166) and our
            registered office address is Unity House, Dudley Road, Darlington, County Durham, DL1 4GG.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            ICO registration: [ZA528445]
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            e2e (collectively referred to as "we", "us" or "our" in this Privacy Policy) is the data controller and is responsible for your personal
            data.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We respect your privacy and are committed to protecting your personal data. Under data protection laws, we are required to provide you
            with certain information about who we are and your rights in relation to your personal data. This Privacy Policy will inform you as to how
            we collect and process your personal data.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            If you provide us with personal information about other people, or if others give us your information, we will only use that information
            for the specific reason for which it was provided to us. By submitting the information, you confirm that you have the right to authorise
            us to process it on your behalf in accordance with this Privacy Policy.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            This website is not intended for children and we do not knowingly collect data relating to children.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            It is important that you read this Privacy Policy together with any other privacy policy or fair processing policy we may provide on
            specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using
            your data. This Privacy Policy supplements other notices and privacy policies and is not intended to override them.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Please also use the Glossary at section 12 to understand the meaning of some of the terms used in this Privacy Policy.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            If you have any questions about this Privacy Policy, including any requests to exercise your legal rights, please contact us using the
            details set out at section 11.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            1. Changes to this privacy policy and your duty to inform us of changes
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We keep our Privacy Policy under regular review. This version was last updated on <strong>23 June 2023</strong>.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes
            during your relationship with us.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            2. Third-party links
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their
            privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            3. The data we collect about you
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Personal data, or personal information, means any information about an individual from which that person can be identified. It does not
            include data where the identity has been removed (anonymous data).
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:
            <List className={classes.bulletPoints}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Identity Data</strong> includes your ID information, including your name and title.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Contact Data</strong> includes your address, telephone number, and email address.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Financial Data</strong> includes your bank account number, sort code, credit/debit card details.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Transaction Data</strong> includes details about payments to and from you.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Contractual Data</strong> includes details about the services we provide to you.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Technical Data</strong> includes your IP address login information, browser type and version, time zone, geolocation
                  information, operating system and version.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Usage Data</strong> includes your URL clickstreams, products/services viewed, page response times, download errors, how long
                  you stayed on our pages, what you do on those pages, how often and other actions.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Registration Data</strong> found on supplied ID documentation.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Communications Data</strong> includes what we learn about you from letters, emails and conversations between us, including
                  social media account profiles.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Publicly Available Data</strong> includes details about you which are publicly available, such as on social media platforms
                  or elsewhere on the internet.
                </Typography>
              </ListItem>
            </List>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We also collect, use and share <strong>Aggregated Data</strong> such as statistical or demographic data for any purpose. Aggregated Data
            could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal
            your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature.
            However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the
            combined data as personal data which will be used in accordance with this Privacy Policy.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We do not collect any <strong>Special Categories of Personal Data</strong> about you (this includes details about your race or ethnicity,
            religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your
            health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>If you fail to provide personal data</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods
            or services). In this case, we may have to cancel a product or service you have with us, but we will notify you if this is the case at the
            time.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            4. How is your personal data collected?
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            e2e is a hub and spoke operation consisting of a network of recycling agents across the UK working as part of the supply chain to fulfil
            clients' needs.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We also use different methods to collect data from and about you including through:
            <List className={classes.bulletPoints}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Direct interactions.</strong> You may give us your Identity, Contact, Transaction, Financial, Communications and Registration Data by
                  filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:
                  <List className={classes.bulletPoints}>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <Typography variant="body1">Browse any page of our website</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <Typography variant="body1">Are contacted by us</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <Typography variant="body1">Use our auction or service</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <Typography variant="body1">Contact us for support or information</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <Typography variant="body1">Register with us </Typography>
                    </ListItem>
                  </List>
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Automated technologies or interactions.</strong> As you interact with our website, we will automatically collect Technical
                  Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar
                  technologies. Please see our Cookie Policy available on our website for further details.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  <strong>Third parties or publicly available sources.</strong> We will receive personal data about you from various third parties as
                  set out below:
                  <List className={classes.bulletPoints}>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <Typography variant="body1">
                        Publicly Available Data from social networking sites, such as Facebook and Twitter, when you interact with us, or from other
                        publicly available sources, such as Companies House, the media or online resources.
                      </Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <Typography variant="body1">Technical and Usage Data from analytics providers, such as Google Analytics.</Typography>
                    </ListItem>
                  </List>
                </Typography>
              </ListItem>
            </List>
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            5. How we use your personal data
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following
            circumstances:
            <List className={classes.bulletPoints}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  Where we need to perform the contract we are about to enter into or have entered into with you.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not
                  override those interests.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">Where we need to comply with a legal obligation.</Typography>
              </ListItem>
            </List>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Please see our <strong>Glossary - Lawful Basis</strong>, to find out more about the types of lawful basis that we will rely on to process
            your personal data.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending
            third party direct marketing communications to you. You have the right to withdraw consent to marketing at any time by contacting us.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Purposes for which we will use your personal data</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we
            rely on to do so. We have also identified what our legitimate interests are where appropriate.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your
            data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more
            than one ground has been set out in the table below.
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Purpose / Activity</TableCell>
                    <TableCell>Type of data</TableCell>
                    <TableCell>Lawful basis for processing including basis of legitimate interest</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>To register you as a new customer</TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                    </TableCell>
                    <TableCell>Performance of a contract with you</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      To process and deliver your order including:
                      <br />
                      (a) Manage payments, fees and charges
                      <br />
                      (b) Collect and recover money owed to us
                    </TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Transaction
                      <br />
                      (d) Marketing and Communications
                      <br />
                      (f) Financial
                    </TableCell>
                    <TableCell>
                      (a) Performance of a contract with you
                      <br />
                      (b) Necessary for our legitimate interests (to recover debts due to us)
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      To manage our relationship with you which will include notifying you about changes to our terms or Privacy Policy
                    </TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Marketing and Communications
                    </TableCell>
                    <TableCell>
                      (a) Performance of a contract with you
                      <br />
                      (b) Necessary to comply with a legal obligation
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>To enable you to provide feedback</TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Usage
                      <br />
                      (d) Marketing and Communications
                    </TableCell>
                    <TableCell>
                      (a) Performance of a contract with you
                      <br />
                      (b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our
                      business)
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance,
                      support, reporting and hosting of data)
                    </TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Technical
                    </TableCell>
                    <TableCell>
                      (a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network
                      security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)
                      <br />
                      (b) Necessary to comply with a legal obligation
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we
                      serve to you
                    </TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Usage
                      <br />
                      (d) Marketing and Communications
                      <br />
                      (e) Technical
                    </TableCell>
                    <TableCell>
                      Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business
                      and to inform our marketing strategy)
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      To use data analytics to improve our website, products/services, marketing, customer relationships and experiences. We use
                      Google Analytics software to collect information about how you use salvagemarket.co.uk and how the auction website performs
                      during your visit. The data is anonymised before being used for analytics and web performance processing.
                    </TableCell>
                    <TableCell>
                      (a) Technical
                      <br />
                      (b) Usage
                    </TableCell>
                    <TableCell>
                      Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated
                      and relevant, to develop our business and to inform our marketing strategy)
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>To make suggestions and recommendations to you about goods or services that may be of interest to you</TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Technical
                      <br />
                      (d) Usage
                      <br />
                      (e) Marketing and Communications
                    </TableCell>
                    <TableCell>Necessary for our legitimate interests (to develop our products/services and grow our business)</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>To provide data to regulators or authorities in compliance with our legal and regulatory obligations</TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                    </TableCell>
                    <TableCell>Necessary to comply with a legal obligation</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>To provide data to other organisations for the purposes of fraud prevention or credit risk reduction</TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                    </TableCell>
                    <TableCell>
                      (a) Necessary to comply with a legal obligation
                      <br />
                      (b) Necessary for our legitimate interests (to protect our business)
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      To register you as a visitor when you visit our premises and ensure your health and safety when you are on our premises
                    </TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                    </TableCell>
                    <TableCell>
                      (a) Necessary to comply with a legal obligation
                      <br />
                      (b) Necessary for our legitimate interests (for the prevention of crime and public safety including the safety of our own
                      employees)
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Prevention of crime and public safety, including through the use of CCTV</TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                    </TableCell>
                    <TableCell>
                      (a) Necessary to comply with a legal obligation
                      <br />
                      (b) Necessary for our legitimate interests (for the prevention of crime and public safety including the safety of our own
                      employees)
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>To establish, enforce and defend legal claims</TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                    </TableCell>
                    <TableCell>Necessary for our legitimate interests (to protect our business)</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>To exercise our rights set out in contracts and agreements </TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                    </TableCell>
                    <TableCell>Performance of a contract with you</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      To send emails and notifications, measure the performance of email campaigns, and provide analysis information to improve the
                      effectiveness of our services. We use the service provider SendGrid to send emails and notifications. SendGrid is a cloud-based
                      SMTP provider that acts as an email delivery system and allows an email to be sent without its own email server. SendGrid
                      manages the technical details of email delivery, such as infrastructure scaling, reputation monitoring and real-time analytics.
                    </TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Technical
                      <br />
                      (d) Usage
                      <br />
                      (e) Marketing and Communications
                    </TableCell>
                    <TableCell>
                      (a) Performance of a contract with you
                      <br />
                      (b) Necessary for our legitimate interests (auction platform communications).
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      To process and deliver your order including:
                      <br />
                      (a) To manage payments, fees and charges
                      <br />
                      (b) To collect and recover money owed to us
                    </TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Transaction
                      <br />
                      (d) Marketing and Communications
                    </TableCell>
                    <TableCell>
                      (a) Performance of a contract with you
                      <br />
                      (b) Necessary for our legitimate interests (to recover debts due to us)
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      To manage our relationship with you which will include notifying you about changes to our terms or this Privacy Policy
                    </TableCell>
                    <TableCell>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Marketing and Communications
                    </TableCell>
                    <TableCell>
                      (a) Performance of a contract with you
                      <br />
                      (b) Necessary to comply with a legal obligation
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Marketing Preferences</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            From time to time SalvageMarket, the seller you register with or the sellers you buy from may send you emails or promotional materials to
            improve your buying experience. These communications are designed to keep you informed of changes to the services we provide or enhance
            your online experience.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            You can update your marketing preferences at any time by visiting the auction website or following the specific 'unsubscribe' instructions
            within any communications you receive.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Notifications</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            If you are a registered user you may receive auction related notifications.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            You can update your notification preferences at any time by visiting the website and turning off the notification settings in your auction
            My Account &gt; Notifications section.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Cookies</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or
            refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about
            the cookies we use, please see our Cookie Policy available on our website for further details.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Change of purpose</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for
            another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the
            new purpose is compatible with the original purpose, please contact us.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to
            do so.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is
            required or permitted by law.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            6. Disclosures of your personal data
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We may share your personal data, as outlined in the section headed <em>Purposes for which we will use your personal data</em>, with the
            following parties:
            <List className={classes.bulletPoints}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">The collecting members of e2e as set out in Section 4 above.</Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">Other companies and service providers who we use to help us carry out our functions.</Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">Our staff, consultants and contractors.</Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">Law enforcement agencies. </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">Relevant regulators, including the Information Commissioner's Office.</Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">Our insurers</Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  Potential or actual purchasers of any part of our business or assets, or other third parties in the context of a possible transfer,
                  merger or restructuring of our business.
                </Typography>
              </ListItem>
            </List>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our
            third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for
            specified purposes and in accordance with our instructions.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            7. International transfers
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Data collected by Google Analytics and SendGrid may be transferred outside the UK for processing.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            8. Data security
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject
            to a duty of confidentiality.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            9. Data retention
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>How long will you use my personal data for?</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the
            purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer
            period in the event of a complaint or if we reasonably believe there is a prospect of litigation fin respect of our relationship with you.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the
            potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data,
            and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other
            requirements.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Details of retention periods for different aspects of your personal data are available in our retention policy which you can request from
            us by contacting us using the details set out at section 11.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            By law we have to keep basic information about our customers (including Contact, Identity and Transaction Data) for six years after they
            cease being customers for tax purposes.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            In some circumstances you can ask us to delete your data: see your <em>legal rights</em> below for further information.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            In some circumstances, we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical
            purposes, in which case we may use this information indefinitely without further notice to you.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            10. Your legal rights
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Under certain circumstances, you have rights under data protection laws in relation to your personal data. You have the right to:
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Request access</strong> to your personal data (commonly known as a "data subject access request"). This enables you to receive a
            copy of the personal data we hold about you and to check that we are lawfully processing it.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate
            data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data where there is no
            good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have
            successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully, or where
            we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your
            request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those of a third party) and
            there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on
            your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing
            purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your
            rights and freedoms.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the processing of your
            personal data in the following scenarios:
            <List className={classes.bulletPoints}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">If you want us to establish the data's accuracy.</Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">Where our use of the data is unlawful but you do not want us to erase it.</Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  You have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.
                </Typography>
              </ListItem>
            </List>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a third party you have
            chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated
            information which you initially provided consent for us to use or where we used the information to perform a contract with you.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However, this will not affect
            the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide
            certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            If you wish to exercise any of the rights set out above, please contact us using the details shown in section 11.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>No fee usually required</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable
            fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these
            circumstances.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>What we may need from you</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data
            (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has
            no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Time limit to respond</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            11. Contact us
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            If you have any questions about this Privacy Policy or our privacy practices, including any requests to exercise your legal rights, please
            contact us:
            <List className={classes.bulletPoints}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  Email: <a href="mailto:assist@salvagemarket.co.uk">assist@salvagemarket.co.uk</a>
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Typography variant="body1">
                  Postal address: Auction Team, e2e Total Loss Vehicle Management, Unity House, Dudley Road, Darlington, England DL1 4GG
                </Typography>
              </ListItem>
            </List>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            You also have the right to lodge a complaint about our processing of your personal data with the Information Commissioner's Office (ICO),
            the UK's regulator for data protection issues.
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            12. Glossary
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>LAWFUL BASIS</strong>
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our business to enable us to give you
            the best service/product, and the best and most secure experience. We make sure we consider and balance any potential impact on you (both
            positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data
            for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted
            to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect
            of specific activities by contacting us.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Performance of Contract</strong> means processing your data where it is necessary for the performance of a contract to which you
            are a party or to take steps at your request before entering into such a contract.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            <strong>Comply with a legal obligation</strong> means processing your personal data where it is necessary for compliance with a legal
            obligation that we are subject to.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PrivacyPolicyContainer;
