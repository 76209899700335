import { makeStyles } from "@material-ui/core/styles";

export const PaymentFormStyles = makeStyles(theme => ({
  cardDetails: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  padding: {
    padding: "5px",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  image: {
    marginRight: "5px",
  },
  amounts: {
    textAlign: "right",
  },
  confirmSummary: {
    margin: "15px",
  },
  wrapText: { overflowWrap: "break-word", wordWrap: "break-word", hyphens: "auto" }
}));
