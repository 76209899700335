import React, { useRef } from "react";
import { Grid, Divider, Card, Button, Typography, TextField, Tooltip, withStyles } from "@material-ui/core";
import { BidGridStyles } from "./styles/BidGridStyles";
import { SubmitBidContainerStyles } from "./styles/SubmitBidContainerStyles";
import BidIncrements from "./sub-components/BidIncrements";
import TimerContainer from "./sub-components/TimerContainer";
import WatchlistHandler from "./sub-components/WatchlistHandler";
import { AppContext } from "../../contexts/AppContext";
import { BidStatus } from "./sub-components/BidStatus";
import { useSnackbar, OptionsObject } from "notistack";
import { calculateBidStatus, canBidOn, canBidOnAuction, IAuctionInfo } from "../../interfaces/auctions/ILatestBid";
import { BidStatusType } from "../../components/bid/types/BidStatusType";
import ReserveMet from "./sub-components/ReserveMet";
import { currencyFormat } from "../../helpers/text-format/TextFormat";
import { WatchlistBidGridStyles } from "../watchlist/styles/WatchlistBidGridStyles";
import { Auction, getIncrementScale } from "../../services/AuctionService";
import Skeleton from "@material-ui/lab/Skeleton";
import { IMsalContext } from "../../authentication/MsalContext";
import { useMsal } from "../../authentication/MsalProvider";
import { useBidWithPostAndSignalR } from "../../services/BidService";
import { BidProgressBar } from "./sub-components/BidProgressBar";
import { useIsActiveOrMember } from "../../helpers/ActiveUserHelper";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { BidIncrementTooltip } from "./sub-components/BidIncrementTooltip";

interface IBidGridComponent {
  auction: Auction;
  isWatchlistMode: boolean;
  auctionUpdated: (updatedAuction: IAuctionInfo) => void;
}

const variantInvalidBid: OptionsObject = { variant: "error" };

const BidGridComponent: React.FC<IBidGridComponent> = ({ auction, isWatchlistMode, auctionUpdated }) => {
  const msal: IMsalContext = useMsal();
  const swappableClasses = isWatchlistMode ? WatchlistBidGridStyles() : BidGridStyles();
  const classesBid = SubmitBidContainerStyles();
  const context = React.useContext(AppContext);
  const isActive = useIsActiveOrMember();
  const submitBidButtonRef = useRef<HTMLButtonElement | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const [isManualBidPermitted, setIsManualBidPermitted] = React.useState(false);
  const [isIncrementBidPermitted, setIsIncrementalBidPermitted] = React.useState(false);
  const [manualBid, setManualBid] = React.useState(0);
  const [lastKnownCurrentBid, setLastKnownCurrentBid] = React.useState(0);
  const [incrementScale, setIncrementScale] = React.useState<number[]>([]);
  const [pendingBid, setPendingBid] = React.useState<number | null>(null);
  const [latestBid, isFetching, isProcessingBid, refreshLatestBid, postLatestBid] = useBidWithPostAndSignalR(
    context,
    auction.id,
    (httpStatus, message) => {

      if (httpStatus === 401) {
        //If you get a 401 unauthorised force refresh page.
        //Bidder account is either expired or suspended.
        window.location.reload();
      } else {
        setManualBid(0);

        enqueueSnackbar(message, variantInvalidBid);
        //Rather than trying immediately, wait a second then try again.
        setTimeout(() => {
          refreshLatestBid();
        }, 1000);
      }
    },
    auctionUpdated
  );

  const [effectiveCurrentBidValue, setEffectiveCurrentBidValue] = React.useState(
    latestBid ? (latestBid.proxyAmount === 0 ? latestBid.amount : latestBid.proxyAmount) : null
  );

  const bidderId = msal.accounts.length > 0 ? context.bidderState.bidder.id : null;

  const bidStatus = calculateBidStatus(auction, latestBid, bidderId, msal.accounts.length > 0, context.bidderState.bidder?.bidderStatus);

  const canBid = canBidOnAuction(auction, latestBid, msal.accounts.length > 0, context.bidderState.bidder?.bidderStatus);

  const cardStyles = (): string => {
    switch (bidStatus) {
      case BidStatusType.LoggedOut:
      case BidStatusType.MemberAccount:
      case BidStatusType.NoBidPlaced:
      case BidStatusType.NoSubscription:
      case BidStatusType.AwaitingIdVerification:
      case BidStatusType.NotActive:
      case BidStatusType.AccountUpgradeRequired: {
        return swappableClasses.loggedOutCard;
      }
      case BidStatusType.BiddingClosed:
      case BidStatusType.BiddingClosedLost:
      case BidStatusType.BiddingClosedWonReserveNotMet:
      case BidStatusType.BiddingClosedWhileSuspended: {
        return swappableClasses.biddingClosedCard;
      }
      case BidStatusType.OutBid:
      case BidStatusType.WinningReserveNotMet: {
        return swappableClasses.outBidCard;
      }
      case BidStatusType.Winning:
      case BidStatusType.BiddingClosedWon:
      default: {
        return swappableClasses.winningCard;
      }
    }
  };

  // If latest bid changes, update the manual bid starting amount.
  // Removed because during periods of rapid bidding it becomes too difficult to keep focus.
  // React.useEffect(() => setManualBid(latestBid && latestBid.amount ? latestBid!.amount : 0), [latestBid]);

  // Checks and validations.
  React.useEffect(() => {
    setIsManualBidPermitted(latestBid !== null && canBidOn(latestBid));
    setIsIncrementalBidPermitted(latestBid !== null && canBidOn(latestBid));
    setEffectiveCurrentBidValue(latestBid ? (latestBid.proxyAmount === null ? latestBid.amount : latestBid.proxyAmount) : null);
    if (!isFetching && pendingBid !== null) {
      setPendingBid(null);
      if (latestBid?.auctionInfo.hasEverBidOn === false) refreshLatestBid();

    }
    if (!isFetching) {
      let amount = auction.startPrice;

      /* ML 2024-03-27: tweaked logic her to cater for proxy bids, and incoming bids via signalR */
      if(latestBid !== undefined && latestBid != null && latestBid.amount != null &&
        effectiveCurrentBidValue !== undefined && effectiveCurrentBidValue != null &&
        effectiveCurrentBidValue > latestBid.amount){
          amount = effectiveCurrentBidValue;
      }
      else if(latestBid !== undefined && latestBid != null && latestBid.amount != null){
        amount = latestBid.amount;
      }
      else if(effectiveCurrentBidValue !== undefined && effectiveCurrentBidValue != null){
        amount = effectiveCurrentBidValue;
      }      

      retrieveIncrementScale(amount, ''); // init load of increments
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestBid, isFetching]);

  const makeBid = async (bidValue: number) => {
    setPendingBid(bidValue);
    if (
      !context.bidderState.checkWatchlist({
        auctionId: auction.id,
      })
    ) {
      context.bidderState.addToWatchlist(auction.id, false);
    }

    try {
      const isPending = await postLatestBid(auction.id, bidValue);
      if (!isPending) {
        // The result has been processed. The result will be sent via signalR
        setPendingBid(null);
        setManualBid(0);

        if (submitBidButtonRef.current) {
          submitBidButtonRef.current.blur();
        }
      }
    } catch (e) {
      // The bid didn't get accepted/processed.
      setPendingBid(null);
      // show the error
      alert(e);
    }
  };

  const incrementManualBid = async (value: number, direction: string) => {
    var currentIncrement = 0;
    if (value != 0 && !isNaN(value)) {
      currentIncrement = value;
    } else {
      currentIncrement = (latestBid && effectiveCurrentBidValue) ?? auction.startPrice;
    }

    if (currentIncrement >= incrementScale[incrementScale.length - 1]) { // we have gone past the incrments we have loaded, so load more
      retrieveIncrementScale(currentIncrement, direction);
    }
    else { // we have the increment but the current value could be invalid
      var indexOfCurrentIncrement = incrementScale.indexOf(currentIncrement);
      if (indexOfCurrentIncrement == -1) { // we are currently on an invalid increment. just find the next one
        for (var i = 0; i < incrementScale.length; i++) {
          if (incrementScale[i] >= currentIncrement) {
            indexOfCurrentIncrement = i;
            currentIncrement = incrementScale[i];
            break;
          }
        }
      }

      if (direction == 'next' && indexOfCurrentIncrement > -1 && incrementScale.length > indexOfCurrentIncrement + 1) {
        setManualBidNextIncrement(currentIncrement, incrementScale);
      } else if (direction == 'prev') {
        if (indexOfCurrentIncrement != 0) {
          setManualBidPrevIncrement(currentIncrement, incrementScale);
        }
      }
    }
  };

  const retrieveIncrementScale = async (currentIncrement: number, direction: string) => {
    getIncrementScale(auction.id, currentIncrement).then(result => {
      if (result.parsedBody != null) {
        setIncrementScale(result.parsedBody);
        if (direction == 'next') {
          setManualBidNextIncrement(currentIncrement, result.parsedBody);
        } else if (direction == 'prev') {
          setManualBidPrevIncrement(currentIncrement, result.parsedBody);
        }
      }
    }).catch(async response => {
      enqueueSnackbar("Could find next increment, please try again.", { variant: "error" });
    })
  }

  const setManualBidNextIncrement = async (value: number, localIncrementScale: number[]) => {
    var indexOfCurrentIncrement = localIncrementScale.indexOf(value);
    if (localIncrementScale.length > indexOfCurrentIncrement + 1) {
      setManualBid(localIncrementScale[indexOfCurrentIncrement + 1]);
    }
  }

  const setManualBidPrevIncrement = async (value: number, localIncrementScale: number[]) => {
    var indexOfCurrentIncrement = localIncrementScale.indexOf(value);
    if (indexOfCurrentIncrement > 0) {
      setManualBid(localIncrementScale[indexOfCurrentIncrement - 1]);
    }
  }

  return (
    <div>
      <Grid className={swappableClasses.bidContainer} container spacing={1} alignItems="center">
        {isWatchlistMode === false && (
          <>
            <Grid className={swappableClasses.time} item>
              {latestBid?.auctionInfo.endDateTimeUtc && (
                <TimerContainer endDateInput={latestBid?.auctionInfo.endDateTimeUtc} auctionStatus={latestBid?.auctionInfo.auctionStatusCode} />
              )}
            </Grid>
            <Grid className={swappableClasses.watchlist} item>
              <WatchlistHandler auctionId={auction.id} context={context} />
            </Grid>
          </>
        )}

        <Card className={cardStyles()}>
          <div className={swappableClasses.bidStatus}>
            <BidStatus bidStatus={bidStatus} bidStatusTextStyle={swappableClasses.bidStatusText} />
          </div>
          <Grid item xs={12} className={swappableClasses.currentBidContainer}>
            <div className={swappableClasses.padding}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  {!latestBid ? (
                    <h1 style={{ paddingLeft: "8px" }}>
                      <Skeleton className={swappableClasses.skeleton} width="100%" />
                    </h1>
                  ) : (
                    <>
                      <h2 className={latestBid && latestBid.proxyAmount !== null ? swappableClasses.currentBid : swappableClasses.currentBidNoProxy}>
                        {latestBid && currencyFormat(latestBid.amount || auction.startPrice)}
                      </h2>
                      {latestBid && latestBid.proxyAmount !== null && (
                        <span className={swappableClasses.currentProxyBid}>(Proxy {currencyFormat(latestBid.proxyAmount ?? 0)})</span>
                      )}
                      {auction.metadata.vatIncluded && <span className={swappableClasses.bidVat}>+ VAT</span>}
                    </>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <div className={swappableClasses.padding}>
                    {!latestBid ? (
                      <h1>
                        <Skeleton className={swappableClasses.skeleton} width="100%" />
                      </h1>
                    ) : (
                      <h5 className={swappableClasses.bidHistory}>{latestBid?.auctionInfo.totalBids} bids</h5>
                    )}
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className={swappableClasses.padding}>
                    {!latestBid ? (
                      <h1>
                        <Skeleton className={swappableClasses.skeleton} width="100%" />
                      </h1>
                    ) : (
                      <>
                        {isActive && latestBid?.auctionInfo.isReserveMet !== null && (
                          <h5 className={latestBid?.auctionInfo.isReserveMet ? swappableClasses.reserveMet : swappableClasses.reserveNotMet}>
                            <ReserveMet latestBid={latestBid} />
                          </h5>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
            <Divider className={swappableClasses.divider} />
          </Grid>

          <Grid item xs={12} className={swappableClasses.bidIncrements}>

            {/* {(isProcessingBid == false && isFetching == false) && ( */}
              <BidIncrements
                pendingBid={pendingBid}
                isDisabled={!isIncrementBidPermitted || !canBid}
                currentBidValue={(latestBid && effectiveCurrentBidValue) ?? auction.startPrice}
                onBidValueSelected={makeBid}
                auction={auction}
                incrementScale={incrementScale}
                latestBid={latestBid}
              />
            {/* )} */}
          </Grid>


          <Grid item xs={12}>
            <BidProgressBar isPlacingBid={isProcessingBid} />
          </Grid>

          <Grid className={swappableClasses.submitBid} container>
            <Grid item xs={12}>

              <Button
                variant="contained"
                color="primary"
                className={classesBid.bidIncrementButton}
                onClick={() => incrementManualBid(manualBid, 'prev')}
                disabled={!isManualBidPermitted || !canBid}
              >
                <RemoveIcon />
              </Button>

              <TextField
                className={classesBid.formControl}
                disabled={/*isProcessing || */ !canBid}
                id="enter-bid"
                label={/*isProcessing ? "Loading..." : */ "Enter Bid..."}
                type="number"
                value={manualBid === 0 ? "" : manualBid}
                onChange={input => setManualBid(input.target.value != null && input.target.value.trim() != '' ? parseInt(input.target.value) : 0)}
                variant="outlined"
                margin="dense"
              />

              <Button
                variant="contained"
                color="primary"
                className={classesBid.bidIncrementButton}
                onClick={() => incrementManualBid(manualBid, 'next')}
                disabled={!isManualBidPermitted || !canBid}
              >
                <AddIcon />
              </Button>

            </Grid>
            <Grid item xs={12} className={classesBid.submitGrid}>
              <Button
                variant="contained"
                color="primary"
                className={classesBid.submitButton}
                onClick={() => makeBid(manualBid)}
                disabled={!isManualBidPermitted || !canBid}
                ref={submitBidButtonRef}
              >
                Submit Bid
              </Button>
            </Grid>

            <Grid item xs={12}>
              <BidIncrementTooltip />
            </Grid>
          </Grid>

        </Card>
      </Grid>
    </div>
  );
};

export default BidGridComponent;
