import React from "react";
import Typography from "@material-ui/core/Typography";
import * as EditorsPick1 from "../../assets/action_image_1.jpg";
import * as Styles from "./styles/ActionBannerStyles";
import { Grid, Card, CardMedia, Box } from "@material-ui/core";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import { IInitialState } from "../../contexts/AppContext";
import { IMsalContext } from "../../authentication/MsalContext";
import { useMsal } from "../../authentication/MsalProvider";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";

const clientSettings: ISalvageClientSettings = GetClientSettings();

export const ActionBanner: React.FC<{ context: IInitialState }> = ({ context }) => {
  const msal: IMsalContext = useMsal();

  const classes = Styles.ActionBannerStyles();
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (context.bidderState.isRegistered === undefined) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(context.bidderState.isRegistered);
    }
  }, [context]);

  const register = () => {
    msal.instance.loginRedirect({
      scopes: [clientSettings.B2CSettings.TokenScope],
      authority: clientSettings.B2CSettings.TenantSignUp,
    });
  };

  return isLoggedIn ? null : (
    <div className={classes.root}>
      <Card className={classes.card} onClick={register}>
        <div className={classes.wrapper}>
          <CardMedia className={classes.image} alt={"Register to get started"} component="img" image={EditorsPick1.toString()} />

          <Grid container className={classes.content}>
            <Grid item xs={12}>
              <Typography variant="h5" align="left" className={classes.title}>
                Register to get started
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.caption}>
              <Typography variant="subtitle2" align="left">
                Our easy 3 step process means you can start bidding in less than 24 hours.
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.action}>
              <Box>
                <Typography className={classes.action} variant="caption" align="left" color="textPrimary">
                  Register Now
                </Typography>
                <ArrowRightAlt style={{ color: "#393939", verticalAlign: "middle" }} />
              </Box>
            </Grid>
          </Grid>
        </div>
      </Card>
    </div>
  );
};
