import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { currencyFormat } from "../../../helpers/text-format/TextFormat";
import { ILatestBid } from "../../../interfaces/auctions/ILatestBid";
import { AuctionSearchResult } from "../../../services/AuctionService";
import TimerContainer from "../../bid/sub-components/TimerContainer";
import * as Styles from "../styles/AuctionListItemStyles";

interface AuctionListItemFooterProps {
  auction: AuctionSearchResult;
  latestBid?: ILatestBid | null;
}

export const AuctionListItemFooter = ({ auction, latestBid }: AuctionListItemFooterProps) => {
  const classes = Styles.AuctionListItemStyles();

  const auctionPrice = auction.currentHighestBid ? auction.currentHighestBid : auction.startPrice;

  return (
    <Grid item container spacing={1} alignItems="stretch" className={classes.searchFooterContainer}>
      <Grid sm={3} xs={12} item className={classes.searchFooter}>
        <Typography variant="h5" color="primary" className={classes.currencyText}>
          {currencyFormat(latestBid?.amount ?? auctionPrice)}
        </Typography>
      </Grid>
      <Grid sm={5} xs={12} item className={classes.searchFooter}>
        <TimerContainer
          endDateInput={latestBid?.auctionInfo.endDateTimeUtc ?? auction.auctionEnd}
          auctionStatus={latestBid?.auctionInfo.auctionStatusCode ?? auction.status}
        />
      </Grid>
      <Grid sm={4} xs={12} item className={classes.searchFooter}>
        <Button component={RouterLink} to={`/Lot/${auction.id}`} className={classes.button} fullWidth>
          Place bid
        </Button>
      </Grid>
    </Grid>
  );
};
