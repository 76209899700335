import * as React from "react";
import { Container } from "@material-ui/core";

import { BidderRegistration } from "../bidder-registration/BidderRegistration";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";
import { useMsal } from "../../authentication/MsalProvider";
import { IMsalContext } from "../../authentication/MsalContext";
import { GetClientSettings } from "../../services/SettingsService";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { LoadingComponent } from "../loading/LoadingComponent";

function Register() {
  const [logIn, setLogIn] = React.useState(false);
  const msal: IMsalContext = useMsal();
  const clientSettings: ISalvageClientSettings = GetClientSettings();

  React.useEffect(() => {
    if (logIn) {
      msal.instance.loginRedirect({
        scopes: [clientSettings.B2CSettings.TokenScope],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logIn]);

  if (!msal.logoutInProgress && !msal.loginInProgress && msal.accounts.length === 0) {
    // We are not authenticated.
    if (!logIn) {
      setLogIn(true);
    }
  }

  if (msal.accounts.length === 0) {
    return <LoadingComponent label="Please Wait"></LoadingComponent>;
  }
  return (
    <Container maxWidth={"lg"} fixed style={{ minHeight: "350px" }}>
      <BidderRegistration />
    </Container>
  );
}

export default withAITracking(reactPlugin, Register, "Register");
