import React from "react";
import { Grid, Divider, Hidden, Card, Typography } from "@material-ui/core";
import * as Styles from "./styles/InformationPagesStyles";

const AboutUsContainer: React.FC = () => {
  const classes = Styles.InformationPagesStyles();

  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            SalvageMarket
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            The site offers a user friendly, open and transparent auction for buyers and sellers of vehicles. Leveraging the very best auction
            technology, we ensure that every asset is ideally prepared and marketed. Specialist vehicles and top marques are given special treatment
            to drive interest and competitive bidding. The auction is accessible 24/7 and, as each piece of salvage is stored at one of our members’
            sites, it can be viewed in person by prior arrangement.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            We offer competitive buyers fees to ensure value for both our buyers and sellers. We have always adopted a fair and reasonable approach
            with all of our fees which are the most competitive in the UK.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            e2e uses search engine optimisation (SEO) techniques to position salvagemarket.co.uk at the top of all relevant search results via the
            web. Its member sites also promote Salvage Market within the local areas therefore full UK coverage is maximised on a daily basis.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            The fees which bidders and buyers pay on the auction site are some of the most competitive in the UK.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Quality control runs through the heart of the auction process. All vehicles are cleaned inside and out, and clearly photographed so that
            they are presented as professionally as possible.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Damage is clearly shown and described, to optimise first time sales activity.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            Auction packs for client’s vehicles are approved by e2e before being uploaded to the auction.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            All bidders are required to provide photographic ID prior to opening a bidder account which is retained for future reference and all
            applications are validated by our staff.
          </Typography>
          <Typography variant="body1">
            Finally, recognising that most bidding activity takes place in the final minute before an auction lot closes, in the bidding process the
            lot end time is extended automatically when bidders are competing for the win. This process is very simple and increases the sale price by
            giving buyers extra time in which to increase their bids resulting in higher returns for our clients. Our auctions will only end when all
            of the bidding has ceased
          </Typography>
        </Grid>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            e2e
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom>
            National Salvage Group (NSG) was formed in 1985. It later incorporated NASA to form National Salvage Association (NSA) and is a member
            network of the UK’s largest, most innovative and established salvage dismantling and recycling companies. The core business is the safe
            procurement, collection, storage, recycling and disposal of motor vehicle salvage. NSG was rebranded as “End to End Total Loss Vehicle
            Management Limited” and launched in 2019.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.container}>
          <Card className={classes.orangeBox}>
            <Typography variant="h5" component="h2" gutterBottom className={classes.whiteText}>
              Why We Are Different
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.whiteText}>
              We have a Membership consisting of over 30 of the most progressive salvage operators located throughout the United Kingdom making us the
              largest Salvage Management Company in the UK. Our Members are located from as far North as Aberdeen in Scotland to as far South as St
              Austell in Cornwall. Should you be successful in winning an auction each of our members offer a UK delivery service with prices
              available on request.
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            What We Do
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            e2e has been providing salvage and end of life vehicle disposal solutions since 1984 and aspires to deliver an unrivalled level of
            service, to each of its customers, that is individual to each of them.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            e2e collect, store, recycle and dispose of vehicle and plant salvage via its independent members, across a network of sites, which include
            some of the most innovative vehicle recyclers operating in the UK.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            e2e can either dispose of the vehicles and plant that is processed via its members, or direct to the end user via its online auction, or a
            hybrid thereof.
          </Typography>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            e2e are committed to disposing vehicles in the most environmentally friendly way possible, for instance e2e break all category B salvage
            vehicles in house and never allow their re-sale. This allows e2e to control where all the wastes and pollutants are being disposed of and
            ensures that Category B vehicles never end up back on the road, or worse, sold to unsuspecting buyers on foreign shores.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default AboutUsContainer;
