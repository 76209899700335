import { Cookies } from "react-cookie-consent";
import ReactGA from "react-ga4";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../services/SettingsService";

const clientSettings: ISalvageClientSettings = GetClientSettings();

const cookieSettings = {
  expires: 365,
  sameSite: "strict",
  secure: true,
};

export const handleAcceptGACookies = () => {
  Cookies.set("CookieConsent", "true", cookieSettings);

  const googleAnalyticsTrackingId = clientSettings.GoogleAnalyticsTrackingId;
  if (googleAnalyticsTrackingId) {
    ReactGA.initialize(googleAnalyticsTrackingId, { gaOptions: { cookieDomain: "none" } });
  }
};

export const handleRejectGACookies = () => {
  Cookies.set("CookieConsent", "false", cookieSettings);
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
  window.location.reload();
};
