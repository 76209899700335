import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { currencyFormat } from "../../helpers/text-format/TextFormat";
import { IStripeProduct } from "../../interfaces/payments/IStripeProduct";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  grid: {
    backgroundColor: "#EBEBEB",
    padding: "30px",
    borderRadius: "10px",
    '&.active': {
      backgroundColor: "#EC6E13",
      color: "#FFFFFF",
      '&.MuiButton-containedSecondary': {
        backgroundColor: "#FFFFFF",
      },
    },
    '&.MuiGrid-item': {
      marginRight: "15px",
      marginBottom: "15px",
      height: "100%",
    },
  },
});

interface IProps {
  stripeProduct: IStripeProduct;
  selectedStripeProduct?: IStripeProduct;
  stripeProductChanged: (toPost: IStripeProduct) => void;
}

export const StripePaymentProduct: React.FC<IProps> = ({ stripeProduct, selectedStripeProduct, stripeProductChanged }) => {
  const classes = useStyles();
  return (
    <Grid
      item xs={12} md={6} spacing={4}>
      <Grid
        item className={`${classes.grid} ${selectedStripeProduct?.id === stripeProduct.id ? 'active' : ''}`}>
        <Typography
          style={{
            paddingBottom: "15px",
            fontWeight: "bold", 
          }}
          component="h3" variant="h6">
          {stripeProduct?.name}<br></br>
        </Typography>
        <Typography
          style={{
            paddingBottom: "30px",
            fontWeight: "bold", 
          }}
          component="h3" variant="h6">
          {currencyFormat(stripeProduct?.amount ? (stripeProduct?.amount / 100) : 0)}{stripeProduct.vatInclusive ? '' : '(+VAT)'}<br></br>
        </Typography>
        <Typography
          style={{
            paddingBottom: "30px",

          }}
        >
          {stripeProduct?.description}<br></br>
        </Typography>
        <Button
          style={{
            paddingLeft: "90px",
            paddingRight: "90px",
            backgroundColor: selectedStripeProduct?.id == stripeProduct.id ? 'white' : undefined,
          }}
          type="button" color={selectedStripeProduct?.id == stripeProduct.id ? 'secondary' : 'primary'} variant="contained" onClick={() => stripeProductChanged(stripeProduct)}>
          {selectedStripeProduct?.id == stripeProduct.id ? 'Selected' : 'Select'}
        </Button>
      </Grid></Grid>
  )
};

