import React, { useEffect } from "react";
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
import ReactGA from "react-ga4";
import { useHistory, useLocation } from "react-router-dom";
import { handleAcceptGACookies } from "../../helpers/cookieHelper";

const CookieWrapper: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const isConsent = getCookieConsentValue() === "true";

  const handleDecline = () => {
    resetCookieConsentValue();
    history.push("/Cookies");
  };

  useEffect(() => {
    if (isConsent) {
      handleAcceptGACookies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isConsent) {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <>
      {children}
      <CookieConsent
        sameSite="strict"
        disableButtonStyles
        buttonClasses="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
        buttonText="Accept all cookies"
        buttonStyle={{ textTransform: "none" }}
        enableDeclineButton
        flipButtons
        declineButtonStyle={{ margin: "16px", textTransform: "none" }}
        declineButtonClasses="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
        declineButtonText="Review cookie settings"
        onAccept={handleAcceptGACookies}
        onDecline={handleDecline}
      >
        Let us know if you agree to cookies. By clicking "Accept all cookies", you agree to the storing of cookies on your device to enhance site
        navigation, analyse site usage, and assist in our marketing efforts.
      </CookieConsent>
    </>
  );
};

export default CookieWrapper;
