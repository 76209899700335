import React from "react";
import { Link, Typography, Grid } from "@material-ui/core";
import { Auction } from "../../../../services/AuctionService";
import { GetClientSettings } from "../../../../services/SettingsService";
import { ISalvageClientSettings } from "../../../../interfaces/ISalvageClientSettings";
import * as Styles from "../../styles/SellerDetailsStyles";
import { IOpeningDays } from "../../../../interfaces/IOpeningDays";
import { openingTimes } from "../../../../helpers/text-format/TextFormat";
import { LoadingComponent } from "../../../loading/LoadingComponent";
import { Member, MemberSite } from "../../../../services/MemberService";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

interface SellerDetailsContainer {
  auction: Auction;
  seller: Member | null;
  sellerLoading: boolean;
}

const SellerDetailsContainer: React.FC<SellerDetailsContainer> = ({ auction, seller, sellerLoading }) => {
  const classes = Styles.SellerDetailsStyles();

  const [sellerSite, setSellerSite] = React.useState<MemberSite>();

  React.useEffect(() => {
    setSellerSite(seller?.sites.find(s => s.isMainSite));
  }, [seller]);

  const openingTimesComponent = (openingDays: IOpeningDays) => {
    if (openingDays) {
      return (
        <Grid container>
          <Grid item xs={6}>
            <Typography align="left">Monday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.monday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Tuesday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.tuesday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Wednesday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.wednesday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Thursday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.thursday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Friday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.friday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Saturday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.saturday)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">Sunday</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="left">{openingTimes(openingDays.sunday)}</Typography>
          </Grid>
        </Grid>
      );
    }
  };
  return (
    <>
      {sellerLoading && <LoadingComponent label="Loading Seller" />}
      {!sellerLoading && (
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <img src={auction.memberImageUrl} alt="member logo" height="48px" width="auto" />
            <Typography className={classes.containerTitle} align="left">
              {seller?.name}
            </Typography>
            <Typography align="left">{sellerSite?.location.line1}</Typography>
            <Typography align="left">{sellerSite?.location.line2}</Typography>
            <Typography align="left">{sellerSite?.location.line3}</Typography>
            <Typography align="left">{sellerSite?.location.city}</Typography>
            <Typography align="left">{sellerSite?.location.county}</Typography>
            <Typography align="left">{sellerSite?.location.postcode}</Typography>
            <br />
            <Typography align="left">{<Link href={`tel:${seller?.phoneNumber}`}>{seller?.phoneNumber ?? ""}</Link>}</Typography>
            <Typography align="left">{<Link href={`mailto:${seller?.email}`}>{seller?.email ?? ""}</Link>}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <iframe
              className={classes.map}
              title="google map location for seller address"
              src={`https://www.google.com/maps/embed/v1/place?q=${sellerSite?.location.postcode}&key=${ClientSettings.GoogleApiKey}`}
            ></iframe>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.containerTitle} align="left">
              Opening times
            </Typography>
            {sellerSite?.openingDays !== undefined && openingTimesComponent(sellerSite?.openingDays)}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SellerDetailsContainer;
