import { ApiService, HttpResponse } from "./ApiService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IsDefaultSettingForSearchConfig, ISearchOptions, IsDefaultSettingForHomepageStats } from "../interfaces/search/ISearchOptions";
import { GetClientSettings } from "./SettingsService";
import { IVehicleSearchConfiguration } from "../interfaces/search/IVehicleSearchConfiguration";
import { IPagingResults } from "../interfaces/paging/paging";
import { Auction, AuctionSearchResult } from "./AuctionService";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const SearchAuctions = async (parameters: ISearchOptions): Promise<HttpResponse<IPagingResults<AuctionSearchResult>>> => {
  return await ApiService().GetWithBody<IPagingResults<AuctionSearchResult>>(ClientSettings.AuctionPublicApiUrl + "/auctions/search", parameters, 'AuctionSearchRequest');
};

export const GetActiveAuctions = async (parameters: string[]): Promise<HttpResponse<Array<Auction>>> => {
  return await ApiService().Post<Array<Auction>>(ClientSettings.AuctionPublicApiUrl + "/auctions/search/active/auctions", {
    auctionsList: parameters,
  });
};

export const SearchConfiguration = async (parameters: ISearchOptions): Promise<HttpResponse<IVehicleSearchConfiguration>> => {

  if (IsDefaultSettingForSearchConfig(parameters)) {
    return await ApiService().Get<IVehicleSearchConfiguration>(
      `${ClientSettings.LotBlobUrl}/cache/searchconfig.json`, true
    );
  }
  else {
    return await ApiService().GetWithBody<IVehicleSearchConfiguration>(
      `${ClientSettings.AuctionPublicApiUrl}/auctions/search/SearchConfiguration`,
      parameters, "AuctionSearchRequest"
    );
  }
};

export const MasterSearchConfiguration = async (): Promise<HttpResponse<IVehicleSearchConfiguration>> => {
  return await ApiService().Get<IVehicleSearchConfiguration>(
    `${ClientSettings.AuctionPublicApiUrl}/auctions/search/MasterSearchConfiguration`
  );
}

export const GetHomepageStats = async (): Promise<HttpResponse<HomepageCategoryStats>> => {
  return await ApiService().Get<HomepageCategoryStats>(
    `${ClientSettings.AuctionPublicApiUrl}/auctions/search/homepagestats`
  );
};

export const GetBillboard = async (): Promise<HttpResponse<Array<AuctionSearchResult>>> => {
  return await ApiService().Get<Array<AuctionSearchResult>>(
    `${ClientSettings.AuctionPublicApiUrl}/auctions/search/homepage/billboard`
  );
};

export const GetSavedSearches = async (): Promise<HttpResponse<Array<SavedSearchResult>>> => {
  return await ApiService().Get<Array<SavedSearchResult>>(
    `${ClientSettings.UsersPrivateApiUrl}/biddersearches`
  );
}

export const SaveSearch = async (savedSearch: SavedSearchResult): Promise<HttpResponse<void>> => {
  return await ApiService().Post<void>(
    `${ClientSettings.UsersPrivateApiUrl}/bidder/search/save`, savedSearch
  );
};

export const DeleteSavedSearch = async (savedSearchId: string): Promise<HttpResponse<void>> => {
  return await ApiService().Delete<void>(
    `${ClientSettings.UsersPrivateApiUrl}/bidder/search/saved/delete/${savedSearchId}`, null
  );
}

export interface HomepageCategoryStats {
  highValue: number | null;
  startsAndDrives: number | null;
  unrecorded: number | null;
  justAdded: number | null;
  featured: number | null;
}

export interface SavedSearchResult {
  id?: string;
  name: string;
  postcode: string;
  distance: string[];
  makes: string[];
  models: string[];
  fuelTypes: string[];
  transmissionTypes: string[];
  years: string[];
  damageCategories: string[];
  startDrives: string[];

  queryString?: string;
}
