import { makeStyles } from "@material-ui/core/styles";

export const StandardNavigationStyles = makeStyles(theme => ({
  navigationContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "4px",
      paddingRight: "4px"
    },
  },
}));
