import * as React from "react";
import { NotLoggedIn } from "./NotLoggedIn";
import { AuthenticationInProgress } from "./AuthenticationInProgress";
import { MsalConsumer } from "../../authentication/MsalContext";

export const SecureArea: React.FC = ({ children }) => {
  return (
    <MsalConsumer>
      {msal => (
        <>
          {msal.loginInProgress && <AuthenticationInProgress />}
          {!msal.loginInProgress && msal.accounts.length > 0 && children}
          {!msal.loginInProgress && msal.accounts.length === 0 && <NotLoggedIn />}
        </>
      )}
    </MsalConsumer>
  );
};
