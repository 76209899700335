import { Grid, makeStyles, Theme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  outer: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
  },
  zero: {
    animation: `$currentEffect 100ms cubic-bezier(0, 0, 0.58, 1)`,
    width: "0%",
    height: "4px",
    backgroundColor: theme.palette.primary.main,
  },
  full: {
    animation: `$currentEffectExit 8000ms cubic-bezier(0, 1, 0.1, 1)`,
    width: "100%",
    height: "4px",
    backgroundColor: theme.palette.primary.main,
  },
  "@keyframes currentEffect": {
    "0%": {
      width: "100%",
    },
    "100%": {
      width: "0%",
    },
  },
  "@keyframes currentEffectExit": {
    "0%": {
      width: "0%",
    },
    "100%": {
      width: "100%",
    },
  },
}));

interface BidProgressBarProps {
  isPlacingBid: boolean;
}

export const BidProgressBar: React.FC<BidProgressBarProps> = ({ isPlacingBid }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.outer}>
      <Grid className={isPlacingBid ? classes.full : classes.zero}></Grid>
    </Grid>
  );
};
