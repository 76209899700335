import { ApiService, HttpResponse } from "./ApiService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IStripePaymentIntentResponse } from "../interfaces/payments/IStripePaymentIntentResponse";
import { IssueRefundDto } from "../interfaces/bidders/IssueRefundDto";
import { IStripePaymentMethodResponse } from "../interfaces/payments/IStripePaymentMethodResponse";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export async function GetPaymentMethods() {
  return await ApiService().Get<IStripePaymentMethodResponse[]>(`${ClientSettings.PaymentsPrivateApiUrl}/stripe/payment-methods`);  
}

export async function DeletePaymentMethod(paymentMethodId: string) {
  return await ApiService().Delete<void>(`${ClientSettings.PaymentsPrivateApiUrl}/stripe/payment-methods/${paymentMethodId}`, {});  
}

export async function CreatePaymentIntent(stripeProductId: string, saveCard: boolean, paymentMethodId?: string) {
  return await ApiService().Get<IStripePaymentIntentResponse>(`${ClientSettings.PaymentsPrivateApiUrl}/stripe/create-payment-intent/${stripeProductId}/${saveCard}/${paymentMethodId != undefined ? paymentMethodId : ''}`);  
}

export async function ConfirmPaymentIntent(paymentIntentId: string) {
  return await ApiService().Get<IStripePaymentIntentResponse>(`${ClientSettings.PaymentsPrivateApiUrl}/stripe/confirm-payment-intent/${paymentIntentId}`);  
}

export async function ProcessPaymentIntentSuccess(paymentIntentId: string) {
  return await ApiService().Get<IStripePaymentIntentResponse>(`${ClientSettings.PaymentsPrivateApiUrl}/stripe/process-payment-intent/${paymentIntentId}`);  
}

export const postRequestRefund = async (refundDetails: IssueRefundDto): Promise<HttpResponse<void>> => {
  return await ApiService().Post<void>(ClientSettings.PaymentsPrivateApiUrl + `/stripe/refund/request`, refundDetails);
};
