import { makeStyles } from "@material-ui/core";

export const SubmitDocumentsStyles = makeStyles(theme => ({
  idPaper: {
    padding: "25px",
  },
  fileInput: {
    visibility: "hidden",
  },
  submitDocsButton: {
    float: "right",
    marginTop: "10px",
  },
  fileNameTextbox: {
    marginRight: "15px",
  },
  loadingUploadingDocumentsComponent: {
    width: "500px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  bottomDivider: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  questionsContainer: {
    marginBottom: "15px;",
  },
  validationMessage: {
    display: "flex",
    alignItems: "center",
  },
  validationImage: {
    marginRight: "5px",
  },
}));
