import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
const borderRadius = "16px";
const success = green[500];

export const BidGridStyles = makeStyles(theme => ({
  bidStatusText: {},
  outBidCard: {
    margin: "4px",
    borderRadius: borderRadius,
    background: theme.palette.error.main,
    width: "100%",
  },
  biddingClosedCard: {
    margin: "4px",
    borderRadius: borderRadius,
    background: theme.palette.error.main,
    width: "100%",
  },
  winningCard: {
    margin: "4px",
    borderRadius: borderRadius,
    background: success,
    width: "100%",
  },
  loggedOutCard: {
    margin: "4px",
    borderRadius: borderRadius,
    background: theme.palette.common.black,
    width: "100%",
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  bidContainer: {
    textAlign: "center",
    margin: "20px 0 0 0",
  },
  bidStatus: {
    color: theme.palette.common.white,
    margin: "0px",
  },
  submitBid: {
    background: theme.palette.secondary.light,
    padding: "10px",
  },
  currentBid: {
    background: theme.palette.secondary.light,
    padding: "4px",
    paddingBottom: "0px",
    marginBottom: "0px",
    marginTop: "0px",
    fontWeight: 1000,
    fontSize: "28px",
  },
  currentBidNoProxy: {
    background: theme.palette.secondary.light,
    fontSize: "28px",
    fontWeight: 1000,
    marginBottom: "0px",
  },
  currentBidContainer: {
    background: theme.palette.secondary.light,
    height: "96px",
  },
  currentProxyBid: {
    display: "block",
    fontSize: "14px",
    paddingBottom: "0px",
    fontWeight: 1000,
  },
  bidVat: { fontWeight: "bolder", fontSize: "14px", padding: "0", margin: "2px" },
  skeleton: { marginTop: "26px", marginBottom: "26px" },
  bidIncrements: {
    background: theme.palette.secondary.light,
  },
  time: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    textAlign: "left",
    fontWeight: 800,
    fontSize: "1.1em",
  },
  watchlist: {
    background: theme.palette.common.white,
    color: theme.palette.secondary.dark,
    textAlign: "right",
    justifyContent: "flex-end",
    flex: 1,
  },
  reserveMet: {
    color: "white",
    background: theme.palette.success.main,
    borderRadius: "5px",
    lineHeight: "26px",
    margin: "30px 0 30px 0",
  },
  reserveNotMet: {
    color: "white",
    background: theme.palette.error.main,
    borderRadius: "5px",
    lineHeight: "26px",
    margin: "28px 10px 0 0",
  },
  bidHistory: { margin: "36px 0 0 0", color: theme.palette.secondary.dark },
  padding: {
    height: "86px",
    padding: "0 4px 0 4px",
  },
}));
