import { makeStyles } from "@material-ui/core/styles";

export const CarouselStyles = makeStyles(theme => ({
  svgIcon: {
    color: theme.palette.common.black,
    fontSize: "40px",
    "& > svg": {
      margin: theme.spacing(2),
    },

    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  play: {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: theme.palette.primary.main,
    fontSize: "60px",
  },
}));
