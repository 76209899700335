import React from "react";
import {
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

export const StatementRow: React.FC<{
  copy: string;
  updateFunction: (isStatementTrue: boolean) => void;
}> = ({ copy, updateFunction }) => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={5}>
        <Typography variant="subtitle2" display="inline">
          {copy}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="subtitle2" color="textPrimary" display="inline">
          <FormControl>
            <RadioGroup
              onChange={(event) => {
                updateFunction(event.target.value === "true");
              }}
              row
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="yes"
                labelPlacement="end"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="no"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </Typography>
      </Grid>
    </Grid>
  );
};
