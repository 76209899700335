import React from "react";
import * as Styles from "./styles/ContainerStyles";
import { Container } from "@material-ui/core";
import { BidHistoryComponent } from "../bid-history/BidHistoryComponent";
import { SecureArea } from "../authentication/SecureArea";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

const BidHistory: React.FC = () => {
  const classes = Styles.ContainerStyles();

  return (
    <SecureArea>
      <Container fixed className={classes.container}>
        <BidHistoryComponent />
      </Container>
    </SecureArea>
  );
};

export default withAITracking(reactPlugin, BidHistory, "BidHistory");
