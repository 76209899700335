import { makeStyles } from "@material-ui/core/styles";

export const MediaRenderStyles = makeStyles(theme => ({
  root: {
    margin: "5px",
  },
  media: {
    height: 150,
  },
}));
