import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useEffect, useState } from "react";

export function useHubConnection(hubConnection?: HubConnection | null) {
  const [hubConnectionState, setHubConnectionState] = useState<HubConnectionState>(hubConnection?.state ?? HubConnectionState.Disconnected);
  const [error, setError] = useState();

  useEffect(() => {
    setError(undefined);

    if (!hubConnection) {
      setHubConnectionState(HubConnectionState.Disconnected);
      return;
    }

    if (hubConnection.state !== hubConnectionState) {
      setHubConnectionState(hubConnection.state);
    }

    let isMounted = true;
    const onStateUpdatedCallback = () => {
      if (isMounted) {
        setHubConnectionState(hubConnection?.state);
      }
    };
    hubConnection.onclose(onStateUpdatedCallback);
    hubConnection.onreconnected(onStateUpdatedCallback);
    hubConnection.onreconnecting(onStateUpdatedCallback);

    if (hubConnection.state === HubConnectionState.Disconnected) {
      const startPromise = hubConnection
        .start()
        .then(onStateUpdatedCallback)
        .catch(reason => setError(reason));
      onStateUpdatedCallback();

      return () => {
        startPromise.then(() => {
          hubConnection.stop();
        });
        isMounted = false;
      };
    }

    return () => {
      hubConnection.stop();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubConnection]);

  return { hubConnectionState, error };
}
