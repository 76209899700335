import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import * as Styles from "../../styles/VehicleDetailsListStyles";

import IconSelector from "./IconSelector";
import { Colour, Info, Label, Mileage, Registration, Gear, VehicleType, RegistrationNumberIcon, Pound } from "../../../icons/Icons";
import { mileFormat } from "../../../../helpers/text-format/TextFormat";
import { Auction } from "../../../../services/AuctionService";
import LotFieldComponent, {
  ReferenceNumberAllow,
  RegistrationNumberAllow,
  DamageCategoryAllow,
  MileageAllow,
  ColourAllow,
  LotTypeAllow,
  EngineSizeAllow,
  VatIncludedAllow,
  HasKeysAllow,
  HasLogBookAllow,
  HasVinAllow,
  DoesStartAllow,
  DoesDriveAllow,
  HasServiceHistoryAllow,
} from "../../../lot-field/LotFieldComponent";
import { Dialog, DialogContent, DialogContentText, DialogTitle, Link, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

interface VehicleDetailsProps {
  auction: Auction;
}

enum InfoType {
  unknown,
  starts,
  odometer,
  drives,
  serviceHistory
}

const StartsDialog = () => (
  <>
    <DialogTitle>Starts</DialogTitle>
    <DialogContent>
      <DialogContentText>
        At the time the vehicle arrived at the seller's premises it was tested to verify whether the engine could be started.
      </DialogContentText>
      <DialogContentText>Starts is defined as:</DialogContentText>
      <DialogContentText>
        <b>Yes</b>
      </DialogContentText>
      <DialogContentText>
      The Seller was able to start the engine. They do not guarantee or provide any warranty to the condition of the engine or that it will start again when collected from the Seller's premises or if it is delivered to a buyer.
      </DialogContentText>
      <DialogContentText>
        <b>No</b>
      </DialogContentText>
      <DialogContentText>The Seller was unable to start the engine.</DialogContentText>
      <DialogContentText>
        <b>Unknown</b>
      </DialogContentText>
      <DialogContentText>
      The Seller has been unable to try and start the engine. This maybe for a number of reasons such as: There is no key with the vehicle; It may cause further damage to the vehicle if the seller attempts to start the engine; The engine/battery are damaged etc.
      </DialogContentText>
    </DialogContent>
  </>
);

const DrivesDialog = () => (
  <>
    <DialogTitle>Drives</DialogTitle>
    <DialogContent>
      <DialogContentText>
      At the time the vehicle arrived at the seller's premises it was tested to verify whether the engine could be started and to determine whether it could be moved forward for a short distance only under its own power.
      </DialogContentText>
      <DialogContentText>Drives is defined as: </DialogContentText>
      <DialogContentText>
        <b>Yes</b>
      </DialogContentText>
      <DialogContentText>
      The vehicle drives for loading purposes only.
The Seller was able to start the engine and select a gear to move the vehicle forward for a short distance under its own power. It does not guarantee the condition of the engine or gearbox or that the vehicle will drive again when collected from the Seller's premises or if it is delivered to a buyer. 
The buyer agrees that you shall not use that vehicle on the public highway and you shall not remove it (or cause it to be removed) from a sellers premises under its own power.
      </DialogContentText>
      <DialogContentText>
        <b>No</b>
      </DialogContentText>
      <DialogContentText>The vehicle could not be moved under its own power.</DialogContentText>
      <DialogContentText>
        <b>Unknown</b>
      </DialogContentText>
      <DialogContentText>The Seller has been unable to try and drive the vehicle.</DialogContentText>
    </DialogContent>
  </>
);

const OdometerDialog = () => (
  <>
    <DialogTitle>Odometer</DialogTitle>
    <DialogContent>
      <DialogContentText>
        The odometer, speedo or mileage displayed or photographed is unconfirmed and should be treated as incorrect and unverified.
      </DialogContentText>
    </DialogContent>
  </>
);

const ServiceHistoryDialog = () => (
  <>
    <DialogTitle>Service History</DialogTitle>
    <DialogContent>
      <DialogContentText>
        The service history is non-verified. You should ask to inspect the paperwork while viewing the vehicle to assess the service history of the
        vehicle.
      </DialogContentText>
    </DialogContent>
  </>
);

const VehicleDetailsList: React.FC<VehicleDetailsProps> = ({ auction }) => {
  const classes = Styles.VehicleDetailsListStyles();
  const mileage = auction.metadata.mileage !== 0 ? mileFormat(auction.metadata.mileage) : "Unknown";

  const [open, setOpen] = React.useState<boolean>(false);
  const [infoType, setInfoType] = React.useState<InfoType>(InfoType.unknown);
  const handleClose = () => {
    setOpen(false);
  };

  const info = () => {
    switch (infoType) {
      case InfoType.starts:
        return <StartsDialog />;
      case InfoType.drives:
        return <DrivesDialog />;
      case InfoType.odometer:
        return <OdometerDialog />;
      case InfoType.serviceHistory:
        return <ServiceHistoryDialog />;
      default:
        return <></>;
    }
  };

  const infoDialog = () => {
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        {info()}
      </Dialog>
    );
  };

  const registrationNumber = () => {
    const regNumber = auction.metadata.registrationNumber;
    if (regNumber) {
      return (
        <ListItemIcon>
          <RegistrationNumberIcon image={regNumber} />
        </ListItemIcon>
      );
    } else {
      return (
        <ListItemText style={{ marginBottom: "0px" }} primary={auction.metadata.registrationNumberMessage ?? "Login to view registration"} />
      );
    }
  }

  return (
    <div>
      {infoDialog()}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <List>
            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={ReferenceNumberAllow}>
              <ListItem>
                <ListItemIcon>
                  <Label />
                </ListItemIcon>

                <ListItemText style={{ marginBottom: "0px" }} primary={`REF ${auction.metadata.referenceNumber}`} />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={RegistrationNumberAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <Registration />
                </ListItemIcon>
                {registrationNumber()}
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={DamageCategoryAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText style={{ marginBottom: "0px" }} primary={`Category ${auction.metadata.damageCategory ? auction.metadata.damageCategory : "Unknown"}`} />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={MileageAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <Mileage />
                </ListItemIcon>
                <ListItemText
                  style={{ marginBottom: "0px" }}
                  primary={
                    <>
                      {mileage}
                      <Link
                        onClick={() => {
                          setInfoType(InfoType.odometer);
                          setOpen(true);
                        }}
                        className={classes.link}
                      >
                        <InfoIcon fontSize="small" className={classes.infoIcon} />
                        info
                      </Link>
                    </>
                  }
                />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={ColourAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <Colour />
                </ListItemIcon>
                <ListItemText style={{ marginBottom: "0px", fontWeight: "bold" }} primary={`${auction.metadata.colour ? auction.metadata.colour : "Unknown"}`} />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={LotTypeAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <VehicleType />
                </ListItemIcon>
                <ListItemText style={{ marginBottom: "0px" }} primary={`${auction.metadata.lotType} `} />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={EngineSizeAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <Gear />
                </ListItemIcon>
                <ListItemText
                  style={{ marginBottom: "0px" }}
                  primary={`${auction.metadata.engineSize !== "0" ? auction.metadata.engineSize : ""} `}
                />
              </ListItem>
            </LotFieldComponent>
            <Divider className={classes.divider} />
            {auction.metadata.additionalFee !== undefined && auction.metadata.additionalFee !== null &&
              <>
                <ListItem>
                  <ListItemIcon>
                    <Pound />
                  </ListItemIcon>
                  <ListItemText
                    style={{ marginBottom: "0px" }}
                    primary={`Additional Site Fee £${auction.metadata.additionalFee}`}
                  />
                </ListItem>
                <Divider className={classes.divider} />
              </>
            }
          </List>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <List>
            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={VatIncludedAllow}>
              <ListItem>
                <ListItemIcon>
                  <IconSelector input={auction.metadata.vatIncluded} />
                </ListItemIcon>
                <ListItemText style={{ marginBottom: "0px" }} primary={`Subject to VAT`} />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={HasKeysAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <IconSelector input={auction.metadata.hasKeys} />
                </ListItemIcon>
                <ListItemText style={{ marginBottom: "0px" }} primary={`Has Keys`} />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={HasLogBookAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <IconSelector input={auction.metadata.hasLogBook} />
                </ListItemIcon>
                <ListItemText style={{ marginBottom: "0px" }} primary={`Has V5`} />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={HasVinAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <IconSelector input={auction.metadata.hasVIN} />
                </ListItemIcon>
                <ListItemText style={{ marginBottom: "0px" }} primary={`Has VIN plate`} />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={DoesStartAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <IconSelector input={auction.metadata.doesStart} />
                </ListItemIcon>
                <ListItemText
                  style={{ marginBottom: "0px" }}
                  primary={
                    <>
                      Starts
                      <Link
                        onClick={() => {
                          setInfoType(InfoType.starts);
                          setOpen(true);
                        }}
                        className={classes.link}
                      >
                        <InfoIcon fontSize="small" className={classes.infoIcon} />
                        info
                      </Link>
                    </>
                  }
                />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={DoesDriveAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <IconSelector input={auction.metadata.doesDrive} />
                </ListItemIcon>
                <ListItemText
                  style={{ marginBottom: "0px" }}
                  primary={
                    <>
                      Drives
                      <Link
                        onClick={() => {
                          setInfoType(InfoType.drives);
                          setOpen(true);
                        }}
                        className={classes.link}
                      >
                        <InfoIcon fontSize="small" className={classes.infoIcon} />
                        info
                      </Link>
                    </>
                  }
                />
              </ListItem>
            </LotFieldComponent>

            <LotFieldComponent currentLotType={auction.metadata.lotType} allowLotTypes={HasServiceHistoryAllow}>
              <Divider className={classes.divider} />
              <ListItem>
                <ListItemIcon>
                  <IconSelector input={auction.metadata.hasServiceHistory} />
                </ListItemIcon>
                <ListItemText
                  style={{ marginBottom: "0px" }}
                  primary={
                    <>
                      Service history known
                      <Link
                        onClick={() => {
                          setInfoType(InfoType.serviceHistory);
                          setOpen(true);
                        }}
                        className={classes.link}
                      >
                        <InfoIcon fontSize="small" className={classes.infoIcon} />
                        info
                      </Link>
                    </>
                  }
                />
              </ListItem>
            </LotFieldComponent>
            <Divider className={classes.divider} />
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography align="left" className={classes.descriptionTitle}>
            Description
          </Typography>
          <div>{auction.metadata.description}</div>
        </Grid>
      </Grid>
    </div >
  );
};

export default VehicleDetailsList;
