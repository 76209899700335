declare global {
  interface Window {
    __e2e_environment: {
      SOURCE_VERSION: string;
      INSTRUMENTATION_KEY: string;
      B2C_USER_SCOPE: string;
      TOKEN_SCOPE: string;
      CLIENT_ID: string;
      TENANT_SIGN_IN: string;
      TENANT_SIGN_UP: string;
      TENANT_FORGOT_PASSWORD: string;
      PUBLIC_LOT_API: string;
      PRIVATE_LOT_API: string;
      PUBLIC_AUCTION_API: string;
      PRIVATE_AUCTION_API: string;
      PRIVATE_BIDDING_API: string;
      PRIVATE_USER_API: string;
      PRIVATE_SIGNALR_API: string;
      PRIVATE_PAYMENTS_API: string;
      LOT_BLOB_URL: string;
      PUBLIC_TIME_SYNC_API: string;
      MEMBER_SITE_IMAGES: string;
      GOOGLE_API_KEY: string;
      GOOGLE_ANALYTICS_TRACKING_ID: string;
      PUBLIC_DATA_SCRAPING_MONITOR_API: string;
      GOOGLE_RECAPTCHA: string;
      STRIPE_PUBLIC_KEY: string;
    };
  }
}

const SOURCE_VERSION = window.__e2e_environment.SOURCE_VERSION;
const INSTRUMENTATION_KEY = window.__e2e_environment.INSTRUMENTATION_KEY;
const B2C_USER_SCOPE = window.__e2e_environment.B2C_USER_SCOPE;
const TOKEN_SCOPE = window.__e2e_environment.TOKEN_SCOPE;
const CLIENT_ID = window.__e2e_environment.CLIENT_ID;
const TENANT_SIGN_IN = window.__e2e_environment.TENANT_SIGN_IN;
const TENANT_SIGN_UP = window.__e2e_environment.TENANT_SIGN_UP;
const TENANT_FORGOT_PASSWORD = window.__e2e_environment.TENANT_FORGOT_PASSWORD;
const PUBLIC_LOT_API = window.__e2e_environment.PUBLIC_LOT_API;
const PRIVATE_LOT_API = window.__e2e_environment.PRIVATE_LOT_API;
const PUBLIC_AUCTION_API = window.__e2e_environment.PUBLIC_AUCTION_API;
const PRIVATE_AUCTION_API = window.__e2e_environment.PRIVATE_AUCTION_API;
const PRIVATE_BIDDING_API = window.__e2e_environment.PRIVATE_BIDDING_API;
const PRIVATE_USER_API = window.__e2e_environment.PRIVATE_USER_API;
const PRIVATE_SIGNALR_API = window.__e2e_environment.PRIVATE_SIGNALR_API;
const PRIVATE_PAYMENTS_API = window.__e2e_environment.PRIVATE_PAYMENTS_API;
const PUBLIC_TIME_SYNC_API = window.__e2e_environment.PUBLIC_TIME_SYNC_API;
const MEMBER_SITE_IMAGES = window.__e2e_environment.MEMBER_SITE_IMAGES;
const GOOGLE_API_KEY = window.__e2e_environment.GOOGLE_API_KEY;
const GOOGLE_ANALYTICS_TRACKING_ID = window.__e2e_environment.GOOGLE_ANALYTICS_TRACKING_ID;
const PUBLIC_DATA_SCRAPING_MONITOR_API = window.__e2e_environment.PUBLIC_DATA_SCRAPING_MONITOR_API;
const LOT_BLOB_URL = window.__e2e_environment.LOT_BLOB_URL;
const GOOGLE_RECAPTCHA = window.__e2e_environment.GOOGLE_RECAPTCHA;
const STRIPE_PUBLIC_KEY = window.__e2e_environment.STRIPE_PUBLIC_KEY;

export {
  SOURCE_VERSION,
  INSTRUMENTATION_KEY,
  B2C_USER_SCOPE,
  TOKEN_SCOPE,
  CLIENT_ID,
  TENANT_SIGN_IN,
  TENANT_SIGN_UP,
  TENANT_FORGOT_PASSWORD,
  PUBLIC_LOT_API,
  PRIVATE_LOT_API,
  PUBLIC_AUCTION_API,
  PRIVATE_AUCTION_API,
  PRIVATE_BIDDING_API,
  PRIVATE_USER_API,
  PRIVATE_SIGNALR_API,
  PRIVATE_PAYMENTS_API,
  PUBLIC_TIME_SYNC_API,
  MEMBER_SITE_IMAGES,
  GOOGLE_API_KEY,
  GOOGLE_ANALYTICS_TRACKING_ID,
  PUBLIC_DATA_SCRAPING_MONITOR_API,
  LOT_BLOB_URL,
  GOOGLE_RECAPTCHA,
  STRIPE_PUBLIC_KEY
};
