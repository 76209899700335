import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useReadonlyData } from "./useVersionedData";
import { IAddress } from "../interfaces/IAddress";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface ReceiptData {
  id: string;
  isRefund: boolean;
  nsgLogoUrl: string;
  nsgAddress: IAddress;
  salvageMarketLogoUrl: string;
  nsgPhoneNumber: string;
  nsgEmail: string;
  bidderName: string;
  bidderBusiness: string | null;
  bidderAddress: IAddress;
  bidderPhoneNumber: string;
  bidderEmail: string;
  saleDate: Date;
  refundedDate: Date;
  saleReceiptNumber: string;
  referenceNumber: string;
  expiryDate: Date | null;
  subscriptionLength: number;
  memberName: string;
  memberWebsiteUrl: string;
  memberAddress: IAddress;
  memberPhoneNumber: string;
  memberEmail: string;
  totalExVat: number;
  vat: number;
  totalIncVat: number;
  paymentType: string;
  transactionNumber: string;
  registeredCompany: string;
  registeredCountry: string;
  companyRegistrationNumber: string;
  vatRegistrationNumber: string;
  orderDescription: string;
  orderStatus: string;
  orderCurrentStatus: string;
  refundAllowed: boolean;
}

export const useBidderPaymentReceipt = (orderId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<ReceiptData>(`${ClientSettings.UsersPrivateApiUrl}/me/receipts/${orderId}`, onError);

export const useAllReceiptsForBidder = (onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<ReceiptData[]>(`${ClientSettings.UsersPrivateApiUrl}/me/receipts/all`, onError);
