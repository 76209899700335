import { IFormsOfId } from "./FormsOfId";

export interface ISelectedFile {
  file: File;
  rotate: number;
  formOfId?: IFormsOfId;
}

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export enum ActionTypes {
  Add = "Add",
  Remove = "Remove",
  Rotate = "Rotate",
  SetFormOfId = "FormOfId",
}

export type SelectedFilePayload = {
  [ActionTypes.Add]: {
    selectedFile: File;
  };
  [ActionTypes.Remove]: {
    selectedFile: ISelectedFile;
  };
  [ActionTypes.Rotate]: {
    selectedFile: ISelectedFile;
    angle: number;
  };
  [ActionTypes.SetFormOfId]: {
    selectedFile: ISelectedFile;
    formOfId: IFormsOfId;
  };
};

export type SelectedFilesActions = ActionMap<SelectedFilePayload>[keyof ActionMap<SelectedFilePayload>];
export const allowedFileTypes = ["image/png", "image/jpeg", "image/gif"]; //, "application/pdf"

export const selectedImagesReducer = (state: ISelectedFile[], action: SelectedFilesActions) => {
  switch (action.type) {
    case ActionTypes.Add: {
      if (state.some((p) => doFilesMatch(action.payload.selectedFile)(p.file))) {
        // The file has already been added to the state - just ignore this.
        return state;
      }
      if (!allowedFileTypes.includes(action.payload.selectedFile.type)) {
        window.alert("Please note we only support images being uploaded")
        return state;
      }
      return [...state, { file: action.payload.selectedFile, rotate: 0 }];
    }
    case ActionTypes.Remove: {
      const index = state.findIndex(doSelectedFilesMatch(action.payload.selectedFile));
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    case ActionTypes.Rotate: {
      const index = state.findIndex(doSelectedFilesMatch(action.payload.selectedFile));
      return [
        ...state.slice(0, index),
        { ...action.payload.selectedFile, rotate: action.payload.selectedFile.rotate + action.payload.angle },
        ...state.slice(index + 1),
      ];
    }
    case ActionTypes.SetFormOfId: {
      const index = state.findIndex(doSelectedFilesMatch(action.payload.selectedFile));
      return [...state.slice(0, index), { ...action.payload.selectedFile, formOfId: action.payload.formOfId }, ...state.slice(index + 1)];
    }
  }
};

const doSelectedFilesMatch = (fileA: ISelectedFile) => (fileB: ISelectedFile): boolean => {
  return doFilesMatch(fileA.file)(fileB.file);
};
const doFilesMatch = (fileA: File) => (fileB: File): boolean => {
  return fileA.name === fileB.name;
};
