import { IVerificationSubmissionBidderDto } from "./IVerificationSubmissionBidderDto";
import moment from "moment";

export enum VerificationStatus {
  Verified = 1,
  AwaitingVerification = 2,
  DocumentsSubmitted = 3,
  FailedVerification = 4,
}
export interface IVerification {
  status: VerificationStatus;
  lastUpdated: string;
  verificationDocumentSubmission: Array<IVerificationSubmissionBidderDto>;
}

export const VerificationDefault: IVerification = {
  status: VerificationStatus.AwaitingVerification,
  lastUpdated: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
  verificationDocumentSubmission: [],
};
