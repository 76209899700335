import * as React from "react";
import { Route } from "react-router-dom";
import * as Styles from "./styles/EditorsPicksStyles";

export const EditorPicksTile: React.FC<{ tile: any }> = ({ tile }) => {
  const classes = Styles.EditorPicksStyles();

  return (
    <Route
      render={({ history }) => (
        <div             style={{maxHeight: "100px", overflow: "hidden",}} className={classes.div}>
          <img className={classes.img} src={tile.backgroundImage.toString()} alt={tile.title} />
          <div
            className={tile.overlayStyle}
            onClick={() => {
              history.push({
                pathname: "/search",
                search: tile.searchState,
              });
            }}
          >
            <h2 style={{fontSize: "30px", marginTop: "6px"}} className={classes.tileTitle}>{tile.title}</h2>
          </div>
        </div>
      )}
    />
  );
};
