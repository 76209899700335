import * as React from "react";
import { Button, Hidden } from "@material-ui/core";
import { IMsalContext } from "../../authentication/MsalContext";
import { useMsal } from "../../authentication/MsalProvider";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";

export const LoggedInButton: React.FC = () => {
  const clientSettings: ISalvageClientSettings = GetClientSettings();
  const msal: IMsalContext = useMsal();

  const register = () => {
    msal.instance.loginRedirect({
      scopes: [clientSettings.B2CSettings.TokenScope],
      authority: clientSettings.B2CSettings.TenantSignUp,
    });
  };

  const login = () => {
    msal.instance.loginRedirect({
      scopes: [clientSettings.B2CSettings.TokenScope],
    });
  };

  return (
    <>
      <Hidden smDown>
        <Button
          onClick={() => login()}
          variant="text"
          color="primary"
          size="large"
          style={{
            margin: "0 8px",
            minWidth: "75px",
            fontWeight: "normal",
          }}
        >
          Login
        </Button>
        <Button
          onClick={() => register()}
          variant="contained"
          color="primary"
          size="large"
          style={{
            margin: "0 8px",
            minWidth: "75px",
            fontWeight: "normal",
          }}
        >
          Register Here
        </Button>
      </Hidden>
      <Hidden mdUp>
        <Button
          onClick={() => login()}
          variant="text"
          color="primary"
          size="small"
          style={{
            display: "flex",
            margin: "0 0 0 4px",
            fontWeight: "normal",
          }}
        >
          Login
        </Button>
        <Button
          onClick={() => register()}
          variant="contained"
          color="primary"
          size="small"
          style={{
            display: "flex",
            margin: "0 0 0 4px",
            minWidth: "75px",
            fontWeight: "normal",
          }}
        >
          Register
        </Button>
      </Hidden>
    </>
  );
};
