import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./assets/black-tie/css/black-tie.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import App from "./App";
import { theme, orange } from "./theme";
import { StyleContext, StyleContextProvider } from "./contexts/StyleContext";

ReactDOM.render(
  <StyleContextProvider>
    <StyleContext.Consumer>
      {context => {
        const primaryColor = context.theme?.primaryColors ?? orange;
        return (
          <ThemeProvider theme={theme(primaryColor)}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        );
      }}
    </StyleContext.Consumer>
  </StyleContextProvider>,

  document.querySelector("#root")
);
