import React, { FunctionComponent, useState, useEffect, useContext, ReactNode } from "react";
import { IPublicClientApplication, AccountInfo, EventType, EventMessage } from "@azure/msal-browser";
import { MsalContext, IMsalContext } from "./MsalContext";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../services/SettingsService";

export type MsalProviderProps = {
  instance: IPublicClientApplication;
  children?: ReactNode;
};

export const MsalProvider: FunctionComponent<MsalProviderProps> = ({ instance, children }: MsalProviderProps) => {
  const clientSettings: ISalvageClientSettings = GetClientSettings();

  const [accounts, setAccounts] = useState<AccountInfo[]>(instance.getAllAccounts());
  const [loginInProgress, setLoginInProgress] = useState<boolean>(true);
  const [logoutInProgress, setLogoutInProgress] = useState<boolean>(false);

  useEffect(() => {
    instance.addEventCallback((message: EventMessage) => {
      switch (message.eventType) {
        case EventType.LOGIN_START:
        case EventType.SSO_SILENT_START:
        case EventType.HANDLE_REDIRECT_START:
          setLoginInProgress(true);
          break;
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
          setAccounts(instance.getAllAccounts());
          setLoginInProgress(false);
          break;
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.ACQUIRE_TOKEN_FAILURE:
          //Detect password reset action.
          if (message.error?.message !== undefined && message.error?.message.indexOf("AADB2C90118") !== -1) {
            setLoginInProgress(true);
            instance.loginRedirect({
              scopes: [clientSettings.B2CSettings.TokenScope],
              authority: clientSettings.B2CSettings.TenantForgotPassword,
            });
          } else if (message.error?.message !== undefined && message.error?.message.indexOf("AADB2C90077") !== -1) {
            //Detects session timeout
            setLoginInProgress(true);
            instance.logout();
          } else {
            setLoginInProgress(false);
          }
          break;
        case EventType.ACQUIRE_TOKEN_SUCCESS:
          setAccounts(instance.getAllAccounts());
          break;
        case EventType.LOGOUT_SUCCESS:
          setLogoutInProgress(true);
          setAccounts(instance.getAllAccounts());
          break;
      }
    });
  }, [instance, clientSettings.B2CSettings.TokenScope, clientSettings.B2CSettings.TenantForgotPassword]);

  const contextValue: IMsalContext = {
    instance,
    loginInProgress,
    logoutInProgress,
    accounts,
  };

  return <MsalContext.Provider value={contextValue}>{children}</MsalContext.Provider>;
};

export const useMsal = () => useContext(MsalContext);
