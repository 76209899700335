import { makeStyles } from "@material-ui/core/styles";

export const ContainerStyles = makeStyles(theme => ({
  container: {
    minHeight: "350px",
    maxWidth: "1280px",
    marginTop: "32px",
  },
  
  button: {
    marginRight: "12px",
  }
}));
