import { makeStyles } from "@material-ui/core";

export const BidHistoryStyles = makeStyles(theme => ({
  headerPadding: {
    paddingBottom: "32px",
  },
  pointer: {
    cursor: "pointer",
  },
  searchButton: { margin: "8px 8px 8px 8px", padding: "7px 20px 7px 20px" },
}));
