import { makeStyles } from "@material-ui/core/styles";

export const SearchComponentStyles = makeStyles(theme => ({
  searchBarContainer: {
    width: "100%",
  },
  searchResultsAreaHeader: {
    fontSize: "20px",
  },
  resultsBar: {
    marginTop: "10px",
    padding: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  resultBarOrderByComponent: {
    float: "right",
    margin: 0,
  },
  paper: {
    padding: "4px 8px",
  },
  noXPadding: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  customGridSpacing: {
    margin: "3px 0", // Adjust the margin as needed
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // [theme.breakpoints.down('md')]: {
    //   display: "NONE",
    // },
  },
  mobileMods: {
    [theme.breakpoints.down('md')]: {
      display: "none",
      position: "fixed",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      background: "#fff",
      overflowY: "Scroll",
      zIndex: "1101",
      '& .MuiFormControl-root': {
        width: "100%",
      },
    },
  },
  mobileOnly: {
    display: "none",
    [theme.breakpoints.down('md')]: {
      display: "block",
    },
  },

  filterVehicles: {
    display: "block",
    width: "100%",
    //  background: "#ffffff",
    // color: "#EC6E13",
    marginTop: "20px",
    marginBottom: "15px"
  },

  active: {
    display: "block",
  },

  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    '& h5': {
      [theme.breakpoints.down('md')]: {

        display: 'none', // Hide on screens smaller than 'md'
      },
    },
  },

  closeButton: {
    marginLeft: "8px",
    marginRight: "8px",
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },

  mediaQueryMod: {
    [theme.breakpoints.down('md')]: {
      width: "100%",
      maxWidth: "100%",
      flexBasis: "100%"
    },
  }

}));
