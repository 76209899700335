import { makeStyles } from "@material-ui/core/styles";
import { success } from "../../../theme";

export const IconStyles = makeStyles(theme => ({
  success: {
    color: success
  },
  fail: {
    color: theme.palette.error.main
  }
}));
