import React from "react";
import { Page, Document, PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { Button } from "@material-ui/core";
import { ReceiptHeader } from "../receipt-pdf/ReceiptHeader";
import { ReceiptCustomerDetails } from "../receipt-pdf/ReceiptCustomerDetails";
import { ReceiptDescription } from "../receipt-pdf/ReceiptDescription";
import { ReceiptPayment } from "../receipt-pdf/ReceiptPayment";
import { ReceiptFooter } from "../receipt-pdf/ReceiptFooter";
import { styles } from "../receipt-pdf/styles/PdfStyles";
import { ReceiptData } from "../../services/ReceiptService";

interface ReceiptPdfProps {
  receiptDetails: ReceiptData;
  orderStatus: string | undefined;
}

export const ReceiptPdf: React.FC<ReceiptPdfProps> = ({ receiptDetails, orderStatus }) => {
  const ReceiptDocument = () => (
    
    <Document title={"Receipt.pdf"}>
      <Page size="A4" style={styles.page}>
        <ReceiptHeader receiptDetails={receiptDetails} isRefundReceipt={receiptDetails.isRefund} orderStatus={orderStatus} />
        <ReceiptCustomerDetails receiptDetails={receiptDetails} isRefundReceipt={receiptDetails.isRefund} orderStatus={orderStatus} />
        <ReceiptDescription receiptDetails={receiptDetails} isRefundReceipt={receiptDetails.isRefund} orderStatus={orderStatus} />
        <ReceiptPayment receiptDetails={receiptDetails} isRefundReceipt={receiptDetails.isRefund} orderStatus={orderStatus} />
        <ReceiptFooter />
      </Page>
    </Document>
  );

  return (
    <>
      <PDFDownloadLink document={ReceiptDocument()} fileName="Receipt">
        {({ loading }) => (loading ? "Loading document..." : <Button>download pdf</Button>)}
      </PDFDownloadLink>
      <PDFViewer width="100%" height="1000px">
        {ReceiptDocument()}
      </PDFViewer>
    </>
  );
};
