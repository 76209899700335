export enum BidStatusType {
  Unknown,
  Winning,
  WinningReserveNotMet,
  OutBid,
  LoggedOut,
  AuctionSuspendedReserveNotMet,
  AuctionSuspended,
  BiddingClosed,
  BiddingClosedWon,
  BiddingClosedWonReserveNotMet,
  BiddingClosedLost,
  BiddingClosedWhileSuspended,
  NoBidPlaced,
  NoSubscription,
  AwaitingIdVerification,
  AccountUpgradeRequired,
  NotActive,
  MemberAccount,
}
