import { ApiService, HttpResponse } from "./ApiService";
import { IBlobToken } from "../interfaces/IBlobToken";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "./SettingsService";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const GetVerificationDocumentBlobToken = async (): Promise<IBlobToken> => {
  var response = await ApiService().Get<IBlobToken>(ClientSettings.UsersPrivateApiUrl + "/verification/me/sasstoken/");
  if (response.parsedBody !== undefined) {
    const token: IBlobToken = response.parsedBody;
    return token;
  }
  throw new Error("Invalid response from server");
};

export const SetDocumentsUploaded = async (
  files: { url: string; documentType: string; rotation: number }[]
): Promise<HttpResponse<undefined>> => {
  return await ApiService().Put(ClientSettings.UsersPrivateApiUrl + "/verification/me/documents/", files);
};
