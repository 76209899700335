import moment from "moment";

export const isSubscribed = (endDate: string) => {
  const now = moment();
  if (now < moment(endDate)) {
    return true;
  } else {
    return false;
  }
};
