import { makeStyles } from "@material-ui/core";

export const RenewalStyles = makeStyles(theme => ({
  container: {
    paddingBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "5px",
    },
  },
  headerPadding: {
    paddingBottom: "32px",
  },
  greenTick: {
    color: "green",
    marginRight: "8px",
  },
  orangeExclamation: {
    color: "orange",
    marginRight: "8px",
  },
  redCross: {
    color: "red",
    marginRight: "8px",
  },
  subscriptionContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "24px"
  },
}));
