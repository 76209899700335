import { Text, View } from "@react-pdf/renderer";
import { styles } from "../styles/PdfStyles";
import React from "react";
import { IOpeningDays } from "../../../interfaces/IOpeningDays";
import { openingTimes } from "../../../helpers/text-format/TextFormat";

interface OpeningDays {
  openingDays: IOpeningDays;
}
export const OpeningDays: React.FC<OpeningDays> = ({ openingDays }) => (
  <View style={styles.row}>
    <View style={styles.openingTimesDayBlock}>
      <Text style={styles.littleBlackText}>Mon</Text>
      <Text style={styles.littleBlackText}>Tue</Text>
      <Text style={styles.littleBlackText}>Wed</Text>
      <Text style={styles.littleBlackText}>Thu</Text>
      <Text style={styles.littleBlackText}>Fri</Text>
      <Text style={styles.littleBlackText}>Sat</Text>
      <Text style={styles.littleBlackText}>Sun</Text>
    </View>
    <View style={styles.openingTimesBlock}>
      <Text style={styles.littleBlackText}>{openingDays !== undefined && openingTimes(openingDays.monday)}</Text>
      <Text style={styles.littleBlackText}>{openingDays !== undefined && openingTimes(openingDays.tuesday)}</Text>
      <Text style={styles.littleBlackText}>{openingDays !== undefined && openingTimes(openingDays.wednesday)}</Text>
      <Text style={styles.littleBlackText}>{openingDays !== undefined && openingTimes(openingDays.thursday)}</Text>
      <Text style={styles.littleBlackText}>{openingDays !== undefined && openingTimes(openingDays.friday)}</Text>
      <Text style={styles.littleBlackText}>{openingDays !== undefined && openingTimes(openingDays.saturday)}</Text>
      <Text style={styles.littleBlackText}>{openingDays !== undefined && openingTimes(openingDays.sunday)}</Text>
    </View>
  </View>
);
