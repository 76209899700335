import { IValidation } from "../../../interfaces/IValidation";

export const isError = (field: string, validation: IValidation): boolean => {
  return validation[field] !== undefined;
};

export const ErrorMessage = (
  field: string,
  validation: IValidation
): string => {
  return validation[field] !== undefined ? validation[field].error : "";
};
