import { makeStyles, lighten } from "@material-ui/core/styles";

export const SubmitBidContainerStyles = makeStyles(theme => ({
  submitButton: {
    background: theme.palette.common.black,
    "&:hover": {
      background: lighten(theme.palette.common.black, 0.45),
    },
    color: theme.palette.common.white,
    marginTop: "4px",
    padding: "8px",
    borderRadius: "5px",
    width: "100%",
  },
  submitButtonDisabled: {
    background: theme.palette.secondary.dark,
    "&:hover": {
      background: lighten(theme.palette.secondary.dark, 0.45),
    },
    color: theme.palette.secondary.main,
    marginTop: "4px",
    padding: "8px",
    borderRadius: "5px",
    width: "100%",
  },
  formControl: {
    width: "150px",
    marginRight: "10px",    
    marginLeft: "10px",
    color: theme.palette.common.white,
    marginTop: 0,    
  '& input[type=number]': {
    '-moz-appearance': 'textfield'
},
'& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
},
'& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
},
  },
  bidIncrementButton:{
    minWidth: "20px",
    width: "40px",
    height: "40px",
  },
  padding: {
    padding: "10px",
  },
  submitGrid: { padding: "4px 0 4px 4px" },
}));
