import * as React from "react";

import { Box, Drawer, List, ListItem, Divider, ListItemText } from "@material-ui/core";
import { Route } from "react-router-dom";

import { INavigationGroup } from "../../interfaces/navigation/INavigationGroup";
import { INavigationGroupLink } from "../../interfaces/navigation/INavigationGroupLinks";

import MenuIcon from "@material-ui/icons/Menu";
import * as Styles from "./styles/MobileMenuStyles";
import { NavbarAuthentication } from "../authentication/NavbarAuthentication";

interface IProps {
  navigationGroups: INavigationGroup[];
}

export const MobileMenu: React.FC<IProps> = props => {
  const classes = Styles.DesktopMenuStyles();

  const [state, setState] = React.useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }

    setState(open);
  };

  const goTo = (history: any, navigationGroupLink: INavigationGroupLink) => {
    if (window.location.pathname.startsWith(`${navigationGroupLink.path}`)) {
      history.replace({
        pathname: navigationGroupLink.path,
        search: navigationGroupLink.search,
      });
      window.location.reload();
    } else {
      history.push({
        pathname: navigationGroupLink.path,
        search: navigationGroupLink.search,
      });
    }
  };

  const NavigationGroup = (navigationGroup: INavigationGroup, index: number) => {
    return (
      <div key={index}>
        <Divider className={classes.divider} />
        {navigationGroup.navigationGroupLinks.map((navigationGroupLink: INavigationGroupLink, index: number) => {
          return NavigationGroupItem(navigationGroupLink, index);
        })}
      </div>
    );
  };

  const NavigationGroupItem = (navigationGroupLink: INavigationGroupLink, index: number) => {
    return (
      <Route
        key={index}
        render={({ history }) => (
          <ListItem
            key={index}
            button
            onClick={() => {
              goTo(history, navigationGroupLink);
              toggleDrawer(false);
            }}
          >
            <ListItemText onClick={toggleDrawer(false)} primary={navigationGroupLink.title} />
          </ListItem>
        )}
      />
    );
  };

  return (
    <>
      <Box display="flex">
        <NavbarAuthentication />
      </Box>
      <Box display="flex">
        <Box height={32}>
          <MenuIcon onClick={toggleDrawer(true)} className={classes.button}></MenuIcon>
          <Drawer open={state} onClose={toggleDrawer(false)}>
            <div className={classes.draw} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
              <List>
                {props.navigationGroups.map((navigationGroup: INavigationGroup, index: number) => {
                  return NavigationGroup(navigationGroup, index);
                })}
              </List>
            </div>
          </Drawer>
        </Box>
      </Box>
    </>
  );
};
