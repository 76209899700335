import React from "react";
import { useSnackbar } from "notistack";
import { useSalesDocumentInfo } from "../../services/SalesDocumentService";
import { LoadingComponent } from "../loading/LoadingComponent";
import { Pdf } from "./pdf/Pdf";

interface SalesDocumentContainerProps {
  auctionId: string;
}

export const SalesDocumentContainer: React.FC<SalesDocumentContainerProps> = ({ auctionId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [salesDocumentInfo, salesDocumentInfoLoading] = useSalesDocumentInfo(auctionId, () => {
    enqueueSnackbar("There was a problem with the auction request please try again.", { variant: "error" });
  });

  return (
    <>
      {salesDocumentInfoLoading && <LoadingComponent label="Loading document" />}
      {!salesDocumentInfoLoading && salesDocumentInfo !== null && <Pdf salesDocumentInfo={salesDocumentInfo} loading={salesDocumentInfoLoading} />}
    </>
  );
};
