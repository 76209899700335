import { useNotificationsReceiver } from "../../services/useNotificationsReceiver";
import React from "react";

const WebsiteNotifications: React.FC = ({ children }) => {
  useNotificationsReceiver();

  return <>{children}</>;
};

export default WebsiteNotifications;
