import { ISalvageClientSettings } from "./interfaces/ISalvageClientSettings";
import { IB2CSettings } from "./interfaces/IB2CSettings";
import {
  SOURCE_VERSION,
  INSTRUMENTATION_KEY,
  B2C_USER_SCOPE,
  TOKEN_SCOPE,
  CLIENT_ID,
  TENANT_SIGN_IN,
  TENANT_SIGN_UP,
  TENANT_FORGOT_PASSWORD,
  PUBLIC_LOT_API,
  PRIVATE_LOT_API,
  PUBLIC_AUCTION_API,
  PRIVATE_AUCTION_API,
  PRIVATE_BIDDING_API,
  PRIVATE_USER_API,
  PRIVATE_SIGNALR_API,
  PRIVATE_PAYMENTS_API,
  PUBLIC_TIME_SYNC_API,
  GOOGLE_API_KEY,
  GOOGLE_ANALYTICS_TRACKING_ID,
  PUBLIC_DATA_SCRAPING_MONITOR_API,
  LOT_BLOB_URL,
  GOOGLE_RECAPTCHA,
  STRIPE_PUBLIC_KEY
} from "./environmentVariables";

const b2CSettings: IB2CSettings = {
  UserScope: B2C_USER_SCOPE,
  TokenScope: TOKEN_SCOPE,
  ClientId: CLIENT_ID,
  TenantSignIn: TENANT_SIGN_IN,
  TenantSignUp: TENANT_SIGN_UP,
  TenantForgotPassword: TENANT_FORGOT_PASSWORD,
};

export const Settings: ISalvageClientSettings = {
  Version: SOURCE_VERSION,
  InstrumentationKey: INSTRUMENTATION_KEY,
  LotPublicApiUrl: PUBLIC_LOT_API,
  LotPrivateApiUrl: PRIVATE_LOT_API,
  AuctionPublicApiUrl: PUBLIC_AUCTION_API,
  AuctionPrivateApiUrl: PRIVATE_AUCTION_API,
  BiddingPrivateApiUrl: PRIVATE_BIDDING_API,
  UsersPrivateApiUrl: PRIVATE_USER_API,
  SignalRPrivateApiUrl: PRIVATE_SIGNALR_API,
  PaymentsPrivateApiUrl: PRIVATE_PAYMENTS_API,
  LotBlobUrl: LOT_BLOB_URL,
  TimeSyncPublicApiUrl: PUBLIC_TIME_SYNC_API,
  B2CSettings: b2CSettings,
  GoogleApiKey: GOOGLE_API_KEY,
  GoogleAnalyticsTrackingId: GOOGLE_ANALYTICS_TRACKING_ID,
  DataScrapingMonitorPublicApiUrl: PUBLIC_DATA_SCRAPING_MONITOR_API,
  GoogleRecaptcha: GOOGLE_RECAPTCHA,
  StripePublicKey: STRIPE_PUBLIC_KEY,
};
