import { Button, Divider, Grid, Hidden, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import { handleAcceptGACookies, handleRejectGACookies } from "../../helpers/cookieHelper";
import * as Styles from "./styles/InformationPagesStyles";

const CookieContainer: React.FC = () => {
  const classes = Styles.InformationPagesStyles();
  const [isAccepted, setIsAccepted] = useState(getCookieConsentValue());

  const handleAcceptCookies = () => {
    handleAcceptGACookies();
    setIsAccepted('true');
  }

  const handleRejectCookies = () => {
    handleRejectGACookies();
    setIsAccepted('false');
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            How can I change my cookie settings?
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be
            about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually
            directly identify you, but it can give you a more personalised web experience. Because we respect your right to privacy, you can choose
            not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However,
            blocking some types of cookies may impact your experience of the site and the services we are able to offer.
          </Typography>
        </Grid>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Strictly Necessary Cookies
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response
            to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.
            You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not
            store any personally identifiable information.
          </Typography>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Cookie Name</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>ai_session</TableCell>
                  <TableCell>30 mins</TableCell>
                  <TableCell>To identify a session.</TableCell>
                  <TableCell>Strictly Necessary</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ai_user</TableCell>
                  <TableCell>1 year</TableCell>
                  <TableCell>To identify a user.</TableCell>
                  <TableCell>Strictly Necessary</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Analytics Cookies
          </Typography>
          {isAccepted && (
            <Typography variant="h5" component="h1" className={classes.orangeText}>
              { isAccepted === 'true' ? 'Accepted' : 'Rejected' }
            </Typography>
          )}
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.textPadding}>
            These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know
            which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated
            and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to
            monitor its performance.
          </Typography>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Cookie Name</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>_ga</TableCell>
                  <TableCell>2 years</TableCell>
                  <TableCell>
                    This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session, campaign data and keep track of
                    site usage for the site's analytics report. The cookies store information anonymously and assign a randomly generated number to
                    identify unique visitors.
                  </TableCell>
                  <TableCell>Analytics</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>_gid</TableCell>
                  <TableCell>1 day</TableCell>
                  <TableCell>
                    This cookie is installed by Google Analytics. The cookie is used to store information of how visitors use a website and helps in
                    creating an analytics report of how the website is doing. The data collected including the number visitors, the source where they
                    have come from, and the pages visited in an anonymous form.
                  </TableCell>
                  <TableCell>Analytics</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid item>
            <Button variant="contained" color="primary" className={classes.acceptCookiesButton} onClick={handleAcceptCookies}>
              Accept cookies
            </Button>
            <Button variant="contained" color="primary" className={classes.rejectCookiesButton} onClick={handleRejectCookies}>
              Reject cookies
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CookieContainer;
