import { makeStyles } from "@material-ui/core/styles";

export const IconStyles = makeStyles(theme => ({
  svgIcon: {
    fontSize: "20px",
    display: "inline-block",
    verticalAlign: "bottom",
  },
  svgIconMileage: {
    paddingLeft: "2px",
    fontSize: "20px",
    display: "inline-block",
  },
  svgIconReference: {
    paddingLeft: "5px",
    fontSize: "20px",
    display: "inline-block",
  },
  svgIconCommute: {
    paddingLeft: "3px",
    fontSize: "20px",
    display: "inline-block",
  },
  red: {
    color: "red",
  },
  green: {
    color: "green",
  },
  highlight: {
    color: theme.palette.primary.main
  },

  secondary: {
    color: theme.palette.secondary.main
  }
}));
