import React from "react";
import { useSnackbar } from "notistack";
import { Grid, Divider, Typography } from "@material-ui/core";
import * as Styles from "../../styles/BidHistoryContainerStyles";
import { bidPlacedFormat, currencyFormat } from "../../../../helpers/text-format/TextFormat";
import { ILatestBidHistoryInfo } from "../../../../interfaces/auctions/ILatestBid";
import { LoadingComponent } from "../../../loading/LoadingComponent";
import { useBidHistory } from "../../../../services/BidService";

interface BidHistory {
  auctionId: string;
}

const BidHistoryContainer: React.FC<BidHistory> = ({ auctionId }) => {
  const classes = Styles.BidHistoryContainerStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [bidHistory, , bidHistoryLoading] = useBidHistory(auctionId, () => {
    enqueueSnackbar("There was a problem with your bid request please try again.", { variant: "error" });
  });

  return (
    <>
      {bidHistoryLoading && <LoadingComponent label="Loading bids" />}

      {bidHistory && bidHistory.length === 0 && (
        <div>
          <h4> No bids</h4>
        </div>
      )}

      {!bidHistoryLoading && bidHistory !== null && bidHistory?.length > 0 && (
        <div>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography align="left">Time bid was placed</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="left">Amount</Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {bidHistory.map((bid: ILatestBidHistoryInfo, index: number) => (
            <div key={index}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography align="left">{bidPlacedFormat(bid.bidDateTime)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="left">{currencyFormat(bid.amount)}</Typography>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default BidHistoryContainer;
