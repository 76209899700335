import * as React from "react";
import * as Styles from "./styles/ContainerStyles";
import { Container } from "@material-ui/core";

import HelpAndFaqContainer from "../information-pages/HelpAndFaqContainer";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

function HelpAndFaq() {
  const classes = Styles.ContainerStyles();
  return (
    <Container fixed className={classes.container}>
      <HelpAndFaqContainer />
    </Container>
  );
}

export default withAITracking(reactPlugin, HelpAndFaq, "HelpAndFaq");
