import { MediaType } from "../types/MediaType";

export const youtubeUrl = (videoId: string) => {
  return `http://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
};

export const youtubeThumbnail = (mediaUrl: string) => {
  const videoId = mediaUrl.split("/")[3];
  return videoId;
};

export const thumbnailUrl = (mediaType: number, mediaUrl: string) => {
  if (mediaType === MediaType.video) {
    const videoId = youtubeThumbnail(mediaUrl);
    const url = youtubeUrl(videoId);
    return url;
  } else {
    return mediaUrl;
  }
};
