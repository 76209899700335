import * as React from "react";
import { ILatestBid } from "../../../interfaces/auctions/ILatestBid";
import { useHub } from "./HubProvider";

enum SignalRHub {
  PrivateUser,
  WebsiteBidResult,
}

export interface ISignalRUserMessage {
  message: string; // Plain text message for the user
  type: string; // Type, being either "info" or "error" or "warning"
  operationNonce: string | undefined; // If this relates to a specific operation, then this is the ID
  timeStamp: string; // If this relates to a specific operation, then this is the ID
}


const useSignalrLatestBid = (auctionId: string): [ILatestBid | null, string | undefined | null] => {

  const [data, setData] = React.useState<ILatestBid | null>(null);
  const hub = useHub();
  const increment = hub.bidData?.increment ?? 0;
  React.useEffect(() => {
    if (hub.bidData && hub.bidData?.auctionId === auctionId) {
      setData(hub.bidData);
    }

  }, [hub.bidData, increment, auctionId])

  return [data, hub.hub?.connectionId];
}

const useSignalrPrivateUser = () => {
  const hub = useHub();
  return hub.privateUserData;
}

const useSignalrLatestBidResults = () => {
  const hub = useHub();
  return hub.websiteBidResult;
}

const useSignalR = (hub: SignalRHub) => {
  switch (hub) {
    case SignalRHub.PrivateUser:
      return useSignalrPrivateUser;
    case SignalRHub.WebsiteBidResult:
      return useSignalrLatestBidResults;
    default:
      throw new Error("Unknown SignalRHub enum");
  }
};

export { useSignalR, SignalRHub, useSignalrLatestBid };
