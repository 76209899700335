import { fade, makeStyles } from "@material-ui/core/styles";
const borderRadius = "16px";
export const EditorPicksStyles = makeStyles(theme => ({
  container: {
    paddingBottom: "30px",
    marginTop:"-53px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
  },
  div: {
    cursor: "pointer",
    borderRadius: borderRadius,
    position: "relative",
    margin: "8px",
    height: "250px",
  },
  img: {
    padding: "1px 1px 1px 1px",
    display: "block",
    objectFit: "cover",
    height: "inherit",
    width: "100%",
    borderRadius: borderRadius,
  },
  overlayOrange: {
    position: "absolute",
    bottom: "0",
    borderRadius: borderRadius,
    background: fade(theme.palette.primary.main, 0.8),
    color: theme.palette.primary.light,
    height: "100%",
    width: "100%",
    "&:hover": {
      background: fade(theme.palette.primary.main, 0.4),
    },
  },
  tileType: { paddingLeft: "20px", color: "white", fontWeight: "lighter" },
  tileTitle: {
    paddingLeft: "20px",
    color: "white",
  },
  tileDescription: {
    padding: "20px",
    color: "white",
  },
}));
