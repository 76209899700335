import React from "react";
import Grid from "@material-ui/core/Grid";

import * as Styles from "../styles/ViewLotStyles";

import VehicleLotDetailsContainer from "./lot-details/VehicleLotDetailsContainer";
import TitleContainer from "../title/TitleContainer";
import MediaContainer from "../lot-images/MediaContainer";
import BidGridComponent from "../../bid/BidGridComponent";
import { SellerCard } from "../seller/SellerCard";
import { Auction } from "../../../services/AuctionService";

interface ViewLotProps {
  auction: Auction;
}

const VehicleViewLot: React.FC<ViewLotProps> = ({ auction }) => {
  const classes = Styles.ViewLotStyles();
  return (
    <Grid container spacing={5} style={{ paddingTop: 20 }}>
      <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
        <MediaContainer lotId={auction.lotId} lotMediaCacheName={auction.lotMediaCacheName} />
      </Grid>

      <Grid className={classes.bidContainer} item xs={12} sm={12} md={6} lg={4} xl={4}>
        <TitleContainer auction={auction} />
        <BidGridComponent auction={auction} isWatchlistMode={false} auctionUpdated={() => { }} />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
        <SellerCard auction={auction} />
      </Grid>

      <Grid item xs={12}>
        <VehicleLotDetailsContainer auction={auction} />
      </Grid>
    </Grid>
  );
};

export default VehicleViewLot;
