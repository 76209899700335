import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import { BrowserRouter, Route, Router } from "react-router-dom";
import { MsalClient, MsalConsumer } from "./authentication/MsalContext";
import { MsalProvider } from "./authentication/MsalProvider";
import { useHandleRedirect } from "./authentication/useHandleRedirect";
import { HubProvider } from "./components/view-lot/hooks/HubProvider";
import { AppContextProvider } from "./contexts/AppContext";
import History from "./history";
import Pages from "./routes/Pages";
import { reactPlugin } from "./services/TelemetryService";

export const App: React.FC = () => {
  useHandleRedirect();
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={MsalClient}>
        <MsalConsumer>
          {context => (
            <AppContextProvider msalContext={context}>
              <HubProvider>
                <Router history={History}>
                  <BrowserRouter>
                    <Route component={Pages} />
                  </BrowserRouter>
                </Router>
              </HubProvider>
            </AppContextProvider>
          )}
        </MsalConsumer>
      </MsalProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
