import React from "react";
import ReactPlayer from "react-player";
import * as Styles from "./../styles/LargeMediaStyles";
import { MediaType } from "./types/MediaType";
import { Media } from "../../../services/MediaService";

interface LargeMediaProps {
  media: Media[];
  mediaIndex: number;
}

const LargeMedia: React.FC<LargeMediaProps> = ({ media, mediaIndex }) => {
  const classes = Styles.LargeMediaStyles();
  if (media[mediaIndex].mediaType === MediaType.image) {
    return <img src={media[mediaIndex].url} className={classes.img} alt={media[mediaIndex].description} />;
  } else {
    return (
      <div className={classes.playerWrapper}>
        <ReactPlayer
          playing
          className={classes.player}
          url={`https://www.youtube.com/watch?v=${media[mediaIndex].video}`}
          width="100%"
          height="100%"
        />
      </div>
    );
  }
};

export default LargeMedia;
