import React from "react";
import * as Styles from "./../styles/MediaRenderStyles";
import { thumbnailUrl } from "./helpers/ThumbnailUrl";
import { Media } from "../../../services/MediaService";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { CardMedia, Card, CardActionArea } from "@material-ui/core";
interface MediaRenderProps {
  media: Media;
  onChangeMediaIndex: React.Dispatch<React.SetStateAction<number>>;
  mediaIndex: number;
}

const ImageClick: React.FC<MediaRenderProps> = MediaRenderProps => {
  MediaRenderProps.onChangeMediaIndex(Number(MediaRenderProps.mediaIndex));
  return null;
};

const MediaRender: React.FC<MediaRenderProps> = MediaRenderProps => {
  const classes = Styles.MediaRenderStyles();
  const url = thumbnailUrl(MediaRenderProps.media.mediaType, MediaRenderProps.media.url);
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia image={url} onClick={() => ImageClick(MediaRenderProps)} className={classes.media}>
          {MediaRenderProps.media.video && <YouTubeIcon fontSize="large"></YouTubeIcon>}
        </CardMedia>
      </CardActionArea>
    </Card>
  );
};

export default MediaRender;
