import { makeStyles } from "@material-ui/core";
import { error } from "../../../theme";

export const SuspensionNotificationStyles = makeStyles(theme => ({
  hidden: {
    position: "absolute",
    opacity: "0",
  },
  messageContainer: {
    userSelect: "none",
    cursor: "pointer",
    position: "static",
    opacity: "1",
    backgroundColor: error,
    "&:hover": {
      backgroundColor: error,
      transition: "background-color 0.1s ease",
    },
    transition: "opacity 0.5s linear, background-color 0.1s ease",
  },
  textLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconPosition: {
    marginRight: "5px",
  },
}));
