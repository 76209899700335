import * as React from "react";
import { Container } from "@material-ui/core";
import * as Styles from "./styles/ContainerStyles";

import { EditorsPicks } from "../homepage/EditorsPicks";
import { ActionBanner } from "../homepage/ActionBanner";
import { Featured } from "../homepage/Featured";
import { AppContext, IInitialState } from "../../contexts/AppContext";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

function Homepage() {
  const classes = Styles.ContainerStyles();

  return (
    <AppContext.Consumer>
      {(context: IInitialState) => (
        <Container fixed className={classes.container}>
          <EditorsPicks />
          <Featured />
          <ActionBanner context={context} />
        </Container>
      )}
    </AppContext.Consumer>
  );
}

export default withAITracking(reactPlugin, Homepage, "Homepage");
