export interface IOpeningDays {
  monday: IOpeningTime;
  tuesday: IOpeningTime;
  wednesday: IOpeningTime;
  thursday: IOpeningTime;
  friday: IOpeningTime;
  saturday: IOpeningTime;
  sunday: IOpeningTime;
}

export interface IOpeningTime {
  openingTimeStatus: OpeningTimesStatus;
  open: string;
  close: string;
}

export enum OpeningTimesStatus {
  Unknown = 0,
  Closed = 1,
  Open = 2,
  PleaseCall = 3,
}
