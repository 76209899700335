import moment from "moment";
import { IOpeningTime, OpeningTimesStatus } from "../../interfaces/IOpeningDays";

export const ellipsisText = (input: string | undefined, maxLength: number) => {
  if (input && input.length > maxLength) return input.substring(0, maxLength) + "...";
  else return input;
};

export const currencyFormat = (amount: number) => {
  if (amount) {
    const localeString = amount.toLocaleString("GBP");
    const currency = "£" + localeString;
    return currency;
  }

  return "£0";
};

export const feeFormat = (fee: number) => {
  if (fee === 0) {
    return "No Fee";
  } else {
    return `${fee * 100}%`;
  }
};

export const booleanToYesNo = (bool: boolean | null) => {
  if (bool === null) {
    return "Unknown";
  } else if (!bool) {
    return "No";
  } else {
    return "Yes";
  }
};

export const openingTimes = (openingTime: IOpeningTime) => {
  if (openingTime.openingTimeStatus === OpeningTimesStatus.Open) {
    const open = moment(openingTime.open).format("HH:mm");
    const close = moment(openingTime.close).format("HH:mm");
    return open + " - " + close;
  } else if (openingTime.openingTimeStatus === OpeningTimesStatus.Closed) {
    return "Closed";
  } else if (openingTime.openingTimeStatus === OpeningTimesStatus.PleaseCall) {
    return "Please call";
  }
};

export const roundedMileFormat = (mileage: number) => {
  const output = mileage.toFixed(2) + " miles";
  return output;
};

export const mileFormat = (mileage: number) => {
  const output = mileage.toString() + " miles";
  return output;
};

export const salesDocumentDateFormat = (bidPlacedInput: string) => {
  const bidPlacedOutput = moment(bidPlacedInput).format("ddd Do MMM YYYY HH:mm");
  return bidPlacedOutput;
};
export const bidPlacedFormat = (bidPlacedInput: string) => {
  const bidPlacedOutput = moment(bidPlacedInput).format("MMM Do YYYY  HH:mm:ss");
  return bidPlacedOutput;
};

export const endDateFormat = (endDate: string) => {
  const endDateOutput = moment(endDate).format("Do MMM YYYY  HH:mm");
  return endDateOutput;
};

export const days = (timeLeftDuration: moment.Duration) => {
  const days = timeLeftDuration.days() - 7 * timeLeftDuration.weeks();
  return days;
};

export const timeLeftMonthsWeeksDays = (timeLeftDuration: moment.Duration) => {
  const timeLeft = `${timeLeftDuration.months()}m ${timeLeftDuration.weeks()}w ${days(timeLeftDuration)}d left `;
  return timeLeft;
};

export const timeLeftWeeksDays = (timeLeftDuration: moment.Duration) => {
  const timeLeft = `${timeLeftDuration.weeks()}w ${days(timeLeftDuration)}d left `;
  return timeLeft;
};

export const timeLeftDaysHours = (timeLeftDuration: moment.Duration) => {
  const timeLeft = `${timeLeftDuration.days()}d ${timeLeftDuration.hours()}h  left `;
  return timeLeft;
};

export const timeLeftHoursMinutes = (timeLeftDuration: moment.Duration) => {
  const timeLeft = `${timeLeftDuration.hours()}h ${timeLeftDuration.minutes()}m  left `;
  return timeLeft;
};

export const timeLeftMinutes = (timeLeftDuration: moment.Duration) => {
  const timeLeft = ` ${timeLeftDuration.minutes()}m  left `;
  return timeLeft;
};

export const timeLeftMinutesSeconds = (timeLeftDuration: moment.Duration) => {
  const timeLeft = `${timeLeftDuration.minutes()}m ${timeLeftDuration.seconds()}s left `;
  return timeLeft;
};

export const timeLeftFormat = (timeLeftDuration: moment.Duration | undefined) => {
  if (timeLeftDuration === undefined) {
    return "";
  } else if (timeLeftDuration.months() > 0) {
    return timeLeftMonthsWeeksDays(timeLeftDuration);
  } else if (timeLeftDuration.weeks() > 0) {
    return timeLeftWeeksDays(timeLeftDuration);
  } else if (timeLeftDuration.days() > 0) {
    return timeLeftDaysHours(timeLeftDuration);
  } else if (timeLeftDuration.hours() > 0) {
    return timeLeftHoursMinutes(timeLeftDuration);
  } else if (timeLeftDuration.minutes() >= 10) {
    return timeLeftMinutes(timeLeftDuration);
  } else {
    return timeLeftMinutesSeconds(timeLeftDuration);
  }
};

export const numberWithCommas = (x: number): string => {
  if (x === undefined || x === null) {
    return "";
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const sentenceCaseTextFromCamelCase = (camelCase: string | null | undefined, preserveCapitalisation = false): string => {
  if (!camelCase) return "";

  var result = camelCase.replace(/([A-Z])/g, " $1").trim();
  return result.charAt(0).toUpperCase() + (preserveCapitalisation ? result : result.toLowerCase()).slice(1);
};
