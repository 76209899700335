import * as React from "react";
import { Container } from "@material-ui/core";
import * as Styles from "./styles/ContainerStyles";
import { GetWatchlist } from "../watchlist/GetWatchlist";
import { SecureArea } from "../authentication/SecureArea";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

function Watchlist() {
  const classes = Styles.ContainerStyles();

  return (
    <SecureArea>
      <Container fixed className={classes.container}>
        <GetWatchlist />
      </Container>
    </SecureArea>
  );
}

export default withAITracking(reactPlugin, Watchlist, "Watchlist");
