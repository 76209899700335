import { makeStyles } from "@material-ui/core";

export const UpdateNotificationStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2, 4, 3),
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
  },
  toggleGroup: {
    marginRight: "8px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#929292",
  },
  italicText: {
    fontStyle: "italic",
    color: theme.palette.secondary.dark,
  },
}));
