import * as React from "react";
import { Button, Hidden } from "@material-ui/core";
import { IMsalContext } from "../../authentication/MsalContext";
import { useMsal } from "../../authentication/MsalProvider";

export const LoggedOutButton: React.FC = () => {
  const msal: IMsalContext = useMsal();

  const logout = () => {
    msal.instance.logout();
  };

  return (
    <>
      <Hidden smDown>
        <Button
          onClick={() => logout()}
          variant="contained"
          color="primary"
          style={{
            margin: "0 16px",
            minWidth: "75px",
            fontWeight: "normal",
          }}
        >
          Logout
        </Button>
      </Hidden>
      <Hidden mdUp>
        <Button
          onClick={() => logout()}
          variant="contained"
          color="primary"
          style={{
            display: "flex",
            margin: "0 8px 0 16px",
            minWidth: "75px",
            fontWeight: "normal",
          }}
        >
          Logout
        </Button>
      </Hidden>
    </>
  );
};
