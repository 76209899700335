import moment from "moment";

export const canRenew = (endDate: string) => {
  const renewFrom = moment().add(1, "month");

  if (renewFrom < moment(endDate)) {
    return false;
  } else {
    return true; 
  }
};
