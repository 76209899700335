import { HubConnection } from "@microsoft/signalr";
import * as React from "react";
import { ILatestBid } from "../../../interfaces/auctions/ILatestBid";
import { ISignalRUserMessage } from "./useSignalR";

export interface IHubContext {
  hub: HubConnection | null;
  bidData: ILatestBid | null;
  websiteBidResult: ISignalRUserMessage | null;
  privateUserData: ISignalRUserMessage | null;
}

const defaultHubContext: IHubContext = {
  hub: null,
  bidData: null,
  websiteBidResult: null,
  privateUserData: null
};

export const HubContext = React.createContext<IHubContext>(defaultHubContext);
export const HubConsumer = HubContext.Consumer;
