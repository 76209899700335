import { makeStyles } from "@material-ui/core";

export const DisconnectedNotificationStyles = makeStyles(theme => ({
  hidden: {
    position: "absolute",
    opacity: "0",
  },
  messageContainer: {
    userSelect: "none",
    position: "static",
    opacity: "1",
    backgroundColor: theme.palette.error.main,
    transition: "opacity 0.5s linear, background-color 0.1s ease",
  },
  textLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconPosition: {
    marginRight: "5px",
  },
}));
