import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { IValidation, ValidationDefault } from "../../interfaces/IValidation";
import { GeocodeResult } from "use-places-autocomplete";
import { IAddress, IsValid as isAddressValid, AddressDefault } from "../../interfaces/IAddress";
import { LocationSearchTextField } from "./LocationSearchTextField";
import { ErrorMessage, isError } from "../bidder-registration/helpers/ErrorHandlers";
import { CountrySelect } from "./CountryPicker";
import { ICountry, CountryDefault } from "../../interfaces/ICountry";

interface IProps {
  validation?: IValidation;
  initialAddress: IAddress;
  onAddressChange: (newAddress: IAddress) => void;
  isValid: (isValid: boolean) => void;
  disabled?: boolean;
}

export const AddressEntryForm: React.FC<IProps> = ({
  initialAddress,
  onAddressChange,
  validation = ValidationDefault,
  isValid,
  disabled = false,
}) => {
  const [isUkResident, setIsUkResident] = React.useState(true);
  const [address, setAddress] = React.useState(initialAddress);

  const onAddressFieldChange = (e: any) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    setAddress(b => ({
      ...address,
      [fieldName]: fieldValue,
      latitude: fieldName === "postcode" ? 0 : address.latitude,
      longitude: fieldName === "postcode" ? 0 : address.longitude,
    }));
  };

  const selectedCountryChanged = (country: ICountry) => {
    setAddress({
      ...AddressDefault,
      country: country,
    });
    setIsUkResident(country.countryCode === CountryDefault.countryCode);
  };

  React.useEffect(() => {
    onAddressChange(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  React.useEffect(() => {
    isValid(isAddressValid(address));
  }, [isValid, isUkResident, address]);

  const autoCompleteAddressSet = (selectedAddress: GeocodeResult) => {
    // Reduce the complex returned value into a simpler array of objects.
    const mappedItems = selectedAddress.address_components.flatMap(item =>
      item.types.map(function(type) {
        return { key: type, value: item.long_name };
      })
    );

    const newAddress = { ...address };

    const street = mappedItems
      .filter(p => p.key === "street_number" || p.key === "route")
      .map(p => p.value)
      .join(" ");

    newAddress.line1 = street;
    newAddress.line2 = mappedItems.find(p => p.key === "sublocality")?.value ?? "";
    newAddress.line3 = mappedItems.find(p => p.key === "locality")?.value ?? "";
    newAddress.city = mappedItems.find(p => p.key === "postal_town")?.value ?? "";
    newAddress.postcode = mappedItems.find(p => p.key === "postal_code")?.value ?? "";
    newAddress.county = mappedItems.find(p => p.key === "administrative_area_level_2")?.value ?? "";
    newAddress.country = address.country;
    newAddress.latitude = selectedAddress.geometry?.location?.lat();
    newAddress.longitude = selectedAddress.geometry?.location?.lng();

    setAddress(newAddress);
  };
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CountrySelect country={address.country} onChange={selectedCountryChanged} disabled={disabled} />
        </Grid>
        <Grid item xs={12}>
          <LocationSearchTextField
            autoFocus
            disabled={disabled}
            onAddressSet={autoCompleteAddressSet}
            required
            fullWidth
            error={isError("Address.Line1", validation)}
            helperText={ErrorMessage("Address.Line1", validation)}
            name="line1"
            label="Line 1"
            value={address.line1}
            countryCode={address.country.alpha2}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            fullWidth
            error={isError("Address.Line2", validation)}
            helperText={ErrorMessage("Address.Line2", validation)}
            name="line2"
            label="Line 2"
            value={address.line2}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            fullWidth
            error={isError("Address.Line3", validation)}
            helperText={ErrorMessage("Address.Line3", validation)}
            name="line3"
            label="Line 3"
            value={address.line3}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            required
            fullWidth
            error={isError("Address.City", validation)}
            helperText={ErrorMessage("Address.City", validation)}
            name="city"
            label="City/town"
            value={address.city}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={disabled}
            fullWidth
            error={isError("Address.County", validation)}
            helperText={ErrorMessage("Address.County", validation)}
            name="county"
            label="County"
            value={address.county}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required={isUkResident}
            disabled={disabled}
            fullWidth
            error={isError("Address.Postcode", validation)}
            helperText={ErrorMessage("Address.Postcode", validation)}
            name="postcode"
            label="Postcode"
            value={address.postcode}
            onChange={value => onAddressFieldChange(value)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
