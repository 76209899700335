import React from "react";
import { Grid, Button, Tooltip } from "@material-ui/core";
import * as Styles from "./styles/MyAccountContainerStyles";
import { BidderStatus, IBidder } from "../../interfaces/bidders/IBidder";
import { GetSubscriptionStatus } from "./helpers/GetSubscriptionStatus";
import { SubscriptionExpiry } from "./SubscriptionExpiry";
import { Link } from "react-router-dom";
import { canRenew } from "../../contexts/canRenew";
import * as BidderService from "../../services/BidderService";
import { useSnackbar } from "notistack";

const PaymentDetails: React.FC<{ bidder: IBidder }> = ({ bidder }) => {
  const classes = Styles.MyAccountContainerStyles();
  const [canRenewSubscription, setCanRenewSubscription] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingBidder, setLoadingBidder] = React.useState(true);

  React.useEffect(() => {
    setLoadingBidder(true);
    BidderService.GetBidder()
      .then(details => {
        if (details.parsedBody != undefined) {
          bidder.bidderStatus = details.parsedBody.bidderStatus;
          bidder.subscriptionEndDate = details.parsedBody.subscriptionEndDate;
          bidder.accountType = details.parsedBody.accountType;

          setCanRenewSubscription(
            (bidder.bidderStatus === BidderStatus.ActiveFull && canRenew(bidder.subscriptionEndDate)) ||
            bidder.bidderStatus === BidderStatus.ActiveFree ||
            bidder.bidderStatus === BidderStatus.SubscriptionExpired
          );
        }
        setLoadingBidder(false);
      })
      .catch((error: Response) => {
        enqueueSnackbar("We have been unable to re-load your details due to an error. Please close your browser and try again", {
          variant: "error",
        });
        setLoadingBidder(false);
      });


  }, []);

  return (
    <div>
      {!loadingBidder && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h4 className={classes.italicText}>An annual subscription is required to place bids, your current status:</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.orangeText}>ACCOUNT STATUS</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.greyText}>{GetSubscriptionStatus(bidder.subscriptionEndDate, bidder.accountType)}</h4>
          </Grid>

          <Grid item xs={12}>
            <SubscriptionExpiry endDate={bidder.subscriptionEndDate} />
          </Grid>

          <Grid item xs={12}>
            <Button className={classes.button} style={{marginBottom: "10px"}} variant="contained" color="primary" component={Link} to="/Renewal" disabled={!canRenewSubscription}>
              Make a subscription payment
            </Button>

            <Button className={classes.button} style={{marginBottom: "10px"}} variant="contained" color="primary" component={Link} to="/SubscriptionHistory">
              Subscription history
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default PaymentDetails;
