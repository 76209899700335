import * as React from "react";
import { reactPlugin } from "../../services/TelemetryService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { SearchComponent } from "../search/SearchComponent";

function Search() {
  return <SearchComponent />;
}

export default withAITracking(reactPlugin, Search, "Search");
