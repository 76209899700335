import { ISelectedFile } from "../steps/id-verification/entities/ImageUploadStateManagement";
import * as VerificationService from "../../../services/VerificationService";
import * as BlobStorageService from "../../../services/BlobStorageService";

export const UploadFiles = async (
  filesToSubmit: Array<ISelectedFile>,
  info: (message: string) => void,
  error: (message: string) => void,
  initialUpload: boolean = true
): Promise<boolean> => {
  try {
    //If document is re-uploaded, we prefix a guid to the file name to avoid issues with approvals when the file name was the same
    //This slice value is used to remove the prefixed value so the original file name is displayed to users for notification toasts
    const sliceValue = initialUpload ? 0 : 37;

    const verificationToken = await VerificationService.GetVerificationDocumentBlobToken();
    const promises: Array<Promise<{ url: string; file: ISelectedFile; success: boolean }>> = [];
    filesToSubmit.forEach(file => {
      const promise = BlobStorageService.UploadFileToBlobStorage(verificationToken, file.file.name, file.file)
        .then(blobResponse => {
          info(`Uploaded ${file.file.name.slice(sliceValue)}`);
          return { url: blobResponse.blobUrl, file: file, success: true };
        })
        .catch((reason: string) => {
          // Display the error message
          error(`There was an error uploading the image ${file.file.name.slice(sliceValue)}. The following error ocurred: ${reason}`);
          return { url: "", file: file, success: false };
        });
      promises.push(promise);
    });
    const uploadedFiles = await Promise.all(promises);
    if (uploadedFiles.some(p => !p.success)) {
      const files = uploadedFiles
        .filter(p => !p.success)
        .map(p => p.file.file.name.slice(sliceValue))
        .join(",");
      error(`We were unable to upload the following file(s). \n${files}\n Please try again.`);
      return false;
    }

    const res = uploadedFiles.map(p => ({ url: p.url, documentType: p.file.formOfId!.serverKey, rotation: p.file.rotate }));
    await VerificationService.SetDocumentsUploaded(res);
    return true;
  } catch (err) {
    // This means there was an error downloading the token to upload the files to blob storage
    error(`An error occurred whilst trying to send your documents. Please try and upload them again.`);

    //TODO: Add in integration with app insights!
    return false;
  }
};
