import * as React from "react";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { CheckToken, VerifyNumber } from "../../../services/BidderService";
import { LoadingComponent } from "../../loading/LoadingComponent";
import { IValidatedNumber } from "../../../interfaces/bidders/ICreateBidder";
import { GetClientSettings } from "../../../services/SettingsService";
import { ISalvageClientSettings } from "../../../interfaces/ISalvageClientSettings";
import ReCAPTCHA from "react-google-recaptcha";

interface IProps {
  validatedNumberCallback: (number: IValidatedNumber) => void;
  addButtonTitle: string;
}

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const VerifiedPhoneNumber: React.FC<IProps> = ({ validatedNumberCallback, addButtonTitle }) => {
  const [openAddNumber, setIsOpenAddNumber] = React.useState(false);
  const [openVerifyNumber, setIsOpenVerifyNumber] = React.useState(false);

  const [number, setNumber] = React.useState("");
  const [token, setToken] = React.useState("");

  const [numberErrorMessage, setNumberErrorMessage] = React.useState("");

  const [tokenErrorMessage, setTokenErrorMessage] = React.useState("");
  const [isBusy, setIsBusy] = React.useState(false);

  const [validCaptcha, setValidCaptcha] = React.useState(false);
  const [captchaCode, setCaptchaCode] = React.useState("");


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAddNumberOpen = () => {
    setValidCaptcha(false);
    setCaptchaCode('');
    setIsOpenAddNumber(true);
  };

  const confirmToken = async () => {
    if (token !== null) {
      if (token === "") {
        setTokenErrorMessage("You must enter the token provided");
      } else if (token.length !== 6) {
        setTokenErrorMessage("The token must be 6 numbers long");
      } else {
        setIsBusy(true);
        const result = await CheckToken({ verifyBySMS: true, phoneNumber: number, captcha: undefined }, token);
        setIsBusy(false);
        switch (result.parsedBody?.result ?? "not returned") {
          case "Success": {
            validatedNumberCallback({
              number: number,
              token: result.parsedBody!.token,
            });
            setToken("");
            setNumber("");
            handleClose();
            break;
          }
          case "Expired": {
            setNumberErrorMessage("The token is only valid for 10 minutes. You will need to try again");
            changeNumber();
            break;
          }
          case "Incorrect": {
            setTokenErrorMessage("This was not the token we sent to your number. Please try again.");
            break;
          }
          case "Error": {
            setTokenErrorMessage("Something went wrong while trying to check the token you sent. Please try again.");
            break;
          }
          case "ToManyErrors": {
            setNumberErrorMessage("The token was entered incorrectly too many times. Please try again.");
            changeNumber();
            break;
          }
          default:
            setTokenErrorMessage("We got an unexpected response while trying to check the token you sent. Plase try again.");
        }
      }
    }
  };
  const handleClose = () => {
    setIsOpenAddNumber(false);
    setIsOpenVerifyNumber(false);
  };

  const captchaOnChange = (value) => {
    let valid = value !== undefined && value != null && value.length > 0;
    setCaptchaCode(value);
    setValidCaptcha(valid);
  }

  const validateNumber = async () => {
    if (validCaptcha) {

      setNumberErrorMessage("");
      setTokenErrorMessage("");

      setIsBusy(true);
      const result = await VerifyNumber({ verifyBySMS: true, phoneNumber: number, captcha: captchaCode });
      setIsBusy(false);

      switch (result.parsedBody) {
        case "Success": {
          setIsOpenAddNumber(false);
          setIsOpenVerifyNumber(true);
          break;
        }
        case "InvalidNumber": {
          setNumberErrorMessage("The number is not a valid mobile number");
          break;
        }
        case "NoSMSToLandLine": {
          setNumberErrorMessage("You must enter a mobile number, not a landline");
          break;
        }
        case "ToManyRequests": {
          setNumberErrorMessage("There have been too many requests to validate this number. Please wait and try again later.");
          break;
        }
        case "Error": {
          setNumberErrorMessage("Something went wrong while trying to validate your number. Please try again.");
          break;
        }
        default:
          setNumberErrorMessage("We got an unexpected response while trying to validate your number. Plase try again.");
      }
    }
  };

  const changeNumber = () => {
    // Need to cancel the token
    setToken("");
    setIsOpenVerifyNumber(false);
    setIsOpenAddNumber(true);
  };
  if (isBusy) {
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
        <DialogTitle id="form-dialog-title">Processing Request</DialogTitle>
        <DialogContent>
          <LoadingComponent label="Please Wait" />
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleAddNumberOpen}>
        {addButtonTitle}
      </Button>
      <Dialog open={openAddNumber} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
        <DialogTitle id="form-dialog-title">Add Verified Mobile Number</DialogTitle>
        <DialogContent>
          <DialogContentText>We require a verified phone number to be able to register on the website</DialogContentText>
          <MuiPhoneNumber
            defaultCountry={"gb"}
            onChange={setNumber}
            value={number}
            margin="dense"
            name="mobileNumber"
            label="Mobile number"
            placeholder="+44"
            error={numberErrorMessage.length > 0}
            helperText={numberErrorMessage}
            required
            fullWidth
          />

          <Grid container>
            <Grid item>
              <Box mt={3}>
                <ReCAPTCHA
                  sitekey={ClientSettings.GoogleRecaptcha}
                  onChange={captchaOnChange}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={validateNumber} color="primary" disabled={!validCaptcha}>
            Validate Number
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openVerifyNumber} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
        <DialogTitle id="form-dialog-title">Add Verified Mobile Number</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We have sent a token to {number}. Please confirm the number you have received. If you don't receive a code within 10 minutes, please click
            "Change Number" and try again.
          </DialogContentText>
          <TextField
            value={token}
            onChange={p => setToken(p.target.value)}
            autoFocus
            error={tokenErrorMessage.length > 0}
            helperText={tokenErrorMessage}
            margin="dense"
            id="name"
            label="Token"
            required
            type="number"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={changeNumber} color="primary">
            Change Number
          </Button>
          <Button onClick={confirmToken} color="primary">
            Confirm Token
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
