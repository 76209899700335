import { makeStyles } from "@material-ui/core/styles";
import { black } from "../../../theme";

export const DesktopMenuStyles = makeStyles(theme => ({
  buttonLight: {
    color: black,
    margin: "0 12px",
    fontSize: "12px"
  },
  buttonDark: {
    color: black,
    margin: "0 12px",
    fontSize: "12px"
  },
  isOpen: { display: "none" },
  notOpen: { display: "" }
}));
