import React from "react";
import { Grid } from "@material-ui/core";
import { SavedSearchResult } from "../../services/SearchService";
import { SavedSearchItem } from "./SavedSearchItem";

export interface ISavedSearchesProps {
    results: SavedSearchResult[] | undefined;
    onItemDeleted(id: string): void;
    onItemViewSearch(queryString: string);
    loading: boolean;
    isDialog?: boolean; // Add this line
}

export const SavedSearchesList: React.FC<ISavedSearchesProps> = ({ results, onItemDeleted, onItemViewSearch, loading, isDialog }) => {
    return (
        <Grid container direction="row" spacing-md={2}>
            {results !== undefined &&
                results.map((item: SavedSearchResult, index: number) => (
                    <Grid item xs={12} key={index}>
                        <SavedSearchItem
                            item={item}
                            index={index}
                            loading={loading}
                            onDeleteEvent={onItemDeleted}
                            onViewSeachEvent={onItemViewSearch}
                            isDialog={isDialog}
                        />
                    </Grid>
                ))}
        </Grid>
    );
};
