import React, { useState } from "react";
import moment from "moment";
import useInterval from "../hooks/useInterval";
import { timeLeftFormat, endDateFormat } from "../../../helpers/text-format/TextFormat";
import { Grid } from "@material-ui/core";
import { AuctionStatus } from "../../../services/AuctionService";
import { adjustedCurrentTime } from "../../../helpers/TimeSync";
import { useIsLoggedIn } from "../../../helpers/ActiveUserHelper";

const oneSecond = 1000;
const oneMinute = 60000;
const oneHour = 3600000;
const oneDay = 86400000;

const getIntervalDuration = (endDate: moment.Moment, CurrentDate: moment.Moment) => {
  const timeRemaining = endDate.diff(CurrentDate);

  if (timeRemaining < oneHour) {
    return oneSecond;
  }
  if (timeRemaining < oneDay) {
    return oneMinute;
  }
  return oneHour;
};

const TimerContainer: React.FC<{ endDateInput: string; auctionStatus: AuctionStatus }> = ({ endDateInput, auctionStatus }) => {
  const [currentDate, setCurrentDate] = useState(adjustedCurrentTime());
  const endDateOutput = endDateFormat(endDateInput);
  const endDate = moment(endDateInput);
  const loggedIn = useIsLoggedIn();

  const [interval, setInterval] = useState(getIntervalDuration(endDate, currentDate));

  useInterval(() => {
    setCurrentDate(adjustedCurrentTime());
    setInterval(getIntervalDuration(endDate, currentDate));
  }, interval);

  const timeLeftMS = endDate.diff(currentDate);

  if (!loggedIn) {
    return (
      <Grid container spacing={2}>
        <Grid item>{endDateOutput}</Grid>
      </Grid>
    );
  } else if (timeLeftMS > 0) {
    const timeLeftDuration = moment.duration(timeLeftMS);
    const timeLeft = timeLeftFormat(timeLeftDuration);
    return (
      <Grid container spacing={2}>
        <Grid item>{endDateOutput}</Grid>
        <Grid item>{timeLeft}</Grid>
      </Grid>
    );
  } else if (auctionStatus === AuctionStatus.Active) {
    return <h4> Closing...</h4>;
  } else {
    return <h4> bidding closed</h4>;
  }
};

export default TimerContainer;
