import * as React from "react";
import * as Styles from "./styles/ContainerStyles";
import { Container } from "@material-ui/core";
import AboutUsContainer from "../information-pages/AboutUsContainer";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

function AboutUs() {
  const classes = Styles.ContainerStyles();

  return (
    <Container fixed className={classes.container}>
      <AboutUsContainer />
    </Container>
  );
}

export default withAITracking(reactPlugin, AboutUs, "AboutUs");
