import * as React from "react";
import { IValidation } from "../../../interfaces/IValidation";
import { BidderDetails } from "./BidderDetails";
import { useState } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { BusinessDetails } from "./BusinessDetails";
import { IBusiness, BusinessDefault } from "../../../interfaces/bidders/IBusiness";
import { ICreateBidder } from "../../../interfaces/bidders/ICreateBidder";

interface IProps {
  bidder: ICreateBidder;
  setBidder: React.Dispatch<React.SetStateAction<ICreateBidder>>;
  validation: IValidation;
  isValid: (isValid: boolean) => void;
}

export const BidderRegistrationForm: React.FC<IProps> = ({ isValid, setBidder, bidder, validation }) => {
  const [isBusiness, setIsBusiness] = React.useState(false);
  const [business, setBusiness] = useState<IBusiness>(BusinessDefault);
  const [isAddressValid, setIsAddressValid] = React.useState(false);
  const [isBusinessValid, setIsBusinessValid] = React.useState(true);
  const [isPhoneValid, setIsPhoneValid] = React.useState(bidder.mobileNumber !== null);

  React.useEffect(() => {
    isValid(isAddressValid && isBusinessValid && isPhoneValid);
  }, [isValid, isAddressValid, isBusinessValid, isPhoneValid]);

  const isBusinessChanges = () => {
    if (isBusiness) {
      setIsBusiness(false);
      bidder.business = null;
      setIsBusinessValid(true);
    } else {
      setIsBusiness(true);
      setIsBusinessValid(false);
    }
  };

  const handleBusinessChanges = (business: IBusiness) => {
    setBidder({
      ...bidder,
      business,
    });
  };

  const businessCheckbox = () => {
    return (
      <FormControlLabel
        label="Are registering as a business?"
        labelPlacement="end"
        control={
          <Checkbox
            name="isBusiness"
            onChange={() => {
              isBusinessChanges();
            }}
          />
        }
      />
    );
  };

  return (
    <div>
      <BidderDetails
        validation={validation}
        isBidderAddressValid={setIsAddressValid}
        isPhoneValid={setIsPhoneValid}
        bidder={bidder}
        onBidderChange={setBidder}
      />
      <h3>Business Details</h3>
      <p>If you are registering as a business we need a little bit more information from you:</p>
      {businessCheckbox()}
      {isBusiness && (
        <BusinessDetails
          isValid={setIsBusinessValid}
          validation={validation}
          business={business}
          onBusinessChange={setBusiness}
          handleBusinessChanges={handleBusinessChanges}
        />
      )}
    </div>
  );
};
