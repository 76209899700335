import { makeStyles, Theme } from "@material-ui/core/styles";
import * as Image from "../../../assets/hero_image.jpg";
import { MEMBER_SITE_IMAGES } from "../../../environmentVariables";

export const InformationNavigationStyles = member =>
  makeStyles((theme: Theme) => ({
    root: {
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      background: `linear-gradient(182deg,rgba(0,0,0,0.40) 85%,rgba(255,255,255,1) 85.5%),url('${MEMBER_SITE_IMAGES}${member.id}/banner.jpg'),url('${Image}')`,
      [theme.breakpoints.down("sm")]: {
        background: "none",
      },
    },
    navigationRoot: {
      marginBottom: "32px",
      backgroundColor: theme.palette.common.white,
    },
    navigationContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "4px",
        paddingRight: "4px"
      },
    },
    contentContainer: {
      paddingTop: "0px",
      [theme.breakpoints.up("sm")]: {
        marginBottom: "75px",
      },
    },
    heroTextContainer: {
      paddingTop: "175px",
      paddingBottom: "175px",
      [theme.breakpoints.up("xs")]: {
        marginLeft: 8,
      },
    },
    heroText: {
      fontWeight: "bold",
      color: "#fff",
      textAlign: "center",
    },
  }));
