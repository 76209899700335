import React, { useRef } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { IValidation } from "../../../interfaces/IValidation";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { ICreateBidder, IValidatedNumber } from "../../../interfaces/bidders/ICreateBidder";
import { IAddress, IsValid as isAddressValid } from "../../../interfaces/IAddress";
import { AddressEntryForm } from "../../core/AddressEntryForm";
import { CountryDefault } from "../../../interfaces/ICountry";
import { FormatPhoneNumber, IsValidInternationalNumber } from "../helpers/PhoneNumberHelpers";
import { VerifiedPhoneNumber } from "../helpers/VerifiedPhoneNumber";
interface IProps {
  validation: IValidation;
  bidder: ICreateBidder;
  onBidderChange: React.Dispatch<React.SetStateAction<ICreateBidder>>;
  isBidderAddressValid: (isValid: boolean) => void;
  isPhoneValid: (isValid: boolean) => void;
}

export const BidderDetails: React.FC<IProps> = ({ bidder, onBidderChange, validation, isBidderAddressValid, isPhoneValid }) => {
  const [, setAddressValid] = React.useState(false);
  const [agreeToTandCs, setAgreeToTandCs] = React.useState(false);
  const [hasValidLandlineNumber, setHasValidLandlineNumber] = React.useState(false);

  const onBidderFieldChange = (e: any) => {
    const fieldValue = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const fieldName = e.target.name;

    onBidderChange(b => ({
      ...b,
      [fieldName]: fieldValue,
    }));
  };

  const onPhoneNumberFieldChange = (e: any) => {
    const fieldValue = FormatPhoneNumber(e.target.value);
    const fieldName = e.target.name;

    onBidderChange(b => ({
      ...b,
      [fieldName]: fieldValue,
    }));
  };

  const onAddressChange = (newAddress: IAddress) => {
    var landlineNumber = bidder.landlineNumber;
    if (newAddress.country.countryCode !== CountryDefault.countryCode && bidder.landlineNumber === "+44") {
      landlineNumber = "";
    }

    onBidderChange(b => ({
      ...b,
      landlineNumber: landlineNumber,
      address: newAddress,
    }));
  };

  const nextInputAfterAddress = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    isBidderAddressValid(agreeToTandCs && isAddressValid(bidder.address) && !!bidder.mobileNumber);
  }, [isBidderAddressValid, bidder, agreeToTandCs]);

  React.useEffect(() => {
    setHasValidLandlineNumber(bidder.landlineNumber === "" || IsValidInternationalNumber(bidder.landlineNumber));
  }, [bidder, agreeToTandCs]);

  const validatedNumberCallback = (number: IValidatedNumber) => {
    onBidderChange(b => ({
      ...b,
      mobileNumber: number,
    }));
    isPhoneValid(true);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>
            We (SalvageMarket.co.uk) use the data we collect to create, validate and administer your account, to help us to provide an effective and
            efficient salvage auction platform and to keep you up to date with any changes. We also use data for training, quality control, research
            and statistical analysis. If you would like more information on how we use your data and on what your rights are, please have a read of
            our full privacy policy.
          </Typography>
          <h3>Address</h3>

          <AddressEntryForm initialAddress={bidder.address} isValid={setAddressValid} onAddressChange={onAddressChange} validation={validation} />
        </Grid>

        <Grid item xs={12}>
          <h3>Phone Number</h3>

          <h4> Mobile Number</h4>
          {bidder.mobileNumber === null && <p>We require a mobile number to register you for the site.</p>}
          {bidder.mobileNumber !== null && <p>{bidder.mobileNumber.number}</p>}

          <VerifiedPhoneNumber
            validatedNumberCallback={validatedNumberCallback}
            addButtonTitle={bidder.mobileNumber === null ? "Add Mobile Number" : "Change Number"}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            inputRef={nextInputAfterAddress}
            type="tel"
            fullWidth
            error={!hasValidLandlineNumber}
            helperText={!hasValidLandlineNumber ? "Please enter phone number in the format +44XXXXXXXXXX" : ""}
            name="landlineNumber"
            label="Landline number"
            placeholder="+44"
            value={bidder.landlineNumber}
            onChange={value => onPhoneNumberFieldChange(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <h3>Marketing Preferences - Opt In</h3>

          <p>SalvageMarket is committed to protecting the privacy of personal data that it maintains about individuals.</p>
          <p>
            By giving your consent from time to time SalvageMarket, the Seller you register with or the Sellers you buy from may send you emails or
            promotional materials to improve your buying experience. These communications are designed to keep you informed of changes to the services
            we provide or enhance your online experience.
          </p>
          <p>
            You can update your marketing preferences at any time by visiting the website or following the specific 'unsubscribe' instructions within
            any communications you receive.
          </p>
          <p>By ticking this box I give consent to receive marketing materials.</p>

          <FormControlLabel
            control={
              <Checkbox value={bidder.mailingListOptIn} onChange={value => onBidderFieldChange(value)} name="mailingListOptIn" color="primary" />
            }
            label="Opt In to receive Marketing Communications"
          />
        </Grid>
        <Grid item xs={12}>
          <h3>T&amp;Cs</h3>
          <p>
            By ticking this box I agree that I am at least 18 years of age and that I have read and agreed to the{" "}
            <a href="TermsConditions" target="_blank">
              terms and conditions
            </a>
          </p>
          <FormControlLabel
            control={<Checkbox value={agreeToTandCs} onChange={event => setAgreeToTandCs(event.target.checked)} name="tandc" color="primary" />}
            label="Agree to T&amp;Cs"
          />
        </Grid>
      </Grid>
    </>
  );
};
