import * as React from "react";
import { Container, Typography } from "@material-ui/core";
import { LoadingComponent } from "../loading/LoadingComponent";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { IMsalContext } from "../../authentication/MsalContext";
import { GetClientSettings } from "../../services/SettingsService";
import { useMsal } from "../../authentication/MsalProvider";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

function ConfirmAccount() {
  const clientSettings: ISalvageClientSettings = GetClientSettings();
  const msal: IMsalContext = useMsal();

  React.useEffect(() => {
    msal.instance.loginRedirect({
      scopes: [clientSettings.B2CSettings.TokenScope],
      authority: clientSettings.B2CSettings.TenantForgotPassword,
      redirectStartPage: window.origin,
    });
  }, [msal.instance, clientSettings.B2CSettings.TenantForgotPassword, clientSettings.B2CSettings.TokenScope]);

  return (
    <Container maxWidth={"lg"} fixed style={{ minHeight: "350px" }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Updating Account
      </Typography>
      <LoadingComponent label="Please wait" />
    </Container>
  );
}

export default withAITracking(reactPlugin, ConfirmAccount, "ConfirmAccount");
