import { makeStyles } from "@material-ui/core/styles";

export const DesktopMenuStyles = makeStyles(theme => ({
  button: {
    fontSize: "30px",
    color: theme.palette.common.black
  },
  draw: {
    minWidth: "400px"
  },
  divider: {
    height: "2px"
  }
}));
