import * as React from "react";

import { Container } from "@material-ui/core";
import { AuctionTimesComponent } from "../auction-times/AuctionTimesComponent";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

const AuctionTimes: React.FC = () => {
  return (
    <Container maxWidth={"lg"} fixed>
      <AuctionTimesComponent />
    </Container>
  );
};

export default withAITracking(reactPlugin, AuctionTimes, "AuctionTimes");
