import { Text, View, Image } from "@react-pdf/renderer";
import { SalesDocument } from "../../../services/SalesDocumentService";
import { salesDocumentDateFormat } from "../../../helpers/text-format/TextFormat";
import { styles } from "../styles/PdfStyles";
import * as arrow from "../../../assets/arrow.png";
import React from "react";

interface BidsTotals {
  salesDocumentInfo: SalesDocument;
}

export const BidsTotals: React.FC<BidsTotals> = ({ salesDocumentInfo }) => (
  <View>
    <View style={styles.title}>
      <Text style={styles.title}>Bids & Totals</Text>
    </View>
    <View style={styles.rowPaddingBottom4}>
      <View style={styles.bidderFinancesBlock}>
        <View style={styles.rowWidth100}>
          <View style={styles.financeInfoCell}></View>
          <View style={styles.financeTitleNoBorderCell}></View>
          <View style={styles.financeNoBorderCell}>
            <Text style={styles.littleGreyTextCentred}>Amount</Text>
          </View>
          <View style={styles.financeNoBorderCell}>
            <Text style={styles.littleGreyTextCentred}>Vat</Text>
          </View>
          <View style={styles.financeNoBorderCell}>
            <Text style={styles.littleGreyTextCentred}>Vat Rate</Text>
          </View>
        </View>

        <View style={styles.rowWidth100}>
          <View style={styles.financeInfoCell}>
            <Text style={styles.littleGreyText}>Date/time placed</Text>
            <Text style={styles.greyText}>{salesDocumentDateFormat(salesDocumentInfo.bidPlacedTime)}</Text>
          </View>
          <View style={styles.financeTitleCell}>
            <Text style={styles.feesText}>Winning bid</Text>
          </View>
          <View style={styles.financeCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.bidAmount}</Text>
          </View>
          <View style={styles.financeCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.bidVat}</Text>
          </View>
          <View style={styles.financeCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.vatIncluded ? salesDocumentInfo.saleVatRate : "0.0"}%</Text>
          </View>
        </View>
        <View style={styles.rowWidth100}>
          <View style={styles.financeInfoCell}>
            <Text style={styles.littleGreyText}>Total bids from all bidders</Text>
            <Text style={styles.greyText}>{salesDocumentInfo.numberOfBids}</Text>
          </View>
          <View style={styles.financeTitleCell}>
            <Text style={styles.feesText}>Auction fees</Text>
          </View>
          <View style={styles.financeCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.auctionFee}</Text>
          </View>
          <View style={styles.financeCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.auctionFeeVat}</Text>
          </View>
          <View style={styles.financeCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.saleVatRate}%</Text>
          </View>
        </View>
        <View style={styles.rowWidth100}>
          <View style={styles.financeInfoCell}></View>
          <View style={styles.financeTitleCell}>
            <Text style={styles.feesText}>Admin fees</Text>
          </View>
          <View style={styles.financeCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.adminFee}</Text>
          </View>
          <View style={styles.financeCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.adminFeeVat}</Text>
          </View>
          <View style={styles.financeCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.saleVatRate}%</Text>
          </View>
          <View style={styles.totalDueCell}>
            <View style={styles.alignBottom}>
              <Text style={styles.totalDueText}>Total due</Text>
            </View>
          </View>
        </View>
        <View style={styles.rowWidth100}>
          <View style={styles.financeInfoCell}></View>
          <View style={styles.financeTitleCell}>
            <Text style={styles.feesText}>Totals</Text>
          </View>
          <View style={styles.financeThickLeftCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.total}</Text>
          </View>
          <View style={styles.financeThickRightCell}>
            <Text style={styles.financeText}>{salesDocumentInfo.totalVat}</Text>
          </View>
          <View style={styles.arrowCell}>
            <Image style={styles.with100} source={arrow.default} />
          </View>
          <View style={styles.totalsCell}>
            <Text style={styles.totalText}>{salesDocumentInfo.totalWithVat}</Text>
          </View>
        </View>
      </View>
      <View style={styles.bidderTotalBlock}>
        <View style={styles.alignCenter}>
          <Text style={styles.littleGreyTextCentred}>
            This is not a VAT invoice. A full VAT invoice will be available from the seller once payment has been received in full.
          </Text>
        </View>
      </View>
    </View>
  </View>
);
