import React from "react";
import { Page, Document, PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { SalesDocument } from "../../../services/SalesDocumentService";
import { styles } from "../styles/PdfStyles";
import { BuyerDeclaration } from "./PdfBuyerDeclaration";
import { PaymentDetails } from "./PdfPaymentDetails";
import { BidsTotals } from "./PdfBidsTotals";
import { VehicleDetails } from "./PdfVehicleDetails";
import { AddressDetails } from "./PdfAddressDetails";
import { LogoBanner } from "./PdfLogoBanner";
import { Hidden, Button, Paper } from "@material-ui/core";
import * as Styles from "../styles/PdfPageStyles";

interface PdfProps {
  salesDocumentInfo: SalesDocument;
  loading: boolean;
}

export const Pdf: React.FC<PdfProps> = ({ salesDocumentInfo, loading }) => {
  const classes = Styles.PdfPageStyles();

  const MyDocument = () => (
    <Document title={salesDocumentInfo.title}>
      <Page size="A4" style={styles.page}>
        <LogoBanner salesDocumentInfo={salesDocumentInfo} />
        <AddressDetails salesDocumentInfo={salesDocumentInfo} />
        <VehicleDetails salesDocumentInfo={salesDocumentInfo} />
        <BidsTotals salesDocumentInfo={salesDocumentInfo} />
        <PaymentDetails salesDocumentInfo={salesDocumentInfo} />
        <BuyerDeclaration />
      </Page>
    </Document>
  );

  return loading ? (
    <> </>
  ) : (
    <>
      <Hidden mdUp>
        <Paper className={classes.container}>
          <h1>Sales Document</h1>
          <p>Please download your sales document below.</p>
          <p> {salesDocumentInfo.title}</p>
          <div style={{ textDecoration: "none" }}>
            <PDFDownloadLink document={<MyDocument />} fileName={salesDocumentInfo.title} className={classes.button}>
              {({ loading }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button variant="contained" color="primary">
                    Download
                  </Button>
                )
              }
            </PDFDownloadLink>
          </div>
        </Paper>
      </Hidden>
      <Hidden smDown>
        <PDFViewer width="100%" height="1000px">
          <MyDocument />
        </PDFViewer>
      </Hidden>
    </>
  );
};
