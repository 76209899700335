import { View, Text } from "@react-pdf/renderer";
import { styles } from "../receipt-pdf/styles/PdfStyles";
import React from "react";

interface ReceiptFooterProps {}

export const ReceiptFooter: React.FC<ReceiptFooterProps> = () => (
  <>
    <View style={styles.footerBox}>
      <View style={styles.registeredRow}>
        <View style={styles.registeredColumn}>
          <Text style={styles.registered}>NSG Limited, registered in England No.: 01907 166</Text>
        </View>
        <View style={styles.vatColumn}>
          <Text style={styles.vat}>GB VAT Registration No.: 493 2788 03</Text>
        </View>
      </View>
    </View>
  </>
);
