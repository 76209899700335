import moment from "moment";

export const DateToDateString = (date: Date) => {
  return moment(date).format("dddd, DD MMMM yyyy");
};

export const DateToTimeString = (date: Date) => {
  return moment(date).format("HH:mm");
};

export const DateTimeFormat = (date: Date) => {
  return moment(date).format("Do MMMM YYYY");
};

export const DateToArray = (date: Date): [number, number, number, number, number] => {
  var dateObject = moment(date).toObject();

  return [dateObject.years, dateObject.months, dateObject.date, dateObject.hours, dateObject.minutes];
};

export const PaymentReceiptSaleDateFormat = (date: Date) => {
  return moment(date).format("Do MMMM YYYY");
};

export const PaymentReceiptExpiryDateFormat = (date: Date) => {
  return moment(date).format("dddd Do of MMMM YYYY");
};

export const DateToDateTimeString = (date: Date) => {
  return moment(date).format("DD/MM/yyyy HH:mm");
};