/***
 *      _____ __  __ _____   ____  _____ _______       _   _ _______    _____  ______          _____    ____  ______ _      ______          __
 *     |_   _|  \/  |  __ \ / __ \|  __ \__   __|/\   | \ | |__   __|  |  __ \|  ____|   /\   |  __ \  |  _ \|  ____| |    / __ \ \        / /
 *       | | | \  / | |__) | |  | | |__) | | |  /  \  |  \| |  | | (_) | |__) | |__     /  \  | |  | | | |_) | |__  | |   | |  | \ \  /\  / / 
 *       | | | |\/| |  ___/| |  | |  _  /  | | / /\ \ | . ` |  | |     |  _  /|  __|   / /\ \ | |  | | |  _ <|  __| | |   | |  | |\ \/  \/ /  
 *      _| |_| |  | | |    | |__| | | \ \  | |/ ____ \| |\  |  | |  _  | | \ \| |____ / ____ \| |__| | | |_) | |____| |___| |__| | \  /\  /   
 *     |_____|_|  |_|_|     \____/|_|  \_\ |_/_/    \_\_| \_|  |_| (_) |_|  \_\______/_/    \_\_____/  |____/|______|______\____/   \/  \/    
 *                                                                                                                                            
 * 
 * ML 2023-21-22               
 * When updating the terms and conditions content please create a new container regardless of how small the content change.
 * It needs to be done like this to maintain the historical data as part of the client requirements.
 * Also don't forget to update the version number in the settings table in the database to match the new page.        
 * It is crucial that these version containers are maintained correctly.                                                                                                                    
 */


import React from "react";
import { Grid, Typography, Hidden, Divider } from "@material-ui/core";
import * as Styles from "../styles/InformationPagesStyles";
import { Link } from "react-router-dom";

const TermConditionsContainer: React.FC = () => {
  const classes = Styles.InformationPagesStyles();

  return (
    <Grid container>
      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText}>
          1. Introduction
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          1.1 This User Agreement is a legal agreement between you (<strong>you or your</strong>) and End-to-End Total Loss Vehicle Management Limited
          (<strong>e2e</strong>), Unity House, Dudley Road, Darlington, England, DL1 4GG, Company number 11920914 (<strong>us</strong> or{" "}
          <strong>we</strong>).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          1.2{" "}
          <strong>
            This User Agreement, the e2e <Link to="/PrivacyPolicy">User Privacy Notice</Link>, and all policies posted on our site set out the terms
            on which e2e offers you access to and use of our sites, services, applications and tools (collectively 'Services'). All policies are
            incorporated into this User Agreement. You agree to comply with all of the above when accessing or using our Services. For information
            regarding the processing of personal data, please see our <Link to="/PrivacyPolicy">User Privacy Notice</Link>.
          </strong>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          1.3 This User Agreement regulates your use of our site and our Services whether you are acting as buyer or seller.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText}>
          2. About e2e
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          2.1 e2e provides a marketplace, salvagemarket.co.uk (our <strong>site</strong>) that allows users who have registered an account with us (
          <strong>Registered Users</strong>) to offer, sell and buy used, recovered stolen, accident-damaged and/or insurance write-off vehicles and
          other used goods / parts (<strong>Items</strong>).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          2.2 We do not have possession of anything listed or sold through our site and we are not involved in the actual transaction between buyers
          and sellers. The contract for the sale is directly between buyer and seller. We are not a party to the transaction and we are not a
          traditional auctioneer save that in some circumstances we may offer for sale Items on behalf of a Registered Users acting as their agent.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          2.3 While we may provide pricing, fees, listing and other guidance in our Services, such guidance is solely informational, and you may
          decide to follow it or not. We do not review Registered Users' listings or content.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          2.4 While we may help facilitate the resolution of disputes through various programmes, we have no control over, and do not guarantee the
          existence, quality, safety or legality of, Items advertised; the truth or accuracy of Registered Users' content, listings or feedback; the
          ability of sellers to sell vehicles; the ability of buyers to pay for Items; or that a buyer or seller will actually complete a transaction
          or return an Item.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText}>
          3. Registration
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          3.1 If you wish to become a Registered User you must (a) complete the online registration process (b) pay a nonrefundable subscription fee (c) upload copies of ID documents as requested. You must notify us immediately of any changes to your details and/or licences. We reserve the right to increase our subscription fee at any time without notice, though we will place such information on our site. Your use of our site or Services thereafter will be deemed to be your express acceptance and/or
          acceptance by conduct of such fee increases.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          3.2 Subject to your application for initial registration and/or renewal being successful and subject to us not suspending
          or revoking such registration, your registration shall be due for renewal on the expiry of your account, which shall have
          been notified to you by us on initial registration (or renewal as the case may be).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          3.3 30 days prior to your renewal date we will contact you to remind you that your registration is due for renewal together with confirmation of any further subscription fee and the date by which the subscription fee must be paid (which will be payable via the site).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          3.4 If you apply to become a Registered User you hereby warrant to us that:
          <ul className={classes.bulletList}>
            <li>You are not listed in any governmental or regulatory sanctions list;</li>
            <li>
              You have not previously been a Registered User or had your registration with us suspended or terminated, other than due to the natural
              expiry of the term of your registration;
            </li>
            <li>You do not owe us any money;</li>
            <li>
              You hold all necessary licences, permits or other applicable standards for the country in which you are resident and/or trading to
              enable you to purchase and deal with any vehicles bought through our auctions; and
            </li>
            <li>
              You do and will comply with all applicable laws, regulations and best practice (both in the United Kingdom and the country to which you
              export any vehicle bought at our auction).
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          3.5 Unless prohibited by law, your guests aged 16 years or older are allowed to enter seller's premises when accompanied by you. Guest
          passes are subject to a fee where applicable and as notified at the seller's premises or on our site. Guests must display their pass at all
          times while on the seller's <strong>premises</strong> and abide by our site rules. Guests are not permitted to bid for Items.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText}>
          4. Using e2e Services
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.1 In connection with using or accessing the Services you will not:
          <ul className={classes.bulletList}>
            <li>post, list or upload content or Items in inappropriate categories or areas on our sites;</li>
            <li>breach or circumvent any laws, third-party rights or our systems or policies;</li>
            <li>sell any counterfeit Items or otherwise infringe the copyright, trademark or other rights of third parties;</li>
            <li>
              use our Services if you are not able to form legally binding contracts (for example if you are temporarily or indefinitely suspended
              from using our Services);
            </li>
            <li>
              fail to pay for Items purchased by you, unless you have a valid reason as set out in an e2e policy, the seller has materially changed
              the Item's description after you bid, a clear typographical error is made, or you cannot contact the seller;
            </li>
            <li>
              fail to deliver or make available collection Items sold by you, unless you have a valid reason as set out in this User Agreement, for
              example the buyer fails to comply with the terms posted in your listing, or you cannot contact the buyer;
            </li>
            <li>manipulate the price of any Item or interfere with any other Registered User's listings;</li>
            <li>post false, inaccurate, misleading, defamatory, or libellous content;</li>
            <li>use the Services for the purpose of harming or attempting to harm minors in any way;</li>
            <li>transfer your e2e account and Registered User ID to another party without our consent;</li>
            <li>distribute or post spam, unsolicited or bulk electronic communications, chain letters, or pyramid schemes;</li>
            <li>
              use the contact information of other Registered Users for any purpose other than in relation to a specific transaction on the e2e site
              (which includes using this information to send marketing materials directly to Registered Users unless the Registered User has given
              explicit consent to receiving these materials);
            </li>
            <li>distribute viruses or any other technologies that may harm us, or the interests or property of Registered Users;</li>
            <li>use any robot, spider, scraper or other automated means to access our Services for any purpose;</li>
            <li>interfere with the working of our Services, or impose an unreasonable or disproportionately large load on our infrastructure;</li>
            <li>export or re-export any e2e application or tools;</li>
            <li>
              infringe the copyright, trademark, patent, moral, database or other intellectual property rights (collectively,{" "}
              <strong>"Intellectual Property Rights"</strong>) that belong to or are licensed to e2e. Some, but not all, actions that may be
              infringement are reproducing, performing, displaying, distributing, copying, reverse engineering, decompiling, disassembling, or
              preparing derivative works from content that belongs to e2e or someone else;
            </li>
            <li>
              infringe any Intellectual Property Rights that belong to third parties affected by your use of the Services or post content that does
              not belongs to you;
            </li>
            <li>commercialise any e2e application or any information or software associated with such application;</li>
            <li>harvest or otherwise collect information about Registered Users, such as email addresses, without their consent; or</li>
            <li>circumvent any technical measures we use to provide the Services.</li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.2 As a Registered User, you acknowledge that you are registering with e2e as a business entity or on behalf of a business entity. You
          represent that you have the authority to legally bind that entity and you expressly acknowledge and agree that you are not a consumer of our
          Services, but a trade buyer or seller (as the case may be).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.3 If you are selling on this site, you must comply with all applicable UK laws relating to online trading.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.4 If you are buying on this site (either via our auction facility or our Buy It Now or Make an Offer as described in{" "}
          <strong>Section 14</strong>), you acknowledge that you are a trader in such vehicles and/or other goods and, whether or not this is your
          first occasion that you are using our Services, you are purchasing all Items, as defined in <strong>Section 2</strong>, with a view to your
          trade and with a view to a profit. As such you accept that it is fair and reasonable in all the circumstances for us to exclude, restrict or
          limit (as the case may be) our obligations and liability to you in respect of the Items we offer for sale on behalf of sellers.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.5 We will commence supplying Services to you as soon as you accept this User Agreement and register with us. This User Agreement will
          terminate automatically if you close your e2e SalvageMarket account. However on termination you will no longer be able to buy or sell on the
          site. You will also lose your purchase and sales history. The same will apply if fail to renew your registration with us.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.6 Before closing your account, consider contacting us first. We do not want to see you go, and we will do everything we can, to sort out
          any issues you have.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.7 If you still want to close your account, make sure:
          <ul className={classes.bulletList}>
            <li>All your fees and any other selling costs are paid;</li>
            <li>You do not have open bids on an Item;</li>
            <li>You do not have any outstanding pay-outs to be processed to your bank account.</li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.8 If you have any suspensions or restrictions active on your account, or if your account does not currently meet our minimum seller
          performance standards (available on our site in the seller's admin area), you will need to contact us in order to close your account.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.9 When you submit a request to close your account, we will send an email to your registered email address letting you know we have
          received it. Generally, it takes 30 days to close an account from the date we receive a request. However, if you have bought or sold Items
          in the 30 days prior to submitting your request, we will keep your account open for 60 days or beyond from receiving your request so any
          outstanding sales or purchases can be completed, and so we can meet our commitments to all buyers and sellers.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.10 After the 30-day or 60-day period (depending on whether you have bought or sold in the past 30 days) your account will be permanently
          closed and your data will be removed. If you change your mind and decide to keep your account, you have 7 days from the account closure to
          request that your account be reopened. We can reopen your account only if it was in good standing when you closed it.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.11 If:
          <ul className={classes.bulletList}>
            <li>
              we believe you are in breach of any of the provisions of this User Agreement you may be subject to a range of actions, including limits
              on your buying and selling privileges, restrictions on listings and account features, suspension of your account, application of fees
              for the introduction of seller to buyer, and recovery of expenses for policy monitoring and enforcement; and
            </li>
            <li>
              you are a seller and you offer or reference your contact information or ask for the buyer's contact information in the context of buying
              or selling outside of e2e, you will be liable for all final value fees in consideration for the introduction to a buyer for that Item on
              the e2e site, even if the Item does not sell.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.12 We may cancel accounts that have not been renewed or accounts that have been inactive for a long time or modify or discontinue our
          Services.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          4.13 Additionally, we reserve the right to vary or terminate all or part of our Services and/or not provide all or part of our Services to
          anyone for any reason and/or period of time, at our discretion.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText} id={"abusing-e2e"}>
          5. Abusing e2e
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          5.1 Without limiting other remedies, we may limit, suspend, or terminate your Registered User account(s) and access to our Services,
          restrict or prohibit access to, and your activities on, our Services, cancel bids, remove or demote or otherwise restrict the visibility of
          listings, delay or remove hosted content, remove any special status associated with the account, reduce or eliminate any discounts, and take
          technical and legal steps to keep you from using our Services if:
          <ul className={classes.bulletList}>
            <li>
              we think that you are creating problems or possible legal liabilities for us, our Registered Users, suppliers or other third parties;
            </li>
            <li>
              we think that such restrictions will improve the security of the e2e community or reduce our or another Registered User's exposure to
              financial liabilities;
            </li>
            <li>we think that you are infringing the rights of third parties;</li>
            <li>
              we think that you are acting inconsistently with the letter or spirit of this User Agreement or our policies or abuse our employees or
              Registered Users;
            </li>
            <li>despite our reasonable endeavours, we are unable to verify or authenticate any information you provide to us; or</li>
            <li>
              you fail to make, or it is evident that you are unable to make, full payment of any fees due for our Services or otherwise owed pursuant
              to this User Agreement by your payment due date.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          5.2 Where a dispute arises between a buyer or seller, we may, when dealing with the dispute pursuant to <strong>Section 18</strong>,
          consider the relevant Registered User's performance history and the specific circumstances in applying our policies.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText} id={"fees"}>
          6. Fees
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          6.1 The premium, member, convenience, storage, Item retrieval, late payment, relist, delivery, guest or other fees payable by buyers ("
          <strong>Buyer Fees</strong>") are subject to change without notice. Current fees are displayed at our premises and/or on our site. You are
          solely responsible for ascertaining the Buyer Fees (if any) applicable to each purchase or sale.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          6.2 Fees for sellers using our Services and selling Items are listed within the seller administration area ("<strong>Seller Fees</strong>").
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          6.3 If you are a seller, you are liable for transaction fees arising out of all sales made using some or all e2e Services, even if sales
          terms are finalised or payment is made outside of our site.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          6.4 You are required to have a valid payment method on file when selling on our site. If your payment method fails or your account is
          overdue, we may collect Seller Fees or Buyer Fees as applicable owed by charging other payment methods on file and retaining collection
          agencies or legal counsel.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          6.5 You authorise us to automatically charge your chosen payment method in accordance with this User Agreement and the applicable billing
          agreement(s) you agree to when setting up or changing your payment method, for future charges and fees incurred in relation to the Services.
          If payments or amounts owed to us cannot be completed through the payment method on file for any reason, you are still required to pay us
          for all unpaid amounts and we reserve the right to seek reimbursement through other means plus any additional costs incurred by us in
          seeking reimbursement. You can change your payment method by contacting the finance team at{" "}
          <a href="mailto:assist@salvagemarket.co.uk">assist@salvagemarket.co.uk</a> during business working hours.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          6.6 Seller Fees do not purchase any exclusive right to an Item's exposure on our site whether on a web page, mobile app, or otherwise. We
          may display third party advertisements (including links and references thereto) or other content in any part of our Services, at our sole
          discretion.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText} id={"listing-conditions"}>
          7. Listing Conditions
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          7.1 When listing an Item, you agree to comply with the "rules for listing and selling" within the seller administration area and that:
          <ul className={classes.bulletList}>
            <li>
              You are responsible for the accuracy, content and legality of the Item listed and agree to list in accordance with the rules for listing
              and selling within the seller administration area;
            </li>
            <li>
              Your listing may not be immediately searchable by keyword or category for up to 24 hours. We do not guarantee exact listing durations;
            </li>
            <li>
              Unless otherwise specified by law, you are responsible for all taxes (including but not limited to the tax amount itself, as well as any
              penalties, fines, charges, or late payment interest) related to your sales on the e2e site;
            </li>
            <li>
              To the extent possible under English law, you shall pay to us as a debt on demand all costs incurred by us, including but not limited to
              tax, penalties and interest, levied by any competent tax authority due to your failure to provide a valid VAT registration number and/or
              your failure to pay any such taxes, penalties or interest;
            </li>
            <li>Content that violates any of e2e's policies may be modified, obfuscated or deleted at our discretion</li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          7.2 We strive to create a vehicle auction platform where buyers find what they are looking for. Therefore, the appearance or placement of
          listings in featured Items, search and browse results will depend on a variety of factors, including, but not limited to:
          <ul className={classes.bulletList}>
            <li>buyers' location, search query, browsing site;</li>
            <li>Item's location, listing format, price, end time, and relevance to the Registered User query; and</li>
            <li>number of listings matching the buyer's query</li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          7.3 You acknowledge that to drive a positive Registered User experience, a listing may not appear in some search and browse results
          regardless of the sort order chosen by the buyer.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          7.4 Where we list an Item for sale, our status and role is that of agent for a seller of the relevant Item and as such:
          <ul className={classes.bulletList}>
            <li>
              any Contract for Sale (as defined in <strong>Section 8</strong> below) shall be deemed made between the buyer and the seller and not us;
              and
            </li>
            <li>we shall have no liability to the buyer in relation to that Item.</li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          7.5 You acknowledge that the regulations{" "}
          <strong>Consumer Protection from Unfair Trading Regulations 2008, Misleading Marketing Regulations 2008, Fraud Act 2006</strong> ban a
          number of commercial practices, which would include shill bidding. Sellers should not bid on their own lots with a view to artificially
          increasing the price of the item, or of making it appear more desirable.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText} id={"purchase-conditions"}>
          8. Purchase Conditions
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          8.1 When buying an Item, you agree that:
          <ul className={classes.bulletList}>
            <li>
              Apart from those Items offered under <strong>Section 14</strong> ("<strong>Buy It Now</strong>" and "<strong>Make An Offer</strong>")
              you can bid for Items by logging on to our site to submit bids electronically (a "<strong>Virtual Bid</strong>") in real time over the
              internet to compete with other Virtual Bids;
            </li>
            <li>You are responsible for reading the full Item listing before making a bid;</li>
            <li>You may preview the Item the seller is advertising for sale by making an appointment directly with the seller;</li>
            <li>
              Subject to those Items offered through our "Buy It Now" and "Make An Offer" programmes, Items may be in one of three categories:
              <ul className={classes.bulletList}>
                <li>
                  condition subsequent sales ("<strong>On Approval Sales</strong>");
                </li>
                <li>
                  subject to a reserve price ("<strong>Minimum Sales</strong>"); and
                </li>
                <li>
                  sales not in the above two categories ("<strong>Pure Sales</strong>").
                </li>
              </ul>
            </li>
            <li>
              Each instruction, transmission, display and receipt in relation to Virtual Bidding shall be deemed to have been carried out in the
              United Kingdom;
            </li>
            <li>
              Once you have submitted a bid, it cannot be retracted, deleted or cancelled and you acknowledge that you will be legally committed to
              purchase that Item if your bid is accepted by the seller;
            </li>
            <li>
              The seller may bid via "<strong>Buy Back</strong>" in respect of an Item they are selling save that they may place one bid only during
              the auction for that Item.
            </li>
            <li>
              You will be notified via the site whether your bid has been accepted or rejected by the seller. If your bid is accepted by the seller a
              contract of sale ("<strong>Contract of Sale</strong>") will be deemed immediately concluded in respect of the Item that was the subject
              matter of your bid in relation to Pure Sales. A Contract of Sale will only be deemed concluded in relation to Minimum Sales if the
              reserve price has been met or exceeded. A Contract of Sale will be deemed concluded in relation to On Approval Sales if and when the
              seller accepts your bid. In each case, the Contract of Sale will be between you as the winning bidder and the seller. The terms set out
              in this User Agreement, however, govern the Contract of Sale.
            </li>
            <li>
              Unless expressly stated to the contrary by us in writing, upon the conclusion of a Contract of Sale you become liable to pay the
              purchase price for the Item you have purchased and as stated in your bid, together with any applicable VAT (collectively the "
              <strong>Price</strong>") and any other fees due in respect of that Item;
            </li>
            <li>
              The seller must receive the Price due under a Contract of Sale in cleared funds within seventy-two (72) hours of conclusion of that
              Contract of Sale ("<strong>Date of Sale</strong>"). All payments to the seller must be made by bank transfer to such account as notified
              to you by the seller or otherwise as advised by the seller to you in writing;
            </li>
            <li>
              Where you have not made payment in accordance with these purchase conditions, you acknowledge that we may, in our absolute discretion,
              cancel the Contract of Sale and/or offer the relevant Item for re-sale without notice to you. In such case, you agree to pay the
              relevant relist fee in force at the time. We may suspend or revoke your registration in our absolute discretion;
            </li>
            <li>Unless otherwise stated, all Prices, Seller Fees and Buyer Fees and other sums are quoted and payable in pounds sterling;</li>
            <li>
              Such right and title as the seller has to each Item you purchase will transfer to you absolutely when they receive the Price and all
              applicable fees in cleared funds, subject always to our right to cancel any Contract of Sale and negate such title transfer in
              accordance with this User Agreement;
            </li>
            <li>
              A Contract for Sale and all terms in this User Agreement deemed to apply between a buyer and a seller are governed by the laws of
              England and Wales and shall be exclusively subject to the dispute resolution provisions set out in <strong>Section 18</strong> below as
              an alternative to litigation.
            </li>
            <li>
              It is your responsibility to arrange collection of each Item you have purchased on or after the Date of Sale from the seller's premises.
              A seller will release an Item to you only when the seller has received full payment in cleared funds of all sums due in respect of that
              Item. When you collect Items from a seller, you agree to comply at all times with that Sellers' safe working procedures and site rules
              in force from time to time and to hold the seller harmless for any loss or injury sustained as a result of your failure to do so;
            </li>
            <li>
              In the event a seller offers a delivery service that service, and any fee will be subject to separate terms and conditions between you
              and the seller directly;
            </li>
            <li>
              You agree that if a vehicle you purchase is (a) by reason of its construction, state of brakes, steering, tyres, lights and equivalent
              reflectors or other damage in an unroadworthy condition, or (b) otherwise cannot be used lawfully on the road, or (c) does not have a
              valid Department of Transport Test Certificate, insurance certificate or any other certificate required by law, then you shall not use
              that vehicle on the public highway and you shall not remove it (or cause it to be removed) from our premises under its own power;
            </li>
            <li>
              The seller's liability in respect of each Item you purchase ends upon the transfer to you of such right and title, as the seller has,
              and you are on risk for all risks from the moment of the concluded Contract of Sale for the purchase of each Item. You must register
              with the DVLA each vehicle you purchase from the seller or complete a statutory off-road notification. All fixed penalty notices and/or
              other legal demands, fees and charges relating to each vehicle you purchase will be your sole responsibility from the Date of Sale of
              that vehicle. At all times you must comply with the ABI Code. If you fail to carry out any of these requirements we may suspend or
              revoke your registration in our absolute discretion and we reserve the right to recoup any sums paid by the seller in respect of any
              such fixed penalty notices and/or other legal demands, fees and charges arising from your failure to register any vehicle with the DVLA
              or complete a statutory off-road notification;
            </li>
            <li>
              Any Item remaining uncollected (and/or that the seller has been unable to release to you) on the seller's premises shall be at your
              absolute risk from the Date of Sale and the seller accepts no responsibility for any loss or damage howsoever caused to such Item from
              the Date of Sale;
            </li>
            <li>
              If an Item remains uncollected (and/or that the seller has been unable to release to you) on the seller's premises after the Date of
              Sale, then you shall pay to the seller a storage fee for each day or part day thereafter, subject to any free period of storage
              applicable at the material time (details of which are displayed on our site). Where either a storage fee has been incurred, or you owe
              the seller relist fees or money in respect of any liability to the seller (irrespective of how long the Item has been stored), the
              seller will not release that Item until you pay the seller the storage fee and/or any other relist fees and outstanding sums in full in
              cleared funds. You agree that the seller may keep possession of such an Item pending payment of all outstanding sums, and you
              acknowledge and accept that the seller may have a lien (whether contractual, general, or specific) over that Item for this purpose. You
              agree that the seller may at any time after seven calendar days from the Date of Sale without further notice, sell an Item you have
              purchased, as agent acting on your behalf. In the event of such sale, the seller shall be entitled to deduct and retain from the sale
              proceeds all storage costs, relist fees and any other outstanding sums owed to the seller by you, as well as the seller's sale costs and
              the seller shall remit the balance (if any) to you.
            </li>
            <li>
              Regarding vehicle registration and licensing:
              <ul className={classes.bulletList}>
                <li>
                  All Items are sold to you without title to the vehicle registration number which will remain the property of the seller except where
                  expressly otherwise notified to you by the seller in writing;
                </li>
                <li>
                  On request by the seller, you shall take all necessary steps to make any vehicle registration number plate within an Item available
                  to the seller without any cost to the seller;
                </li>
                <li>
                  The seller does not supply existing vehicle registration documents or MOT certificates with any Item except where expressly stated
                  otherwise in writing;
                </li>
                <li>
                  The seller may, in its sole discretion, notify the DVLA of the change of ownership of an Item to you. The name and address so
                  notified shall be the full name and address provided by you on registration of your account with us.
                </li>
              </ul>
            </li>
            <li>
              Buyer requested cancellations: Acceptance of a buyer's request to cancel a bid or Contract for Sale is purely at the discretion of the
              seller.
            </li>
            <li>No refunds: No refunds or financial compensation will be considered once the Item has been removed from the seller's premises.</li>
          </ul>
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText}>
          9. International buying, selling and translation
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          Many of our Services are accessible internationally. We may offer certain site experiences of particular interest to international sellers
          and buyers, such as estimated local currency conversion and international shipping calculation tools. Sellers and buyers are responsible for
          complying with all laws and regulations applicable to the international sale, purchase, and shipment of Items.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText} id={"content"}>
          10. Content
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          10.1 When providing us with content (including causing content to be posted using our Services), you grant us a non-exclusive, worldwide,
          perpetual (or for the duration of any copyright or other rights in such content), irrevocable, royalty-free, sublicensable (through multiple
          tiers) right to use the content (including, without limitation, creating and using derivative works).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          10.2 We may in particular use your content, including any photographs you upload, for marketing and promotional purposes. You authorise us
          to exercise any and all copyright, trademark, publicity, database or other Intellectual Property Rights you have in or to the content in any
          media known now or developed in the future for these purposes. Further, to the fullest extent permitted under applicable law, you waive your
          moral rights in the content and promise not to assert such rights or any other Intellectual Property Rights you have in the content against
          us, our sublicensees or our assignees.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          10.3 We may offer product data (including images, descriptions, and specifications) that are provided by third parties (including Registered
          Users). Subject always to the exclusion of liability provisions within this User Agreement, you may use that content solely in your e2e
          listings during the time your listings are on our site. That permission is subject to modification or revocation at any time at our sole
          discretion.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          10.4 We try to offer reliable data but cannot promise that the content provided through the Services will always be available, accurate,
          complete and up to date. You agree that we are not responsible for examining or warranting the listings or content provided by third parties
          through the Services, and that you will not hold or attempt to hold us or our product data providers liable for inaccuracies. If you choose
          to use product content and/or product data in connection with your listings, you agree to ensure that the content directly associated with
          your listings is and remains accurate, and that you continue to fully comply with this User Agreement and all e2e policies. The product data
          include copyrighted, trademarked, and other proprietary materials. You agree not to remove any copyright, proprietary or identification
          markings in the catalogue or product data or create any derivative works based on that data (other than by including the data in your
          listings).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          10.5 The name "e2e" and other e2e marks, logos, designs, and phrases that e2e use in connection with our Services are trademarks, service
          marks, or trade dress of e2e in the UK and other countries. They may not be used unless expressly authorised by e2e in writing.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText}>
          11. Data protection and Privacy
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          11.1 We shall each process personal data received under and/or in connection with this User Agreement each as a separate and independent
          controller. In no event will you or us process personal data under and/or in connection with this User Agreement as joint controllers or in
          a controller-to-processor relationship. As such separate and independent controllers, you shall be individually and separately responsible
          for complying with the obligations that apply to you as a controller under applicable data protection laws.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          11.2 You shall comply with your obligations under applicable data protection laws (including but not limited to Regulation (EU) 2016/679 (EU
          General Data Protection Regulation "GDPR") and supplementing EU and local data protection laws. This includes, but is not limited to, the
          obligation to provide appropriate safeguards for the transfer of personal data to a third country or an international organisation.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          11.3 You shall process personal data received from us exclusively for the purpose(s) you received the personal data under and/or in
          connection with this User Agreement. You shall erase the personal data received under this User Agreement immediately after the respective
          purpose(s) has/have been fulfilled. Any further processing of the personal data is not permitted except when legally required (e.g., if a
          retention obligation applies).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          11.4 Failure to abide by these obligations may result in disciplinary action up to and including account suspension.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          11.5 As part of the Services, we have access to various information about Registered Users, including personal data. This includes
          information that Registered Users submit to us as part of the Services (such as contact information or Item descriptions) as well as
          information that is generated by the provision and processing of the Services (such as ratings, sales analysis or communication).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          11.6 Via the seller admin area ,or upon request, you receive information which is necessary for the processing of transactions (in
          particular, the contact information of the Item buyer), as well as data on the performance of your listings, aggregated information on the
          performance of the auction platform, which are partly related to the data of other Registered Users of the Services (such as number of view
          of an Item, bid volumes or return rates).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          11.7 We only share information about Registered Users with third parties if this is necessary for the provision of the Services or if we are
          legally or contractually entitled to do so.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          11.8 Upon termination or expiry of this User Agreement, we will delete the information you provided, or which was generated through your use
          of the Services from your e2e account. Notwithstanding this, we maintain this information after the termination or expiry of the User
          Agreement if we are legally obliged or have a legitimate interest to do so. We will generally maintain aggregated data generated using the
          Services (e.g., statistics about sales in a category) upon expiry of the User Agreement.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          11.9 For information regarding the processing of personal data by us, including sharing such data with third parties and your rights as a
          data subject, please see our <Link to="/PrivacyPolicy">User Privacy Notice</Link>.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText}>
          12. Correcting mistakes in payments to buyers and sellers
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          We reserve the right to fix any processing errors e2e discover.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText}>
          13. Contact with previous owners
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          You agree that you shall not contact the previous owner of an Item, unless we have given you written confirmation that such previous owner
          is the seller of such Item and unless we have authorised you to contact such seller. Any breach of this term will result in your account
          being suspended.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText}>
          14. "Buy It Now" and "Make An Offer" Sales
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          14.1 We may offer some Items for purchase outside our usual auction through your use of our "Buy It Now" and "Make An Offer" facilities
          available from time to time at our discretion. This <strong>Section 14</strong> shall apply (as permitted by law) to all "Buy It Now" and
          "Make An Offer" sales, with the provisions of this section taking precedence over any conflicting provisions elsewhere in this User
          Agreement.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          14.2 When you place a "Buy It Now" or "Make An Offer" order to purchase an Item, that order represents your offer to purchase the relevant
          Item at the advertised price. Your offer is only accepted when the seller has sent e-mail confirmation to you that the seller has accepted
          your offer (the "<strong>Offer Acceptance e-mail</strong>"). Acceptance will be complete at the time the seller sends the Offer Acceptance
          e-mail to you.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          14.3 We will not be held responsible if, for any reason outside our reasonable control, either your offer and/or the Offer Acceptance e-mail
          does not reach its intended destination or is delayed.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          14.4 All "Buy It Now" or "Make An Offer" sales are final - returns are not accepted - and are made on an "as is, where is" basis. It is your
          sole responsibility to make all necessary enquiries and inspections to satisfy yourself as to an Item's status, condition, and history
          before making a "Buy It Now" or "Make An Offer". The provisions of <strong>Section 19</strong> <a href="#disclaimers">Disclaimers</a> apply
          in full to "Buy It Now" or "Make An Offer" purchases.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          14.5 Unless stated otherwise, all "Buy It Now" or "Make An Offer" prices are exclusive of VAT (at the applicable prevailing rate) and our
          Fees. Payment terms for "Buy It Now" or "Make An Offer" purchases are the same as for auction purchases and are set out in{" "}
          <strong>Section 8</strong> <a href="#purchase-conditions">Purchase Conditions</a>.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          14.6 We may at our discretion cancel your "Buy It Now" or "Make An Offer" order, either before or after acceptance. If we cancel your "Buy
          It Now" or "Make An Offer" order after you have made payment in respect of that order, we will refund the sums you have already paid.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText}>
          15. Yard Rules
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          15.1 You must not undertake any repairs or alterations to Items on the seller's premises.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          15.2 If you have told the seller the date on which you are going to collect an Item you have purchased, any such Item not collected and
          removed from the seller's premises one hour prior to the stated closing time of the seller's premises on that date will be returned to the
          secured storage area. The seller will not release it to you until you have paid them the Item retrieval fee and storage fee in cleared
          funds.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          15.3 Any person caught stealing, damaging or vandalising any Item, parts, keys, or the seller's property will be reported to the
          authorities.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          15.4 Where permitted by law, the seller's premises are protected by electric fencing and other security measures. You must not touch or
          otherwise interfere with such fences or other security measures: if you do, you do so at your own risk.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText} id="liability">
          16. Liability
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          16.1 We try to keep e2e and our Services safe, secure, and functioning properly, but we cannot guarantee the continuous operation of or
          access to our Services. Bid update and other notification functionality in our Services may not occur in real time. Such functionality is
          subject to delays beyond our control. Consequently, we exclude all implied conditions, warranties, representations or other terms that may
          apply to our site or any content on it or to our Services.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          16.2 We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or
          otherwise, even if foreseeable, arising under or in connection with:
          <ul className={classes.bulletList}>
            <li>use of, or inability to use, our site;</li>
            <li>use of or reliance on any content displayed on our site;</li>
            <li>your use of or your inability to use our Services;</li>
            <li>pricing, or other guidance provided by us;</li>
            <li>delays or disruptions in our Services;</li>
            <li>viruses or other malicious software obtained by accessing, or linking to, our Services;</li>
            <li>glitches, bugs, errors, or inaccuracies of any kind in our Services;</li>
            <li>damage to your hardware device from the use of any e2e Service;</li>
            <li>
              the content, actions, or inactions of third parties, including Items listed using our Services or the destruction of allegedly fake
              Items;
            </li>
            <li>
              a suspension or other action taken with respect to your account or breach of <strong>Section 5</strong>{" "}
              <a href="#abusing-e2e">Abusing e2e</a>;
            </li>
            <li>
              the duration or manner in which your listings appear in search results as set out in <strong>Section 7</strong>{" "}
              <a href="#listing-conditions">Listing conditions</a>; or
            </li>
            <li>
              your need to modify practices, content, or behaviour or your loss of or inability to do business, as a result of changes to this
              Registered User Agreement or our policies
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          16.3 In particular, we will not be liable for:
          <ul className={classes.bulletList}>
            <li>loss of profits, sales, business, or revenue;</li>
            <li>business interruption;</li>
            <li>loss of anticipated savings;</li>
            <li>loss of data;</li>
            <li>loss of goodwill or reputation;</li>
            <li>loss of business opportunity, goodwill or reputation; or</li>
            <li>any indirect or consequential loss or damage.</li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          16.4 So far as permitted by law you, and your guests irrevocably and unconditionally waive and release your/their rights (if any) to recover
          from us (including our members, affiliates, directors, agents, employees, subsidiaries, partners, affiliates or suppliers, any and all
          damages, losses, liabilities, costs, expenses or claims (whether direct or indirect, known or unknown, foreseen or unforeseen) that may
          arise from or be related to property damage or any other event which occurs on or at any seller's premises or the site.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          16.5 You accept sole responsibility for the legality of your actions under laws applying to you and the legality of any Items you list on
          any of our sites.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          16.6 Although we use techniques that aim to verify the accuracy and truth of the information provided by our Registered Users, Registered
          User verification on the internet is difficult. We cannot and does not confirm, and is not responsible for ensuring, the accuracy or
          truthfulness of Registered Users' purported identities or the validity of the information which they provide to us or post on our sites.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          16.7 Regardless of the previous paragraphs, if we are found to be liable, our liability to you or to any third party is limited to the
          greater of (a) the amount of fees in dispute not to exceed the total fees which you paid to us in the 12 months prior to the action giving
          rise to the liability, or (b) £100.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          16.8 Nothing in this User Agreement shall limit or exclude our liability for fraudulent misrepresentation, for death or personal injury
          resulting from our negligence or the negligence of our agents or employees, or for any other liability that cannot be limited or excluded by
          law.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText} id="compensation">
          17. Compensation
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          17.1 So far as permitted by law you agree to indemnify and keep indemnified, defend and hold us harmless from any and all damages, losses,
          liabilities, costs, expenses (including legal fees and disbursements) suffered or incurred by us in connection with:
          <ul className={classes.bulletList}>
            <li>
              any failure by you to comply with all applicable laws, import and export procedures, inspections, fees, proof of emissions or any other
              requirements that may apply to your purchase and collection and transportation of such purchase from our premises which shall be your
              sole responsibility at all times; and/or
            </li>
            <li>
              claims by you or your guests arising from or related to property damage or any other event that occurs on or at any of our premises or
              our site; and/or
            </li>
            <li>
              in respect of any liability to taxation, duty, charge or levy in the nature of taxation (including all penalties, charges, costs and
              interest relating to any of them), past, present or future, wherever imposed, that we suffer as a direct or indirect consequence of your
              use of our Services; and/or
            </li>
            <li>any breach by you of the terms of this User Agreement.</li>
          </ul>
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText} id="disputes">
          18. Buyer and Seller Disputes
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          18.1 As an alternative to litigation we shall act as sole arbiters in all disputes and matters between buyers and sellers arising out of or
          in connection with any pre-sale, auction or post-sale matters, including (without limitation) bidding for, withdrawing or altering the sale
          order of any Item, refusing bids from you, or the cancellation of any Contract of Sale made at or during any sale and our decision (for
          which no reason may be given) shall be final.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          18.2 If a dispute arises between you and us, we strongly encourage you to first email us on{" "}
          <a href="mailto:assist@salvagemarket.co.uk">assist@salvagemarket.co.uk</a> to seek a resolution. We will consider reasonable requests to
          resolve the dispute through alternative dispute resolution procedures, such as mediation or arbitration, as alternatives to litigation.
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText} id={"disclaimers"}>
          19. Disclaimers
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          19.1 All Items offered for sale are sold on an "as is where is" basis. This means that such sales are made without any express or implied
          warranty (including without limitation any warranty of fitness for a particular purpose or of satisfactory quality).
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          19.2 We expressly disclaim the accuracy or completeness of any and/or all information provided to you regarding Items whether provided in
          written, verbal or digital image form ("<strong>Item information</strong>"). Item information is provided for convenience only.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          19.3 You agree that you will not rely on Item information in deciding whether or how much to bid on any Item. Item information includes
          without limitation: year, make, model, condition, damage amount, damage type, roadworthiness, drivability, accessories, mileage, odometer
          readings, vehicle identification numbers, title, repairs needed, repair cost, repair history, title history, service history and total loss
          history. We expressly disclaim any and all representations (whether written or verbal), warranties and guarantees regarding the Items the
          sellers offer for sale. We do not guarantee that keys are available for any vehicles sold through us, regardless of whether or not keys are
          present in online images or are present in the vehicle during any pre-purchase inspection. Items the sellers offer for sale may be missing
          components or parts. We do not guarantee that vehicles meet or can be modified to meet local emission or safety requirements or are able or
          permitted to be repaired. In the case of electric or hybrid electric vehicles we make no representation nor offer any warranty that the
          batteries are included (or the right to use the batteries) within the sale. You may not acquire ownership or the right to use relevant
          battery packs and you should satisfy yourself of this prior to bidding. It is your sole responsibility to ascertain, confirm, research,
          inspect and/or investigate to your satisfaction the Items we offer for sale and any and all Item information prior to deciding whether and
          how much you wish to bid on any Item. An HPI history check or equivalent is strongly recommended.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          19.4 You agree that all Items are not represented to be in a roadworthy condition, mechanically sound or maintained at any level of quality
          whatsoever or that they may be repaired. The Items may not be fit for purpose as a means of transportation or for any other purpose and may
          require substantial repairs at your expense. The Items may not be of satisfactory quality, taking into account all considerations, including
          without limitation the price you pay for any Item the seller offers for sale. You acknowledge and agree that we have excluded our liability
          in respect of terms that would otherwise have been implied into the contract of sale under the sale of goods act 1979 (as amended) to the
          fullest extent permitted by law, including without limitation the terms relating to Items complying with their description and/or being fit
          for a particular purpose and you acknowledge it is your responsibility to satisfy yourself as to the condition and history of an Item prior
          to removing it from the seller's premises.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          19.5 Given the nature of our business, the nature of the Items our sellers offer for sale, and the fact that you are a trade member, you
          acknowledge and agree that it is fair and reasonable in all the circumstances for us to rely upon the restrictions, limitations and
          exclusions of our liability set out in this clause. You acknowledge and agree that your use of the site, services and your bidding for
          and/or purchase of Items is expressly on the basis that these terms and conditions have been read and understood by you, and that you accept
          in the circumstances they are fair and reasonable.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          19.6 We do not guarantee or warrant in any way that any Item a seller sells, and you purchase can be legally registered in any jurisdiction
          and you accept all risks associated with any variations in title or registration laws (if any) between jurisdictions that may adversely
          impact upon the marketability or roadworthiness of Items you purchase.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          19.7 We are not responsible or liable for any losses you sustain arising out of or in connection with any defects, errors or omissions in
          any paperwork issued by any government department, executive agency or other authority of competent jurisdiction (including without
          limitation the DVLA and VOSA).
        </Typography>
      </Grid>

      <Grid item xs={12} lg={3} className={classes.container}>
        <Typography variant="h5" component="h1" className={classes.greyText} id="general">
          20. General
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={1} className={classes.container}>
          <Divider className={classes.divider} orientation="vertical" />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.container}>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.1 If any provision of this User Agreement is held to be invalid, void or for any reason unenforceable, such provision shall be struck out
          and shall not affect the validity and enforceability of the remaining provisions.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.2 We shall have no liability to you in the event we are unable to fulfil any obligation due to events outside of our reasonable control.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.3 You agree not to assign, transfer or novate your rights or obligations under this User Agreement or in respect of any Item you
          purchase. We may do so where we have a good reason.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.4 Headings are for reference purposes only and do not limit the scope or extent of such section.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.5 Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar
          breaches. We do not guarantee we will take action against all breaches of this User Agreement.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.6 If you have a dispute with one or more Registered Users, you release us (and our affiliates and subsidiaries, and our and their
          respective officers, directors, employees, and agents) from claims, demands and damages (actual and consequential) of every kind and nature,
          known and unknown, arising out of or in any way connected with such disputes.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.7 We may amend this User Agreement at any time by email, or by posting the amended terms on the site. All amended terms shall
          automatically be effective when posted on the site. Your continued use of our Services after the effective date of these amended terms
          constitutes your acceptance of them. This User Agreement may not be otherwise amended except through mutual agreement by you and an e2e
          representative authorised to do so.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.8 The policies posted on our sites may be changed from time to time. Changes take effect when we post them on the site.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.9 No agency, partnership, joint venture, employee-employer, sublicense, or franchiser-franchisee relationship is intended or created by
          this User Agreement.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.10 A person who is not a party to this User Agreement has no right under the Contracts (Rights of Third Parties) Act 1999 to enforce any
          term of this Registered User Agreement, but this does not affect any right or remedy of a third party specified in this Registered User
          Agreement, or which exists or is available apart from that Act.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.11 The User Agreement and all policies posted on our site are the entire agreement between you and we and supersede all prior
          understandings and agreements of the parties.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.12 Any provision of this User Agreement that expressly or by implication is intended to come into or continue in force on or after expiry
          or termination of this Agreement will remain in full force and effect including without limitation: <strong>Section 6</strong>{" "}
          <a href="#fees">Fees</a> (with respect to fees owed for our Services), <strong>Section 10</strong> <a href="#content">Content</a>, Section
          16 <a href="#liability">Liability</a>, <strong>Section 17</strong> <a href="#compensation">Compensation</a>, <strong>Section 18</strong>{" "}
          <a href="#disputes">Buyer and Seller Disputes</a> and the release contained in this <strong>Section 20</strong>{" "}
          <a href="#general">General</a>.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.13 Legal notices shall be served by registered mail to End to End Total Loss Vehicle Management Limited (e2e), Unity House, Dudley Road,
          Darlington, England, DL1 4GG. We shall send notices to you by email to the email address you provide to e2e during the registration process.
          Notice to you shall be deemed given 24 hours after the email is sent, unless the sending party is notified that the email address is
          invalid. Alternatively, we may give you legal notice by registered mail to the address provided during the registration process. Notices
          sent to either party by registered mail shall be deemed to have been received by that party three days after the date of mailing. The
          provisions of this clause will not apply to the service of any proceedings or other document in any legal action.
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.textPadding}>
          20.14 Any claim, dispute or matter arising under or in connection with this Registered User Agreement shall be governed and construed in all
          respects by the laws of England and Wales. You and we both agree to submit to the exclusive jurisdiction of the English courts.
        </Typography>

        <Typography variant="body1" gutterBottom className={classes.textPaddingTop}>
          This User Agreement was last updated on <strong>30/09/2022</strong>.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TermConditionsContainer;
