import React from "react";
import { Cross, Check, Unknown } from "../../../icons/Icons";
import * as Styles from "../../styles/IconsStyles";

const IconSelector: React.FC<{ input: boolean | null }> = ({ input }) => {
  const classes = Styles.IconStyles();
  if (input === true) {
    return (
      <div className={classes.success}>
        <Check />
      </div>
    );
  }
  if (input === false) {
    return (
      <div className={classes.fail}>
        <Cross />
      </div>
    );
  } else {
    return (
      <div className={classes.fail}>
        <Unknown />
      </div>
    );
  }
};

export default IconSelector;
