export interface IFormsOfId {
  title: string;
  description: string;
  isRequired: (answers: IAnsweredQuestions) => boolean;
  serverKey: string;
}

export interface IAnsweredQuestions {
  hasLicense: boolean;
  registeredAsBusiness: boolean;
  ukResident: boolean;
}

const drivingLicense: IFormsOfId = {
  title: "Driving Licence",
  description: "A valid driving licence photocard which has not expired and shows your current address that you’ve entered during your auction account registration",
  isRequired: (answers: IAnsweredQuestions) => answers.hasLicense,
  serverKey: "DrivingLicence",
};

const utilityBill: IFormsOfId = {
  title: "Utility Bill",
  description:
    "Please send one of the following documents (dated within the last three months): utility bill (Gas / Electric / Telephone), current community charge bill, Bank statement or credit card statement or an Inland Revenue statement or bill",
  isRequired: (answers: IAnsweredQuestions) => true,
  serverKey: "UtilityBill",
};

const business: IFormsOfId = {
  title: "Business document",
  description:
    "Please send one of the following documents: bank statement, VAT registration certificate, certificate of incorporation (if a Limited Company) or Company letterhead with Director or Partner details",
  isRequired: (answers: IAnsweredQuestions) => answers.registeredAsBusiness,
  serverKey: "BusinessDocument",
};

const passport: IFormsOfId = {
  title: "Passport",
  description: "A valid Passport",
  isRequired: (answers: IAnsweredQuestions) => !answers.hasLicense && answers.ukResident,
  serverKey: "Passport",
};

const passportOrPhotoId: IFormsOfId = {
  title: "Passport",
  description: "A valid Passport (or national photo id)",
  isRequired: (answers: IAnsweredQuestions) => !answers.hasLicense && !answers.ukResident,
  serverKey: "PassportOrPhotoId",
};
const formsOfIds = [drivingLicense, utilityBill, business, passport, passportOrPhotoId];

export const getRequiredFormsOfId = (answers: IAnsweredQuestions): IFormsOfId[] => {
  return formsOfIds.filter(p => p.isRequired(answers));
};

export const getFormOfId = (serverKey: string): IFormsOfId | undefined => {
  return formsOfIds.find(id => id.serverKey === serverKey);
};
