import { makeStyles } from "@material-ui/core";

export const SavedSearchItemStyles = makeStyles(theme => ({
    savedSearchListItemContainer: {
        border: "2px solid #C7C7C7",
        borderRadius: "8px",
        padding: "15px",
        marginBottom: "15px",
        [theme.breakpoints.down('md')]: {
            flexDirection: "row",
        },
        '& h5': {
            fontSize: '18px', // example: setting font size
            marginBottom: "5px",
            [theme.breakpoints.down('md')]: {
                whiteSpace: "normal",
            },
        },
        '& svg': {
            marginRight: "6px",
        }
        
    },
    savedSearchIcon: {
        display: "flex",
        alignItems: "center",
        marginRight: "1.5rem",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        [theme.breakpoints.down('md')]: {
            width: "30%",
            flexDirection: "row",
        },
    },
    customSpacing: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        '& span': {
            textTransform: "uppercase"
        }
    },

    deleteButton: {
        background: "#eeeeee",
        marginBottom: "7px",
        [theme.breakpoints.down('md')]: {
            marginBottom: "10px", // Add margin-bottom for md and down
        },
    },

}));
