import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import history from "../history";
import { ISalvageClientSettings } from '../interfaces/ISalvageClientSettings';
import { GetClientSettings } from './SettingsService';

const ClientSettings: ISalvageClientSettings = GetClientSettings();

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: ClientSettings.InstrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: history }
        }
    }
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };