import { makeStyles } from "@material-ui/core/styles";

export const VehicleDetailsListStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(1),
  },

  divider: {
    margin: theme.spacing(1, 0),
  },
  descriptionTitle: {
    color: theme.palette.secondary.dark,
    fontWeight: "bold",
  },
  infoIcon: {
    verticalAlign: "text-top",
    marginRight: "4px"
  },
  link: { paddingLeft: "16px", cursor: "pointer" },
}));
