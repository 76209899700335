import React, { useState, useEffect } from "react";
import { Card, CardContent, CardMedia, Typography, Chip, Box, useMediaQuery, Theme, Link, Grid, Divider, Paper, Button, useTheme } from "@material-ui/core";

import { Route, Link as RouterLink } from "react-router-dom";

import * as Styles from "./styles/HomepageBillboardSlideStyles";

import { currencyFormat, timeLeftFormat, ellipsisText } from "../../helpers/text-format/TextFormat";
import moment, { Duration } from "moment";

import { IInitialState } from "../../contexts/AppContext";
import { AddToWatchlistIcon, RemoveFromWatchlistIcon } from "../icons/HomePageIcons";
import { useLotMedia, Media } from "../../services/MediaService";
import { AuctionSearchResult, FeaturedAuction } from "../../services/AuctionService";
import { adjustedCurrentTime } from "../../helpers/TimeSync";
import { useIsLoggedIn } from "../../helpers/ActiveUserHelper";
import TimerContainer from "../bid/sub-components/TimerContainer";
import { useMsal } from "../../authentication/MsalProvider";
import { IMsalContext } from "../../authentication/MsalContext";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";


interface AuctionItemProps {
    item: AuctionSearchResult;
    context: IInitialState;
}

export const HomepageBillboardSlide: React.FC<AuctionItemProps> = ({ item, context }) => {
    const classes = Styles.HomepageBillboardSlideStyles();
    const loggedIn = useIsLoggedIn();

    const [isWatching, setIsWatching] = useState(
        context.bidderState.checkWatchlist({
            auctionId: item.id,
        })
    );
    const theme = useTheme();
    const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));
    const [currentDate, setCurrentDate] = useState(adjustedCurrentTime());
    const [endDate, setEndDate] = useState(moment(item.auctionEnd));
    const [timeLeftMS, setTimeLeftMS] = useState(0);
    const [timeLeftDuration, setTimeLeftDuration] = useState<Duration>();
    const [mediaUrl, setMediaUrl] = useState<string>("");
    const [price, setPrice] = useState(item.currentHighestBid ? item.currentHighestBid : item.startPrice);
    const [media] = useLotMedia(item.lotId, item.lotMediaCacheName);
    const msal: IMsalContext = useMsal();
    const clientSettings: ISalvageClientSettings = GetClientSettings();

    useEffect(() => {
        setCurrentDate(adjustedCurrentTime());
    }, []);

    useEffect(() => {
        setEndDate(moment(item.auctionEnd));
    }, [item.auctionEnd]);

    useEffect(() => {
        setTimeLeftMS(endDate.diff(currentDate));
    }, [endDate, currentDate]);

    useEffect(() => {
        setTimeLeftDuration(moment.duration(timeLeftMS));
    }, [timeLeftMS]);

    useEffect(() => {
        setIsWatching(
            context.bidderState.checkWatchlist({
                auctionId: item.id,
            })
        );
    }, [context, item.id]);

    useEffect(() => {
        if (media !== null && media.length > 0) {
            const primaryMedia: Media[] = media.filter(item => {
                return item.order === 0;
            });
            if (primaryMedia.length > 0) {
                setMediaUrl(primaryMedia[0].versions.thumbnail);
            } else if (primaryMedia.length > 1) {
                setMediaUrl(primaryMedia[0].versions.thumbnail);
            } else {
                setMediaUrl(media[0].versions.thumbnail);
            }
        }
    }, [item.id, media]);

    const getWatchingContainerClasses = () => {
        return `${classes.watchingContainer} ${isWatching ? classes.watching : classes.notWatching}`;
    };

    const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
    const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const md = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    const lg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

    const getTitleLength = () => {
        if (xs) {
            return 75;
        }

        if (sm) {
            return 77;
        }

        if (md) {
            return 55;
        }

        if (lg) {
            return 85;
        }

        return 85;
    };

    const imageContainer = () => {
        if (mediaUrl !== undefined) {
            return <CardMedia className={classes.cardMedia} component="img" alt={item.title} image={mediaUrl} title={item.title} />;
        }
        return <></>;
    };

    useEffect(() => {
        setPrice(item.currentHighestBid ? item.currentHighestBid : item.startPrice);
    }, [price, item]);

    const login = () => {
        msal.instance.loginRedirect({
            scopes: [clientSettings.B2CSettings.TokenScope],
        });
    };

    return (


        <Paper style={{ borderRadius: "20px",overflow:"hidden", border: '1px solid #b8b8b8', width: '100%' }}>
            <Grid container spacing={0} style={{ minHeight: "295px", borderRadius: "3px", overflow:"hidden", width: '100%' }}>
                <Grid item lg={4} md={4} sm={12}>
                    <Link component={RouterLink} to={`/Lot/${item.id}`}>
                        {imageContainer()}
                    </Link>
                </Grid>
                <Grid item lg={8} md={8} sm={12} style={{padding: isMdWidth ? '15px' : '15px 60px', }}>
                    <Typography variant="h4" component="h4" color="primary" className={classes.currentBitAmount} style={{ fontSize: '30px' }}>
                        Highest Value Vehicles
                    </Typography>
                    <p style={{ fontSize: '18px', color: '#242424', fontWeight: 'bold', textTransform: 'uppercase', padding: '0', margin: '0', marginTop: '10px', marginBottom: '20px', minHeight: '52px' }}>{item.title}</p>
                    <p style={{ fontSize: '18px', color: '#242424', textTransform: 'uppercase', padding: '0', margin: '0', marginTop: '10px', marginBottom: '20px'}}></p>
                    <Grid container spacing={0}>
                        <Grid item lg={6} md={5} sm={12}>
                            <Typography variant="h6" component="h1" color="primary" className={classes.currentBitAmount} style={{ fontSize: '30px', marginTop: isMdWidth ? '0px' : '0px' }}>
                                {currencyFormat(price)}
                            </Typography>
                            <p style={{ fontSize: '14px', margin: '0', color: '#242424', fontWeight: "bold", paddingBottom: '7px'}}>{item.currentBidsCount} Bids</p>
                            <TimerContainer endDateInput={item.auctionEnd} auctionStatus={item.status} />
                        </Grid>
                        <Grid item lg={5} md={7} sm={12}>
                            {msal.accounts.length === 0 && <Button
                                onClick={() => login()}
                                variant="text"
                                color="primary"
                                size="large"
                                style={{
                                    margin: isMdWidth ? '65px 8px' : '10px 0px', // Adjust width based on screen size
                                    minWidth: "75px",
                                    fontSize: "0.875rem",
                                    backgroundColor: "#EC6E13",
                                    color: "#FFFFFF",
                                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2)",
                                    borderRadius: "4px",
                                    top: isMdWidth ? '50px' : '0px',
                                    textTransform: "capitalize",
                                    paddingLeft: "30px",
                                    paddingRight: "30px",
                                    fontWeight: "bold",
                                }}
                            >
                                Login to Bid
                            </Button>}
                            {msal.accounts.length > 0 && <Route render={({ history }) => (
                                <Button
                                    onClick={() => history.push(`/Lot/${item.id}`)}
                                    variant="text"
                                    color="primary"
                                    size="large"
                                    style={{
                                        margin: isMdWidth ? '65px 8px' : '10px 0px', // Adjust width based on screen size
                                        minWidth: "75px",
                                        fontSize: "0.875rem",
                                        backgroundColor: "#EC6E13",
                                        color: "#FFFFFF",
                                        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2)",
                                        borderRadius: "4px",
                                        top: isMdWidth ? '50px' : '0px', // Adjust width based on screen size
                                        textTransform: "capitalize",
                                        paddingLeft: "30px",
                                        paddingRight: "30px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    View to Bid
                                </Button>)} />}
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Paper>


    );
};