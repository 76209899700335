import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useReadonlyData } from "./useVersionedData";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface Media {
  id: string;
  title: string;
  description: string;
  url: string;
  mediaType: number;
  mediaSize: string;
  metadataSet: boolean;
  order: number;
  versions: Versions;
  video?: string;
}

export interface Versions {
  mobile: string;
  thumbnail: string;
  fullsize: string;
}

export const useLotMedia = (lotId: string, lotMediaCacheName: string) => useReadonlyData<Media[]>(`${ClientSettings.LotBlobUrl}/${lotId}/${lotMediaCacheName}`, undefined, false);
