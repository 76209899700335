import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import * as Styles from "./styles/ContainerStyles";

export function NotLoggedIn() {
  const classes = Styles.ContainerStyles();

  return (
    <Container fixed className={classes.container}>
      <Grid item>
        <Typography variant="h5" color="primary" gutterBottom>
          Not Logged In
        </Typography>
        <Typography variant="subtitle1" color="textPrimary" gutterBottom>
          Please click login to access the platform.
        </Typography>
      </Grid>
    </Container>
  );
}
