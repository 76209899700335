import React from "react";
import { Dialog, Grid, Typography } from "@material-ui/core";
import { DocumentOptionContainer } from "./DocumentOptionContainer";
import { SubmitDocumentsComponent } from "./SubmitDocumentsComponent";
import { IAnsweredQuestions } from "./entities/FormsOfId";
import { StepContainer } from "../../StepContainer";
import * as Styles from "../../styles/SubmitDocumentsStyles";
import { CountryDefault } from "../../../../interfaces/ICountry";
import { IBidder } from "../../../../interfaces/bidders/IBidder";
import { LoadingComponent } from "../../../loading/LoadingComponent";
import { DateToDateString } from "../../../../helpers/dateHelper";

export const IdVerificationContainer: React.FC<{ bidder: IBidder; documentsSubmitted: () => void }> = ({ bidder, documentsSubmitted }) => {
  const [answeredQuestions, setAnsweredQuestions] = React.useState<IAnsweredQuestions | null>();
  const [submitButtonEnabled, setSubmitButtonEnabled] = React.useState(false);
  const [uploadDocuments, setUploadDocuments] = React.useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = React.useState(false);
  const classes = Styles.SubmitDocumentsStyles();
  const registeredAsBusiness = bidder.business !== null;

  React.useEffect(() => {
    const callbackStatus = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );

    if (callbackStatus != undefined && callbackStatus != null && callbackStatus == 'succeeded') {
      setShowPaymentSuccess(true);
    }

  }, []);

  const submitDocuments = () => {
    setUploadDocuments(true);
  };

  const loadingDialogContents = () => {
    return (
      <Dialog open={uploadDocuments}>
        <Grid
          direction="column"
          container
          alignItems="stretch"
          alignContent="center"
          justify="center"
          className={classes.loadingUploadingDocumentsComponent}
        >
          <LoadingComponent label="Submitting documents"></LoadingComponent>
        </Grid>
      </Dialog>
    );
  };

  return (
    <StepContainer
      nextButtonEnabled={submitButtonEnabled}
      title="Verify your identity"
      subTitle="By law we need to verify your identity before you can bid."
      handleNext={submitDocuments}
      nextButtonText="Submit Documents"
    >
      {loadingDialogContents()}

      {showPaymentSuccess && bidder.subscriptionEndDate != undefined &&
        <Typography style={{ backgroundColor: "#fefefe", width: "100%", border: "1px solid #cccccc", borderRadius: "10px", padding: "24px" }} component="h1" variant="h6">
          Thank you for your payment. Please contact assist@salvagemarket.co.uk with any further questions.
        </Typography>
      }

      <DocumentOptionContainer
        allQuestionsAnswered={setAnsweredQuestions}
        ukResident={bidder.address.country.countryCode === CountryDefault.countryCode}
        registeredAsBusiness={registeredAsBusiness}
      />
      {answeredQuestions != null && (
        <SubmitDocumentsComponent
          documentsSubmitted={documentsSubmitted}
          answeredQuestions={answeredQuestions!}
          setSubmitButtonEnabled={setSubmitButtonEnabled}
          uploadDocuments={uploadDocuments}
          closeDialog={() => setUploadDocuments(false)}
        />
      )}
    </StepContainer>
  );
};
