import React from "react";
import usePlacesAutocomplete, { getGeocode, GeocodeResult, Suggestion } from "use-places-autocomplete";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  Grid,
  TextFieldProps,
  ListItemIcon,
  ClickAwayListener,
  Popper,
  Box,
  Paper,
} from "@material-ui/core";
import { Place } from "@material-ui/icons";
import google from "../../assets/powered_by_google_on_white.png";

export type ILocationSearchTextField = TextFieldProps & { onAddressSet: (address: GeocodeResult) => void; countryCode: string };

// A lot has been taken from this demo code:  https://www.npmjs.com/package/use-places-autocomplete
export const LocationSearchTextField: React.FC<ILocationSearchTextField> = ({
  onAddressSet,
  onChange,
  value,
  countryCode,
  ...textboxProps
}: ILocationSearchTextField) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
  const {
    suggestions: { data },
    setValue: setAutoCompleteValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here. Currently we only search for uk addresses. This can be used to filter down to just addresses rather than places */

      componentRestrictions: {
        country: countryCode,
      },
    },
    debounce: 300,
  });

  const [highlightedRow, setHighlightedRow] = React.useState<number>(-1);

  /**
   * Fires when a user updates an input field
   * @param e The event fired when a user makes a change on the input field
   */
  const handleInput = (e: any) => {
    /* Tell the google autocomplete hook we have updated the input field  */
    setAutoCompleteValue(e.target.value);
    /* This tells the popup what to anchor itself to */
    setAnchorEl(e.currentTarget);
    /* Make sure the autocomplete popup is visible */
    setAutoCompleteOpen(true);
    /* Tell the parent component we have changed our value (this is because we implement a normal textbox interface) */
    if (onChange != null) {
      onChange(e);
    }
    /* Remove the highlighted row */
    setHighlightedRow(-1);
  };

  /**
   * Called when the user chooses one of the autocomplete options.
   */
  const handleSelect = (select: Suggestion) => {
    clearSuggestions();
    setAutoCompleteOpen(false);
    //TODO: Add lat/long
    getGeocode({ address: select.description })
      .then(results => onAddressSet(results[0]))
      .catch(error => {
        // TODO App insights on the UI
      });
  };

  /**
   * Renders all the individual suggestions made by google
   */
  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <div key={suggestion.id}>
          <ListItem button onClick={() => handleSelect(suggestion)} selected={index === highlightedRow}>
            <ListItemIcon>
              <Place />
            </ListItemIcon>
            <ListItemText primary={main_text} secondary={secondary_text} />
          </ListItem>
        </div>
      );
    });

  /**
   * Handle when the user clicks another part of the page. Used to close the auto complete part.
   */
  const handleClickAway = () => {
    setAutoCompleteOpen(false);
    setHighlightedRow(-1);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 38 && highlightedRow > 0) {
      // Down arrow
      setHighlightedRow(row => row - 1);
    } else if (e.keyCode === 40 && highlightedRow < data.length - 1) {
      setHighlightedRow(row => row + 1); // Up Arrow
    } else if (e.keyCode === 13) {
      // Enter
      handleSelect(data[highlightedRow]);
    }
  };

  return (
    <div>
      <TextField {...textboxProps} value={value} onChange={handleInput} onKeyDown={handleKeyDown} />
      {/* After calling the clearSuggestions(), the "status" is reset so the dropdown is hidden */}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper open={autoCompleteOpen && data.length > 0} anchorEl={anchorEl} placement="bottom-start">
          <Paper>
            <Box width={500}>
              <List component="nav" aria-label="Address autocomplete">
                {renderSuggestions()}
                <ListItem>
                  <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    {/* This is required by the licensing https://developers.google.com/places/web-service/policies */}
                    <img src={google} alt="Powered by google"></img>
                  </Grid>
                </ListItem>
              </List>
            </Box>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </div>
  );
};
