import { MsalClient } from "../authentication/MsalContext";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IValidation, IValidationError } from "../interfaces/IValidation";
import { GetClientSettings } from "./SettingsService";

const clientSettings: ISalvageClientSettings = GetClientSettings();

export interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export interface Http400Response extends Response {
  validation?: IValidation;
}

export const ApiService = () => {

  const getHttpResponse = async <T>(response: Response) => {
    try {
      const parsedBody = (await response.json()) as T;
      const httpResponse: HttpResponse<T> = { ...response, parsedBody };
      return httpResponse;
    } catch (err) {
      const httpResponse: HttpResponse<T> = { ...response };
      return httpResponse;
    }
  };

  const getHttp400Response = async (response: Response) => {
    const validationErrors = (await response.json()) as IValidationError[];

    const validation: IValidation = {} as IValidation;
    for (let i = 0; i < validationErrors.length; i++) {
      const key = validationErrors[i]["field"];
      validation[key] = validationErrors[i];
    }

    const http400Response: Http400Response = response as Http400Response;
    http400Response.validation = validation;
    return http400Response;
  };

  const handleHttpFailure = async (response: Response) => {
    if (response.status === 400) {
      return await getHttp400Response(response);
    } else {
      return response;
    }
  };

  const Http = async <T>(request: any) => {
    const response = await fetch(request);
    if (response.ok) {
      return await getHttpResponse<T>(response);
    } else {
      throw await handleHttpFailure(response);
    }
  };

  const getRequestInit = async (requestMethod: string, requestBody: any, allowAnonymous: boolean = false) => {
    const request: RequestInit = {
      method: requestMethod,
      body: requestBody,
    };

    if (!allowAnonymous) {
      const authHeader = await GetHeadersRecord();
      if (authHeader !== null) {
        request.headers = authHeader;
      }
    }

    return request;
  }

  const GetHeadersRecord = async (): Promise<Record<string, string> | null> => {
    if (MsalClient.getAllAccounts().length > 0) {
      try {
        const token = await MsalClient.acquireTokenSilent({
          scopes: [clientSettings.B2CSettings.TokenScope],
          account: MsalClient.getAllAccounts()[0],
          forceRefresh: false,
        });
        if (token) {
          return { Authorization: "Bearer " + token.accessToken };
        }

      }
      catch (error) {
        //Acquire token silent failure, and send an interactive request
        window.location.reload();
      }


    }
    return null;
  }

  const Get = async <T>(path: string, allowAnonymous: boolean = false) => {
    const request: RequestInit = await getRequestInit("get", null, allowAnonymous);
    return await Http<T>(new Request(path, request));
  };
  const GetWithBody = async <T>(path: string, body: any, objectName: string, allowAnonymous: boolean = false) => {
    const jsonBody: string = encodeURI(JSON.stringify(body));
    const request: RequestInit = await getRequestInit("get", null, allowAnonymous);

    return await Http<T>(new Request(path + '?' + objectName + '=' + jsonBody, request));
  };
  const Post = async <T>(path: string, body: any) => {
    const jsonBody: string = JSON.stringify(body);
    const request: RequestInit = await getRequestInit("post", jsonBody);

    return await Http<T>(new Request(path, request));
  };

  const Put = async <T>(path: string, body: any): Promise<HttpResponse<T>> => {
    const jsonBody: string = JSON.stringify(body);
    const request: RequestInit = await getRequestInit("put", jsonBody);

    return await Http<T>(new Request(path, request));
  };

  const Delete = async <T>(path: string, body: any): Promise<HttpResponse<T>> => {
    const jsonBody: string = JSON.stringify(body);
    const request: RequestInit = await getRequestInit("delete", jsonBody);

    return await Http<T>(new Request(path, request));
  };

  const Patch = async <T>(path: string, body: any): Promise<HttpResponse<T>> => {
    const jsonBody: string = JSON.stringify(body);
    const request: RequestInit = await getRequestInit("patch", jsonBody);
    return await Http<T>(new Request(path, request));
  };

  return { Get, GetWithBody, Post, Put, Delete, Patch }
}