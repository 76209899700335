import { fade, makeStyles } from "@material-ui/core/styles";
const borderRadius = "16px";

export const ActionBannerStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(8),
  },
  card: {
    borderRadius: borderRadius,
    height: "175px",
  },
  wrapper: {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
  },
  content: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    color: theme.palette.primary.contrastText,
    display: "block",
    padding: theme.spacing(2),
    background: fade(theme.palette.primary.main, 0.8),
    "&:hover": {
      background: fade(theme.palette.primary.main, 0.85),
    },
  },
  image: {
    height: "-webkit-fill-available",
  },
  title: {
    fontWeight: "bolder",
    paddingTop: theme.spacing(1),
    maxWidth: "90%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
  },
  caption: {
    paddingTop: theme.spacing(1),
    lineHeight: "0px",
    maxWidth: "40%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
  },
  action: {
    paddingTop: theme.spacing(1),
    lineHeight: "48px",
    maxWidth: "90%",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#393939",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
  },
  overlay: {
    background: "rgba(13,52,119,0.80)",
    color: theme.palette.primary.light,
    height: "100%",
    "&:hover": {
      background: "rgba(13,52,119,0.85)",
    },
  },
}));
