import { makeStyles } from "@material-ui/core/styles";
import { darkGrey } from "../../../theme";
const borderRadius = "16px";

export const AuctionItemStyles = makeStyles(theme => ({
  card: {
    margin: "8px",
    borderRadius: borderRadius,
    minHeight: "530px",
  },
  cardMediaContainer: { padding: "0px" },
  cardMedia: {
    height: "220px",
    borderTopLeftRadius: "20px",
  },
  watchingContainer: {
    position: "absolute",
    top: "8px",
    left: "8px",
    height: "60px",
    width: "60px",
    borderTopLeftRadius: "16px",
  },
  watching: {
    cursor: "pointer",
    color: theme.palette.secondary.main,
    background: `linear-gradient(-45deg, transparent 50%, ${theme.palette.primary.main} 50.5%)`,
  },
  notWatching: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    background: `linear-gradient(-45deg, transparent 50%, ${theme.palette.secondary.main} 50.5%)`,
  },
  noUnderline: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  titleBox: {
    display: "flex",
    textDecoration: "none"
  },
  currentBitAmount: {
    fontWeight: "bold",
    paddingRight: theme.spacing(3),

    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  category: {
    borderRadius: "6px",
    fontSize: " 0.7125rem",
  },
  categoryFiller: {
    height: "24px",
  },
  title: {
    minHeight: "170px",
    fontWeight: "bold",
    color: darkGrey,
    paddingTop: "8px",
  },
  spacing: {
    marginBottom: "8px",
  },
  location: {
    marginBottom: "8px",
    fontWeight: "bold",
    minHeight: "31px",
  },
  detailsItem: {
    display: "flex",
    alignItems: "center",
  },
  description: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  timeLeftGrid: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  timeLeft: {
    fontWeight: "bold",
    marginTop: "0px",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    textAlign: "right",
  },
}));
