import React from "react";
import { Box, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import * as Styles from "./styles/DisconnectedNotificationStyles";
import { useHub } from "../view-lot/hooks/HubProvider";
import { HubConnectionState } from "@microsoft/signalr";

const DisconnectedNotification: React.FC = () => {
  const hubContext = useHub();
  const state = hubContext.hub?.state;

  const [reloading, setReloading] = React.useState(false);

  window.addEventListener("beforeunload", function (e) {
    setReloading(true);
    setTimeout(() => {
      this.window.location.reload();
    }, 1000)
  });

  const classes = Styles.DisconnectedNotificationStyles();

  const message =
    (state === HubConnectionState.Reconnecting || state === HubConnectionState.Disconnected) && !reloading
      ? "You are currently not receiving live updates."
      : undefined;

  return (
    <Box bgcolor="info.main" color="info.contrastText" className={message ? classes.messageContainer : classes.hidden} p={2}>
      <Typography className={classes.textLayout}>
        <WarningIcon className={classes.iconPosition} />
        {message}
      </Typography>
    </Box>
  );
};

export default DisconnectedNotification;
