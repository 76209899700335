import { OrderBy } from "./ISearchOptions";

export interface ISearchBarOrderProps {
  id: OrderBy;
  displayTitle: string;
}

const closingSoon: ISearchBarOrderProps = {
  id: OrderBy.ClosingSoon,
  displayTitle: "Closing time (soonest)",
};

const closing: ISearchBarOrderProps = {
  id: OrderBy.ClosingLate,
  displayTitle: "Closing time (longest)",
};

const makeModel: ISearchBarOrderProps = {
  id: OrderBy.MakeAndModel,
  displayTitle: "Make and Model",
};

const yearDescending: ISearchBarOrderProps = {
  id: OrderBy.YearDesc,
  displayTitle: "Registration (Newest)",
};

const yearAscending: ISearchBarOrderProps = {
  id: OrderBy.YearAsc,
  displayTitle: "Registration (Oldest)",
};

const distanceDescending: ISearchBarOrderProps = {
  id: OrderBy.DistanceDesc,
  displayTitle: "Distance (Furthest)",
};

const distanceAscending: ISearchBarOrderProps = {
  id: OrderBy.DistanceAsc,
  displayTitle: "Distance (Closest)",
};

const priceDescending: ISearchBarOrderProps = {
  id: OrderBy.PriceDesc,
  displayTitle: "Price (Highest)",
};

const priceAscending: ISearchBarOrderProps = {
  id: OrderBy.PriceAsc,
  displayTitle: "Price (Lowest)",
};

const recentlyAdded: ISearchBarOrderProps = {
  id: OrderBy.RecentlyAdded,
  displayTitle: "Recently Added",
};

export const SearchOrderingOptions: Array<ISearchBarOrderProps> = [
  closingSoon,
  closing,
  makeModel,
  yearDescending,
  yearAscending,
  distanceDescending,
  distanceAscending,
  priceDescending,
  priceAscending,
  recentlyAdded,
];
