import React from "react";
import { IInitialState } from "../../../contexts/AppContext";
import { Watching } from "../../icons/WatchingIcon";
import * as Styles from "../styles/WatchlistHandlerStyles";
import { FormControlLabel, Switch, withStyles } from "@material-ui/core";
import { grey } from "../../../theme";
import { useTheme } from "@material-ui/core/styles";
import { IMsalContext } from "../../../authentication/MsalContext";
import { useMsal } from "../../../authentication/MsalProvider";
import { useSnackbar } from "notistack";

const WatchlistHandler: React.FC<{
  auctionId: string;
  context: IInitialState;
}> = ({ auctionId, context }) => {
  const msal: IMsalContext = useMsal();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const classes = Styles.WatchlistHandlerStyles();
  const [isWatching, setIsWatching] = React.useState(context.bidderState.checkWatchlist({ auctionId: auctionId }));
  const isLoggedIn = msal.accounts.length > 0;

  const addToWatchList = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsWatching(context.bidderState.checkWatchlist({ auctionId: auctionId }));
    if (isWatching) {
      context.bidderState
        .removeFromWatchlist(auctionId)
        .then(() => {
          setIsWatching(false);
        })
        .catch(() => {
          enqueueSnackbar("An error occured with your watchlist, please try again", { variant: "error" });
        });
    } else {
      context.bidderState
        .addToWatchlist(auctionId)
        .then(() => {
          setIsWatching(true);
        })
        .catch(() => {
          enqueueSnackbar("An error occured with your watchlist, please try again", { variant: "error" });
        });
    }
  };

  React.useEffect(() => {
    setIsWatching(context.bidderState.checkWatchlist({ auctionId: auctionId }));
  }, [auctionId, context.bidderState]);

  const WatchlistSwitch = withStyles({
    switchBase: {
      color: theme.palette.primary.main,
      "&$checked": {
        color: theme.palette.primary.main,
      },
      "&$checked + $track": {
        backgroundColor: grey,
      },
    },
    checked: {},
    track: { backgroundColor: grey },
  })(Switch);

  return (
    <FormControlLabel
      value="start"
      className={classes.padding}
      control={<WatchlistSwitch checked={isWatching} onChange={addToWatchList} value="isWatching" disabled={!isLoggedIn} />}
      label={
        <div className={classes.icon}>
          <Watching isWatching={isWatching} />
        </div>
      }
      labelPlacement="start"
    />
  );
};

export default WatchlistHandler;
