import React from "react";
import { Box, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import * as Styles from "./styles/MemberNotificationStyles";
import { AccountType } from "../../interfaces/bidders/IBidder";
import { AppContext } from "../../contexts/AppContext";

const MemberNotification: React.FC = () => {
  const context = React.useContext(AppContext);

  const classes = Styles.MemberNotificationStyles();

  const message = context.bidderState.bidder.accountType === AccountType.Member ? "You are currently signed in as a member." : undefined;

  return (
    <Box bgcolor="info.main" color="info.contrastText" className={message ? classes.messageContainer : classes.hidden} p={2}>
      <Typography className={classes.textLayout}>
        <InfoIcon className={classes.iconPosition} />
        {message}
      </Typography>
    </Box>
  );
};

export default MemberNotification;
