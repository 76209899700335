import React from "react";
import { IBidder } from "../../interfaces/bidders/IBidder";
import { Grid } from "@material-ui/core";
import * as Styles from "./styles/MyAccountContainerStyles";
import { LoadingComponent } from "../loading/LoadingComponent";

interface IProps {
  bidder: IBidder;
}

const BidderDetailsList: React.FC<IProps> = props => {
  const classes = Styles.MyAccountContainerStyles();

  if (props.bidder !== undefined && props.bidder.business) {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <h4 className={classes.orangeText}>NAME </h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.greyText}>{props.bidder.name}</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.orangeText}>EMAIL ADDRESS</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.greyText}>{props.bidder.email}</h4>
          </Grid>
        </Grid>
      </div>
    );
  } else if (props.bidder.address !== undefined) {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <h4 className={classes.orangeText}>NAME </h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.greyText}>{props.bidder.name}</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.orangeText}>EMAIL ADDRESS</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.greyText}>{props.bidder.email}</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.orangeText}>ADDRESS</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.greyText}>{props.bidder.address.line1}</h4>
            <h4 className={classes.greyText}>{props.bidder.address.line2}</h4>
            <h4 className={classes.greyText}> {props.bidder.address.line3}</h4>
            <h4 className={classes.greyText}>{props.bidder.address.city}</h4>
            <h4 className={classes.greyText}>{props.bidder.address.county}</h4>
            <h4 className={classes.greyText}>{props.bidder.address.country.name}</h4>
            <h4 className={classes.greyText}>{props.bidder.address.postcode}</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.orangeText}>LANDLINE NUMBER</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.greyText}> {props.bidder.landlineNumber}</h4>
          </Grid>

          <Grid item xs={6}>
            <h4 className={classes.orangeText}>MOBILE NUMBER</h4>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.greyText}>{props.bidder.mobileNumber ? props.bidder.mobileNumber : "not given"}</h4>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return <LoadingComponent label="Loading your details" />;
  }
};

export default BidderDetailsList;
