import { View, Text } from "@react-pdf/renderer";
import { styles } from "../receipt-pdf/styles/PdfStyles";
import React from "react";
import { ReceiptData } from "../../services/ReceiptService";
import { PaymentReceiptExpiryDateFormat } from "../../helpers/dateHelper";
import { currencyFormat } from "../../helpers/text-format/TextFormat";

interface ReceiptDescriptionProps {
  receiptDetails: ReceiptData;
  isRefundReceipt: boolean;
  orderStatus: string | undefined;
}

export const ReceiptDescription: React.FC<ReceiptDescriptionProps> = ({ receiptDetails, isRefundReceipt, orderStatus }) => (
  <>
    <View style={styles.descriptionBox}>
      <View style={styles.titleRow}>
        <View style={styles.titleColumn}>
          <Text style={styles.titleFont}>Description</Text>
        </View>
      </View>
      <View style={styles.description}>
        <View style={styles.descriptionColumn}>
          <View style={styles.text}>
            <Text>{(orderStatus == 'Refunded' && isRefundReceipt) === true ? "REFUND DESCRIPTION" : "BIDDER REGISTRATION"}</Text>
            <Text>
              Auction registration for {receiptDetails.subscriptionLength}. 
            </Text>
          </View>
          <View style={styles.text}>
            <Text>Your account is registered with:</Text>
            <Text>{receiptDetails.memberName}</Text>
            <Text>{receiptDetails.memberAddress.line1}</Text>
            <Text>{receiptDetails.memberAddress.line2}</Text>
            <Text>{receiptDetails.memberAddress.line3}</Text>
            <Text>{receiptDetails.memberAddress.city}</Text>
            <Text>{receiptDetails.memberAddress.county}</Text>
            <Text>{receiptDetails.memberAddress.postcode}</Text>
          </View>
          <View style={styles.text}>
            <Text>email: {receiptDetails.memberEmail}</Text>
          </View>
        </View>
        <View style={styles.regColumn}>
          <Text style={styles.right}>
            {(orderStatus == 'Refunded' && isRefundReceipt) === true ? "– " + currencyFormat(receiptDetails.totalExVat / 100) : currencyFormat(receiptDetails.totalExVat / 100)}
          </Text>
        </View>
      </View>
      <View style={styles.costsRow}>
        <View style={styles.textRight}>
          <Text>Total Ex. VAT </Text>
          <Text>VAT (20.00%) </Text>
          <Text>Total Inc. VAT </Text>
        </View>
        <View style={styles.textRight}>
          <Text>
            {(orderStatus == 'Refunded' && isRefundReceipt) === true ? "– " + currencyFormat(receiptDetails.totalExVat / 100) : currencyFormat(receiptDetails.totalExVat / 100)}
          </Text>
          <Text>
            {(orderStatus == 'Refunded' && isRefundReceipt) === true
              ? "– " + currencyFormat((receiptDetails.totalExVat * receiptDetails.vat) / 100)
              : currencyFormat((receiptDetails.totalExVat * receiptDetails.vat) / 100)}
          </Text>
          <Text>
            {(orderStatus == 'Refunded' && isRefundReceipt) === true ? "– " + currencyFormat(receiptDetails.totalIncVat / 100) : currencyFormat(receiptDetails.totalIncVat / 100)}
          </Text>
        </View>
      </View>
    </View>
  </>
);
