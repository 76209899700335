import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import * as Styles from "../../styles/LotDetailsContainerStyles";

import VehicleDetailsList from "./VehicleDetailsList";
import BidHistoryContainer from "./BidHistoryContainer";
import SellerDetailsContainer from "./SellerDetailsContainer";
import { Auction } from "../../../../services/AuctionService";
import LocationDetailsContainer from "./LocationDetailsContainer";
import { useMember } from "../../../../services/MemberService";
import { useSnackbar } from "notistack";
import { useIsActiveOrMember } from "../../../../helpers/ActiveUserHelper";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function tabProperties(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface VehicleLotDetailsContainerProps {
  auction: Auction;
}

const VehicleLotDetailsContainer: React.FC<VehicleLotDetailsContainerProps> = ({ auction }) => {
  const { enqueueSnackbar } = useSnackbar();
  const isActive = useIsActiveOrMember();

  const classes = Styles.LotDetailsContainerStyles();

  const [seller, sellerLoading] = useMember(auction.memberId, () => {
    enqueueSnackbar("There was a problem with your auctions request please try again.", { variant: "error" });
  });

  const [tabNumber, setTabNumber] = React.useState(0);

  function handleTabChange(_: any, newTabNumber: number) {
    setTabNumber(newTabNumber);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
        <Tabs value={tabNumber} onChange={handleTabChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto">
          <Tab label="Lot Details" {...tabProperties(0)} />
          <Tab label="Seller Details" {...tabProperties(1)} />
          <Tab label="Location Details" {...tabProperties(2)} />
          {isActive && <Tab label="Bid history" {...tabProperties(3)} />}
          {/* REMOVED FEES TAB AS PART OF CHANGE REQUEST 25 */}
          {/* {isActiveUser && <Tab label="Fees" {...tabProperties(4)} disabled={!isActiveUser} />} */}
        </Tabs>
      </AppBar>
      <TabPanel value={tabNumber} index={0}>
        <VehicleDetailsList auction={auction} />
      </TabPanel>
      <TabPanel value={tabNumber} index={1}>
        <SellerDetailsContainer auction={auction} seller={seller} sellerLoading={sellerLoading} />
      </TabPanel>
      <TabPanel value={tabNumber} index={2}>
        <LocationDetailsContainer auction={auction} seller={seller} sellerLoading={sellerLoading} />
      </TabPanel>
      {isActive && (
        <TabPanel value={tabNumber} index={3}>
          {tabNumber === 3 &&
            <BidHistoryContainer auctionId={auction.id} />
          }
        </TabPanel>
      )}
      {/* REMOVED FEES TAB AS PART OF CHANGE REQUEST 25 */}
      {/* {isActiveUser && (
        <TabPanel value={tabNumber} index={4}>
          <AuctionFeesContainer auctionId={auction.id} />
        </TabPanel>
      )} */}
    </div>
  );
};

export default VehicleLotDetailsContainer;
