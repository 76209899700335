import * as React from "react";
import { LoadingStyles } from "./styles/LoadingStyles";

import { Typography, Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

export const LoadingComponent: React.FC<{ label: string }> = ({ label }) => {
  const classes = LoadingStyles();
  return (
    <Grid direction="column" container alignItems="center" className={classes.loadingResultsComponent}>
      <Grid item>
        <Typography variant="h5" gutterBottom style={{ textAlign: "center" }}>
          {label}...
        </Typography>
      </Grid>
      <Grid item>
        <CircularProgress color="primary" size={60} thickness={8} />
      </Grid>
    </Grid>
  );
};
