import { ApiService, HttpResponse } from "./ApiService";
import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { IWatchlistItem } from "../interfaces/watchlist/IWatchlistItem";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const AddToWatchlist = async (auctionId: string): Promise<HttpResponse<string>> => {
  const watchlistItem: IWatchlistItem = {
    auctionId: auctionId,
  };
  return await ApiService().Put<string>(`${ClientSettings.UsersPrivateApiUrl}/watchlist/`, watchlistItem);
};

export const RemoveFromWatchlist = async (auctionId: string): Promise<HttpResponse<string>> => {
  const watchlistItem: IWatchlistItem = {
    auctionId: auctionId,
  };
  return await ApiService().Delete<string>(`${ClientSettings.UsersPrivateApiUrl}/watchlist/`, watchlistItem);
};
