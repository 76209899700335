import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import React from "react";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { IStripePaymentIntentResponse } from "../../interfaces/payments/IStripePaymentIntentResponse";
import { GetClientSettings } from "../../services/SettingsService";
import * as StripeService from "../../services/StripeService";
import { useSnackbar } from "notistack";
import { IStripeProduct } from "../../interfaces/payments/IStripeProduct";
import { LoadingComponent } from "../loading/LoadingComponent";

interface IProps {
  paymentIntent: string;
  paymentIntentSecret: string;
  redirectUrl: string;
  paymentMethod?: string;
  saveCard: boolean;
  updateSaveCard: (boolean) => void;
  refresh: () => void;
}

export const StripePaymentForm: React.FC<IProps> = ({ paymentIntent, paymentIntentSecret, redirectUrl, paymentMethod, saveCard, updateSaveCard, refresh }) => {
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [saveValCard, setSaveValCard] = React.useState<boolean>(saveCard);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    if (paymentMethod != 'new') {
      // existing payment method
      StripeService.ConfirmPaymentIntent(paymentIntent)
        .then(success => {
          let intent = success.parsedBody as IStripePaymentIntentResponse;
          if (intent.status == 'requires_action') {
            stripe.confirmCardPayment(paymentIntentSecret).then(function (actionComplete) {
              if (actionComplete.paymentIntent != undefined) {
                window.location.href = window.location.origin + redirectUrl + '?payment_intent=' + paymentIntent;
              } else {
                // refresh payment intent in parent here as last one expired
                refresh();
                if (actionComplete.error.type === "invalid_request_error") {
                  setMessage(actionComplete.error.message as string);
                } else {
                  setMessage("An unexpected error occurred.");
                }
                setIsLoading(false);
              }
            });
          } else if (intent.status == 'succeeded') {
            window.location.href = window.location.origin + redirectUrl + '?payment_intent=' + paymentIntent;
          }
        })
        .catch((error: Response) => {
          enqueueSnackbar("We have been unable to process this payment. Please close your browser and try again", {
            variant: "error",
          });
          setIsLoading(false);
        })
    } else {
      // new payment method
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin + redirectUrl,
        },
      });

      const errorMessageTrailingText = " - we were unable to obtain the necessary payment authorisation from your bank. Please retry using a different method of payment.";
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message as string + errorMessageTrailingText);
      } else {
        setMessage("Unfortunately your payment has been declined" + errorMessageTrailingText);
      }
      setIsLoading(false);
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  } as StripePaymentElementOptions;

  return (
    <div>
      {paymentIntentSecret != undefined && paymentIntentSecret != '' &&

        <Grid container>
          {paymentMethod == 'new' &&
            <Grid item xs={12} container justify="center" style={{ marginTop: '15px' }}>
              <FormControlLabel
                control={<Checkbox value={saveValCard} checked={saveValCard} onChange={event => { setSaveValCard(event.target.checked); updateSaveCard(event.target.checked) }} name="saveCard" color="primary" />}
                label="Save card details"
              />
            </Grid>
          }

          {paymentMethod == 'new' &&
            <Grid item xs={12} style={{ display: isLoading ? 'none' : 'block' }}>
              <PaymentElement id="payment-element" options={paymentElementOptions} />
            </Grid>
          }
          {isLoading &&
            <Grid item xs={12}>
              <LoadingComponent label="Please Wait"></LoadingComponent>
            </Grid>
          }





          <Grid item xs={12}>
            <Button
              style={{ paddingLeft: "90px", paddingRight: "90px", margin: "0 auto", textAlign: "center", display: "block", position: "relative", marginTop: "30px", }}
              color="primary" variant="contained" onClick={() => handleSubmit()} disabled={isLoading}>Pay Now</Button>
          </Grid>
          <Grid item xs={12} >
            {message &&
              <div id="payment-message" style={{ marginTop: "40px", backgroundColor: "#fefefe", border: "1px solid #cccccc", borderRadius: "10px", padding: "24px", marginBottom: "25px " }}>
                {message}<br></br>
                Alternatively, please contact assist@salvagemarket.co.uk with any questions.
              </div>}
          </Grid>
        </Grid>
      }
    </div>

  )
};

