import { parse, ParsedQuery } from "query-string";
import { HomepageCategoryStats } from "../../services/SearchService";

export const DistanceOptions: Record<string, number> = { "5 miles": 0, "10 miles": 0, "20 miles": 0, "50 miles": 0, "100 miles": 0 };

export enum EditorPickSearch {
  None = "0",
  MostViewed = "1",
  MostWatched = "2",
  MostBids = "3",
  RegisteredInLastYear = "4",
  HGVAndPlant = "5",
}

export enum QuickSearch {
  None = "0",
  HighValue = '1',
  StartsAndDrives = '2',
  Featured = '3',
  RecentlyAdded = '4',
}

export enum OrderBy {
  ClosingSoon = "1",
  ClosingLate = "2",
  MakeAndModel = "3",
  YearDesc = "4",
  YearAsc = "5",
  DistanceDesc = "6",
  DistanceAsc = "7",
  PriceDesc = "8",
  PriceAsc = "9",
  RecentlyAdded = "10"
}

export enum AuctionSearchPageSize {
  Twenty = 20,
  Thirty = 30,
  Forty = 40,
}

export interface ISearchOptions {
  auction: string[];
  make: string[];
  model: string[];
  damageCategory: string[];
  transmissionType: string[];
  fuelType: string[];
  seller: string[];
  year: string[];
  startDrive: string[];
  distance: string[];
  // REMOVED FOR COMMERCIAL CHANGES IN CR28
  // subscription: string;
  searchText: string;
  latitude: number;
  longitude: number;
  editorPickSearch: EditorPickSearch;
  quickSearch: QuickSearch;
  bucketId: string;
  bucketDetails: string;
  orderBy: OrderBy;
  pageNumber: number;
  pageSize: AuctionSearchPageSize;
}

export const editorPickSearchString = (editorPickSearch: EditorPickSearch) => {
  switch (editorPickSearch) {
    case EditorPickSearch.MostBids: {
      return "Auctions with Most Bids";
    }
    case EditorPickSearch.MostViewed: {
      return "Most Viewed Auction";
    }
    case EditorPickSearch.MostWatched: {
      return "Most Watched Auction";
    }
    case EditorPickSearch.RegisteredInLastYear: {
      return "Registered in the last year";
    }
    case EditorPickSearch.HGVAndPlant: {
      return "HGV & Plant";
    }
    default: {
      return null;
    }
  }
};

export const quickSearchString = (quickSearchString: QuickSearch) => {
  switch (quickSearchString) {
    case QuickSearch.Featured: {
      return "Featured";
    }
    case QuickSearch.HighValue: {
      return "High Value";
    }
    case QuickSearch.StartsAndDrives: {
      return "Starts & Drives";
    }
    case QuickSearch.RecentlyAdded: {
      return "Just Added";
    }
    default: {
      return null;
    }
  }
};

export function SearchOption(lat?: number, long?: number): ISearchOptions {
  return {
    auction: [""],
    make: [""],
    model: [""],
    damageCategory: [""],
    transmissionType: [""],
    fuelType: [""],
    seller: [""],
    year: [""],
    startDrive: [""],
    distance: [""],
    // REMOVED FOR COMMERCIAL CHANGES IN CR28
    // subscription: "",
    searchText: "",
    latitude: lat ?? 0,
    longitude: long ?? 0,
    editorPickSearch: EditorPickSearch.None,
    quickSearch: QuickSearch.None,
    bucketId: "",
    bucketDetails: "",
    orderBy: OrderBy.ClosingSoon,
    pageNumber: 0,
    pageSize: AuctionSearchPageSize.Twenty,
  };
}

export function HomepageStats(): HomepageCategoryStats {
  return {
    highValue: null,
    startsAndDrives: null,
    unrecorded: null,
    justAdded: null,
    featured: null
  };
}


export const IsDefaultSettingForSearch = (setting: ISearchOptions) => {
  const defaultOptions = SearchOption();
  return (
    IsDefaultSettingForSearchConfig(setting) &&
    defaultOptions.orderBy === setting.orderBy &&
    defaultOptions.pageNumber === setting.pageNumber &&
    defaultOptions.pageSize === setting.pageSize
  );
};
export const IsDefaultSettingForSearchConfig = (setting: ISearchOptions) => {
  const defaultOptions = SearchOption();
  return (
    defaultOptions.auction === setting.auction &&
    defaultOptions.make === setting.make &&
    defaultOptions.damageCategory === setting.damageCategory &&
    defaultOptions.transmissionType === setting.transmissionType &&
    defaultOptions.fuelType === setting.fuelType &&
    defaultOptions.seller === setting.seller &&
    defaultOptions.year === setting.year &&
    defaultOptions.startDrive === setting.startDrive &&
    defaultOptions.distance === setting.distance &&
    defaultOptions.searchText === setting.searchText &&
    defaultOptions.editorPickSearch === setting.editorPickSearch &&
    defaultOptions.bucketId === setting.bucketId
  );
};

export const IsDefaultSettingForHomepageStats = () => {
  const defaultOptions = SearchOption();
  return defaultOptions;
};

export function SetSearchOption(parsedQuery: ParsedQuery): ISearchOptions {
  if (Object.keys(parsedQuery).length === 0) {
    var sessionQuery = sessionStorage.getItem("search");
    if (sessionQuery) {
      parsedQuery = parse(sessionQuery, { arrayFormat: 'bracket' });
    }
  }

  return {
    auction: parsedQuery.auction === undefined ? [""] : parsedQuery.auction as string[],
    make: parsedQuery.make === undefined ? [""] : parsedQuery.make as string[],
    model: parsedQuery.model === undefined ? [""] : parsedQuery.model as string[],
    damageCategory: parsedQuery.damageCategory === undefined ? [""] : parsedQuery.damageCategory as string[],
    transmissionType: parsedQuery.transmissionType === undefined ? [""] : parsedQuery.transmissionType as string[],
    fuelType: parsedQuery.fuelType === undefined ? [""] : parsedQuery.fuelType as string[],
    seller: parsedQuery.seller === undefined ? [""] : parsedQuery.seller as string[],
    year: parsedQuery.year === undefined ? [""] : parsedQuery.year as string[],
    startDrive: parsedQuery.startDrive === undefined ? [""] : parsedQuery.startDrive as string[],
    distance: parsedQuery.distance === undefined ? [""] : parsedQuery.distance as string[],
    // REMOVED FOR COMMERCIAL CHANGES IN CR28
    // subscription: parsedQuery.premium === undefined ? "" : (parsedQuery.premium as string),
    searchText: parsedQuery.searchText === undefined ? "" : (parsedQuery.searchText as string),
    latitude: parsedQuery.latitude === undefined ? 0 : Number(parsedQuery.latitude),
    longitude: parsedQuery.longitude === undefined ? 0 : Number(parsedQuery.longitude),
    editorPickSearch: parsedQuery.editorPickSearch === undefined ? EditorPickSearch.None : (parsedQuery.editorPickSearch as EditorPickSearch),
    quickSearch: parsedQuery.quickSearch === undefined ? QuickSearch.None : (parsedQuery.quickSearch as QuickSearch),
    bucketId: parsedQuery.bucketId === undefined ? "" : (parsedQuery.bucketId as string),
    bucketDetails: parsedQuery.bucketDetails === undefined ? "" : (parsedQuery.bucketDetails as string),
    orderBy: parsedQuery.orderBy === undefined ? OrderBy.ClosingSoon : (parsedQuery.orderBy as OrderBy),
    pageNumber: parsedQuery.pageNumber === undefined ? 0 : Number(parsedQuery.pageNumber),
    pageSize: parsedQuery.pageSize === undefined ? AuctionSearchPageSize.Twenty : Number(parsedQuery.pageSize),
  };
}
