import { Typography, Grid } from "@material-ui/core";
import { IFormsOfId } from "./entities/FormsOfId";
import React from "react";

export const RequiredDocument: React.FC<{ document: IFormsOfId }> = ({ document }) => {
  return (
    <>
      <Grid item md={2} xs={12}>
        <Typography style={{ fontWeight: 600 }} variant="subtitle1">
          {document.title}:
        </Typography>
      </Grid>
      <Grid item md={10} xs={12}>
        <Typography variant="body1" gutterBottom>
          {document.description}
        </Typography>
      </Grid>
    </>
  );
};
