import { IAddress, AddressDefault } from "../IAddress";
import { AccountType } from "./IBidder";
import { IBusiness } from "./IBusiness";

export interface ICreateBidder {
  landlineNumber: string;
  mobileNumber: IValidatedNumber | null;
  address: IAddress;
  business: IBusiness | null;
  mailingListOptIn: boolean;
  accountType: AccountType;
  registrationUrl: string
}

export const CreateBidderDefault: ICreateBidder = {
  landlineNumber: "",
  mobileNumber: null,
  address: AddressDefault,
  business: null,
  mailingListOptIn: false,
  accountType: AccountType.Unknown,
  registrationUrl: window.location.origin
};

export interface IValidatedNumber {
  number: string,
  token: string
}