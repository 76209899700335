import { makeStyles } from "@material-ui/core";

export const DocumentationRemediationStyles = makeStyles(theme => ({
  imageReplaced: {
    filter: "grayscale(100%)",
  },
  textLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  },
  iconPosition: {
    marginLeft: "5px",
  },
  padding: {
    padding: "10px",
    margin: "10px",
    marginBottom: "0px",
  },
  replaceImageButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  errorBackground: {
    padding: "10px",
    margin: "10px",
    marginBottom: "0px",
    backgroundColor: "rgba(255, 230, 230,0.5)",
    "&:hover": {
      backgroundColor: "rgb(255, 230, 230)",
      transition: "background-color 0.5s ease",
    },
  },
  loadingUploadingDocumentsComponent: {
    width: "500px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));
