import React from "react";
import * as Styles from "./styles/IconStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

interface Watching {
  isWatching: boolean;
}

const Watching: React.FC<Watching> = ({ isWatching }) => {
  const classes = Styles.IconStyles();
  return (
    <div className={classes.svgIcon} title={isWatching ? "Remove from watchlist" : "Add to watchlist"}>
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={faEye} className={isWatching ? classes.highlight : classes.secondary} size="lg" />
      </span>
    </div>
  );
};

export { Watching };
