import { makeStyles } from "@material-ui/core/styles";

export const SellerContainerStyles = makeStyles(theme => ({
  siteName: { margin: "0", marginLeft: "8px", color: theme.palette.secondary.dark },
  sellerName: { marginBottom: "0", marginLeft: "8px", marginRight: "8px" },
  text: { marginLeft: "8px" },
  imageContainer: {
    marginLeft: "8px",
    marginRight: "8px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: "100%",
  },
}));
