import React from "react";
import { GridList, GridListTile, useMediaQuery, Theme } from "@material-ui/core";
import { FeaturedAuctionItem } from "./FeaturedAuctionItem";
import { IInitialState } from "../../contexts/AppContext";
import * as Styles from "./styles/AuctionListStyles";
import { FeaturedAuction } from "../../services/AuctionService";
import { useSnackbar } from "notistack";

export const FeaturedAuctionList: React.FC<{
  results: FeaturedAuction[];
  context: IInitialState;
}> = ({ results, context }) => {
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const classes = Styles.AuctionListStyles();
  const { enqueueSnackbar } = useSnackbar();

  const getGridListCols = () => {
    if (xs) {
      return 1;
    }

    if (sm) {
      return 2;
    }

    return 4;
  };

  const watchlistHandler = (e: any, auctionId: string) => {
    e.stopPropagation();
    if (context.bidderState.checkWatchlist({ auctionId: auctionId })) {
      context.bidderState.removeFromWatchlist(auctionId).catch(() => {
        enqueueSnackbar("An error occured with your watchlist, please try again", { variant: "error" });
      });
    } else {
      context.bidderState.addToWatchlist(auctionId).catch(() => {
        enqueueSnackbar("An error occured with your watchlist, please try again", { variant: "error" });
      });
    }
  };

  return (
    <GridList cols={getGridListCols()}>
      {results.map((item: FeaturedAuction, index: number) => (
        <GridListTile className={classes.tile} key={index}>
          <FeaturedAuctionItem item={item} watchlistHandler={watchlistHandler} context={context} />
        </GridListTile>
      ))}
    </GridList>
  );
};
