import { makeStyles } from "@material-ui/core";

export const ImportantInformationStyles = makeStyles(theme => ({
  divider: { paddingLeft: "40px", paddingRight: "0px" },
  importantInfoContainer: {
    padding: "15px"
  },
  infoContainer: {
    padding: "15px;"
  },
  titleContainer: {
    padding: "40px",
    paddingBottom: "70px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px"
    }
  },

  loadingStatusComponent: {
    marginTop: "150px;",
    marginBottom: "150px;"
  }
}));
