export enum RegistrationSteps {
  Unknown = 0,
  Start = 1,
  Details = 2,
  // REMOVED FOR COMMERCIAL CHANGES IN CR28
  // AccountType = 3,
   Payment = 3,
  IdValidation = 4,
  AwaitingVerification = 5,
  FailedValidation = 6,
  Complete = 7,
  Duplicate = 99
}
