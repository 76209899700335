import React from "react";
import * as Styles from "./styles/InformationPagesStyles";
import { Grid, Typography, Hidden, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import * as StripeProductService from "../../services/StripeProductService";
import { IStripeProduct } from "../../interfaces/payments/IStripeProduct";

const AuctionFeesContainer: React.FC = () => {
  const classes = Styles.InformationPagesStyles();
  const [stripeProducts, setStripeProducts] = React.useState<IStripeProduct[]>();

  React.useEffect(() => {
    loadStripeProducts();
  }, []);

  const loadStripeProducts = () => {
    StripeProductService.GetUnAuthedActive()
      .then(success => {
        setStripeProducts(success.parsedBody);
      })
      .catch((error: Response) => {
      })
  };


  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Hammer fee
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom className={classes.orangeText}>
            Fees for auctions closing on or after 30th November 2023
          </Typography>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Hammer / selling price range</TableCell>
                  <TableCell>Buyers fee</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>£0.00 to £99.99</TableCell>
                  <TableCell>£43.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£100.00 to £199.99</TableCell>
                  <TableCell>£85.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£200.00 to £299.99</TableCell>
                  <TableCell>£93.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£300.00 to £399.99</TableCell>
                  <TableCell>£107.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£400.00 to £499.99</TableCell>
                  <TableCell>£120.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£500.00 to £699.99</TableCell>
                  <TableCell>£143.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£700.00 to £899.99</TableCell>
                  <TableCell>£158.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£900.00 to £1,099.99</TableCell>
                  <TableCell>£167.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£1,100.00 to £1,299.99</TableCell>
                  <TableCell>£179.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£1,300.00 to £1,499.99</TableCell>
                  <TableCell>£194.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£1,500.00 to £1,699.99</TableCell>
                  <TableCell>£214.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£1,700.00 to £2,499.99</TableCell>
                  <TableCell>£253.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£2,500.00 to £2,999.99</TableCell>
                  <TableCell>£319.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£3,000.00 to £3,499.99</TableCell>
                  <TableCell>£389.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£3,500.00 to £3,999.99</TableCell>
                  <TableCell>£393.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£4,000.00 to £4,499.99</TableCell>
                  <TableCell>£419.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£4,500.00 to £4,999.99</TableCell>
                  <TableCell>£479.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£5,000.00 to £5,499.99</TableCell>
                  <TableCell>£509.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£5,500.00 to £5,999.99</TableCell>
                  <TableCell>£509.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£6,000.00 to £6,499.99</TableCell>
                  <TableCell>£528.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£6,500.00 to £6,999.99</TableCell>
                  <TableCell>£528.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£7,000.00 to £9,499.99</TableCell>
                  <TableCell>£539.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£9,500.00 to £11,999.99</TableCell>
                  <TableCell>£540.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>£12,000.00 +</TableCell>
                  <TableCell>6.00%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} lg={12} className={classes.container}>
          <p className={classes.greyText}>
            * All the above fees are subject to VAT
          </p>
        </Grid>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Other fees
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Fee</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Admin fee</TableCell>
                  <TableCell>£35.00</TableCell>
                </TableRow>
                {stripeProducts?.map((stripeProduct, index) => (
                  <TableRow>
                    <TableCell>{stripeProduct.durationInMonths} Month(s) Subscription fee</TableCell>
                    <TableCell>A subscription fee of £{(stripeProduct.amount / 100).toFixed(2)} for {stripeProduct.durationInMonths} month(s).</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>Site fee</TableCell>
                  <TableCell>For details please see lot details page</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Loading charge</TableCell>
                  <TableCell>£15.00 per vehicle loaded onto a multi-vehicle transporter</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Late payment charge</TableCell>
                  <TableCell>£25.00 where payment is not received in full within 72 hours of the date of Sale</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Storage charge</TableCell>
                  <TableCell>£15.00 per day if the Lot is not collected within 7 calendar days</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Not collected within 7 calendar days or paid for within 72 hours</TableCell>
                  <TableCell>
                    We reserve the right to apply a re-listing / backing out fee of 10% of the winning bid (Min £100.00, Max £500.00) to reactivate
                    your account. Continual non payment will result in permanent suspension of your account.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Delivery fee</TableCell>
                  <TableCell>
                    Where expressly agreed between the Seller and the Buyer, a delivery fee in respect of the delivery of the Lot to the Buyer.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Export fee</TableCell>
                  <TableCell>
                    An administration fee of £50 to process VAT refunds to bidders who remove Lots from the UK. Any bank charges or currency exchange
                    costs related to the refund transaction will be payable by the buyer.
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell>Membership fee</TableCell>
                  <TableCell>We provide a 6 months free trial membership period</TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} lg={12} className={classes.container}>
          <p className={classes.greyText}>
            * All the above fees are subject to VAT
          </p>
        </Grid>
        <Grid item xs={12} lg={3} className={classes.container}>
          <Typography variant="h5" component="h1" className={classes.greyText}>
            Notice
          </Typography>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} className={classes.container}>
            <Divider className={classes.divider} orientation="vertical" />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="body1" gutterBottom>
            Server time is for indication only. The server time may be out by several seconds. Do not rely on the server time panel for the accurate
            time, it is to help users in a different time zone understand when vehicles are ending.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuctionFeesContainer;
