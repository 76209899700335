import { makeStyles } from "@material-ui/core/styles";
import { white } from "../../../theme";

export const HomePageIconStyles = makeStyles(theme => ({
  remove: {
    cursor: "pointer",
    fontSize: "16px",
    padding: "10px",
    color: white,
  },
  add: {
    cursor: "pointer",
    fontSize: "16px",
    padding: "10px",
    color: theme.palette.secondary.dark,
  },
}));
