const GoogleGeocodeService = () => {
  var service = new google.maps.Geocoder();
  const getDistance = async (address: string | undefined) => {
    return new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
      try {
        service.geocode(
          {
            address: address,
          },
          (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
            if (status === google.maps.GeocoderStatus.OK) {
              resolve(results);
            } else {
              reject(status);
            }
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  return { getDistance };
};

export default GoogleGeocodeService;
