import { View, Text } from "@react-pdf/renderer";
import { styles } from "../receipt-pdf/styles/PdfStyles";
import React from "react";
import { ReceiptData } from "../../services/ReceiptService";
import { PaymentReceiptSaleDateFormat } from "../../helpers/dateHelper";

interface ReceiptCustomerDetailsProps {
  receiptDetails: ReceiptData;
  isRefundReceipt: boolean;
  orderStatus: string | undefined;
}

export const ReceiptCustomerDetails: React.FC<ReceiptCustomerDetailsProps> = ({ receiptDetails, isRefundReceipt, orderStatus  }) => (
  <>
    <View style={styles.customerBox}>
      <View style={styles.titleRow}>
        <View style={styles.titleColumn}>
          <Text style={styles.titleFont}>Customer</Text>
        </View>
      </View>
      <View style={styles.customerDetails}>
        <View style={styles.customerContact}>
          <View style={styles.customerText}>
            <Text>{receiptDetails.bidderName}</Text>
            {(receiptDetails.bidderBusiness !== null || receiptDetails.bidderBusiness !== "") && <Text>{receiptDetails.bidderBusiness}</Text>}
            <Text>{receiptDetails.bidderAddress.line1}</Text>
            <Text>{receiptDetails.bidderAddress.line2}</Text>
            <Text>{receiptDetails.bidderAddress.line3}</Text>
            <Text>{receiptDetails.bidderAddress.city}</Text>
            <Text>{receiptDetails.bidderAddress.county}</Text>
            <Text>{receiptDetails.bidderAddress.postcode}</Text>
          </View>
          <View style={styles.text}>
            <Text>Tel: {receiptDetails.bidderPhoneNumber}</Text>
            <Text>email: {receiptDetails.bidderEmail}</Text>
          </View>
        </View>
        <View>
          <View style={styles.text}>
            <Text>{orderStatus == 'Refunded' && isRefundReceipt === true ? "Refund Date: " : "Sale Date:"} {orderStatus == 'Refunded' && isRefundReceipt === true ? PaymentReceiptSaleDateFormat(receiptDetails.refundedDate) : PaymentReceiptSaleDateFormat(receiptDetails.saleDate)}</Text>
          </View>
          <View style={styles.text}>
            <Text>Sale Receipt No.: {receiptDetails.saleReceiptNumber}</Text>
          </View>
          <View style={styles.text}>
            <Text>
              {orderStatus == 'Refunded' && isRefundReceipt === true ? "Original Invoice No.: " + receiptDetails.referenceNumber : "Our Ref: " + receiptDetails.referenceNumber}
            </Text>
          </View>
        </View>
      </View>
    </View>
  </>
);
