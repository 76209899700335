import React from "react";
import { SvgIcon } from "@material-ui/core";
import * as Styles from "./styles/CarouselStyles";

interface CustomArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<any>;
  currentSlide?: number;
  slideCount?: number;
}

export const NextArrow: React.FC<CustomArrowProps> = CustomArrowProps => {
  const classes = Styles.CarouselStyles();
  return (
    <div
      className={CustomArrowProps.className}
      style={{
        ...CustomArrowProps.style,
        right: "16px",
      }}
      onClick={CustomArrowProps.onClick}
    >
      <SvgIcon className={classes.svgIcon}>
        <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
        <path fill="none" d="M0 0h24v24H0z" />
      </SvgIcon>
    </div>
  );
};

export const PrevArrow: React.FC<CustomArrowProps> = CustomArrowProps => {
  const classes = Styles.CarouselStyles();
  return (
    <div
      className={CustomArrowProps.className}
      style={{
        ...CustomArrowProps.style,
        left: "8px",
        zIndex: 10,
      }}
      onClick={CustomArrowProps.onClick}
    >
      <SvgIcon className={classes.svgIcon}>
        <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
        <path fill="none" d="M0 0h24v24H0z" />
      </SvgIcon>
    </div>
  );
};

export const NextArrowLessThanFourImages: React.FC<CustomArrowProps> = CustomArrowProps => {
  const classes = Styles.CarouselStyles();
  return (
    <div
      className={CustomArrowProps.className}
      style={{
        ...CustomArrowProps.style,
        right: "20px",
      }}
      onClick={CustomArrowProps.onClick}
    >
      <SvgIcon className={classes.svgIcon}>
        <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
        <path fill="none" d="M0 0h24v24H0z" />
      </SvgIcon>
    </div>
  );
};

export const PrevArrowLessThanFourImages: React.FC<CustomArrowProps> = CustomArrowProps => {
  const classes = Styles.CarouselStyles();
  return (
    <div
      className={CustomArrowProps.className}
      style={{
        ...CustomArrowProps.style,
        left: "10px",
        zIndex: 10,
      }}
      onClick={CustomArrowProps.onClick}
    >
      <SvgIcon className={classes.svgIcon}>
        <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
        <path fill="none" d="M0 0h24v24H0z" />
      </SvgIcon>
    </div>
  );
};

export const PlayIcon: React.FC = () => {
  const classes = Styles.CarouselStyles();
  return (
    <SvgIcon className={classes.play} viewBox="-30 -30 0 0">
      <path d="M8 5v14l11-7z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </SvgIcon>
  );
};
