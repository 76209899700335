import * as React from "react";
import { ISearchResultsTopBarProps } from "../../../interfaces/search/ISearchResultsTopBarProps";
import { Select, MenuItem, Grid, Paper } from "@material-ui/core";
import { SearchOrderingOptions } from "../../../interfaces/search/ISearchBarOrderProps";
import { OrderBy } from "../../../interfaces/search/ISearchOptions";

export const SearchResultsTopBar: React.FC<ISearchResultsTopBarProps> = props => {

  const [orderBySelected, setOrderBySelected] = React.useState<OrderBy>(props.searchOptions.orderBy);

  React.useEffect(() => {
    setOrderBySelected(props.searchOptions.orderBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchOptions.orderBy]);

  const handleOrderChange = (event: any) => {
    var selected = event.target.value as OrderBy;
    setOrderBySelected(selected);
    props.orderByChanged(selected, undefined);
  };

  return (
    <Grid container style={{width: "100%"}}>
        <Grid item style={{width: "100%"}}>
          <Select style={{fontSize: "14px", borderBottom: "none", width: "100%"}} value={orderBySelected} onChange={handleOrderChange} displayEmpty >
            {SearchOrderingOptions.map(option => (
              <MenuItem style={{fontSize: "14px", borderBottom: "none"}} key={option.id} value={option.id} selected={option.id === orderBySelected}>
                {option.displayTitle}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
  );
};
