import * as React from "react";
import { Container } from "@material-ui/core";
import * as Styles from "./styles/ContainerStyles";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

function NotAuthorised() {
  const classes = Styles.ContainerStyles();

  return (
    <Container fixed className={classes.container}>
      <h1>Not Authorised</h1>

      <p>You must authenticate to see this page.</p>
    </Container>
  );
}

export default withAITracking(reactPlugin, NotAuthorised, "NotAuthorised");
