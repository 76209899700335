import React from "react";
import { Box, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import * as Styles from "./styles/SuspensionNotificationStyles";
import { BidderStatus } from "../../interfaces/bidders/IBidder";
import { AppContext } from "../../contexts/AppContext";
import { useHistory } from "react-router-dom";

const SuspensionNotification: React.FC = () => {
  const context = React.useContext(AppContext);
  const history = useHistory();
  const classes = Styles.SuspensionNotificationStyles();

  const message =
    context.bidderState.bidder.bidderStatus === BidderStatus.Suspended
      ? "We are sorry to inform you that your account has been suspended. Please visit the My Account page for details why your account has been suspended."
      : undefined;

  return (
    <Box
      bgcolor="info.main"
      color="info.contrastText"
      className={message ? classes.messageContainer : classes.hidden}
      p={2}
      onClick={() => history.push("/MyAccount")}
    >
      <Typography className={classes.textLayout}>
        <InfoIcon className={classes.iconPosition} />
        {message}
      </Typography>
    </Box>
  );
};

export default SuspensionNotification;
