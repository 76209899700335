import * as React from "react";
import * as Styles from "./styles/ContainerStyles";
import { Container, Typography, Box, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useMsal } from "../../authentication/MsalProvider";
import { LoadingComponent } from "../loading/LoadingComponent";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";

function NotFoundPage() {
  const { loginInProgress } = useMsal();
  const classes = Styles.ContainerStyles();
  return (
    <Container fixed className={classes.container}>
      {!loginInProgress && (
        <>
          <Box style={{ paddingTop: "32px" }}>
            <Typography variant="h1" component="h1" align="center" gutterBottom>
              404
            </Typography>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              Ooops!!
            </Typography>
            <Typography variant="h5" component="h3" align="center" gutterBottom>
              That page doesn't exist or is unavailable.
            </Typography>
          </Box>
          <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "32px" }}>
            <Button variant="contained" color="primary" component={RouterLink} to="/">
              Go Back to Home
            </Button>
          </Box>
        </>
      )}

      {loginInProgress && (
        <Box style={{ paddingTop: "32px" }}>
          <LoadingComponent label="Please wait" />
        </Box>
      )}
    </Container>
  );
}

export default withAITracking(reactPlugin, NotFoundPage, "NotFoundPage");
