import React from "react";
import { Route, Redirect } from "react-router-dom";
import { IMsalContext } from "../authentication/MsalContext";
import { useMsal } from "../authentication/MsalProvider";
import { AppContext } from "../contexts/AppContext";

const RegisteredRoute = ({ component, ...rest }: any) => {
  const context = React.useContext(AppContext);
  const msal: IMsalContext = useMsal();

  const routeComponent = (props: any) =>

    msal.accounts.length > 0 && context.bidderState.isRegistered !== undefined && !context.bidderState.isRegistered ? (
      <Redirect
        push={false}
        to={{
          pathname: "/Register",
        }}
      />
    ) : (

      msal.accounts.length > 0 && context.bidderState.archived == true ? (
        <Redirect
          push={false}
          to={{
            pathname: "/Archived",
          }}
        />
        // React.createElement(component, props)
      ): (


        msal.accounts.length > 0 && context.bidderState.currentTermsAccepted == false && context.bidderState.termsEnabled == true && context.bidderState.archived == false ? (
          <Redirect
            push={false}
            to={{
              pathname: "/UpdateTerms",
            }}
          />
          // React.createElement(component, props)
        ) : (
          React.createElement(component, props)
        )
      )
    );
return <Route {...rest} render={routeComponent} />;
};

export default RegisteredRoute;
