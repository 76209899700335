import { ApiService, HttpResponse } from "./ApiService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "./SettingsService";
import { ICreateSecuredOrderPaymentRequest } from "../interfaces/payments/ICreateSecuredOrderPaymentRequest";
import { ISecuredOrderPaymentRequest } from "../interfaces/payments/ISecuredOrderPaymentRequest";
import { IPayZoneInitialResponse } from "../interfaces/payments/IPayzoneInitialResponse";
import { IPaymentResult } from "../interfaces/payments/IPaymentResult";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export const SecurePaymentRequest = async (
  parameters: ICreateSecuredOrderPaymentRequest
): Promise<HttpResponse<ISecuredOrderPaymentRequest>> => {
  return await ApiService().Post<ISecuredOrderPaymentRequest>(ClientSettings.PaymentsPrivateApiUrl + "/payment/request", parameters);
};

export const ProcessPayZoneResponse = async (parameters: IPayZoneInitialResponse): Promise<HttpResponse<IPaymentResult>> => {
  return await ApiService().Post<any>(ClientSettings.PaymentsPrivateApiUrl + "/payment/response", parameters);
};
