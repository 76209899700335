import * as React from "react";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

import { Button, MenuItem, Menu } from "@material-ui/core";
import { Link, Route } from "react-router-dom";

import { INavigationGroup } from "../../interfaces/navigation/INavigationGroup";
import { INavigationGroupLink } from "../../interfaces/navigation/INavigationGroupLinks";

import * as Styles from "./styles/DesktopMenuStyles";

interface DesktopMenuProps {
  navigationGroup: INavigationGroup;
  lightTheme: boolean;
}

export const DesktopMenu: React.FC<DesktopMenuProps> = ({ navigationGroup, lightTheme }) => {
  const classes = Styles.DesktopMenuStyles();

  const [open, setOpen] = React.useState(false);
  const prevOpen = React.useRef(open);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const getMenuTheme = () => {
    return lightTheme ? classes.buttonLight : classes.buttonDark;
  };

  const SingleOptionMenu = () => {
    return (
      <Button component={Link} to={navigationGroup.navigationGroupLinks[0].path} className={getMenuTheme()}>
        {navigationGroup.navigationGroupLinks[0].title}
      </Button>
    );
  };

  const goTo = (history: any, navigationGroupLink: INavigationGroupLink) => {
    if (window.location.pathname.startsWith(`${navigationGroupLink.path}`)) {
      history.replace({
        pathname: navigationGroupLink.path,
        search: navigationGroupLink.search,
      });
      window.location.reload();
    } else {
      history.push({
        pathname: navigationGroupLink.path,
        search: navigationGroupLink.search,
      });
    }
  };

  const MultiOptionMenu = () => {
    return (
      <div>
        <Button ref={anchorRef} onClick={handleToggle} className={getMenuTheme()}>
          {navigationGroup.navigationGroupName}
          <ExpandLess className={open ? classes.notOpen : classes.isOpen} />
          <ExpandMore className={!open ? classes.notOpen : classes.isOpen} />
        </Button>
        <Menu
          anchorEl={anchorRef.current}
          keepMounted
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          {navigationGroup.navigationGroupLinks.map((navigationGroupLink: INavigationGroupLink, index: number) => {
            return (
              <Route
                key={index}
                render={({ history }) => (
                  <MenuItem
                    onClick={() => {
                      goTo(history, navigationGroupLink);
                      handleClose();
                    }}
                  >
                    {navigationGroupLink.title}
                  </MenuItem>
                )}
              />
            );
          })}
        </Menu>
      </div>
    );
  };

  const MenuComponent = () => {
    if (navigationGroup.navigationGroupLinks.length === 1) {
      return SingleOptionMenu();
    } else {
      return MultiOptionMenu();
    }
  };

  return MenuComponent();
};
