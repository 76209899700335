import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../view-lot/styles/slick-carousel/_hacks.css";
import { EditorPicksTile } from "./EditorPicksTile";

import { Typography, Hidden } from "@material-ui/core";
import * as Styles from "./styles/EditorsPicksStyles";
import * as EditorsPick1 from "../../assets/EditorsPick1.png";
import * as EditorsPick2 from "../../assets/EditorsPick2.png";
import * as EditorsPick3 from "../../assets/EditorsPick3.png";

import * as EditorsPick5 from "../../assets/EditorsPick2.png";
import * as EditorsPick6 from "../../assets/EditorsPick3.png";
import { NextArrow, PrevArrow } from "../icons/EditorPicksArrows";
import { EditorPickSearch, OrderBy } from "../../interfaces/search/ISearchOptions";

export const EditorsPicks: React.FC = () => {
  const classes = Styles.EditorPicksStyles();

  const tileData = [
    {
      type: "Most Bids",
      title: "Auctions with Most Bids",
      description: "Looking for auctions with the most bids?",
      backgroundImage: EditorsPick1,
      overlayStyle: classes.overlayOrange,
      searchState: `?editorPickSearch=${EditorPickSearch.MostBids}`,
    },
    {
      type: "Popular",
      title: "Most Viewed Auctions",
      description: "Show all auctions with the most views?",
      backgroundImage: EditorsPick2,
      overlayStyle: classes.overlayOrange,
      searchState: `?editorPickSearch=${EditorPickSearch.MostViewed}`,
    },
    // {
    //   type: "High Price",
    //   title: "Highest Value Auctions",
    //   description: "Auctions with a high starting price",
    //   backgroundImage: EditorsPick6,
    //   overlayStyle: classes.overlayOrange,
    //   searchState: `?orderBy=${OrderBy.PriceDesc}`,
    // },
    {
      type: "HGV & Plant",
      title: "HGV & Plant Auctions",
      description: "Desc?",
      backgroundImage: EditorsPick3,
      overlayStyle: classes.overlayOrange,
      searchState: `?editorPickSearch=${EditorPickSearch.HGVAndPlant}`,
    },
    {
      type: "New",
      title: `${new Date().getFullYear()} Registration Plates`,
      description: "Looking for the latest models to resell?",
      backgroundImage: EditorsPick3,
      overlayStyle: classes.overlayOrange,
      searchState: `?editorPickSearch=${EditorPickSearch.RegisteredInLastYear}`,
    },
    {
      type: "Watched",
      title: "Most Watched Auctions",
      description: "Most watched auctions",
      backgroundImage: EditorsPick5,
      overlayStyle: classes.overlayOrange,
      searchState: `?editorPickSearch=${EditorPickSearch.MostWatched}`,
    },
  ];

  const lgSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const mdSettings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const smSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
  };

  return (
    <div className={classes.container}>
      <Typography gutterBottom variant="h4" align="left">
        Editor's picks
      </Typography>
      <Hidden mdDown>
        <Slider {...lgSettings}>
          {tileData.map((tile, index: number) => (
            <EditorPicksTile tile={tile} key={index} />
          ))}
        </Slider>
      </Hidden>
      <Hidden only={["xs", "sm", "lg", "xl"]}>
        <Slider {...mdSettings}>
          {tileData.map((tile, index: number) => (
            <EditorPicksTile tile={tile} key={index} />
          ))}
        </Slider>
      </Hidden>

      <Hidden mdUp>
        <Slider {...smSettings}>
          {tileData.map((tile, index: number) => (
            <EditorPicksTile tile={tile} key={index} />
          ))}
        </Slider>
      </Hidden>
    </div>
  );
};
