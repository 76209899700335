import React, { ReactNode } from "react";
import { AddToWatchlist, RemoveFromWatchlist } from "../services/WatchlistService";
import { IBidderState, BidderState } from "../interfaces/bidders/IBidderState";
import { IWatchlistItem } from "../interfaces/watchlist/IWatchlistItem";
import { AccountType, IBidder } from "../interfaces/bidders/IBidder";
import * as BidderService from "../services/BidderService";
import { isSubscribed } from "./isSubscribed";
import { IMsalContext } from "../authentication/MsalContext";

export interface IInitialState {
  bidderState: IBidderState;
}

export const InitialState: IInitialState = {
  bidderState: BidderState,
};

type Props = {
  msalContext: IMsalContext;
  children: ReactNode;
};

export class AppContextProvider extends React.Component<Props> {
  state: IInitialState = InitialState;
  msalContext: IMsalContext;

  loadingBidder: boolean = false;

  componentDidUpdate() {
    this.getBidder();
  }

  constructor(props: Props) {
    super(props);
    this.msalContext = props.msalContext;
    this.state = {
      ...this.state,
      bidderState: {
        bidder: {} as IBidder,
        bidderLoaded: false,
        isRegistered: undefined,
        currentTermsAccepted: undefined,
        termsEnabled: undefined,
        isSubscribed: undefined,
        archived: false,
        getBidder: this.getBidder,
        checkWatchlist: this.checkWatchlist,
        addToWatchlist: this.addToWatchlist,
        removeFromWatchlist: this.removeFromWatchlist,
        bidderRegistered: this.bidderRegistered,
        bidderAccountTypeSelect: this.bidderAccountTypeSelect,
        verificationDocumentsSubmitted: this.verificationDocumentsSubmitted,
      },
    };
  }

  private getBidder = (ignoreLoading: boolean = false) => {
    if (this.loadingBidder && !ignoreLoading) return;
    this.loadingBidder = true;

    if (this.msalContext.instance.getAllAccounts().length === 0) {
      this.loadingBidder = false;
      return;
    }

    BidderService.GetBidder().then(result => {
      if (result.parsedBody !== undefined) {
        this.setState({
          ...this.state,
          bidderState: {
            ...this.state.bidderState,
            bidder: result.parsedBody,
            bidderLoaded: true,
            isRegistered: true,
            currentTermsAccepted: result.parsedBody.currentTermsAccepted,
            termsEnabled: result.parsedBody.termsEnabled,
            isSubscribed: isSubscribed(result.parsedBody.subscriptionEndDate),
            archived: result.parsedBody.archived
          },
        });
      } else {
        this.setState({
          ...this.state,
          bidderState: {
            ...this.state.bidderState,
            bidderLoaded: true,
            isRegistered: false,
            isSubscribed: false,
            archived: false
          },
        });
      }
    });
  };

  private verificationDocumentsSubmitted = () => {
    this.setState({
      ...this.state,
      bidderState: {
        ...this.state.bidderState,
      },
    });
  };

  private bidderRegistered = (registeredBidder: IBidder) => {
    this.setState({
      ...this.state,
      bidderState: {
        ...this.state.bidderState,
        bidder: registeredBidder,
        isRegistered: true,
        bidderLoaded: true,
      },
    });
  };

  private bidderAccountTypeSelect = (accountType: AccountType) => {
    this.setState({
      ...this.state,
      bidderState: {
        ...this.state.bidderState,
        bidder: {
          ...this.state.bidderState.bidder,
          accountType: accountType,
        },
      },
    });
  };

  private checkWatchlist = (watchlistItem: IWatchlistItem) => {
    if (this.state.bidderState.bidder.watchlist) {
      var watchlistFilter = this.state.bidderState.bidder.watchlist.filter(value => {
        return value === watchlistItem.auctionId;
      });
      return watchlistFilter.length > 0;
    } else {
      return false;
    }
  };

  private addToWatchlist = async (auctionId: string, persistToAPI: boolean = true) => {
    if (persistToAPI) {
      await AddToWatchlist(auctionId);
    }

    this.setState({
      ...this.state,
      bidderState: {
        ...this.state.bidderState,
        bidder: {
          ...this.state.bidderState.bidder,
          watchlist: [...this.state.bidderState.bidder.watchlist, auctionId],
        },
      },
    });
  };

  private removeFromWatchlist = async (auctionId: string) => {
    await RemoveFromWatchlist(auctionId);
    this.setState({
      ...this.state,
      bidderState: {
        ...this.state.bidderState,
        bidder: {
          ...this.state.bidderState.bidder,
          watchlist: this.state.bidderState.bidder.watchlist.filter(x => x !== `${auctionId}`),
        },
      },
    });
  };

  render() {
    return <AppContext.Provider value={this.state}>{this.props.children}</AppContext.Provider>;
  }
}

export const AppContext = React.createContext(InitialState);
