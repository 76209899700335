import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { useReadonlyData } from "./useVersionedData";
import { IAddress } from "../interfaces/IAddress";
import { IOpeningDays } from "../interfaces/IOpeningDays";

const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface SalesDocument {
  id: string;
  lotImageUrl: string;
  auctionEnd: string;
  title: string;
  bidPlacedTime: string;
  description: string;
  registrationNumber: string;
  vatIncluded: boolean;
  model: string;
  colour: string;
  year: number;
  damageCategory: string;
  hasKeys: boolean | null;
  doesStart: boolean | null;
  doesDrive: boolean | null;
  referenceNumber: string;
  make: string;
  trimLevel: string;
  fuelType: string;
  mileage: number;
  hasLogBook: boolean | null;
  engineSize: string;
  siteName: string;
  collectionLocation: IAddress;
  collectionDays: IOpeningDays;
  siteEmail: string;
  sitePhoneNumber: string;
  memberName: string;
  memberPhoneNumber: string;
  memberEmail: string;
  bank: string;
  accountName: string;
  sortCode: string;
  accountNumber: string;
  swift: string;
  iban: string;
  cashRate: number;
  bankTransferRate: number;
  debitCardRate: number;
  saleVatRate: string;
  memberImageUrl: string;
  mainSiteName: string;
  mainSiteAddress: IAddress;
  mainSiteOpeningDays: IOpeningDays;
  bidderId: string;
  bidderName: string;
  bidderAddress: IAddress;
  bidderEmail: string;
  bidderNumber: string;
  bidAmount: string;
  auctionFee: string;
  adminFee: string;
  bidVat: string;
  auctionFeeVat: string;
  adminFeeVat: string;
  total: string;
  totalVat: string;
  totalWithVat: string;
  totalWithVatAndDebitCardFee: string;
  totalWithVatAndCashFee: string;
  totalWithVatAndBankTransferFee: string;
  bankTransferNotes: string;
  debitCardNotes: string;
  cashNotes: string;
  paymentNote: string;
  paymentDue: string;
  numberOfBids: number;
  collectionCode: string;
}

export const useSalesDocumentInfo = (auctionId: string, onError: (httpStatusCode: number, resultBody?: string) => void) =>
  useReadonlyData<SalesDocument>(`${ClientSettings.AuctionPrivateApiUrl}/auctions/ended/id/${auctionId}/salesdocumentinfo`, onError);
