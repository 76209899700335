import React from "react";
import { ILatestBid } from "../../../interfaces/auctions/ILatestBid";

interface ReserveMetProps {
  latestBid: ILatestBid | null;
}

const ReserveMet: React.FC<ReserveMetProps> = ({ latestBid }) =>
  latestBid !== null ? <>{latestBid.auctionInfo.isReserveMet ? "Reserve Met" : "Reserve Not Met"}</> : <>&nbsp;</>;

export default ReserveMet;
