import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SecureArea } from "../authentication/SecureArea";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../services/TelemetryService";
import { ReceiptContainer } from "../receipt-pdf/ReceiptContainer";

type TParams = { id: string, status: string | undefined };

function PaymentReceipt({ match }: RouteComponentProps<TParams>) {
  return (
    <SecureArea>
      <p>Please reload to see your receipt</p>
      <ReceiptContainer orderId={match.params.id} orderStatus={match.params.status} />;
    </SecureArea>
  );
}

export default withAITracking(reactPlugin, PaymentReceipt, "PaymentReceipt");
