import { AppContext } from "../contexts/AppContext";
import { IMsalContext } from "../authentication/MsalContext";
import { useMsal } from "../authentication/MsalProvider";
import React from "react";
import { BidderStatus } from "../interfaces/bidders/IBidder";

export const useIsActiveUser = () => {
  const msal: IMsalContext = useMsal();
  const context = React.useContext(AppContext);

  return (
    msal.accounts.length > 0 &&
    (context.bidderState.bidder?.bidderStatus === BidderStatus.ActiveFull || context.bidderState.bidder?.bidderStatus === BidderStatus.ActiveFree)
  );
};

export const useIsActiveOrMember = () => {
  const msal: IMsalContext = useMsal();
  const context = React.useContext(AppContext);

  return (
    msal.accounts.length > 0 &&
    (context.bidderState.bidder?.bidderStatus === BidderStatus.ActiveFull ||
      context.bidderState.bidder?.bidderStatus === BidderStatus.ActiveFree ||
      context.bidderState.bidder?.bidderStatus === BidderStatus.Member)
  );
};

export const useIsLoggedIn = () => {
  const msal: IMsalContext = useMsal();
  return typeof msal.accounts !== "undefined" && msal.accounts.length > 0;
};
