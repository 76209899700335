import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Typography, Box, Button, Table, TableHead, TableCell, TableRow, TableBody } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { AppContext } from "../../contexts/AppContext";
import { LoadingComponent } from "../loading/LoadingComponent";
import { isSubscribed } from "../../contexts/isSubscribed";
import { DateToDateString } from "../../helpers/dateHelper";
import * as Styles from "./styles/RenewalStyles";
import { canRenew } from "../../contexts/canRenew";
import { canRenewOn } from "../../contexts/canRenewOn";
import { BidderStatus } from "../../interfaces/bidders/IBidder";
import { useSnackbar } from "notistack";
import * as StripeProductService from "../../services/StripeProductService";
import * as StripeService from "../../services/StripeService";
import { IStripeProduct } from "../../interfaces/payments/IStripeProduct";
import { currencyFormat } from "../../helpers/text-format/TextFormat";
import { IStripePaymentMethodResponse } from "../../interfaces/payments/IStripePaymentMethodResponse";
import { IStripePaymentIntentResponse } from "../../interfaces/payments/IStripePaymentIntentResponse";
import { Elements } from "@stripe/react-stripe-js";
import { StripePaymentForm } from "../payment/StripePaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { GetClientSettings } from "../../services/SettingsService";
import { StripePaymentProduct } from "../payment/StripePaymentProduct";
import { BoxProps } from '@material-ui/core/Box';

const ClientSettings: ISalvageClientSettings = GetClientSettings();
export function RenewalComponent() {
  const { enqueueSnackbar } = useSnackbar();
  const stripePromise = loadStripe(ClientSettings.StripePublicKey);
  const classes = Styles.RenewalStyles();
  let context = React.useContext(AppContext);
  const [loadingBidder, setLoadingBidder] = React.useState(true);
  const [canRenewSubscription, setCanRenewSubscription] = React.useState(false);
  const [status, setStatus] = React.useState<BidderStatus>(BidderStatus.Unknown);
  const [stripeProducts, setStripeProducts] = React.useState<IStripeProduct[]>();
  const [selectedStripeProduct, setSelectedStripeProduct] = React.useState<IStripeProduct>();
  const [paymentMethods, setPaymentMethods] = React.useState<IStripePaymentMethodResponse[]>();
  const [selectedPaymentMethod, setPaymentMethod] = React.useState<IStripePaymentMethodResponse>();
  const [paymentIntent, setPaymentIntent] = React.useState<string>('');
  const [paymentIntentSecret, setPaymentIntentSecret] = React.useState<string>('');
  const [saveCard, setSaveCard] = React.useState<boolean>(false);

  const options = {
    clientSecret: paymentIntentSecret,
  };
  

  React.useEffect(() => {
    setStatus(context.bidderState.bidder.bidderStatus);
  }, [context.bidderState.bidder.bidderStatus]);

  React.useEffect(() => {
    if (context.bidderState.bidderLoaded) {
      loadStripeProducts();
      loadPaymentMethods();
    }
  }, [context.bidderState.bidder, context.bidderState.bidderLoaded]);

  React.useEffect(() => {
    setCanRenewSubscription(
      (context.bidderState.bidder.bidderStatus === BidderStatus.ActiveFull && canRenew(context.bidderState.bidder.subscriptionEndDate)) ||
      context.bidderState.bidder.bidderStatus === BidderStatus.ActiveFree ||
      context.bidderState.bidder.bidderStatus === BidderStatus.SubscriptionExpired
    );
  }, [context.bidderState.bidder.bidderStatus, context.bidderState.bidder.subscriptionEndDate]);

  const loadStripeProducts = () => {
    setSelectedStripeProduct(undefined);
    setLoadingBidder(true);
    StripeProductService.GetActive()
      .then(success => {
        setStripeProducts(success.parsedBody);
        setLoadingBidder(false);
      })
      .catch((error: Response) => {
        enqueueSnackbar("We have been unable to load subscription products. Please close your browser and try again", {
          variant: "error",
        });
        setLoadingBidder(false);
      })
  };

  const loadPaymentMethods = () => {
    setPaymentMethod(undefined);
    StripeService.GetPaymentMethods()
      .then(success => {
        setPaymentMethods(success.parsedBody);
        if(success.parsedBody?.length == 0){
          setPaymentMethod({ id: 'new', card: {}, new: true });
        }
      })
      .catch((error: Response) => {
        enqueueSnackbar("We have been unable to load payment methods. Please close your browser and try again", {
          variant: "error",
        });
        setLoadingBidder(false);
      })
  };

  const stripeProductChanged = (newValue?: IStripeProduct, saveCardVal: boolean | undefined = undefined) => {
    setLoadingBidder(true);
    setSelectedStripeProduct(newValue);
    createPaymentIntent(newValue, selectedPaymentMethod, saveCardVal);
  };

  const paymentMethodChanged = (newValue?: IStripePaymentMethodResponse, saveCardVal: boolean | undefined = undefined) => {
    setLoadingBidder(true);
    setPaymentMethod(newValue);
    createPaymentIntent(selectedStripeProduct, newValue, saveCardVal);
  };

  const createPaymentIntent = (stripeProduct?: IStripeProduct, paymentMethod?: IStripePaymentMethodResponse, saveCardVal: boolean | undefined = undefined) => {
    if (stripeProduct != undefined) {
      let cardValToUse = saveCardVal != undefined ? saveCardVal : saveCard;

      StripeService.CreatePaymentIntent(stripeProduct.id, cardValToUse, paymentMethod?.id)
        .then(success => {
          let intent = success.parsedBody as IStripePaymentIntentResponse;
          setPaymentIntent(intent.id);
          setPaymentIntentSecret(intent.client_secret);
          setLoadingBidder(false);
        })
        .catch((error: Response) => {
          enqueueSnackbar("We have been unable to create this payment. Please close your browser and try again", {
            variant: "error",
          });
          setLoadingBidder(false);
        })
    } else {
      setLoadingBidder(false);
    }
  };

  const setSaveCardAndReload = (val: boolean) => {
    setSaveCard(val);

    if (selectedStripeProduct != null) {
      stripeProductChanged(selectedStripeProduct, val);
    }
  }

  return (
    <Box display="flex" justifyContent="center">
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" component="h1"  style={{ fontWeight: "bold"}} gutterBottom className={classes.headerPadding}>
          Subscription
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {loadingBidder && <LoadingComponent label="Loading renewal form" />}
        {!loadingBidder && (
          <>
            {status === BidderStatus.ActiveFree && (
              <div className={classes.subscriptionContainer}>
                <Typography>Your are currently on a free account. To access all auctions, please select one of the options below</Typography>
              </div>
            )}

            {status === BidderStatus.ActiveFull && !canRenewSubscription && (
              <div className={classes.subscriptionContainer} style={{ backgroundColor : "#fefefe", border : "1px solid #cccccc", borderRadius: "10px", padding : "24px", marginBottom : "25px " }}>
                <CheckCircleIcon className={classes.greenTick} />
                <Typography>
                  Your current subscription will expire on {DateToDateString(new Date(context.bidderState.bidder.subscriptionEndDate))}, you can renew
                  on {DateToDateString(canRenewOn(context.bidderState.bidder.subscriptionEndDate))}.
                </Typography>
              </div>
            )}

            {canRenewSubscription && (
              <>
                {(status === BidderStatus.ActiveFull || status === BidderStatus.SubscriptionExpired) && (
                  <div style={{ backgroundColor : "#fefefe", border : "1px solid #cccccc", borderRadius: "10px", padding : "24px", marginBottom : "25px " }} className={`${classes.subscriptionContainer} ${classes.headerPadding}`}>
                    {isSubscribed(context.bidderState.bidder.subscriptionEndDate) && <ReportProblemIcon className={classes.orangeExclamation} />}
                    {!isSubscribed(context.bidderState.bidder.subscriptionEndDate) && <CancelIcon className={classes.redCross} />}
                    {context.bidderState.bidder.subscriptionEndDate != null ?
                      <Typography>
                        Your subscription {isSubscribed(context.bidderState.bidder.subscriptionEndDate) ? "will expire on" : "expired on"}{" "}
                        {DateToDateString(new Date(context.bidderState.bidder.subscriptionEndDate))}.
                      </Typography>
                      :
                      <Typography>
                        You do not currently have a subscription.
                      </Typography>
                    }
                  </div>
                )}

                <Typography>We (SalvageMarket.co.uk) use the data we collect to create, validate and administer your account, to help us to provide an effective and efficient salvage auction platform and to keep you up to date with any changes. We also use data for training, quality control, research and statistical analysis. If you would like more information on how we use your data and on what your rights are, please have a read of our full privacy policy.</Typography>
                <br></br>
                <Grid container style={{marginTop: "20px",}}>
                  {stripeProducts?.map((stripeProduct, index) => (
                    <StripePaymentProduct key={`stripe-product${index}`} stripeProduct={stripeProduct} selectedStripeProduct={selectedStripeProduct} stripeProductChanged={stripeProductChanged}  />
                  ))}
                </Grid>
                <br></br>

                <Typography style={{ fontWeight: "bold", textAlign: "center",  }}  component="h3" variant="h6">
                  Make your payment
                </Typography>
                <Typography style={{ maxWidth: "890px", margin: "0 auto", textAlign: "center",  }}  >We (SalvageMarket.co.uk) use the data we collect to create, validate and administer your account, to help us to provide an effective and efficient salvage auction platform.</Typography>
                <br></br>

                <Typography  style={{ fontWeight: "bold", marginBottom: "15px", textAlign: "center",  }}   component="h3" variant="h6">
                  Your Cards
                </Typography>
                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center" style={{ overflowX: 'auto' }}>

                <Table style={{ boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.05)", maxWidth: "890px", margin: "0 auto", textAlign: "center",  }}  aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Card Number</TableCell>
                      <TableCell>Card Type</TableCell>
                      <TableCell>Expiry Date</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentMethods?.map((paymentMethod, index) => (                      
                      <TableRow key={`stripe-payment-method${index}`}>
                        <TableCell>************{paymentMethod?.card.last4}</TableCell>
                        <TableCell>{paymentMethod?.card.brand}</TableCell>
                        <TableCell>{paymentMethod?.card.exp_month}/{paymentMethod?.card.exp_year}</TableCell>
                        <TableCell>
                          {selectedPaymentMethod?.id == paymentMethod.id ?
                            <Button type="button" style={{backgroundColor: "#FFFFFF"}} color="secondary" variant="contained">Card Selected</Button>
                            :
                            <Button type="button" color="primary" variant="contained" onClick={() => paymentMethodChanged(paymentMethod)}>Use Card For Payment</Button>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key={`stripe-payment-method99`}>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell><Button type="button" color={selectedPaymentMethod?.id == 'new' ? 'secondary' : 'primary'} variant="contained" onClick={() => paymentMethodChanged({ id: 'new', card: {}, new: true })}>Add New Card</Button></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </Box>
                <Grid container>
                  <Grid item xs={12}>
                    {paymentIntentSecret != undefined && paymentIntentSecret != '' && selectedPaymentMethod != undefined &&
                      <Elements options={options} stripe={stripePromise} >
                        <StripePaymentForm paymentIntent={paymentIntent} paymentIntentSecret={paymentIntentSecret} redirectUrl={'/RenewalProcess'} paymentMethod={selectedPaymentMethod?.id} saveCard={saveCard} updateSaveCard={(e) => { setSaveCardAndReload(e); }} refresh={() => { loadStripeProducts(); loadPaymentMethods(); }} />
                      </Elements>
                    }
                  </Grid>
                </Grid>
              </>
            )}

            {status === BidderStatus.Suspended && (
              <Grid container>
                <Grid item xs={12}>
                  <Box component="div" className={`${classes.subscriptionContainer} ${classes.headerPadding}`}>
                    <CancelIcon className={classes.redCross} />
                    <Typography>
                      We are sorry to inform you that your account has been suspended. Please visit the My Account page for details why your account
                      has been suspended.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}

            {/* Registration Incomplete */}
            {status !== BidderStatus.ActiveFull &&
              status !== BidderStatus.ActiveFree &&
              status !== BidderStatus.SubscriptionExpired &&
              status !== BidderStatus.Suspended &&
              status !== BidderStatus.Member && (
                <Grid container>
                  <Grid item xs={12}>
                    <Box component="div" className={`${classes.subscriptionContainer} ${classes.headerPadding}`}>
                      <CancelIcon className={classes.redCross} />
                      <Typography>Please complete your registration. Once you have completed registration you will be able to pay for your subscription.</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" component={RouterLink} to={"/Register"}>
                      Complete Registration
                    </Button>
                  </Grid>
                </Grid>
              )}
          </>
        )}
      </Grid>
    </Grid>
    </Box>
  );
}
