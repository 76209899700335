import React, { ReactNode } from "react";
import { LoadingComponent } from "../components/loading/LoadingComponent";
import { DefaultMemberThemeDto, getCachedSiteTheme, MemberThemeDto, updateCachedTheme } from "../services/MemberService";

export interface IInitialState {
  theme: MemberThemeDto;
}

export const InitialState: IInitialState = {
  theme: DefaultMemberThemeDto,
};

type Props = {
  children: ReactNode;
};
export const StyleContextProvider = ({ children }) => {
  const cachedSite = getCachedSiteTheme();
  const [theme, setTheme] = React.useState<MemberThemeDto>(cachedSite ?? DefaultMemberThemeDto);
  const [themeLoaded, setThemeLoaded] = React.useState(false);

  React.useEffect(() => {
    if (themeLoaded) {
      // If the theme has already been updated then there is no need to update it again
      return;
    }
    if (!cachedSite) {
      updateCachedTheme().then(newTheme => {
        if (newTheme) {
          setTheme(newTheme);
        } else {
          // The theme didn't update - display an error message
          setTheme(DefaultMemberThemeDto);
        }

        setThemeLoaded(true);
      });
    } else {
      setThemeLoaded(true);
      var cachedSiteString = JSON.stringify(cachedSite);
      updateCachedTheme().then(newTheme => {
        if (newTheme) {
          // See if the new theme differs from the cached one.
          var newSiteString = JSON.stringify(newTheme);
          if (cachedSiteString !== newSiteString) {
            setTheme(newTheme);
          }
        }
      });
    }
  }, [theme, themeLoaded, cachedSite]);

  if (!cachedSite || !themeLoaded) {
    return <LoadingComponent label="Please Wait, Loading Details..."></LoadingComponent>;
  }
  return <StyleContext.Provider value={{ theme }}>{children}</StyleContext.Provider>;
};

export const StyleContext = React.createContext(InitialState);
