import React from "react";
import * as Styles from "./styles/HomePageIconStyles";

const AddToWatchlistIcon: React.FC = () => {
  const classes = Styles.HomePageIconStyles();
  return (
    <div className={classes.add} title="Add to watchlist">
      <div className="btb bt-eye"></div>
    </div>
  );
};

const RemoveFromWatchlistIcon: React.FC = () => {
  const classes = Styles.HomePageIconStyles();
  return (
    <div className={classes.remove} title="Remove from watchlist">
      <div className="btb bt-eye"></div>
    </div>
  );
};

export { AddToWatchlistIcon, RemoveFromWatchlistIcon };
