import React from "react";
import { useSnackbar } from "notistack";
import { Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, Hidden, TableBody, FormControl, TextField, Button, Grid, TablePagination } from "@material-ui/core";
import { LoadingComponent } from "../loading/LoadingComponent";
import { UseDefaultAuctionsWonRequest, useAuctionsWon } from "../../services/AuctionService";
import { WinHistoryRow } from "./WinHistoryRow";
import * as Styles from "./styles/WinHistoryStyles";

export const WinHistoryComponent: React.FC = () => {
  const classes = Styles.WinHistoryStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const [request, setRequest, apiPostAsync, winHistory, loadingWinHistory] = useAuctionsWon(pageNumber, pageSize, statusCode => {
    if (statusCode !== 401) {
      enqueueSnackbar("There was a problem loading your won auctions, please try again.", { variant: "error" });
    }
  });

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageNumber(newPage);
  };

  React.useEffect(() => {
    setRequest(UseDefaultAuctionsWonRequest());
    apiPostAsync(UseDefaultAuctionsWonRequest());
  }, []);
  
  React.useEffect(() => {
    if (request != null && pageNumber !== null && pageSize !== null) {
      apiPostAsync();
    }
  }, [pageNumber, pageSize]);

  const changeHandler = (event: any, property: string) => {
    if (request) {
      if (event.target.value === "All") {
        setRequest({ ...request, [property]: null });
      } else {
        setRequest({ ...request, [property]: event.target.value });
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      apiPostAsync();
    }
  };

  const search = () => {
    setPageNumber(0);
    apiPostAsync();
  };

  const paginationComponent = (
    <TablePagination
      component="div"
      count={winHistory?.count ? winHistory?.count : 0}
      page={pageNumber}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[
        { value: 10, label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" },
      ]}
      labelRowsPerPage="Rows"
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      nextIconButtonProps={{ style: { padding: 0 } }}
      backIconButtonProps={{ style: { padding: 0 } }}
    />
  );

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        Auctions won
      </Typography>
      <Typography variant="subtitle1" component="h2" gutterBottom className={classes.headerPadding}>
        This page can be used to view your all auctions you have won.
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="outlined"
            id="search"
            label="Search"
            disabled={loadingWinHistory}
            placeholder="Search auctions won.."
            type="text"
            margin="dense"
            onChange={value => {
              changeHandler(value, 'search')
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid item xs={3} lg={1}>
          <Button variant="contained" color="primary" disabled={loadingWinHistory} onClick={search} className={classes.searchButton} fullWidth>
            Search
          </Button>
        </Grid>

        {loadingWinHistory && winHistory === null && <LoadingComponent label="Loading your auctions" />}
        {!loadingWinHistory && winHistory && (
          <>
            <Grid item xs={12} lg={7}>
              {paginationComponent}
            </Grid>

            <Grid item xs={12} lg={12}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell>Title</TableCell>
                      <Hidden smDown>
                        <TableCell>Auction End</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Amount</TableCell>
                      </Hidden>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {winHistory?.count === 0 && (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography variant="subtitle1" component="h2">
                            You have not won any auctions.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {winHistory?.results.map((won, index) => (
                      <TableRow key={index}>
                        <WinHistoryRow auctionListing={won} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} lg={12}>
              {paginationComponent}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
