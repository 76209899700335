import React from "react";
import * as Styles from "./styles/UpdateNotificationStyles";
import { makeStyles } from '@material-ui/core/styles';
import { LoadingComponent } from "../loading/LoadingComponent";
import { useVersionedData, useReadonlyData } from "../../services/useVersionedData";
import { GetClientSettings } from "../../services/SettingsService";
import { ISalvageClientSettings } from "../../interfaces/ISalvageClientSettings";
import { Button, Typography, Box, Grid, Tooltip, Accordion, AccordionSummary, AccordionDetails, Dialog, DialogTitle, DialogContent, DialogActions, Select, InputLabel, MenuItem } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SmsIcon from "@material-ui/icons/Sms";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailIcon from "@material-ui/icons/Email";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScheduleIcon from '@material-ui/icons/Schedule';

// Source for all available notification rules
import {
  NotificationPreferenceType,
  INotificationOptionSet,
  INotificationGroup,
  getNotificationOptionName,
  NotificationOptionAvailability,
  NotificationPreferenceFrequency,
  allNotificationPreferenceFrequency,
  NotificationPreferenceFrequencyDescription
} from "./helpers/RuleIdentifiers";

// Data structure to manipulate
import { INotificationFrequency, INotificationPreferences } from "../../interfaces/bidders/INotificationPreferences";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  accordionFlex: {
    flexDirection: 'column',
    gap: '10px',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      gap: '30px',
    }
  },
  mobileStyle: {
    [theme.breakpoints.down('md')]: {
      display: "flex",
      flexDirection: "column"
    },
  },
  dialogPaper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '623px',
    },
  },
}));

const ClientSettings: ISalvageClientSettings = GetClientSettings();

interface CheckBoxesForRuleProps {
  definition: INotificationOptionSet;
  frequency: NotificationPreferenceFrequency;
  onChange(ruleIdentifier: string, destination: NotificationPreferenceType, checked: boolean): void;
  onFrequencyChange(ruleIdentifier: string, frequency: NotificationPreferenceFrequency): void;
  isSelected(ruleIdentifier: string, destination: NotificationPreferenceType): boolean;
}

const CheckBoxesForRules: React.FC<CheckBoxesForRuleProps> = ({ definition, frequency, onChange, onFrequencyChange, isSelected }) => {
  const [togglePreferenceType, setTogglePreferenceType] = React.useState<NotificationPreferenceType | null>(null);
  const classes = Styles.UpdateNotificationStyles();
  const [frequencyModal, setFrequencyModal] = React.useState(false);
  const [currentFrequency, setCurrentFrequency] = React.useState<NotificationPreferenceFrequency>(NotificationPreferenceFrequency.None);
  const styles = useStyles();
  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newTogglePreference: NotificationPreferenceType | null) => {
    setTogglePreferenceType(newTogglePreference);

    var sms = newTogglePreference ? newTogglePreference === NotificationPreferenceType.Sms : false;
    var whatsApp = newTogglePreference ? newTogglePreference === NotificationPreferenceType.WhatsApp : false;

    if (sms) {
      onChange(definition.ruleIdentifier, NotificationPreferenceType.Sms, true);
    } else if (whatsApp) {
      onChange(definition.ruleIdentifier, NotificationPreferenceType.WhatsApp, true);
    } else {
      if (togglePreferenceType) onChange(definition.ruleIdentifier, togglePreferenceType, false);
    }
  };

  React.useEffect(() => {
    if (isSelected(definition.ruleIdentifier, NotificationPreferenceType.Sms)) {
      setTogglePreferenceType(NotificationPreferenceType.Sms);
    }
    if (isSelected(definition.ruleIdentifier, NotificationPreferenceType.WhatsApp)) {
      setTogglePreferenceType(NotificationPreferenceType.WhatsApp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [definition]);

  const frequencyChange = (value: any) => {
    setCurrentFrequency(value.target.value);
  };

  const save = () => {
    onFrequencyChange(definition.ruleIdentifier, currentFrequency);
    setFrequencyModal(false);
  }

  const emailChange = (ruleIdentifier: string, destination: NotificationPreferenceType, checked: boolean) => {
    if (!checked && definition.allowFrequencyPopup) {
      setCurrentFrequency(NotificationPreferenceFrequency.None);
      onFrequencyChange(definition.ruleIdentifier, NotificationPreferenceFrequency.None);
    }

    onChange(ruleIdentifier, destination, checked);
  }

  return (
    <>
      <Grid container spacing={3} className={styles.mobileStyle} style={{ padding: "0px" }}>
        <Grid item xs={9} className={classes.labelContainer}>
          <Typography>{getNotificationOptionName(definition.ruleIdentifier)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <ToggleButtonGroup value={togglePreferenceType} exclusive className={classes.toggleGroup} onChange={handleAlignment} size="small">
            <Tooltip title={"Email"}>
              <div>
                <ToggleButton
                  value={NotificationPreferenceType.Email}
                  onClick={() => {
                    emailChange(
                      definition.ruleIdentifier,
                      NotificationPreferenceType.Email,
                      !isSelected(definition.ruleIdentifier, NotificationPreferenceType.Email)
                    );
                  }}
                >
                  <EmailIcon color={isSelected(definition.ruleIdentifier, NotificationPreferenceType.Email) ? "primary" : undefined} />
                </ToggleButton>
              </div>
            </Tooltip>
            {definition.smsAvailability !== NotificationOptionAvailability.Unavailable && (
              <Tooltip title="Text Message">
                <ToggleButton value={NotificationPreferenceType.Sms}>
                  <SmsIcon color={togglePreferenceType === NotificationPreferenceType.Sms ? "primary" : undefined} />
                </ToggleButton>
              </Tooltip>
            )}
            {definition.whatsAppAvailability !== NotificationOptionAvailability.Unavailable && (
              <Tooltip title="WhatsApp">
                <ToggleButton value={NotificationPreferenceType.WhatsApp}>
                  <WhatsAppIcon color={togglePreferenceType === NotificationPreferenceType.WhatsApp ? "primary" : undefined} />
                </ToggleButton>
              </Tooltip>
            )}

            {definition.allowFrequencyPopup && (
              <Tooltip title="Notification Frequency">
                <ToggleButton onClick={() => { setCurrentFrequency(frequency); setFrequencyModal(true); }}>
                  <ScheduleIcon color={"primary"} />
                </ToggleButton>
              </Tooltip>
            )}


          </ToggleButtonGroup>
        </Grid>
      </Grid>

      {frequencyModal && (
        <Dialog
          onClose={() => setFrequencyModal(false)}
          open={frequencyModal}
          classes={{ paper: styles.dialogPaper }}
        >

          <DialogTitle id="customized-dialog-title">
            Notification Frequency
          </DialogTitle>
          <DialogContent dividers>


            <Grid item xs={12} sm={12}>
              <InputLabel htmlFor="currentFrequency">Notification Frequency</InputLabel>
              <Select
                required
                fullWidth
                label="Notification Frequency"
                id="currentFrequency"
                name="currentFrequency"
                value={currentFrequency}
                onChange={value => frequencyChange(value)}
              >
                {allNotificationPreferenceFrequency.map(status => (
                  <MenuItem key={status} value={status}>
                    {NotificationPreferenceFrequencyDescription(status)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>


          </DialogContent>
          <DialogActions>
            <div>
              <Button
                autoFocus
                onClick={() => { setFrequencyModal(false); }}
                variant="contained"
              >
                Cancel
              </Button>

            </div>
            <div>
              <Button
                autoFocus
                onClick={save}
                variant="contained"
                color="primary"
              >
                Select
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}


    </>
  );
};

export const NotificationPreferencesContainer: React.FC = () => {
  const classes = Styles.UpdateNotificationStyles();
  const styles = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData, isLoading, , put, refresh] = useVersionedData<INotificationPreferences[]>(
    `${ClientSettings.UsersPrivateApiUrl}/biddernotifications/preferences/`,
    () => enqueueSnackbar(`There was a problem with your preferences. Please try again later.`, { variant: "error" })
  );
  const [available, isLoadingAvailable] = useReadonlyData<INotificationGroup[]>(
    `${ClientSettings.UsersPrivateApiUrl}/biddernotifications/availableoptions/grouped`
  );

  const [preferenceData, setPreferenceData, isPreferenceLoading, , putPreference, refreshPreference] = useVersionedData<INotificationFrequency[]>(
    `${ClientSettings.UsersPrivateApiUrl}/biddernotifications/frequencies/`,
    () => enqueueSnackbar(`There was a problem with your preferences. Please try again later.`, { variant: "error" })
  );



  // Comparator for notification preferences being the same.
  const isSame = (prefA: INotificationPreferences, prefB: INotificationPreferences) => {
    return prefA.destinationType === prefB.destinationType && prefA.ruleIdentifier === prefB.ruleIdentifier;
  };

  // Function to ensure a preference exists in the data
  const ensurePref = (pref: INotificationPreferences) => {
    if (data && !data.some(existing => isSame(existing, pref))) {
      // Logic to enforce either/or on sms and whatsapp.
      const oppositeType: INotificationPreferences = {
        ruleIdentifier: pref.ruleIdentifier,
        destinationType:
          pref.destinationType === NotificationPreferenceType.Sms ? NotificationPreferenceType.WhatsApp : NotificationPreferenceType.Sms,
      };
      const dataExceptOppositeType = data.filter(existing => !isSame(existing, oppositeType));
      setData([...dataExceptOppositeType, pref]);
    }
  };

  // Function to remove a preference, if it exists in the data
  const removePref = (pref: INotificationPreferences) => {
    if (data && data.some(existing => isSame(existing, pref))) {
      setData(data.filter(existing => !isSame(existing, pref)));
    }
  };

  // Call ensurePref or removePref as appropriate on change of checkbox.
  const onChange = (ruleIdentifier: string, destination: NotificationPreferenceType, checked: boolean) => {

    if (data) {
      const pref = {
        ruleIdentifier: ruleIdentifier,
        destinationType: destination,
      };
      if (checked) {
        ensurePref(pref);
      } else {
        removePref(pref);
      }
    }
  };

  const onFrequencyChange = (ruleIdentifier: string, frequency: NotificationPreferenceFrequency) => {
    if (preferenceData == null) {
      setPreferenceData([]);
    }

    if (preferenceData != null) {
      let tmpFrequencyData = [...preferenceData];

      let idx = tmpFrequencyData.findIndex(o => o.ruleIdentifier == ruleIdentifier);

      if (idx > -1) {
        tmpFrequencyData[idx].frequency = frequency;
      } else {
        tmpFrequencyData.push({ ruleIdentifier: ruleIdentifier, frequency: frequency });
      }

      setPreferenceData(tmpFrequencyData);
    }
  };

  // Checkbox selected state is just a test of whether the data has that selection as present or not.
  const isSelected = (ruleIdentifier: string, destination: NotificationPreferenceType) =>
    data !== null && data.some(existing => isSame(existing, { ruleIdentifier: ruleIdentifier, destinationType: destination }));



  const submit = () => {
    let submit: boolean = false;

    data?.forEach(item => {
      if (item.ruleIdentifier == 'auctionwon') {
        submit = true;
      }
    });

    if (submit) {
      put().then(() => {
        putPreference();
      });
    } else {
      enqueueSnackbar("Please select a notification preference for 'Notify when you have won an auction.'", { variant: "error" })
    }


  }

  const findFrequency = (ruleIdentifier: string) => {
    if (preferenceData != null) {
      let idx = preferenceData.findIndex(o => o.ruleIdentifier == ruleIdentifier);

      if (idx != null && idx > -1) {
        return preferenceData[idx].frequency;
      }
    }

    return NotificationPreferenceFrequency.None;
  }


  return (
    <>
      {(isLoading || isPreferenceLoading || isLoadingAvailable) && <LoadingComponent label={"Loading notification preferences"} />}
      {(!(isLoading || isPreferenceLoading) && (data === null || available === null)) && <></>}
      {!(isLoading || isPreferenceLoading) && data !== null && available !== null && (
        <div className={classes.paper} style={{ padding: "0px" }}>
          <h2> Manage Notifications </h2>

          <h4 className={classes.italicText}>Make sure any changes made are saved before leaving the page.</h4>

          {available.map((availableGroup, groupIndex) => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading} style={{ fontWeight: 'bold', fontSize: "16px", color: "rgba(0, 0, 0, 0.87)" }} >{availableGroup.name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionFlex}>

                  {availableGroup.notificationOptions.map((availableOption, index) => {
                    return (
                      <CheckBoxesForRules definition={availableOption} onChange={onChange} isSelected={isSelected} key={index} frequency={findFrequency(availableOption.ruleIdentifier)} onFrequencyChange={onFrequencyChange} />
                    )
                  })}

                </AccordionDetails>
              </Accordion>


            );
          })}

          <Box display="flex" justifyContent="center" mt={2}>
            <Box mx={1}>
              <Button variant="contained" color="primary" disableElevation onClick={() => submit()}>
                Save settings
              </Button>
            </Box>
            {/* <Button variant="contained" color="primary" disableElevation onClick={() => { refresh(); refreshPreference() }}>
              Undo
            </Button> */}
          </Box>
        </div>
      )}
    </>
  );
};
