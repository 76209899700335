import React from "react";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

import * as Styles from "../styles/SellerContainerStyles";

import { Auction } from "../../../services/AuctionService";
import { Paper, Typography } from "@material-ui/core";
import { useMember } from "../../../services/MemberService";
import { useSnackbar } from "notistack";

interface SellerCard {
  auction: Auction;
}

export const SellerCard: React.FC<SellerCard> = ({ auction }) => {
  const classes = Styles.SellerContainerStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [seller, sellerLoading] = useMember(auction.memberId, () => {
    enqueueSnackbar("There was a problem with your seller request please try again.", { variant: "error" });
  });

  return (
    <>
      <Paper square className={classes.paper}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} md={4}>
            <div className={classes.imageContainer}>
              {sellerLoading ? <Skeleton width="90%" /> : <img src={auction.memberImageUrl} alt="member logo" height="45px" width="auto" />}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" align="center" className={classes.sellerName}>
              {sellerLoading ? <Skeleton width="90%" /> : seller?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" align="center" className={classes.sellerName}>
              {auction.metadata.siteName ?? auction.metadata.city}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
