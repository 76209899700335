import { GetClientSettings } from "./SettingsService";
import { ISalvageClientSettings } from "../interfaces/ISalvageClientSettings";
import { usePostedData } from "./useVersionedData";
import { useEffect } from "react";
const ClientSettings: ISalvageClientSettings = GetClientSettings();

export interface EncryptedData {
  data: string;
  iv: string;
}

export const useDataScrapingMonitorEncryptedData = (
  encryptedData: EncryptedData | undefined,
  onError: (httpStatusCode: number, resultBody?: string) => void
): [string | null, boolean] => {
  const uri = encryptedData
    ? `${ClientSettings.DataScrapingMonitorPublicApiUrl}/data-scraping-monitor/encrypted/data`
    : null;
  
  const [,,toPost, registrationNumberImageBytes, isLoading] = usePostedData<string, EncryptedData>(uri, onError);

  useEffect(() => {
    toPost(encryptedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encryptedData]);
  
  return [registrationNumberImageBytes, isLoading];
}